<template>
    <v-dialog
        v-model="activeComputed"
        width="480"
        persistent
        :fullscreen="smAndDown"
        :hide-overlay="smAndDown"
    >
        <v-card class="text-center pa-1" dark color="blue-grey darken-4">
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="red darken-1"
                    icon
                    @click="activeComputed = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-actions>
            <v-card-title class="justify-center display-1 mb-2">{{ $t('dashboard.sections.bonuses.lowBalance') }}</v-card-title>
            <v-card-subtitle>
                {{ $t('dashboard.sections.bonuses.lowBalanceTitle') }}
            </v-card-subtitle>
            <v-card-text v-if="message">
                {{ message }}
            </v-card-text>
            <v-card-text v-if="Array.isArray(rules) && rules.length">
                <v-list-item :key="i" v-for="(rule, i) in rules" class="black darken-5 mb-2 py-2">
                    <v-list-item-content v-text="rule" class="justify-center"></v-list-item-content>
                </v-list-item>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import breaks from "@/mixins/breaks";

export default {
    props: {
        'rules': {default: [], },
        'value': {default: false},
        'message': {default: ''},
    },
    mixins: [breaks],
    data() {
        return {}
    },
    computed: {
        activeComputed: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {}
}
</script>

<style scoped>

</style>
