<template>
    <v-navigation-drawer
        class="profile-drawer"
        app
        floating
        temporary
        touchless
        hide-overlay
        stateless
        disable-resize-watcher
        :right="!$vuetify.rtl"
        :width="325"
        v-model="drawerActive"
        style="z-index: 9999"
        v-click-outside="{
            handler: closeProfileDrawer,
            include: include()
        }"
        @transitionend="drawerTransitionEnd"
    >
        <toolbar-left-menu @click="closeProfileDrawer" />
    </v-navigation-drawer>
</template>

<script>
import {mapActions, mapState} from "vuex";

import ToolbarLeftMenu from "@/components/user/ToolbarLeftMenu.vue";

export default {
    name: "ProfileDrawer",
    components: {ToolbarLeftMenu},
    data: () => ({
        drawerOpened: false,
    }),
    computed: {
        ...mapState({
            drawerState: (state) => state.app.profileDrawer,
        }),
        drawerActive: {
            get() {
                return this.drawerState;
            },
            set(value) {
                this.setProfileDrawerActive(value);
            }
        },
    },
    methods: {
        ...mapActions('app', ['setProfileDrawerActive']),
        closeProfileDrawer() {
            if (this.drawerOpened) {
                this.drawerActive = false;
            }
        },
        drawerTransitionEnd() {
            this.drawerOpened = this.drawerActive;
        },
        include() {
            return document.querySelector('.toolbar-left-menu');
        }
    },
};
</script>

<style lang="scss">
.profile-drawer {
    background-color: transparent !important;
    box-shadow: none !important;
    top: 78px !important;
    height: auto !important;

    @media screen and (max-width: 600px) {
        top: 45px !important;
    }
}
</style>
