export default [
    {
        path: '/user/info',
        name: 'user-info',
        component: () => import(/* webpackChunkName: "user-info" */ '@/pages/user/InfoPage'),
        meta: {
            layout: 'dashboard',
            title: 'dashboard.sections.overview.pageTitle',
            auth: true
        }
    },
    {
        path: '/user/detail',
        name: 'user-detail',
        component: () => import(/* webpackChunkName: "user-info" */ '@/pages/user/DetailPage'),
        meta: {
            layout: 'dashboard',
            title: 'dashboard.sections.detail.pageTitle',
            auth: true
        }
    },
    {
        path: '/user/security',
        name: 'user-security',
        component: () => import(/* webpackChunkName: "user-info" */ '@/pages/user/SecurityPage'),
        meta: {
            layout: 'dashboard',
            title: 'dashboard.sections.detail.pageTitle',
            auth: true
        }
    },
    {
        path: '/user/verification',
        name: 'user-verification',
        component: () => import(/* webpackChunkName: "user-info" */ '@/pages/user/VerificationPage'),
        meta: {
            layout: 'dashboard',
            title: 'dashboard.sections.memberVerify.pageTitle',
            auth: true
        }
    },
    {
        path: '/user/score',
        name: 'user-score',
        component: () => import(/* webpackChunkName: "user-score" */ '@/pages/user/ScorePage'),
        meta: {
            layout: 'dashboard',
            title: 'dashboard.sections.guess.pageTitle',
            auth: true
        }
    },
    {
        path: '/user/my-bets',
        name: 'user-mybets',
        component: () => import(/* webpackChunkName: "user-mybets" */ '@/pages/user/MyBetsPage'),
        meta: {
            layout: 'dashboard',
            title: 'dashboard.sections.betsHistory.title',
            auth: true
        }
    },
    {
        path: '/user/messages',
        name: 'user-messages',
        component: () => import(/* webpackChunkName: "user-messages" */ '@/pages/user/MessagesPage'),
        meta: {
            layout: 'dashboard',
            title: 'dashboard.sections.messages.pageTitle',
            auth: true
        }
    },
    {
        path: '/user/documents',
        name: 'user-documents',
        component: () => import(/* webpackChunkName: "user-documents" */ '@/pages/user/DocumentsPage'),
        meta: {
            layout: 'dashboard',
            title: 'dashboard.sections.documents.pageTitle',
            auth: true
        }
    },
    // {
    //     path: '/user/deposit',
    //     name: 'user-deposit',
    //     component: () => import(/* webpackChunkName: "user-deposit" */ '@/pages/user/DepositPage'),
    //     meta: {
    //         layout: 'dashboard',
    //         title: 'dashboard.sections.deposit.pageTitle',
    //         auth: true
    //     }
    // },
    // {
    //     path: '/user/payment-templates',
    //     name: 'payment-templates',
    //     component: () => import(/* webpackChunkName: "payment-templates" */ '@/pages/user/PaymentTemplates'),
    //     meta: {
    //         layout: 'dashboard',
    //         title: 'dashboard.sections.paymentTemplates.title',
    //         auth: true
    //     }
    // },
    // {
    //     path: '/user/withdrawal',
    //     name: 'user-withdrawal',
    //     component: () => import(/* webpackChunkName: "user-info" */ '@/pages/user/WithdrawalPage'),
    //     meta: {
    //         layout: 'dashboard',
    //         title: 'dashboard.sections.withdrawal.pageTitle',
    //         auth: true
    //     }
    // },
    {
        path: '/user/paysystems',
        name: 'user-paySystems',
        component: () => import(/* webpackChunkName: "user-info" */ '@/pages/user/PaySystemsPage'),
        meta: {
            layout: 'dashboard',
            title: 'dashboard.sections.paySystems.title',
            auth: true
        }
    },
    {
        path: '/user/bonuses',
        name: 'user-bonuses',
        component: () => import(/* webpackChunkName: "user-info" */ '@/pages/user/BonusesPage'),
        meta: {
            layout: 'dashboard',
            title: 'dashboard.sections.bonuses.pageTitle',
            auth: true
        }
    },
    {
        path: '/user/transfer',
        name: 'user-transfer',
        component: () => import(/* webpackChunkName: "user-info" */ '@/pages/user/TransferPage'),
        meta: {
            layout: 'dashboard',
            title: 'dashboard.sections.sendToFriend.pageTitle',
            auth: true
        }
    },
    {
        path: '/user/history',
        name: 'user-history',
        component: () => import(/* webpackChunkName: "user-info" */ '@/pages/user/HistoryPage'),
        meta: {
            layout: 'dashboard',
            title: 'dashboard.sections.history.pageTitle',
            auth: true
        }
    }
]
