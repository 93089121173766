import en from '../translations/en'
import tr from '../translations/tr'

const supported = ['en', 'tr']
let locale = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'tr'

export default {
    // current locale
    locale,

    // when translation is not available fallback to that locale
    fallbackLocale: 'en',

    // available locales for user selection
    availableLocales: [
        {
            code: 'en',
            flag: 'gb',
            label: 'English',
            messages: en
        },
        {
            code: 'tr',
            flag: 'tr',
            label: 'Türkçe',
            messages: tr
        },
    ]
}
