import Swal from 'sweetalert2';

const norificationAlert = async (title, msg, confirm) => {
    return new Promise((reject) => {
        Swal.fire({
            title: title,
            text: msg,
            type: 'info',
            showCancelButton: false,
            confirmButtonColor: '#05090c',
            cancelButtonColor: '#ff3774',
            confirmButtonText: confirm,
        }).then(res => {
            reject(res.isConfirmed)
        });
    })
}

export default norificationAlert
