<template>
    <v-dialog
        content-class="user-dashboard-dialog rounded-0"
        v-model="activeComputed"
        persistent
        :fullscreen="isMobile"
    >
        <v-toolbar
            height="68px"
        >
            <v-btn
                @click="toggleShowMenu"
            >
                <v-icon :class="{'icon-rotate':menuActive}">betvamos-chevron-left</v-icon>
            </v-btn>
            <v-spacer/>
            <v-toolbar-title>
                {{ title }}
            </v-toolbar-title>
            <v-spacer/>
            <v-btn
                @click="close"
            >
                <v-icon>betvamos-close</v-icon>
            </v-btn>
        </v-toolbar>

        <transition name="slide-x-transition">
            <left-menu
                :active-path="path"
                v-show="menuActive"
                @click="toggleShowMenu"
            />
        </transition>

        <transition name="slide-x-transition">
            <component
                v-show="!menuActive"
                :is="component"

            />
        </transition>
    </v-dialog>
</template>

<script>
import breaks from "@/mixins/breaks";
import {mapActions, mapState} from "vuex";

import LeftMenu from "@/components/user/LeftMenu.vue";

export default {
    components: {
        LeftMenu
    },
    props: {
        value: {default: false},
    },
    mixins: [breaks],
    data() {
        return {
            menuActive: false,
        }
    },
    computed: {
        ...mapState('app', ['userDashboardMobileDialog']),
        activeComputed: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        component () {
            return this.userDashboardMobileDialog.component;
        },
        title () {
            return this.$t(this.userDashboardMobileDialog.title);
        },
        path() {
            return this.userDashboardMobileDialog.route ? this.userDashboardMobileDialog.route.path : '';
        }
    },
    methods: {
        ...mapActions('app', ['makeActiveWindow']),
        close() {
            this.makeActiveWindow({type: 'userDashboardMobile', active: false})
            this.menuActive = false;
        },
        toggleShowMenu() {
            this.menuActive = !this.menuActive;
        }
    },
    beforeUpdate() {
        window.scrollTo({x:0,y:0})
    }
}
</script>

<style lang="scss">
.user-dashboard-dialog {

    .v-icon.icon-rotate {
        transform: rotateY(180deg);
    }

    .list-active {
        background-color: var(--v-gray-3-base) !important;
        opacity: 1 !important;
    }

    .user__left-menu .v-list-item--active {
        background-color: transparent !important;
    }

    &.v-dialog--fullscreen > .v-card {
        min-height: calc(100% - 70px);

    }

    .v-toolbar__content {
        padding: 16px;

        .v-toolbar__title {
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }

        .v-btn {
            min-width: auto;
            padding: 12px;
            border-radius: 4px;
            border: 1px solid var(--v-gray-2-base);
            background: #130D01;

            .v-icon::before {
                margin: 0 -5px;
            }
        }
    }
}
</style>
