import mutations from "@/store/modules/games/mutations"
import actions from "@/store/modules/games/actions"
import getters from "@/store/modules/games/getters";

const state = {
    initialized: false,
    categories: [],
    providersGroupBySlug: {},
    gamePage: '',
    platform: 'desktop',
    gamesByCategories: {}, // 'category_id' => [games...]
    games: [],
    filter: {},
    hasNextPage: true,

    loaded: false,
    filteredByProvider: false,
    provider: {},
    items: [],
    paginatePage: {},
    pagination: {},
    gameUrl: "",
    activeCategoryId: null,
    activeProviderSlug: null,
    prev_page_url: null,
    next_page_url: null,
    current_page: 2,
    page: 1,
    isMorePagination: false,
    search: "",
    isSearch: false,
    observerOptions: {threshold: 0.1},
    containerHide: false,
    loading: true,
    query: null,
    scrollIndex: 0,
    providerId: [],
    promoted_games: []
}

// const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
