<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        offset-y
        transition="scale-transition"
        min-width="auto"
        v-bind="menuProps"
    >
        <template v-slot:activator="{ on, attrs }">
            <custom-text-field
                :class="{'input--white': light}"
                v-model="value"
                :label="label"
                :placeholder="placeholder"
                :persistent-placeholder="persistentPlaceholder"
                :step="step"
                :disabled="disabled"
                :required="required"
                readonly
                v-bind="attrs"
                dark
                :rules="rules"
                :hide-details="hideDetails"
                outlined
                v-on="on"
                :style="stylesTextField"
            >
            </custom-text-field>
        </template>
        <!--      :max="new Date().toISOString().substr(0, 10)"-->
        <!--            :show-current="minAllowedDate"-->
        <v-date-picker
            ref="picker"
            v-model="dateValue"
            :active-picker.sync="activePicker"
            :disabled="disabled"
            :locale="lang"
            min="1900-01-01"
            :max="minAllowedDate"
            :show-current="minAllowedDate"
            class="text-body-1"
            @change="save"
        ></v-date-picker>
    </v-menu>
</template>

<script>
import moment from "moment-timezone";
import CustomTextField from "@/components/ui/CustomTextField.vue";
import CustomRadioGroup from "@/components/ui/CustomRadioGroup.vue";
import {mapState} from "vuex";

export default {
    components: {CustomRadioGroup, CustomTextField},
    data: () => ({
        menu: false,
        activePicker: null,
        // dateValue: null
    }),
    props: {
        value: {
            type: String,
        },
        rules: {
            type: Array,
        },
        label: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        persistentPlaceholder: {
            type: String,
        },
        step: {
            type: String,
        },
        stylesTextField: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        hideDetails: {
            type: Boolean,
            default: false,
        },
        light: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        menu(val) {
            val && setTimeout(() => (this.activePicker = "YEAR"));
        },
        // dateValue (val, oldVal) {
        //     if (val !== oldVal) {
        //       this.$emit('changeBirthday', val);
        //         this.$emit('input', val);
        //     }
        // }
    },
    methods: {
        save(date) {
            this.$refs.menu.save(date);
        },
        // allowedDates: val => moment(val).isSameOrBefore(moment().subtract(18, "years").format("YYYY-MM-DD")),
        allowedDates: val => moment(val).isSameOrBefore(moment().subtract(10, "years").format("YYYY-MM-DD")),

        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        parseDate (date) {
            if (!date) return null

            const [day, month, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
    },
    computed: {
        ...mapState({
            currentLang: state => state.user.current_lang,
        }),
        lang() {
            return this.currentLang ? this.currentLang : localStorage.getItem("lang") ? localStorage.getItem("lang") : "en"
        },
        menuProps () {
            return this.light ? { dark: false, light: true, contentClass: 'menu--white' } : {};
        },
        minAllowedDate: {
            get() {
                // return moment().subtract(18, "years").format("YYYY-MM-DD");
                return moment().subtract(10, "years").format("YYYY-MM-DD");
            },
        },
        dateValueFormated () {
            return this.formatDate(this.dateValue);
        },
        dateValue: {
            get() {
                return this.value;
                // return this.formatDate(this.value);
            },
            set(value) {
                this.$emit('changeBirthday', value);
                this.$emit('input', value);

            }
        }
    }
};
</script>

<style lang="scss">

</style>
