import actions from "./actions";
import mutations from "./mutations";

const state = {
    amount: 0,
    bonus_amount: 0,
    total_amount: 0,
    bonus_target: 0,
    bonus_deposit: {},
    migrated_user: false,
    login: false,
    current_lang: '',
    loadedGame: null,
    favorite_games: [],
    game_amount: 0,
    game_bonus_amount: 0,
    game_total_amount: 0,
    hide_balance: false,
    crypto: {
        balances: [],
        current: {
            name: "NUMMUS",
            amount: 0,
            amount_bonus: 0,
            amount_crypto: "0.00000000",
            amount_bonus_crypto: "0.00000000",
        },
        fiat_currency: 'USD',
        crypto_currency: 'NUMMUS',
        is_print: false,
        is_null_balance: false
    },
    bonus: {
        isActive: false,
        data: {
            id: 0,
            status: "",
            name: "",
            type: "",
            game: "",
            currency: ""
        }
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
