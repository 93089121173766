<template>
    <div class="main-layout d-flex flex-grow-1">

        <navigation-drawer v-model="navigationDrawerActive" :carousel="quickNavItems" :mobile_menu="mobileMenuItems"/>

<!--        <profile-drawer v-model="profileDrawerActive"/>-->

        <toolbar @open-navigation="openNavigationDrawer" @open-profile="openProfileDrawer" v-if="layout.header"/>

        <v-main class="fill-height layout">

            <v-container class="pa-0" fluid style="height: 100%">
                <v-layout style="height: 100%">
                    <slot></slot>
                    <!--                    <div-->
                    <!--                        :class="$route.name === 'slots-game' ? 'full-width' : 'loaded-game'"-->
                    <!--                        v-if="loadedGame && mdAndUp"-->
                    <!--                    >-->
                    <!--                        <div class="loaded-game-icon" v-if="$route.name !== 'slots-game'" @click="goToGame">-->
                    <!--                            <v-icon x-large  color="white">mdi-play</v-icon>-->
                    <!--                        </div>-->
                    <!--                        <div class="loaded-game-close" v-if="$route.name !== 'slots-game'" @click="$store.commit('user/setLoadedGame', null)">-->
                    <!--                            <v-icon color="white">mdi-close</v-icon>-->
                    <!--                        </div>-->
                    <!--                        <component-->
                    <!--                            is="MultiSlotGame"-->
                    <!--                        ></component>-->
                    <!--                    </div>-->
                </v-layout>
            </v-container>

            <app-footer v-if="layout.footer"/>
        </v-main>

    </div>
</template>

<script>
import {mapActions, mapState} from "vuex"
import NavigationDrawer from "@/components/navigation/NavigationDrawer"
import ProfileDrawer from "@/components/navigation/ProfileDrawer"
import Toolbar from "@/components/toolbar/Toolbar"
import FlagIcon from "@/components/common/FlagIcon"
import BottomBar from "@/components/navigation/BottomBar"
import AppFooter from "@/components/layout/footer/AppFooter"
import breaks from "@/mixins/breaks"

// navigation menu configurations
import config from '@/configs'
import MultiSlotGame from "../components/pages/slots/game/MultiSlotGame"

export default {
    components: {
        NavigationDrawer,
        ProfileDrawer,
        Toolbar,
        FlagIcon,
        BottomBar,
        AppFooter,
        MultiSlotGame,
    },
    mixins: [breaks],
    data() {
        return {
            navigationDrawerActive: false,
            profileDrawerActive: false,
            navigation: config.navigation,
            locales: config.locales.availableLocales,
            searchQuery: null,
        }
    },
    created() {
        if (this.$route.name === 'home') {
            this.$store.commit('user/setLoadedGame', null)
        }
    },
    computed: {
        ...mapState({
            layout: state => state.app.layout,
            quickNavItems: state => state.app.controlContents.quickNav?.items,
            mobileMenuItems: state => state.app.controlContents.mobileMenuItems,
            loadedGame: state => state.user.loadedGame
        })
    },
    methods: {
        goToGame() {
            if (this.$route.fullPath !== this.loadedGame) {
                this.$router.push(this.loadedGame)
            }
        },
        ...mapActions({
            setLayout: "app/setLayout",
        }),
        openNavigationDrawer() {
            this.navigationDrawerActive = true;
        },
        openProfileDrawer() {
            this.profileDrawerActive = true;
        },
        search() {
            // search action
        }
    }
}
</script>

<style lang="scss">

.layout {
    //height: 100vh;
    //
    //@media screen and (max-width: 600px) {
    //    height: unset;
    //}
    @media screen and (max-width: 600px) {
        padding-top: 30px;
    }
    @media screen and (max-width: 599px) {
        padding-top: 0;
    }
}

.loaded-game {
    max-width: 350px;
    width: 100%;
    height: 200px;
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 999;
    cursor: pointer;

    .loaded-game-close {
        position: absolute;
        top: 8px;
        right: -12px;
        cursor: pointer;
        z-index: 11;
        background-color: #111b27;
    }

    .loaded-game-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, .7);
        z-index: 10;
        width: 326px;
        height: 183px;
        transition: all .3s ease;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            width: 80px;
            height: 80px;
            border: 2px solid #fff;
            transition: all .3s ease;
        }

        &:hover {
            background-color: rgba(0, 0, 0, .3);

            &::before {
                width: 70px;
                height: 70px;
            }
        }
    }
}

//.fill-height {
//    padding-top: 99px !important;
//    &.pt-0 {
//        padding-top: 0 !important;
//    }
//    @include media("md-and-down") {
//        padding-top: 64px !important;
//    }
//}

.main-layout {
    //background-color: #070d17 !important;
    overflow-x: hidden;

    .v-main__wrap {
        display: flex;
        flex-direction: column;
    }
}
</style>
