<template>
    <div class="custom-text-field">
        <div
            v-if="$attrs.label"
            class="custom-text-field__messages"
        >
            <div class="custom-text-field__label">
                <slot name="label">
                    {{ $attrs.label }}
                </slot>
                :
                <span v-if="required && !getErrors" class="error--text">
                    *
                </span>
            </div>

            <div class="custom-text-field__error"> {{ getErrors }}</div>
        </div>
        <v-text-field
            v-bind="$attrs"
            ref="textField"
            v-on="$listeners"
        >
            <template
                v-for="slot in parentSlots"
                #[slot]
            >
                <slot :name="slot" />
            </template>

            <template
                v-if="getErrors && !noErrorIcon"
                #append
            >
                <v-icon color="error">
                    betvamos-alert-rhombus-outline
                </v-icon>
            </template>
        </v-text-field>
    </div>
</template>

<script>
export default {
    name: "CustomTextField",

    components: {},

    inheritAttrs: false,

    props: {
        noErrorIcon: {
            type: Boolean
        },
        required: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            isMounted: false
        }
    },

    computed: {
        parentSlots() {
            return Object.keys(this.$slots).filter(s => s !== 'label')
        },

        getErrors() {
            if (this.isMounted) {
                const textField = this.$refs.textField
                return (textField.hasState && textField?.errorBucket?.length)
                    ? this.$refs.textField.errorBucket[0]
                    : this.$attrs['error-messages']
            }
            return '';
        }
    },

    mounted() {
        this.isMounted = true;

        // hack for chrome autofill (somehowe add auto-fill-previewed to input)
        this.$refs.textField.$el.querySelector('input').addEventListener('click', e => e.target.value = e.target.value)
    },
}
</script>

<style lang="scss">
.custom-text-field {
    margin-bottom: 8px;

    .custom-text-field__messages {
        display: flex;
        align-items: flex-end;
        width: 100%;
        text-align: left;

        color: var(--v-gray-2-base);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        .custom-text-field__error {
            margin-left: 8px;
            color: var(--v-error-base);
        }
    }

    .v-input {
        &.primary--text, .primary--text {
            color: var(--v-gray-2-base) !important;
            caret-color: var(--v-gray-2-base) !important;
        }

        color: var(--v-gray-2-base);
        caret-color: var(--v-gray-2-base);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;

        input {
            color: var(--v-gray-2-base);
            caret-color: var(--v-gray-2-base);
        }

        &.v-input--is-disabled input {
            color: rgba(255, 255, 255, 0.5);
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            -webkit-text-fill-color: var(--v-gray-2-lighten3) !important;
            transition: background-color 5000s ease-in-out 0s !important;
            box-shadow: inset 0 0 20px 20px var(--v-gray-3-base) !important;
        }

        .error--text {
            color: var(--v-error-base) !important;
            caret-color: var(--v-error-base) !important;

            ::placeholder {
                color: var(--v-error-base) !important;
                caret-color: var(--v-error-base) !important;
            }
        }

        .v-input__control {

            .v-input__slot {
                min-height: 40px !important;
                background-color: var(--v-gray-3-base);
            }
        }

        .v-input__append-inner {
            margin-top: 8px !important;
        }

        .v-label {
            display: none;
        }

        .v-text-field__details {
            display: none;
        }

        legend {
            width: 0 !important;
        }
    }

    &.input--gray {
        .v-input {
            &.primary--text, .primary--text {
                color: var(--v-background-base) !important;
                caret-color: var(--v-background-base) !important;
            }

            color: var(--v-background-base);
            caret-color: var(--v-background-base);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;

            input, input::placeholder {
                color: var(--v-background-base);
                caret-color: var(--v-background-base);
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
                -webkit-text-fill-color: var(--v-gray-2-lighten3) !important;
                transition: background-color 5000s ease-in-out 0s !important;
                box-shadow: inset 0 0 20px 20px var(--v-gray-3-base) !important;
            }

            .error--text {
                color: var(--v-error-base) !important;
                caret-color: var(--v-error-base) !important;

                ::placeholder {
                    color: var(--v-error-base) !important;
                    caret-color: var(--v-error-base) !important;
                }
            }

            .v-input__control {
                .v-input__slot {
                    min-height: 40px !important;
                    background-color: var(--v-gray-2-base);
                }
            }

            .v-input__append-inner {
                margin-top: 8px !important;
            }
        }
    }

    &.input--white {
        .custom-text-field__label {
            color: black;
        }

        .v-input {
            &.primary--text, .primary--text {
                color: var(--v-background-base) !important;
                caret-color: var(--v-background-base) !important;
            }

            color: var(--v-background-base);
            caret-color: var(--v-background-base);

            .v-icon {
                color: var(--v-background-base);
            }

            input, input::placeholder {
                color: var(--v-background-base);
                caret-color: var(--v-background-base);
            }

            input::placeholder {
                color: var(--v-gray-3-base);
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
                -webkit-text-fill-color: var(--v-gray-3-base) !important;
                transition: background-color 5000s ease-in-out 0s !important;
                box-shadow: inset 0 0 20px 20px white !important;
            }

            .error--text {
                color: var(--v-error-base) !important;
                caret-color: var(--v-error-base) !important;

                ::placeholder {
                    color: var(--v-error-base) !important;
                    caret-color: var(--v-error-base) !important;
                }
            }

            .v-input__control {
                .v-input__slot {
                    background-color: white;
                }
            }

        }
    }

    &.input--white .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
        color: rgba(0, 0, 0, .40);
    }
    &.input--white .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot:hover fieldset {
        color: rgba(0, 0, 0, .60) !important;
    }
}
</style>
