<template>
    <svg
        class="svg-icon"
        :class="className"
        :style="style"
        viewBox="0 0 512 512"
    >
        <defs id="defs5">
            <linearGradient
                id="gradient1"
                gradientUnits="userSpaceOnUse"
                x1="265.7821"
                y1="244"
                x2="265.7821"
                y2="514"
                gradientTransform="matrix(1,0,0,-1,0,514)">
                <stop
                    offset="0"
                    style="stop-color:#FD5900"
                    id="stop1" />
                <stop
                    offset="1"
                    style="stop-color:#FFDE00"
                    id="stop2" />
            </linearGradient>
            <linearGradient
                id="gradient2"
                gradientUnits="userSpaceOnUse"
                x1="246.2323"
                y1="2"
                x2="246.2323"
                y2="272"
                gradientTransform="matrix(1,0,0,-1,0,514)">
                <stop
                    offset="0"
                    style="stop-color:#FFE59A"
                    id="stop3" />
                <stop
                    offset="1"
                    style="stop-color:#FFFFD5"
                    id="stop4" />
            </linearGradient>
        </defs>
        <path
            class="refresh-1"
            d="M 439.31,76.63 C 392.89,30.19 326.26,0 256.09,0 195.74,0 136.65,22.18 91,61.48 L 68.65,24.21 C 65.37,18.76 59.01,16.04 52.83,17.22 46.59,18.48 41.84,23.52 40.93,29.8 L 19.72,178.3 c -1.45,10.03 7.27,18.36 16.96,16.98 l 148.48,-21.21 c 6.3,-0.91 11.34,-5.65 12.6,-11.88 1.26,-6.23 -1.55,-12.57 -7,-15.84 L 155.37,125.11 C 203.75,87.2 254.51,90.52 257.53,90 c 56.04,0 93.28,25.43 114.64,46.8 31.32,31.32 49.95,75.5 49.83,118.15 0,8.5 6.86,15.04 15,15.04 h 60 c 8.26,0 14.97,-6.68 15,-14.94 0.26,-68.93 -24.87,-130.63 -72.69,-178.42 z" />
        <path
            class="refresh-2"
            d="m 475.34,316.85 -148.48,21.21 c -6.3,0.91 -11.34,5.65 -12.6,11.88 -1.26,6.23 1.55,12.57 7,15.83 l 35.27,21.18 c -28.77,22.53 -65.54,35.14 -101.57,35.04 -56.48,0.75 -93.69,-25.37 -115.14,-46.8 C 108.52,343.88 89.89,299.7 90,257.04 90,248.54 83.14,242 75,242 H 15 c -8.26,0 -14.97,6.68 -15,14.94 -0.26,68.94 24.87,130.63 72.69,178.43 46.14,46.15 108.57,74.85 183.22,76.63 60.35,0 119.41,-22.18 165.06,-61.45 l 22.41,37.37 c 3.28,5.45 9.61,8.2 15.82,6.99 6.24,-1.26 10.99,-6.3 11.89,-12.58 L 492.3,333.84 c 1.46,-10.05 -7.36,-18.48 -16.96,-16.99 z" />
    </svg>

</template>

<script>

const DEFAULT_MAX_SIZE = '32px';

export default {
    props: {
        size: {
            type: [String, Number],
        },
        rotate: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        className() {
            return {
                rotate: this.rotate,
            }
        },
        sizeComputed() {
            return this.size ? `${this.size}px` : null;
        },
        style() {
            return this.size
                ?
                {
                    width: this.sizeComputed,
                    height: this.sizeComputed,
                }
                :
                {
                    maxWidth: DEFAULT_MAX_SIZE,
                    maxHeight: DEFAULT_MAX_SIZE,
                }
        }
    }
}
</script>

<style scoped >
.svg-icon {
    transition: all .3s ease;
}

.svg-icon:hover {
    filter: brightness(1.3) saturate(.8);
}

.svg-icon.rotate {
    animation: rotate .8s linear infinite;
    filter: saturate(.4);
}

.svg-icon >>> .refresh-1 {
    fill:url(#gradient1);
}
.svg-icon >>> .refresh-2 {
    fill:url(#gradient2);
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}


</style>
