export default [
        {
            title: {
                en: 'Sportsbook'
            },
            subtitle: {
                en: 'Football, Basketball and more'
            },
            image: '/images/icons/products-icon/soccer.svg',
            link: '/sports1#/prematch/',
        },
        {
            title: {
                en: 'Casino'
            },
            subtitle: {
                en: 'All types of Slots'
            },
            image: '/images/icons/products-icon/casino.svg',
            link: '/casino/',
        },
        {
            title: {
                en: 'Esport'
            },
            subtitle: {
                en: 'Ner era of sports betting'
            },
            image: '/images/icons/products-icon/esport.svg',
            link: '/virtual-sports/',
        },
        {
            title: {
                en: 'Live Casino'
            },
            subtitle: {
                en: 'Worldwide Tables'
            },
            image: '/images/icons/products-icon/l_casino.svg',
            link: '/live-casino',
        },
        {
            title: {
                en: 'Bingo'
            },
            subtitle: {
                en: 'Tombala'
            },
            image: '/images/icons/products-icon/bingo.svg',
            link: '/?1',
        },
        {
            title: {
                en: 'Crash Games'
            },
            subtitle: {
                en: 'To the moon!'
            },
            image: '/images/icons/products-icon/crash_games.svg',
            link: '/?4',
        },
    ]
