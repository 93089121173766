<template>
    <v-dialog v-model="cloneDialog" max-width="400" persistent>
        <v-card
            dark
            color="#111b27"
            tile
            flat
            class="pa-4">
            <v-card-title>{{ $t('dialog.cloneGameMessage') }}</v-card-title>
            <v-card-actions>
                <v-btn dark @click="closeCloneDialog()">{{ $t('buttons.goBack') }}</v-btn>
                <v-btn color="primary" class="ml-2" @click="cloneGame()">{{ $t('buttons.open') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "CloneDialog",
    props: {
        cloneDialog: {
            type: Boolean,
            default: () => false
        }
    },
    methods: {
        cloneGame() {
            this.$emit('cloneGame')
        },
        closeCloneDialog() {
            this.$emit('closeCloneDialog')
        }
    }
}
</script>

<style scoped>

</style>
