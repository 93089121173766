import breaks from "@/mixins/breaks";

export default {
    mixins: [breaks],
    methods: {
        getGameImage: (gameItem, cloudflare_size = 'public', cdn_size) => {
            function getDesktopBanner() {
                if (gameItem.banner_cloudflare) {
                    return gameItem.banner_cloudflare + cloudflare_size
                } else {
                    return gameItem.banner
                }
            }

            function getMobileBanner() {
                if (gameItem.mobile_banner_cloudflare) {
                    return gameItem.mobile_banner_cloudflare + cloudflare_size
                } else {
                    return gameItem.mobile_banner;
                }
            }

            let banner = getDesktopBanner();
            if (breaks.isDesktop) {
                banner = banner ?? getMobileBanner();
            } else {
                banner = getMobileBanner() ?? banner;
            }
            return banner;
        },
        getGameBackgroundImage: (gameItem, cloudflare_size = 'public', cdn_size) => {
            function getDesktopBanner() {
                if (gameItem.background_banner_cloudflare) {
                    return gameItem.background_banner_cloudflare + cloudflare_size
                }
                return '';
            }
            let banner = '';
            if(gameItem) {
                banner = getDesktopBanner();
            }

            return banner;
        },
        getProviderLogo(gameItem, cloudflare_size = 'public', cdn_size) {
            if (gameItem) {
                return `https://imagedelivery.net/5sGpUK8iCiLBOwPe7K6Mnw/${gameItem.game_provider.logo_cloudflare}/${cloudflare_size}`

            }
        }
    }
}
