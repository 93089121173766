<template>
    <v-dialog v-model="addGameDialog" max-width="1200" persistent>
        <v-card
            dark
            color="#111b27"
            tile
            flat
            class="pa-2 pa-md-8 mx-auto position-relative">
            <v-icon @click="closeSearchGameDialog" class="slots-game-search-game-close">mdi-close</v-icon>

            <div class="text-h4">{{ $t('title.addGame') }}</div>

            <div>
                <v-text-field
                    hide-details
                    flat
                    autofocus
                    class="text-body-2 search-overlay__input"
                    :placeholder="$t('form.search.placeholder')"
                    @keyup.enter="inputSearch"
                    v-model="search_input"
                >
                    <template v-slot:append-outer>
                        <v-btn
                            color="primary"
                            :loading="loading"
                            :disabled="loading"
                            @click="inputSearch"
                        >
                            {{ $t('common.search') }}
                            <v-icon
                                right
                                dark
                            >
                                mdi-magnify
                            </v-icon>
                        </v-btn>
                    </template>
                </v-text-field>
            </div>

            <div class="mt-6">
                <div class="mb-2 text-overline px-2">
                    {{ $t('labels.categories') }}
                </div>
                <div class="d-flex flex-wrap">
                    <div
                        v-for="category in categories"
                        :key="category.id"
                        class="game-wrap-list-item text-capitalize py-1 px-2"
                        :class="category.activeClass ? 'active' : ''"
                        @click="setSearchQuery(category.id, null), showGames(null, category), category.activeClass = true"
                    >
                        {{ category.name }}
                    </div>
                </div>
            </div>

            <div class="mt-6">
                <div class="mb-2 text-overline px-2">
                    {{ $t('labels.providers') }}
                </div>
                <div class="d-flex flex-wrap">
                    <div
                        v-for="(provider, i) in providers"
                        :key="i"
                        class="game-wrap-list-item text-capitalize py-1 px-2"
                        :class="i === selectedProviderSlug ? 'active' : ''"
                        @click="setSearchQuery(null, provider.map(v => v.id)), showGames(provider.map(v => v.id), null), selectedProviderSlug = i"
                    >
                        {{ provider[0].name }}
                    </div>
                </div>
            </div>

            <div class="text-overline px-2 my-4" v-if="searchGames.length">{{ $t("slotsGame.showing") }}
                {{ searchGames.length }} {{ $t("slotsGame.games") }}
            </div>
            <div class="text-overline px-2 my-6" v-else>{{ $t("slotsGame.gamesNotFound") }}</div>
            <div class="text-center my-6 px-2" v-if="loading">
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </div>

            <div class="found-games-wrap d-flex flex-wrap" v-if="searchGames.length">

                <v-sheet
                    color="transparent"
                    dark
                    class="slots-game-search-game mb-2"
                    v-for="game in searchGames"
                    :key="game.id"
                    @click="addGame(game, currentIframe.item, currentIframe.index), closeSearchGameDialog()"
                    style="cursor:pointer;"
                >
                    <v-badge
                        v-if="game.badge"
                        tile
                        class="game-badge"
                        dark
                        color="red"
                        :content="game.badge.name.toUpperCase()"
                    />
                    <div
                        class="slots-game-search-game-wrap d-flex flex-column align-center justify-center"
                    >
                        <div class="slots-game-search-game-title text-lg-h4 text-center lh-1 d-none d-lg-block">
                            <v-icon x-large class="d-block mb-2">mdi-plus</v-icon>
                            {{ game.original_name }}
                        </div>
                    </div>
                    <v-img
                        v-if="mdAndUp"
                        class="rounded elevation-1"
                        aspect-ratio="1"
                        :src="game.banner.includes('.svg') || game.banner.includes('gif') ? game.banner : images_cdn + '600/' + game.banner"
                        :lazy-src="game.banner.includes('.svg') || game.banner.includes('gif') ? game.banner : images_cdn + '10/' + game.banner"
                        position="center top">
                    </v-img>
                    <v-img
                        v-if="smAndDown"
                        class="rounded elevation-1"
                        aspect-ratio="1"
                        :src="game.banner.includes('.svg') || game.banner.includes('gif') ? game.banner : images_cdn + '300/' + game.banner"
                        :lazy-src="game.banner.includes('.svg') || game.banner.includes('gif') ? game.banner : images_cdn + '10/' + game.banner"
                        position="center top">
                    </v-img>
                </v-sheet>

                <div class="text-center my-4" style="width: 100%;"
                     v-if="pagination_current_page < pagination_last_page">
                    <v-btn
                        :loading="loading"
                        large
                        color="primary"
                        @click="changePagination(), showGames()">
                        {{ $t('buttons.more') }}
                    </v-btn>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapState} from "vuex";
import GameItem from "../../../../models/GameItem";
import breaks from "../../../../mixins/breaks";

export default {
    name: "SearchGameDialog",
    props: {
        currentIframe: {
          type: Object,
            default: () => {}
        },
        addGameDialog: {
            type: Boolean,
            default: () => false
        },
        loading: {
            type: Boolean,
            default: () => false
        },
        categories: {
            type: Array,
            default: () => []
        },
        providers: {
            type: Object,
            default: () => {}
        },
        searchGames: {
            type: Array,
            default: () => []
        },
        search_category_id: {
            type: Number,
            default: () => null
        },
        search_provider_id: {
            type: Array,
            default: () => []
        },
        pagination_current_page: {
            type: Number,
            default: () => 1
        },
        pagination_last_page: {
            type: Number,
            default: () => null
        }
    },
    data() {
      return {
          search_input: '',
          selectedProviderSlug: null
      }
    },
    mixins: [breaks],
    computed: {
        ...mapState({
            images_cdn: state => state.app.brandSettings.images_cdn,
        })
    },
    methods: {
        addGame(game, item, index) {
            this.$emit('addGame', game, item, index)
        },
        showGames(provider, category) {
            this.$emit('showGames', provider, category)
        },
        inputSearch() {
            this.$emit('inputSearch', this.search_input)
        },
        setSearchQuery(category_id = null, provider_id = []) {
            this.$emit('setSearchQuery', category_id, provider_id)
        },
        closeSearchGameDialog() {
            this.$emit('closeSearchGameDialog')
        },
        cleanSearch() {
            this.search_input = ''
            this.$emit('cleanSearch')
        },
        changePagination() {
            this.$emit('changePagination')
        }
    }
}
</script>

<style scoped>

</style>
