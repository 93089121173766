export const BONUS_TYPES = {
    DEPOSIT: 'deposit',
    FREESPIN: 'freespin',
    FREEBET: 'freebet',
    NO_DEPOSIT: 'no-deposit',
}


export const MOCK_FREESPIN = {
    "id": 5463,
    "user_id": 7187,
    "bonus_id": 0,
    "transaction_id": null,
    "name": "2342",
    "type": "freespin",
    "game_type": "casino",
    "is_quick": 1,
    "is_active": 1,
    "cashback_enable": 0,
    "status": "1",
    "amount_processed": 0,
    "amount_to_process": 0,
    "amount_wins": 0,
    "win": null,
    "goal": null,
    "total_amount": "0.00",
    "total_currency": null,
    "amount_reserved": 0,
    "amount_wager": 0,
    "balance_id": 108028,
    "currency": null,
    "sub_bonus_id": null,
    "sub_bonus": null,
    "starts_at": "2024-06-13T04:08:45.000000Z",
    "completed_at": null,
    "cancel_at": null,
    "ends_at": "2024-06-23T04:08:45.000000Z",
    "created_at": "2024-06-13T04:08:45.000000Z",
    "updated_at": "2024-06-13T04:08:45.000000Z",
    "user": {
        "id": 7187,
        "site_id": 90,
        "affiliate_id": null,
        "agent_id": null,
        "site_user_id": 126,
        "username": "m4voit121",
        "email": "2y65986r2e@rentforsale7.com",
        "firstname": null,
        "lastname": null,
        "gender": 0,
        "birthdate": null,
        "language": "en",
        "currency": null,
        "status": 2,
        "is_test": 0,
        "verified_email": false,
        "created_at": "2024-06-07T07:55:50.000000Z",
        "updated_at": "2024-06-07T10:06:39.000000Z",
        "deleted_at": null,
        "disable_validity": null,
        "site": {
            "id": 90,
            "url": "https://nummus.casino",
            "brand": "Nummus",
            "is_crypto": 1,
            "is_affiliate": 0,
            "site_access_token": "16961|LdHIzOCWhFQhUXQIEnMWYPBYlPTYG5FRin4Ek3pY",
            "created_at": "2024-02-28T04:21:37.000000Z",
            "updated_at": "2024-05-22T08:47:35.000000Z",
            "is_blocked": false
        },
        "balance": [
            {
                "id": 108027,
                "user_id": 7187,
                "currency": "TRY",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108029,
                "user_id": 7187,
                "currency": "EUR",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108030,
                "user_id": 7187,
                "currency": "JPY",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108031,
                "user_id": 7187,
                "currency": "BRL",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108032,
                "user_id": 7187,
                "currency": "CAD",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108033,
                "user_id": 7187,
                "currency": "CNY",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108034,
                "user_id": 7187,
                "currency": "DKK",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108035,
                "user_id": 7187,
                "currency": "IDR",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108036,
                "user_id": 7187,
                "currency": "INR",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108037,
                "user_id": 7187,
                "currency": "KRW",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108038,
                "user_id": 7187,
                "currency": "MXN",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108039,
                "user_id": 7187,
                "currency": "PHP",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108040,
                "user_id": 7187,
                "currency": "RUB",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108041,
                "user_id": 7187,
                "currency": "NGN",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108042,
                "user_id": 7187,
                "currency": "BTC",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108043,
                "user_id": 7187,
                "currency": "ETH",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "Activate deposit bonus",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 1,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-13T04:07:44.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108044,
                "user_id": 7187,
                "currency": "LTC",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108045,
                "user_id": 7187,
                "currency": "DOGE",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108046,
                "user_id": 7187,
                "currency": "BCH",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108047,
                "user_id": 7187,
                "currency": "XRP",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108048,
                "user_id": 7187,
                "currency": "TRON",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108049,
                "user_id": 7187,
                "currency": "EOS",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108050,
                "user_id": 7187,
                "currency": "BNB",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108051,
                "user_id": 7187,
                "currency": "USDT",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108052,
                "user_id": 7187,
                "currency": "USDC",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108053,
                "user_id": 7187,
                "currency": "DOT",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108054,
                "user_id": 7187,
                "currency": "LINK",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108055,
                "user_id": 7187,
                "currency": "XLM",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108056,
                "user_id": 7187,
                "currency": "SHIB",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108057,
                "user_id": 7187,
                "currency": "BUSD",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108028,
                "user_id": 7187,
                "currency": "USD",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "softswiss bonus bet 1086559",
                "bet_id": null,
                "entity_id": 1086559,
                "currency_default": 1,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-13T04:08:51.000000Z",
                "amount_crypto": "0.00000000"
            }
        ]
    },
    "balance": {
        "id": 108028,
        "user_id": 7187,
        "currency": "USD",
        "amount": 0,
        "amount_reserve": 0,
        "amount_bonus": 0,
        "amount_bonus_crypto": "0.00000000",
        "amount_reserved_crypto": "0.00000000",
        "amount_debt": 0,
        "last_operation_detail": "softswiss bonus bet 1086559",
        "bet_id": null,
        "entity_id": 1086559,
        "currency_default": 1,
        "crypto_currency_default": 0,
        "created_at": "2024-06-07T07:55:50.000000Z",
        "updated_at": "2024-06-13T04:08:51.000000Z",
        "amount_crypto": "0.00000000"
    },
    "settings": {
        "id": 5463,
        "bonus_id": 5463,
        "strict": false,
        "global_wager_goal_percent": null,
        "auto_bonus": null,
        "softswiss": null,
        "freespin_games": [
            "pragmaticexternal:JourneytotheWest",
            "pragmaticexternal:VegasNights",
            "pragmaticexternal:GoldRush",
            "pragmaticexternal:FruitParty1",
            "pragmaticexternal:TripleDragons",
            "pragmaticexternal:Super7s",
            "pragmaticexternal:3BuzzingWilds",
            "pragmaticexternal:3DancingMonkeys",
            "pragmaticexternal:5Lions",
            "pragmaticexternal:5LionsDance",
            "pragmaticexternal:5LionsGold",
            "pragmaticexternal:5LionsMegaways1",
            "pragmaticexternal:7Piggies",
            "pragmaticexternal:8GoldenDragonChallenge",
            "pragmaticexternal:AfricanElephant",
            "pragmaticexternal:AladdinandtheSorcerer",
            "pragmaticexternal:AncientEgypt",
            "pragmaticexternal:AncientEgyptClassic",
            "pragmaticexternal:Asgard",
            "pragmaticexternal:AztecBlaze",
            "pragmaticexternal:AztecBonanza",
            "pragmaticexternal:AztecGems",
            "pragmaticexternal:AztecGemsDeluxe1",
            "pragmaticexternal:BarnFestival",
            "pragmaticexternal:BigBassAmazonXtreme",
            "pragmaticexternal:BigBassBonanza",
            "pragmaticexternal:BigBassBonanzaMegaways",
            "pragmaticexternal:BigBassHalloween",
            "pragmaticexternal:BigBassHoldandSpinner",
            "pragmaticexternal:BigBassHoldSpinnerMegaways",
            "pragmaticexternal:BigBassKeepingitReel",
            "pragmaticexternal:BigBassSplash",
            "pragmaticexternal:BiggerBassBlizzardChristmasCatch",
            "pragmaticexternal:BiggerBassBonanza",
            "pragmaticexternal:BigJuan",
            "pragmaticexternal:BlackBull",
            "pragmaticexternal:BombBonanza",
            "pragmaticexternal:BookofFallen",
            "pragmaticexternal:BookofGoldenSands",
            "pragmaticexternal:BookofKingdoms1",
            "pragmaticexternal:BookofTutMegaways",
            "pragmaticexternal:BookofVikings",
            "pragmaticexternal:BountyGold",
            "pragmaticexternal:BroncoSpirit",
            "pragmaticexternal:BuffaloKing",
            "pragmaticexternal:BuffaloKingMegaways1",
            "pragmaticexternal:CaishensCash",
            "pragmaticexternal:CaishensGold",
            "pragmaticexternal:CandyBlitz",
            "pragmaticexternal:CandyStars",
            "pragmaticexternal:CashBonanza",
            "pragmaticexternal:CashBox",
            "pragmaticexternal:CashChips",
            "pragmaticexternal:CashElevator1",
            "pragmaticexternal:CashPatrol",
            "pragmaticexternal:ChickenChase",
            "pragmaticexternal:ChickenDrop",
            "pragmaticexternal:ChilliHeat",
            "pragmaticexternal:ChilliHeatMegaways",
            "pragmaticexternal:ChristmasBassBananza",
            "pragmaticexternal:ChristmasCarolMegaways1",
            "pragmaticexternal:Cleocatra",
            "pragmaticexternal:CloverGold",
            "pragmaticexternal:ClubTropicana",
            "pragmaticexternal:ColossalCashZone",
            "pragmaticexternal:CongoCash1",
            "pragmaticexternal:CosmicCash",
            "pragmaticexternal:CowboyCoins",
            "pragmaticexternal:Cowboysgold1",
            "pragmaticexternal:CrownofFire",
            "pragmaticexternal:CrystalCavernsMegaways",
            "pragmaticexternal:CurseoftheWerewolfMegaways",
            "pragmaticexternal:CyclopsSmash",
            "pragmaticexternal:DanceParty",
            "pragmaticexternal:DaVincisTreasure",
            "pragmaticexternal:DayofDead",
            "pragmaticexternal:DemonPots",
            "pragmaticexternal:DiamondCascade",
            "pragmaticexternal:DiamondsOfEgypt",
            "pragmaticexternal:DiamondStrike",
            "pragmaticexternal:DowntheRails",
            "pragmaticexternal:DragoJewelsofFortune",
            "pragmaticexternal:DragonHero",
            "pragmaticexternal:DragonHotHoldandSpin1",
            "pragmaticexternal:DragonKingdom",
            "pragmaticexternal:DragonKingdomEyesOfFire1",
            "pragmaticexternal:DrillThatGold",
            "pragmaticexternal:DwarvenGoldDeluxe",
            "pragmaticexternal:EgyptianFortunes",
            "pragmaticexternal:EightDragons",
            "pragmaticexternal:ElementalGemsMegaways",
            "pragmaticexternal:EmeraldKing",
            "pragmaticexternal:EmeraldKingRainbowRoad",
            "pragmaticexternal:EmptytheBank1",
            "pragmaticexternal:ExcaliburUnleashed",
            "pragmaticexternal:ExtraJuicy",
            "pragmaticexternal:ExtraJuicyMegaways",
            "pragmaticexternal:EyeofCleopatra",
            "pragmaticexternal:EyeOfTheStorm1",
            "pragmaticexternal:FairytaleFortune",
            "pragmaticexternal:FatPanda",
            "pragmaticexternal:Fire88",
            "pragmaticexternal:FireArcher",
            "pragmaticexternal:FirebirdSpirit",
            "pragmaticexternal:FireHot100",
            "pragmaticexternal:FireHot20",
            "pragmaticexternal:FireHot40",
            "pragmaticexternal:FireHot5",
            "pragmaticexternal:FireStrike",
            "pragmaticexternal:FireStrike2",
            "pragmaticexternal:FishEye",
            "pragmaticexternal:FishinReels",
            "pragmaticexternal:FloatingDragon1",
            "pragmaticexternal:FloatingDragonDragonBoatFestival",
            "pragmaticexternal:FloatingDragonMegaways",
            "pragmaticexternal:ForgeofOlympus",
            "pragmaticexternal:FortuneofGiza",
            "pragmaticexternal:FortunesofAztec",
            "pragmaticexternal:FrozenTropics",
            "pragmaticexternal:FruitParty2",
            "pragmaticexternal:FruitRainbow",
            "pragmaticexternal:FuryofOdinMegaways",
            "pragmaticexternal:GatesOfOlympus1",
            "pragmaticexternal:GatesofValhalla",
            "pragmaticexternal:GemsBonanza1",
            "pragmaticexternal:GemsofSerengeti",
            "pragmaticexternal:GoblinHeistPowernudge",
            "pragmaticexternal:GodsofGiza",
            "pragmaticexternal:GoldenBeauty",
            "pragmaticexternal:GoldOasis",
            "pragmaticexternal:GoldParty",
            "pragmaticexternal:GoldTrain",
            "pragmaticexternal:GorillaMayhem",
            "pragmaticexternal:GravityBonanza",
            "pragmaticexternal:GreatRhino",
            "pragmaticexternal:GreatRhinoDeluxe",
            "pragmaticexternal:GreatRhinoMegaways",
            "pragmaticexternal:GreedyWolf",
            "pragmaticexternal:GreekGods",
            "pragmaticexternal:HappyHooves",
            "pragmaticexternal:HeartofRio1",
            "pragmaticexternal:HeistfortheGoldenNugget",
            "pragmaticexternal:HellvisWild",
            "pragmaticexternal:HerculesandPegasus",
            "pragmaticexternal:HerculesSonZeus",
            "pragmaticexternal:HoneyHoneyHoney",
            "pragmaticexternal:HotChilli",
            "pragmaticexternal:HotFiesta1",
            "pragmaticexternal:HotPepper",
            "pragmaticexternal:HotSafari",
            "pragmaticexternal:Hottoburn1",
            "pragmaticexternal:HottoBurnExtreme",
            "pragmaticexternal:HottoBurnHoldandSpin1",
            "pragmaticexternal:InfectiveWild",
            "pragmaticexternal:JadeButterfly",
            "pragmaticexternal:JaneHunterandtheMaskofMontezuma",
            "pragmaticexternal:JasmineDreams",
            "pragmaticexternal:JewelRush",
            "pragmaticexternal:JohnHunterandtheAztecTreasure",
            "pragmaticexternal:JohnHunterandtheBookofTut",
            "pragmaticexternal:JohnHunterandtheBookofTutRespin",
            "pragmaticexternal:JohnHunterAndtheMayanGods",
            "pragmaticexternal:JohnHunterandtheQuestforBermudaRiches",
            "pragmaticexternal:JohnHunterandtheTomboftheScarabQueen",
            "pragmaticexternal:JokerKing1",
            "pragmaticexternal:JokersJewels",
            "pragmaticexternal:JokersJewelsDice",
            "pragmaticexternal:JuicyFruits",
            "pragmaticexternal:JungleGorilla1",
            "pragmaticexternal:JurassicGiants",
            "pragmaticexternal:KingdomofTheDead",
            "pragmaticexternal:KnightHotSpotz",
            "pragmaticexternal:LadyGodiva",
            "pragmaticexternal:LampOfInfinity",
            "pragmaticexternal:LeprechaunCarol",
            "pragmaticexternal:LeprechaunSong",
            "pragmaticexternal:LittleGem",
            "pragmaticexternal:LobsterBobsCrazyCrabShack",
            "pragmaticexternal:LuckyDragons",
            "pragmaticexternal:LuckyGraceandCharm",
            "pragmaticexternal:LuckyLightning1",
            "pragmaticexternal:LuckyNewYear",
            "pragmaticexternal:MadameDestiny",
            "pragmaticexternal:MadameDestinyMegaways1",
            "pragmaticexternal:MagicCrystals",
            "pragmaticexternal:MagiciansSecrets",
            "pragmaticexternal:MagicJourney",
            "pragmaticexternal:MagicMoneyMaze",
            "pragmaticexternal:MammothGoldMegaways",
            "pragmaticexternal:MasterChensFortune",
            "pragmaticexternal:MasterJoker",
            "pragmaticexternal:MightofRa",
            "pragmaticexternal:MightyKong",
            "pragmaticexternal:Mochimon",
            "pragmaticexternal:MoneyMouse",
            "pragmaticexternal:MonkeyMadness",
            "pragmaticexternal:MonkeyWarrior",
            "pragmaticexternal:MonsterSuperlanche",
            "pragmaticexternal:MuertosMultiplierMegaways",
            "pragmaticexternal:MustangGold",
            "pragmaticexternal:MustangTrail",
            "pragmaticexternal:Mysterious",
            "pragmaticexternal:MysteriousEgypt",
            "pragmaticexternal:MysteryoftheOrient",
            "pragmaticexternal:MysticChief",
            "pragmaticexternal:NorthGuardians",
            "pragmaticexternal:OctobeerFortunes",
            "pragmaticexternal:PandaFortune",
            "pragmaticexternal:PandaFortune21",
            "pragmaticexternal:PantherQueen",
            "pragmaticexternal:PeakPower",
            "pragmaticexternal:PekingLuck",
            "pragmaticexternal:PhoenixForge1",
            "pragmaticexternal:PiggyBankBills",
            "pragmaticexternal:PiggyBankers",
            "pragmaticexternal:PinupGirls",
            "pragmaticexternal:PirateGold",
            "pragmaticexternal:PirateGoldDeluxe1",
            "pragmaticexternal:PirateGoldenAge",
            "pragmaticexternal:PiratesPub",
            "pragmaticexternal:PixieWings",
            "pragmaticexternal:PIZZAPIZZAPIZZA",
            "pragmaticexternal:PowerofMerlinMegaways",
            "pragmaticexternal:PowerofThorMegaways",
            "pragmaticexternal:PubKings",
            "pragmaticexternal:PyramidKing1",
            "pragmaticexternal:Queenie",
            "pragmaticexternal:QueenOfAtlantis",
            "pragmaticexternal:QueenofGods",
            "pragmaticexternal:QueenOfGold",
            "pragmaticexternal:RabbitGarden",
            "pragmaticexternal:RainbowReels",
            "pragmaticexternal:ReelBanks",
            "pragmaticexternal:ReleasetheKraken",
            "pragmaticexternal:ReleasetheKraken2",
            "pragmaticexternal:ReturnOftheDead1",
            "pragmaticexternal:RiseofGizaPowerNudge",
            "pragmaticexternal:RocketBlastMegaways",
            "pragmaticexternal:RockVegas",
            "pragmaticexternal:SafariKing",
            "pragmaticexternal:Santa",
            "pragmaticexternal:SantasGreatGifts",
            "pragmaticexternal:SantasWonderland",
            "pragmaticexternal:SecretCityGold",
            "pragmaticexternal:SevenMonkeys",
            "pragmaticexternal:ShieldofSparta",
            "pragmaticexternal:ShiningHot100",
            "pragmaticexternal:ShiningHot20",
            "pragmaticexternal:ShiningHot40",
            "pragmaticexternal:ShiningHot5",
            "pragmaticexternal:SkyBounty",
            "pragmaticexternal:SmugglersCove",
            "pragmaticexternal:SnakesandLadders2SnakeEyes",
            "pragmaticexternal:SnakesandLaddersMegadice",
            "pragmaticexternal:SpartanKing",
            "pragmaticexternal:SpellbindingMystery",
            "pragmaticexternal:SpinandScoreMegaways",
            "pragmaticexternal:SpiritofAdventure",
            "pragmaticexternal:StarBounty",
            "pragmaticexternal:StarlightChristmas",
            "pragmaticexternal:StarlightPrincess",
            "pragmaticexternal:StarlightPrincess1000",
            "pragmaticexternal:StarPiratesCode",
            "pragmaticexternal:StarzMegaways",
            "pragmaticexternal:StickyBees",
            "pragmaticexternal:StreetRace",
            "pragmaticexternal:StrikingHot5",
            "pragmaticexternal:SugarRush1",
            "pragmaticexternal:SugarSupremePowernudge",
            "pragmaticexternal:SuperJoker",
            "pragmaticexternal:SuperX",
            "pragmaticexternal:SweetBonanza",
            "pragmaticexternal:SweetBonanzaDice",
            "pragmaticexternal:SweetBonanzaXmas",
            "pragmaticexternal:SweetPowernudge",
            "pragmaticexternal:SwordofAres",
            "pragmaticexternal:TemujinTreasures",
            "pragmaticexternal:TheAmazingMoneyMachine1",
            "pragmaticexternal:TheChampions",
            "pragmaticexternal:TheDogHouse",
            "pragmaticexternal:TheDogHouseDiceShow",
            "pragmaticexternal:TheDogHouseMegaways1",
            "pragmaticexternal:TheDogHouseMultihold",
            "pragmaticexternal:TheGreatChickenEscape",
            "pragmaticexternal:TheGreatStickUp",
            "pragmaticexternal:TheHandOfMidas",
            "pragmaticexternal:TheKnightKing",
            "pragmaticexternal:TheMagicCauldronEnchantedBrew1",
            "pragmaticexternal:TheMoneyMenMegaways",
            "pragmaticexternal:TheRedQueen",
            "pragmaticexternal:TheUltimate5",
            "pragmaticexternal:TheWildMachine",
            "pragmaticexternal:ThreeGenieWishes",
            "pragmaticexternal:ThreeKindoms",
            "pragmaticexternal:ThreeStarFortune",
            "pragmaticexternal:TicTacTake",
            "pragmaticexternal:ToweringFortunes",
            "pragmaticexternal:TreasureHorse",
            "pragmaticexternal:TreasureWild",
            "pragmaticexternal:TreeofRiches",
            "pragmaticexternal:TripleJokers",
            "pragmaticexternal:TripleTigers",
            "pragmaticexternal:TropicalTiki",
            "pragmaticexternal:TundrasFortune",
            "pragmaticexternal:TwilightPrincess",
            "pragmaticexternal:UltraBurn",
            "pragmaticexternal:UltraHoldandSpin",
            "pragmaticexternal:VampiresvsWolves",
            "pragmaticexternal:VegasMagic",
            "pragmaticexternal:VikingForge",
            "pragmaticexternal:VoodooMagic",
            "pragmaticexternal:WildBeachParty",
            "pragmaticexternal:WildBisonCharge",
            "pragmaticexternal:WildBooster1",
            "pragmaticexternal:WildCelebrityBusMegaways",
            "pragmaticexternal:WildDepth",
            "pragmaticexternal:WildGladiators",
            "pragmaticexternal:WildHopDrop",
            "pragmaticexternal:WildPixies",
            "pragmaticexternal:WildSpells",
            "pragmaticexternal:WildWalker",
            "pragmaticexternal:WildWestDuels",
            "pragmaticexternal:WildWestGold",
            "pragmaticexternal:WildWestGoldMegaways",
            "pragmaticexternal:WildWildBananas",
            "pragmaticexternal:WildWildRiches",
            "pragmaticexternal:WildWildRichesMegaways",
            "pragmaticexternal:WisdomofAthena",
            "pragmaticexternal:WolfGold",
            "pragmaticexternal:YumYumPowerways",
            "pragmaticexternal:ZeusvsHadesGodsofWar",
            "pragmaticexternal:ZombieCarnival",
            "pragmaticexternal:ChaseforGlory",
            "pragmaticexternal:NileFortune",
            "pragmaticexternal:TimberStacks",
            "pragmaticexternal:FruitParty1"
        ],
        "freespin_bet_level": 2,
        "freespin_quantity": 3,
        "freespin_currency": "USD",
        "freespin_is_real_money": 0,
        "freespin_with_deposit": 0,
        "min_try_amount": null,
        "max_try_amount": null,
        "min_usd_amount": null,
        "max_usd_amount": null,
        "min_eur_amount": null,
        "max_eur_amount": null,
        "rollover_requirement": 2,
        "percent_of_deposit": null,
        "is_only_bonus_money": false,
        "deposit_freespin": null,
        "providers": [
            220
        ],
        "game_types": [
            3
        ],
        "categories": [
            6
        ],
        "timeframe": {
            "start": "2024-06-13",
            "end": "2024-06-30"
        },
        "reusable": null,
        "time_till_withdrawal": null,
        "percentage_by_casino_category": [
            10
        ],
        "week_days": null,
        "payment_methods": null,
        "multiple": null,
        "multiple_count": null,
        "single_bet": null,
        "multiple_bet": null,
        "sports": null,
        "cashback_end_at": null,
        "cashback_start_at": null,
        "cashback_period": null,
        "cashback_recurring": null,
        "cashback_period_arbitrary": null,
        "cashback_percent": null,
        "cashback_request_mode": null,
        "cashback_continuous_mode": 0,
        "cashback_threshold": null,
        "cashback_autopayout": null,
        "freebet_min_amount": null,
        "freebet_coupon_amount": null,
        "withdrawal_at": null,
        "expire_at": null,
        "freespin_valid_until": "2024-06-23 23:59:59",
        "deposit_amount": null,
        "created_at": "2024-06-13T04:08:45.000000Z",
        "updated_at": "2024-06-13T04:08:45.000000Z",
        "user_activation_limit": 0
    },
    "bonus_wallet": [],
    "cashback_detail": [],
    "bonus": null
}

export const MOCK_FREEBET = {
    "id": 5469,
    "user_id": 7187,
    "bonus_id": 0,
    "transaction_id": null,
    "name": "xcvcv",
    "type": "freebet",
    "game_type": "sportbook",
    "is_quick": 1,
    "is_active": 1,
    "cashback_enable": 0,
    "status": "1",
    "amount_processed": 0,
    "amount_to_process": 0,
    "amount_wins": 0,
    "win": null,
    "goal": null,
    "total_amount": "0.00",
    "total_currency": null,
    "amount_reserved": 0,
    "amount_wager": 0,
    "balance_id": 108028,
    "currency": null,
    "sub_bonus_id": null,
    "sub_bonus": null,
    "starts_at": "2024-06-13T10:36:23.000000Z",
    "completed_at": null,
    "cancel_at": null,
    "ends_at": "2024-07-13T10:36:23.000000Z",
    "created_at": "2024-06-13T10:36:23.000000Z",
    "updated_at": "2024-06-13T10:36:23.000000Z",
    "user": {
        "id": 7187,
        "site_id": 90,
        "affiliate_id": null,
        "agent_id": null,
        "site_user_id": 126,
        "username": "m4voit121",
        "email": "2y65986r2e@rentforsale7.com",
        "firstname": null,
        "lastname": null,
        "gender": 0,
        "birthdate": null,
        "language": "en",
        "currency": null,
        "status": 2,
        "is_test": 0,
        "verified_email": false,
        "created_at": "2024-06-07T07:55:50.000000Z",
        "updated_at": "2024-06-07T10:06:39.000000Z",
        "deleted_at": null,
        "disable_validity": null,
        "site": {
            "id": 90,
            "url": "https://nummus.casino",
            "brand": "Nummus",
            "is_crypto": 1,
            "is_affiliate": 0,
            "site_access_token": "16961|LdHIzOCWhFQhUXQIEnMWYPBYlPTYG5FRin4Ek3pY",
            "created_at": "2024-02-28T04:21:37.000000Z",
            "updated_at": "2024-05-22T08:47:35.000000Z",
            "is_blocked": false
        },
        "balance": [
            {
                "id": 108027,
                "user_id": 7187,
                "currency": "TRY",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108029,
                "user_id": 7187,
                "currency": "EUR",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108030,
                "user_id": 7187,
                "currency": "JPY",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108031,
                "user_id": 7187,
                "currency": "BRL",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108032,
                "user_id": 7187,
                "currency": "CAD",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108033,
                "user_id": 7187,
                "currency": "CNY",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108034,
                "user_id": 7187,
                "currency": "DKK",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108035,
                "user_id": 7187,
                "currency": "IDR",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108036,
                "user_id": 7187,
                "currency": "INR",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108037,
                "user_id": 7187,
                "currency": "KRW",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108038,
                "user_id": 7187,
                "currency": "MXN",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108039,
                "user_id": 7187,
                "currency": "PHP",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108040,
                "user_id": 7187,
                "currency": "RUB",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108041,
                "user_id": 7187,
                "currency": "NGN",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108042,
                "user_id": 7187,
                "currency": "BTC",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108043,
                "user_id": 7187,
                "currency": "ETH",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "Activate deposit bonus",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 1,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-13T04:07:44.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108044,
                "user_id": 7187,
                "currency": "LTC",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108045,
                "user_id": 7187,
                "currency": "DOGE",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108046,
                "user_id": 7187,
                "currency": "BCH",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108047,
                "user_id": 7187,
                "currency": "XRP",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108048,
                "user_id": 7187,
                "currency": "TRON",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108049,
                "user_id": 7187,
                "currency": "EOS",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108050,
                "user_id": 7187,
                "currency": "BNB",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108051,
                "user_id": 7187,
                "currency": "USDT",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108052,
                "user_id": 7187,
                "currency": "USDC",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108053,
                "user_id": 7187,
                "currency": "DOT",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108054,
                "user_id": 7187,
                "currency": "LINK",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108055,
                "user_id": 7187,
                "currency": "XLM",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108056,
                "user_id": 7187,
                "currency": "SHIB",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108057,
                "user_id": 7187,
                "currency": "BUSD",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-07T13:11:43.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 108028,
                "user_id": 7187,
                "currency": "USD",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "Cancel bonus 5463",
                "bet_id": null,
                "entity_id": 1086559,
                "currency_default": 1,
                "crypto_currency_default": 0,
                "created_at": "2024-06-07T07:55:50.000000Z",
                "updated_at": "2024-06-13T10:36:23.000000Z",
                "amount_crypto": "0.00000000"
            }
        ]
    },
    "balance": {
        "id": 108028,
        "user_id": 7187,
        "currency": "USD",
        "amount": 0,
        "amount_reserve": 0,
        "amount_bonus": 0,
        "amount_bonus_crypto": "0.00000000",
        "amount_reserved_crypto": "0.00000000",
        "amount_debt": 0,
        "last_operation_detail": "Cancel bonus 5463",
        "bet_id": null,
        "entity_id": 1086559,
        "currency_default": 1,
        "crypto_currency_default": 0,
        "created_at": "2024-06-07T07:55:50.000000Z",
        "updated_at": "2024-06-13T10:36:23.000000Z",
        "amount_crypto": "0.00000000"
    },
    "settings": {
        "id": 5469,
        "bonus_id": 5469,
        "strict": false,
        "global_wager_goal_percent": null,
        "auto_bonus": null,
        "softswiss": null,
        "freespin_games": null,
        "freespin_bet_level": null,
        "freespin_quantity": null,
        "freespin_currency": null,
        "freespin_is_real_money": null,
        "freespin_with_deposit": 0,
        "min_try_amount": null,
        "max_try_amount": null,
        "min_usd_amount": null,
        "max_usd_amount": null,
        "min_eur_amount": null,
        "max_eur_amount": null,
        "rollover_requirement": null,
        "percent_of_deposit": null,
        "is_only_bonus_money": false,
        "deposit_freespin": null,
        "providers": null,
        "game_types": null,
        "categories": null,
        "timeframe": null,
        "reusable": null,
        "time_till_withdrawal": null,
        "percentage_by_casino_category": null,
        "week_days": null,
        "payment_methods": null,
        "multiple": null,
        "multiple_count": null,
        "single_bet": 3,
        "multiple_bet": 4,
        "sports": null,
        "cashback_end_at": null,
        "cashback_start_at": null,
        "cashback_period": null,
        "cashback_recurring": null,
        "cashback_period_arbitrary": null,
        "cashback_percent": null,
        "cashback_request_mode": null,
        "cashback_continuous_mode": 0,
        "cashback_threshold": null,
        "cashback_autopayout": null,
        "freebet_min_amount": 200,
        "freebet_coupon_amount": 3400,
        "withdrawal_at": null,
        "expire_at": null,
        "freespin_valid_until": null,
        "deposit_amount": null,
        "created_at": "2024-06-13T10:36:23.000000Z",
        "updated_at": "2024-06-13T10:36:23.000000Z",
        "user_activation_limit": 0
    },
    "bonus_wallet": [],
    "cashback_detail": [],
    "bonus": null
}

export const MOCK_DEPOSIT = {
    "id": 5370,
    "user_id": 7058,
    "bonus_id": 0,
    "transaction_id": null,
    "name": "test",
    "type": "deposit",
    "game_type": "universal",
    "is_quick": 1,
    "is_active": 1,
    "cashback_enable": 0,
    "status": "1",
    "amount_processed": 122.33,
    "amount_to_process": 2200,
    "amount_wins": 0,
    "win": null,
    "goal": null,
    "total_amount": "11.00",
    "total_currency": "USDT",
    "amount_reserved": 0,
    "amount_wager": 0,
    "balance_id": 105652,
    "currency": "USD",
    "sub_bonus_id": null,
    "sub_bonus": null,
    "starts_at": "2024-06-04T00:34:28.000000Z",
    "completed_at": null,
    "cancel_at": null,
    "ends_at": "2024-07-04T00:34:28.000000Z",
    "created_at": "2024-06-04T00:34:28.000000Z",
    "updated_at": "2024-06-04T00:34:51.000000Z",
    "user": {
        "id": 7058,
        "site_id": 90,
        "affiliate_id": null,
        "agent_id": null,
        "site_user_id": 60,
        "username": "Lilittest1",
        "email": "Lilit@angelshub.com",
        "firstname": null,
        "lastname": null,
        "gender": 0,
        "birthdate": null,
        "language": "en",
        "currency": null,
        "status": 1,
        "is_test": 0,
        "verified_email": false,
        "created_at": "2024-05-22T13:59:20.000000Z",
        "updated_at": "2024-06-04T00:37:03.000000Z",
        "deleted_at": null,
        "disable_validity": null,
        "site": {
            "id": 90,
            "url": "https://nummus.casino",
            "brand": "Nummus",
            "is_crypto": 1,
            "is_affiliate": 0,
            "site_access_token": "16961|LdHIzOCWhFQhUXQIEnMWYPBYlPTYG5FRin4Ek3pY",
            "created_at": "2024-02-28T04:21:37.000000Z",
            "updated_at": "2024-05-22T08:47:35.000000Z",
            "is_blocked": false
        },
        "balance": [
            {
                "id": 105628,
                "user_id": 7058,
                "currency": "TRY",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-05-22T13:59:20.000000Z",
                "updated_at": "2024-06-04T00:37:00.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 105630,
                "user_id": 7058,
                "currency": "EUR",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-05-22T13:59:20.000000Z",
                "updated_at": "2024-06-04T00:37:00.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 105631,
                "user_id": 7058,
                "currency": "JPY",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-05-22T13:59:20.000000Z",
                "updated_at": "2024-06-04T00:37:00.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 105632,
                "user_id": 7058,
                "currency": "BRL",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-05-22T13:59:20.000000Z",
                "updated_at": "2024-06-04T00:37:00.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 105633,
                "user_id": 7058,
                "currency": "CAD",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-05-22T13:59:20.000000Z",
                "updated_at": "2024-06-04T00:37:00.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 105634,
                "user_id": 7058,
                "currency": "CNY",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-05-22T13:59:20.000000Z",
                "updated_at": "2024-06-04T00:37:00.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 105635,
                "user_id": 7058,
                "currency": "DKK",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-05-22T13:59:20.000000Z",
                "updated_at": "2024-06-04T00:37:00.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 105636,
                "user_id": 7058,
                "currency": "IDR",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-05-22T13:59:20.000000Z",
                "updated_at": "2024-06-04T00:37:00.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 105637,
                "user_id": 7058,
                "currency": "INR",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-05-22T13:59:20.000000Z",
                "updated_at": "2024-06-04T00:37:00.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 105638,
                "user_id": 7058,
                "currency": "KRW",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-05-22T13:59:20.000000Z",
                "updated_at": "2024-06-04T00:37:00.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 105639,
                "user_id": 7058,
                "currency": "MXN",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-05-22T13:59:20.000000Z",
                "updated_at": "2024-06-04T00:37:00.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 105640,
                "user_id": 7058,
                "currency": "PHP",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-05-22T13:59:20.000000Z",
                "updated_at": "2024-06-04T00:37:00.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 105641,
                "user_id": 7058,
                "currency": "RUB",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-05-22T13:59:20.000000Z",
                "updated_at": "2024-06-04T00:37:00.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 105642,
                "user_id": 7058,
                "currency": "NGN",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-05-22T13:59:20.000000Z",
                "updated_at": "2024-06-04T00:37:00.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 105643,
                "user_id": 7058,
                "currency": "BTC",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 1,
                "created_at": "2024-05-22T13:59:20.000000Z",
                "updated_at": "2024-06-04T00:37:00.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 105644,
                "user_id": 7058,
                "currency": "ETH",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-05-22T13:59:20.000000Z",
                "updated_at": "2024-06-04T00:37:00.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 105645,
                "user_id": 7058,
                "currency": "LTC",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-05-22T13:59:20.000000Z",
                "updated_at": "2024-06-04T00:37:00.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 105646,
                "user_id": 7058,
                "currency": "DOGE",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-05-22T13:59:20.000000Z",
                "updated_at": "2024-06-04T00:37:00.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 105647,
                "user_id": 7058,
                "currency": "BCH",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-05-22T13:59:20.000000Z",
                "updated_at": "2024-06-04T00:37:00.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 105648,
                "user_id": 7058,
                "currency": "XRP",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-05-22T13:59:20.000000Z",
                "updated_at": "2024-06-04T00:37:00.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 105649,
                "user_id": 7058,
                "currency": "TRON",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-05-22T13:59:20.000000Z",
                "updated_at": "2024-06-04T00:37:00.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 105650,
                "user_id": 7058,
                "currency": "EOS",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-05-22T13:59:20.000000Z",
                "updated_at": "2024-06-04T00:37:00.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 105651,
                "user_id": 7058,
                "currency": "BNB",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-05-22T13:59:20.000000Z",
                "updated_at": "2024-06-04T00:37:00.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 105652,
                "user_id": 7058,
                "currency": "USDT",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "11.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "Activate deposit bonus",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-05-22T13:59:20.000000Z",
                "updated_at": "2024-06-04T00:37:00.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 105653,
                "user_id": 7058,
                "currency": "USDC",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-05-22T13:59:20.000000Z",
                "updated_at": "2024-06-04T00:37:00.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 105654,
                "user_id": 7058,
                "currency": "DOT",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-05-22T13:59:20.000000Z",
                "updated_at": "2024-06-04T00:37:00.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 105655,
                "user_id": 7058,
                "currency": "LINK",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-05-22T13:59:20.000000Z",
                "updated_at": "2024-06-04T00:37:00.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 105656,
                "user_id": 7058,
                "currency": "XLM",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-05-22T13:59:20.000000Z",
                "updated_at": "2024-06-04T00:37:00.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 105657,
                "user_id": 7058,
                "currency": "SHIB",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-05-22T13:59:20.000000Z",
                "updated_at": "2024-06-04T00:37:00.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 105658,
                "user_id": 7058,
                "currency": "BUSD",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-05-22T13:59:20.000000Z",
                "updated_at": "2024-06-04T00:37:00.000000Z",
                "amount_crypto": "0.00000000"
            },
            {
                "id": 105629,
                "user_id": 7058,
                "currency": "USD",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "Cancel bonus 5361",
                "bet_id": null,
                "entity_id": 1041286,
                "currency_default": 1,
                "crypto_currency_default": 0,
                "created_at": "2024-05-22T13:59:20.000000Z",
                "updated_at": "2024-06-04T00:37:00.000000Z",
                "amount_crypto": "0.00000000"
            }
        ]
    },
    "balance": {
        "id": 105652,
        "user_id": 7058,
        "currency": "USDT",
        "amount": 0,
        "amount_reserve": 0,
        "amount_bonus": 0,
        "amount_bonus_crypto": "11.00000000",
        "amount_reserved_crypto": "0.00000000",
        "amount_debt": 0,
        "last_operation_detail": "Activate deposit bonus",
        "bet_id": null,
        "entity_id": null,
        "currency_default": 0,
        "crypto_currency_default": 0,
        "created_at": "2024-05-22T13:59:20.000000Z",
        "updated_at": "2024-06-04T00:37:00.000000Z",
        "amount_crypto": "0.00000000"
    },
    "settings": {
        "id": 5370,
        "bonus_id": 5370,
        "strict": false,
        "global_wager_goal_percent": {
            "casino": "2",
            "sportbook": "2"
        },
        "auto_bonus": null,
        "softswiss": null,
        "freespin_games": null,
        "freespin_bet_level": null,
        "freespin_quantity": null,
        "freespin_currency": null,
        "freespin_is_real_money": null,
        "freespin_with_deposit": 0,
        "min_try_amount": null,
        "max_try_amount": null,
        "min_usd_amount": null,
        "max_usd_amount": null,
        "min_eur_amount": null,
        "max_eur_amount": null,
        "rollover_requirement": 2,
        "percent_of_deposit": null,
        "is_only_bonus_money": false,
        "deposit_freespin": null,
        "providers": [
            4,
            6,
            9,
            11,
            16,
            24,
            25,
            35,
            36,
            37,
            38,
            39,
            40,
            42,
            47,
            52,
            56,
            59,
            60,
            176,
            179,
            181,
            182,
            184,
            189,
            190,
            192,
            194,
            195,
            201,
            203,
            204,
            205,
            206,
            208,
            215,
            216,
            217,
            220,
            223,
            225,
            226,
            228,
            230,
            243,
            248,
            249,
            250,
            251,
            252,
            253,
            254,
            255,
            256,
            257,
            258,
            259,
            260,
            261,
            262,
            263,
            265,
            266,
            267,
            268,
            269,
            270,
            271,
            272,
            273,
            274,
            275,
            276,
            277,
            278,
            279
        ],
        "game_types": [],
        "categories": [
            1,
            2,
            3,
            4,
            6,
            7,
            9,
            10,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
            24,
            25,
            26,
            27,
            28,
            29,
            30,
            31,
            32,
            34,
            65,
            71,
            223,
            224
        ],
        "timeframe": null,
        "reusable": null,
        "time_till_withdrawal": null,
        "percentage_by_casino_category": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "week_days": null,
        "payment_methods": null,
        "multiple": null,
        "multiple_count": null,
        "single_bet": 2,
        "multiple_bet": 2,
        "sports": [],
        "cashback_end_at": null,
        "cashback_start_at": null,
        "cashback_period": null,
        "cashback_recurring": null,
        "cashback_period_arbitrary": null,
        "cashback_percent": null,
        "cashback_request_mode": null,
        "cashback_continuous_mode": 0,
        "cashback_threshold": null,
        "cashback_autopayout": null,
        "freebet_min_amount": null,
        "freebet_coupon_amount": null,
        "withdrawal_at": null,
        "expire_at": null,
        "freespin_valid_until": null,
        "deposit_amount": null,
        "created_at": "2024-06-04T00:34:28.000000Z",
        "updated_at": "2024-06-04T00:34:28.000000Z",
        "user_activation_limit": 0
    },
    "bonus_wallet": [],
    "cashback_detail": [],
    "bonus": null
}

export const MOCK_NO_DEPOSIT = {
    "id": 5843,
    "user_id": 7399,
    "bonus_id": 257,
    "transaction_id": null,
    "name": "No Deposit Test",
    "type": "deposit",
    "game_type": "casino",
    "is_quick": 1,
    "is_active": 1,
    "cashback_enable": 0,
    "status": "1",
    "amount_processed": 0,
    "amount_to_process": 3000,
    "amount_wins": 0,
    "win": null,
    "goal": null,
    "total_amount": "10.00",
    "total_currency": "USDT",
    "amount_reserved": 1000,
    "amount_wager": 0,
    "balance_id": 113036,
    "currency": "USDT",
    "sub_bonus_id": null,
    "sub_bonus": null,
    "starts_at": "2024-07-17T07:30:08.000000Z",
    "completed_at": null,
    "cancel_at": null,
    "ends_at": "2024-07-24T23:59:59.000000Z",
    "created_at": "2024-07-17T07:30:08.000000Z",
    "updated_at": "2024-07-17T07:30:09.000000Z",
    "user": {
        "id": 7399,
        "site_id": 2,
        "affiliate_id": null,
        "agent_id": null,
        "site_user_id": 225,
        "username": "m4voittest141",
        "email": "kjxlcvkjv@sdflkjs.df",
        "firstname": null,
        "lastname": null,
        "gender": 0,
        "birthdate": null,
        "language": "en",
        "currency": null,
        "status": 1,
        "is_test": 0,
        "verified_email": false,
        "created_at": "2024-06-29T17:01:32.000000Z",
        "updated_at": "2024-07-04T09:17:04.000000Z",
        "deleted_at": null,
        "disable_validity": null,
        "site": {
            "id": 2,
            "url": "https://demo2.jetxplatform.com",
            "brand": "Jetxplatform-2",
            "is_crypto": 1,
            "is_affiliate": 0,
            "site_access_token": "28550|7CNEKiiH4uCPWDIyi3Ez9aUrR1WS8UxlOhEinTnl",
            "created_at": "2023-03-23T16:25:17.000000Z",
            "updated_at": "2024-07-16T09:22:07.000000Z",
            "is_blocked": false
        },
        "balance": [
            {
                "id": 113011,
                "user_id": 7399,
                "currency": "TRY",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_crypto": "0.00000000",
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-29T17:01:32.000000Z",
                "updated_at": "2024-06-29T17:01:32.000000Z"
            },
            {
                "id": 113013,
                "user_id": 7399,
                "currency": "EUR",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_crypto": "0.00000000",
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-29T17:01:32.000000Z",
                "updated_at": "2024-06-29T17:01:32.000000Z"
            },
            {
                "id": 113014,
                "user_id": 7399,
                "currency": "JPY",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_crypto": "0.00000000",
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-29T17:01:32.000000Z",
                "updated_at": "2024-06-29T17:01:32.000000Z"
            },
            {
                "id": 113015,
                "user_id": 7399,
                "currency": "BRL",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_crypto": "0.00000000",
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-29T17:01:32.000000Z",
                "updated_at": "2024-06-29T17:01:32.000000Z"
            },
            {
                "id": 113016,
                "user_id": 7399,
                "currency": "CAD",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_crypto": "0.00000000",
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-29T17:01:32.000000Z",
                "updated_at": "2024-06-29T17:01:32.000000Z"
            },
            {
                "id": 113017,
                "user_id": 7399,
                "currency": "CNY",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_crypto": "0.00000000",
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-29T17:01:32.000000Z",
                "updated_at": "2024-06-29T17:01:32.000000Z"
            },
            {
                "id": 113018,
                "user_id": 7399,
                "currency": "DKK",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_crypto": "0.00000000",
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-29T17:01:32.000000Z",
                "updated_at": "2024-06-29T17:01:32.000000Z"
            },
            {
                "id": 113019,
                "user_id": 7399,
                "currency": "IDR",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_crypto": "0.00000000",
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-29T17:01:32.000000Z",
                "updated_at": "2024-06-29T17:01:32.000000Z"
            },
            {
                "id": 113020,
                "user_id": 7399,
                "currency": "INR",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_crypto": "0.00000000",
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-29T17:01:32.000000Z",
                "updated_at": "2024-06-29T17:01:32.000000Z"
            },
            {
                "id": 113021,
                "user_id": 7399,
                "currency": "KRW",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_crypto": "0.00000000",
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-29T17:01:32.000000Z",
                "updated_at": "2024-06-29T17:01:32.000000Z"
            },
            {
                "id": 113022,
                "user_id": 7399,
                "currency": "MXN",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_crypto": "0.00000000",
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-29T17:01:32.000000Z",
                "updated_at": "2024-06-29T17:01:32.000000Z"
            },
            {
                "id": 113023,
                "user_id": 7399,
                "currency": "PHP",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_crypto": "0.00000000",
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-29T17:01:32.000000Z",
                "updated_at": "2024-06-29T17:01:32.000000Z"
            },
            {
                "id": 113024,
                "user_id": 7399,
                "currency": "RUB",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_crypto": "0.00000000",
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-29T17:01:32.000000Z",
                "updated_at": "2024-06-29T17:01:32.000000Z"
            },
            {
                "id": 113025,
                "user_id": 7399,
                "currency": "NGN",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_crypto": "0.00000000",
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-29T17:01:32.000000Z",
                "updated_at": "2024-06-29T17:01:32.000000Z"
            },
            {
                "id": 113026,
                "user_id": 7399,
                "currency": "GBP",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_crypto": "0.00000000",
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-29T17:01:32.000000Z",
                "updated_at": "2024-06-29T17:01:32.000000Z"
            },
            {
                "id": 113027,
                "user_id": 7399,
                "currency": "BTC",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_crypto": "0.00000000",
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 1,
                "created_at": "2024-06-29T17:01:32.000000Z",
                "updated_at": "2024-06-29T17:01:32.000000Z"
            },
            {
                "id": 113028,
                "user_id": 7399,
                "currency": "ETH",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_crypto": "0.00000000",
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-29T17:01:32.000000Z",
                "updated_at": "2024-06-29T17:01:32.000000Z"
            },
            {
                "id": 113029,
                "user_id": 7399,
                "currency": "LTC",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_crypto": "0.00000000",
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-29T17:01:32.000000Z",
                "updated_at": "2024-06-29T17:01:32.000000Z"
            },
            {
                "id": 113030,
                "user_id": 7399,
                "currency": "DOGE",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_crypto": "0.00000000",
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-29T17:01:32.000000Z",
                "updated_at": "2024-06-29T17:01:32.000000Z"
            },
            {
                "id": 113031,
                "user_id": 7399,
                "currency": "BCH",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_crypto": "0.00000000",
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-29T17:01:32.000000Z",
                "updated_at": "2024-06-29T17:01:32.000000Z"
            },
            {
                "id": 113032,
                "user_id": 7399,
                "currency": "XRP",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_crypto": "0.00000000",
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-29T17:01:32.000000Z",
                "updated_at": "2024-06-29T17:01:32.000000Z"
            },
            {
                "id": 113033,
                "user_id": 7399,
                "currency": "TRON",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_crypto": "0.00000000",
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-29T17:01:32.000000Z",
                "updated_at": "2024-06-29T17:01:32.000000Z"
            },
            {
                "id": 113034,
                "user_id": 7399,
                "currency": "EOS",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_crypto": "0.00000000",
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-29T17:01:32.000000Z",
                "updated_at": "2024-06-29T17:01:32.000000Z"
            },
            {
                "id": 113035,
                "user_id": 7399,
                "currency": "BNB",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_crypto": "0.00000000",
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-29T17:01:32.000000Z",
                "updated_at": "2024-06-29T17:01:32.000000Z"
            },
            {
                "id": 113036,
                "user_id": 7399,
                "currency": "USDT",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_crypto": "0.00000000",
                "amount_bonus_crypto": "10.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "Activate deposit bonus",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-29T17:01:32.000000Z",
                "updated_at": "2024-07-17T07:30:09.000000Z"
            },
            {
                "id": 113037,
                "user_id": 7399,
                "currency": "USDC",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_crypto": "0.00000000",
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-29T17:01:32.000000Z",
                "updated_at": "2024-06-29T17:01:32.000000Z"
            },
            {
                "id": 113038,
                "user_id": 7399,
                "currency": "DOT",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_crypto": "0.00000000",
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-29T17:01:32.000000Z",
                "updated_at": "2024-06-29T17:01:32.000000Z"
            },
            {
                "id": 113039,
                "user_id": 7399,
                "currency": "LINK",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_crypto": "0.00000000",
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-29T17:01:32.000000Z",
                "updated_at": "2024-06-29T17:01:32.000000Z"
            },
            {
                "id": 113040,
                "user_id": 7399,
                "currency": "XLM",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_crypto": "0.00000000",
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-29T17:01:32.000000Z",
                "updated_at": "2024-06-29T17:01:32.000000Z"
            },
            {
                "id": 113041,
                "user_id": 7399,
                "currency": "SHIB",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_crypto": "0.00000000",
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-29T17:01:32.000000Z",
                "updated_at": "2024-06-29T17:01:32.000000Z"
            },
            {
                "id": 113042,
                "user_id": 7399,
                "currency": "BUSD",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_crypto": "0.00000000",
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "create balance",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 0,
                "crypto_currency_default": 0,
                "created_at": "2024-06-29T17:01:32.000000Z",
                "updated_at": "2024-06-29T17:01:32.000000Z"
            },
            {
                "id": 113012,
                "user_id": 7399,
                "currency": "USD",
                "amount": 0,
                "amount_reserve": 0,
                "amount_bonus": 0,
                "amount_crypto": "0.00000000",
                "amount_bonus_crypto": "0.00000000",
                "amount_reserved_crypto": "0.00000000",
                "amount_debt": 0,
                "last_operation_detail": "Cancel bonus 5615",
                "bet_id": null,
                "entity_id": null,
                "currency_default": 1,
                "crypto_currency_default": 0,
                "created_at": "2024-06-29T17:01:32.000000Z",
                "updated_at": "2024-07-17T07:30:08.000000Z"
            }
        ]
    },
    "balance": {
        "id": 113036,
        "user_id": 7399,
        "currency": "USDT",
        "amount": 0,
        "amount_reserve": 0,
        "amount_bonus": 0,
        "amount_crypto": "0.00000000",
        "amount_bonus_crypto": "10.00000000",
        "amount_reserved_crypto": "0.00000000",
        "amount_debt": 0,
        "last_operation_detail": "Activate deposit bonus",
        "bet_id": null,
        "entity_id": null,
        "currency_default": 0,
        "crypto_currency_default": 0,
        "created_at": "2024-06-29T17:01:32.000000Z",
        "updated_at": "2024-07-17T07:30:09.000000Z"
    },
    "settings": {
        "id": 5836,
        "bonus_id": 5843,
        "strict": false,
        "global_wager_goal_percent": {
            "casino": null,
            "sportbook": null
        },
        "auto_bonus": 0,
        "softswiss": 0,
        "freespin_games": [],
        "freespin_bet_level": null,
        "freespin_quantity": null,
        "freespin_currency": null,
        "freespin_crypto_currency": null,
        "freespin_is_real_money": 0,
        "freespin_with_deposit": 0,
        "min_try_amount": null,
        "max_try_amount": null,
        "min_usd_amount": null,
        "max_usd_amount": null,
        "min_eur_amount": null,
        "max_eur_amount": null,
        "rollover_requirement": 3,
        "rollover_real_money": null,
        "percent_of_deposit": 0,
        "is_only_bonus_money": false,
        "is_bonus_money_first": false,
        "bmf_stage": null,
        "deposit_freespin": 0,
        "providers": [
            4,
            6,
            9,
            11,
            16,
            24,
            25,
            35,
            36,
            37,
            38,
            39,
            40,
            42,
            47,
            52,
            56,
            59,
            60,
            176,
            179,
            181,
            182,
            184,
            189,
            190,
            192,
            194,
            195,
            201,
            203,
            204,
            205,
            206,
            208,
            215,
            216,
            217,
            220,
            223,
            225,
            226,
            228,
            230,
            243,
            248,
            249,
            250,
            251,
            252,
            253,
            254,
            255,
            256,
            257,
            258,
            259,
            260,
            261,
            262,
            263,
            265,
            266,
            267,
            268,
            269,
            270,
            271,
            272,
            273,
            274,
            275,
            276,
            277,
            278,
            279
        ],
        "game_types": [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
            30,
            33,
            34,
            35,
            45,
            48,
            49,
            50,
            51,
            52,
            53,
            54,
            55,
            56,
            57,
            58,
            59,
            60,
            61,
            62,
            63,
            64,
            65,
            66,
            67,
            68,
            69,
            71,
            72
        ],
        "categories": [
            227
        ],
        "timeframe": {
            "start": "2024-07-01",
            "end": "2024-07-08"
        },
        "reusable": true,
        "time_till_withdrawal": null,
        "percentage_by_casino_category": [
            100
        ],
        "week_days": [],
        "payment_methods": [],
        "multiple": 0,
        "multiple_count": 10,
        "single_bet": 0,
        "multiple_bet": 0,
        "sports": [],
        "cashback_end_at": null,
        "cashback_start_at": null,
        "cashback_period": null,
        "cashback_recurring": 0,
        "cashback_period_arbitrary": 0,
        "cashback_percent": [
            {
                "min": null,
                "max": null,
                "percentage": null,
                "currency": null
            }
        ],
        "cashback_request_mode": 0,
        "cashback_continuous_mode": 0,
        "cashback_threshold": 0,
        "cashback_autopayout": 0,
        "freebet_min_amount": null,
        "freebet_coupon_amount": null,
        "freebet_currency": null,
        "withdrawal_at": null,
        "expire_at": null,
        "freespin_valid_until": null,
        "deposit_amount": {
            "USDT": {
                "amount": 10,
                "currency": "USDT"
            }
        },
        "created_at": "2024-07-17T07:30:08.000000Z",
        "updated_at": "2024-07-17T07:30:08.000000Z",
        "user_activation_limit": 10
    },
    "bonus_wallet": [],
    "cashback_detail": [],
    "bonus": {
        "id": 257,
        "name": "No Deposit Test",
        "promocode": null,
        "type_id": 1,
        "type": "casino",
        "is_no_deposit": 1,
        "settings": {
            "global_wager_goal_percent": {
                "casino": null,
                "sportbook": null
            },
            "strict": false,
            "withdrawal_at": null,
            "expire_at": "7",
            "auto_bonus": false,
            "softswiss": false,
            "freespin_aggregator_id": null,
            "freespin_providers": [],
            "freespin_games": [],
            "freespin_valid_until": null,
            "freespin_bet_level": null,
            "freespin_quantity": null,
            "freespin_currency": null,
            "freespin_is_real_money": false,
            "freespin_with_deposit": false,
            "freespin_crypto_currency": null,
            "sub_bonus": false,
            "sub_bonus_id": null,
            "cashback_period": null,
            "cashback_recurring": false,
            "cashback_period_arbitrary": false,
            "cashback_start_at": null,
            "cashback_end_at": null,
            "cashback_threshold": 0,
            "cashback_request_mode": false,
            "cashback_continuous_mode": false,
            "cashback_autopayout": false,
            "cashback_percent": [
                {
                    "min": null,
                    "max": null,
                    "percentage": null,
                    "currency": null
                }
            ],
            "freebet_coupon_amount": null,
            "freebet_min_amount": null,
            "percentage_by_casino_category": [
                100
            ],
            "week_days": [],
            "payment_methods": [],
            "multiple": false,
            "multiple_count": "10",
            "user_activation_limit": "10",
            "deposit_amount": {
                "USDT": {
                    "amount": 10,
                    "currency": "USDT"
                }
            },
            "rollover_requirement": "3",
            "percent_of_deposit": 0,
            "is_only_bonus_money": false,
            "providers": [
                4,
                6,
                9,
                11,
                16,
                24,
                25,
                35,
                36,
                37,
                38,
                39,
                40,
                42,
                47,
                52,
                56,
                59,
                60,
                176,
                179,
                181,
                182,
                184,
                189,
                190,
                192,
                194,
                195,
                201,
                203,
                204,
                205,
                206,
                208,
                215,
                216,
                217,
                220,
                223,
                225,
                226,
                228,
                230,
                243,
                248,
                249,
                250,
                251,
                252,
                253,
                254,
                255,
                256,
                257,
                258,
                259,
                260,
                261,
                262,
                263,
                265,
                266,
                267,
                268,
                269,
                270,
                271,
                272,
                273,
                274,
                275,
                276,
                277,
                278,
                279
            ],
            "game_types": [
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                8,
                9,
                10,
                11,
                12,
                13,
                14,
                15,
                16,
                17,
                18,
                19,
                20,
                21,
                22,
                23,
                30,
                33,
                34,
                35,
                45,
                48,
                49,
                50,
                51,
                52,
                53,
                54,
                55,
                56,
                57,
                58,
                59,
                60,
                61,
                62,
                63,
                64,
                65,
                66,
                67,
                68,
                69,
                71,
                72
            ],
            "categories": [
                227
            ],
            "timeframe_start": "2024-07-01",
            "timeframe_end": "2024-07-08",
            "reusable": true,
            "deposit_freespin": false,
            "single_bet": 0,
            "multiple_bet": 0,
            "sports": []
        },
        "start_at": "2024-07-01 00:00:00",
        "end_at": "2024-07-08 23:59:59",
        "created_at": "2024-07-01T14:36:33.000000Z",
        "updated_at": "2024-07-01T14:36:33.000000Z",
        "sportbook_settings": null,
        "description": "{\"no\":null}",
        "active": 1
    },
    "freespin": null
}
