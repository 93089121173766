<template>
    <div
        class="text-center"
        :class="cssClass"
        style="height: 30px;"
    >
        <v-menu
            v-model="menu"
            offset-y
            :max-height="$auth.user().is_crypto ? '400px' : '200px'"
            z-index="110"
            offset-overflow
        >
            <template v-slot:activator="{ on, attrs }">
                <v-chip
                    dark
                    small
                    label
                    v-bind="attrs"
                    v-on="on"
                    style="height: 30px;"
                    class="flex"
                >
                    <div
                        v-if="showCryptoAsFiat"
                        v-html="getSymbol(fiatCurrency)"
                        class="mr-1"
                    />

                    <div class="font-weight-bold">
                        {{ balanceTotalFormatted }}
                    </div>

                    <svg
                        v-if="$auth.user().cryptoBalance"
                        v-html="getIcon(cryptoCurrency)"
                        style="max-width: 18px; height: 18px"
                        class="ml-2"
                    />

                    <v-icon class="ml-1">
                        {{ menu ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                    </v-icon>
                </v-chip>

                <!--                <v-tooltip-->
                <!--                    v-if="freebetBonus"-->
                <!--                    bottom-->
                <!--                >-->
                <!--                    <template v-slot:activator="{ on, attrs }">-->
                <!--                        <v-icon-->
                <!--                            class="ml-3 cashback-icon"-->
                <!--                            v-bind="attrs"-->
                <!--                            v-on="on"-->
                <!--                            @click="getFreebetInfo()"-->
                <!--                        >-->
                <!--                            mdi-gift-->
                <!--                        </v-icon>-->
                <!--                    </template>-->
                <!--                    <span>{{ $t('common.freebetBonus') }}</span>-->
                <!--                </v-tooltip>-->

                <!--                <v-tooltip-->
                <!--                    v-if="freespinBonus"-->
                <!--                    bottom-->
                <!--                >-->
                <!--                    <template v-slot:activator="{ on, attrs }">-->
                <!--                        <v-icon-->
                <!--                            class="ml-3 cashback-icon"-->
                <!--                            v-bind="attrs"-->
                <!--                            v-on="on"-->
                <!--                            @click="getFreespinInfo()"-->
                <!--                        >-->
                <!--                            mdi-slot-machine-->
                <!--                        </v-icon>-->
                <!--                    </template>-->
                <!--                    <span>{{ $t('common.freespinBonus') }}</span>-->
                <!--                </v-tooltip>-->

            </template>

            <v-list v-if="bonus.isActive" class="blue-grey darken-5">
                <v-list-item>
                    <v-list-item-title class="text-subtitle-2 d-inline-flex align-center">
                        <span>{{bonus.data.name}}</span>
                        <div class="spacer" />
                        <span class="ml-2" >status: {{bonus.data.status}}</span>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
            <v-list class="blue-grey darken-5">
                <v-list-item
                    v-for="(item, i) in cryptoBalances"
                    :key="i"
                    v-show="showCryptoCurrency(item)"
                    @click="setDefaultCryptoBalance(item)"
                >
                    <v-list-item-title class="text-subtitle-2 d-inline-flex align-center">
                        <div v-if="showCryptoAsFiat">
                            <span v-html="getSymbol($auth.user().currency)"/>

                            <span class="font-weight-bold">
                                {{ parseFloat(item.amount).toFixed(2) }}
                            </span>
                        </div>

                        <div v-else>
                            {{ parseFloat(item.amount_crypto).toFixed(4) }}
                        </div>

                        <div class="spacer" />

                        <div class="d-flex align-center ml-8">
                            {{ item.name }}

                            <svg
                                v-html="getIcon(item.name)"
                                style="width: 18px; height: 18px"
                                class="ml-1"
                            />
                        </div>
                    </v-list-item-title>
                </v-list-item>
            </v-list>

            <v-list-item @click="balanceDialog = true" class="wallet-settings-link">
                <v-list-item-title class="text-subtitle-2 d-flex align-center">
                    <v-icon class="mr-2">mdi-cog-outline</v-icon>
                    <span>{{ $t("menu.settingsWallet") }}</span>
                </v-list-item-title>
            </v-list-item>
        </v-menu>

        <v-dialog
            v-if="$auth.user().active_bonus"
            v-model="freebet_dialog"
            max-width="600"
            dark
        >
            <v-card class="pb-2">
                <v-card-title class="headline">
                    {{ $t("dashboard.sections.bonuses.freebetDetails") }}
                </v-card-title>

                <v-card-text class="d-flex align-center mb-2">
                    <div class="text-subtitle-1">{{ $t('dashboard.sections.bonuses.couponAmount') }}:</div>

                    <div class="text-subtitle-1 font-weight-bold ml-2">
                        {{ $auth.user().active_bonus.settings.freebet_coupon_amount / 100 }}
                    </div>
                </v-card-text>

                <v-card-text class="d-flex align-center mb-2">
                    <div class="text-subtitle-1">{{ $t('dashboard.sections.bonuses.minimumOddsForSingleBets') }}:</div>
                    <div class="text-subtitle-1 font-weight-bold ml-2">
                        {{ $auth.user().active_bonus.settings.single_bet }}
                    </div>
                </v-card-text>

                <v-card-text class="d-flex align-center mb-2">
                    <div class="text-subtitle-1">
                        {{ $t('dashboard.sections.bonuses.minimumOddsForMultipleBets') }}:
                    </div>
                    <div class="text-subtitle-1 font-weight-bold ml-2">
                        {{ $auth.user().active_bonus.settings.multiple_bet }}
                    </div>
                </v-card-text>

                <v-card-text class="d-flex align-center mb-2">
                    <div class="text-subtitle-1">{{ $t('dashboard.sections.bonuses.notice') }}:</div>
                    <div class="text-subtitle-1 font-weight-bold ml-2">
                        {{ $t('dashboard.sections.bonuses.freebetNotice') }}
                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="tile-gradient-dark"
                        text
                        @click="freebet_dialog = false"
                    >
                        {{ $t("common.close") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-if="$auth.user().active_bonus"
            v-model="freespin_dialog"
            max-width="600"
            dark
        >
            <v-card class="pb-2">
                <v-card-title class="headline">
                    {{ $t("dashboard.sections.bonuses.freespinDetails") }}
                </v-card-title>

                <v-card-text class="d-flex align-center mb-2">
                    <div class="text-subtitle-1">{{ $t('dashboard.sections.bonuses.freespinsAmount') }}:</div>
                    <div class="text-subtitle-1 font-weight-bold ml-2">
                        {{ $auth.user().active_bonus.settings.freespin_quantity }}
                    </div>
                </v-card-text>

                <v-card-text v-if="freespinAllowedProviders && freespinAllowedProviders.length">
                    <div class="text-subtitle-1 mb-2">{{ $t('dashboard.sections.bonuses.allowedGameProviders') }}:</div>
                    <span v-for="provider in freespinAllowedProviders" :key="provider.id">
                        <router-link :to="'/casino/' + provider.slug" class="text-subtitle-1 font-weight-bold">
                            {{ provider.original_name }}
                        </router-link>
                    </span>
                </v-card-text>

                <v-card-text v-if="freespinAllowedGames && freespinAllowedGames.length">
                    <div class="text-subtitle-1 mb-2">{{ $t('dashboard.sections.bonuses.allowedGames') }}:</div>
                    <div class="d-flex flex-wrap justify-space-between justify-lg-start">
                        <router-link
                            v-for="game in freespinAllowedGames" :key="game.id"
                            :to="'/casino/' + (game.game_provider ? game.game_provider.slug : 'provider') + '/' + game.slug"
                            class="freespin-game">
                            <v-img
                                max-width="150"
                                class="rounded elevation-1"
                                aspect-ratio="1"
                                :src="getImage(game, 'public', 200)"
                                :lazy-src="getImage(game, '250x250', 50)"
                                position="center top">
                            </v-img>
                        </router-link>
                    </div>
                </v-card-text>

                <v-card-text v-if="freespinAllowedCategories && freespinAllowedCategories.length">
                    <div class="text-subtitle-1 mb-2">{{ $t('dashboard.sections.bonuses.allowedCategories') }}:</div>
                    <span v-for="category in freespinAllowedCategories" :key="category.id">
                        <router-link :to="'/casino/category/' + category.slug" class="text-subtitle-1 font-weight-bold">
                            {{ category.name }}
                        </router-link>
                    </span>
                </v-card-text>

                <v-card-text>
                    <div class="text-subtitle-1 mb-2">{{ $t('dashboard.sections.bonuses.turnoverRequirements') }}:
                        {{ $auth.user().active_bonus.settings.rollover_requirement }}x
                        {{ $t('dashboard.sections.bonuses.turnoverRequirementsText') }}
                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="tile-gradient-dark"
                        text
                        @click="freespin_dialog = false"
                    >
                        {{ $t("common.close") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="balanceDialog"
            max-width="600"
            dark
            :persistent=true
        >
            <v-card class="pb-2">
                <v-card-title class="headline text-h4">
                    {{ $t("dashboard.sections.cryptoTitle") }}
                </v-card-title>

                <v-card-text class="d-flex align-center mt-4 mb-2">
                    <v-switch
                        class="mt-0 pa-0"
                        dense
                        inset
                        hide-details
                        color="success"
                        :false-value=0
                        :true-value=1
                        v-model="cryptoNullBalance"
                    />

                    <span>{{ $t('dashboard.sections.hideZeroBalanceText') }}</span>
                </v-card-text>

                <v-card-text class="d-flex align-center mt-4 mb-2">
                    <v-switch
                        class="mt-0 pa-0"
                        dense
                        inset
                        hide-details
                        color="success"
                        :false-value=0
                        :true-value=1
                        v-model="showCryptoAsFiat"
                    />

                    <span>{{ $t('dashboard.sections.cryptoPrintText') }}</span>
                </v-card-text>

                <v-card-text v-if="available_currencies">
                    <div class="text-subtitle-1 text-overline mb-1">{{ $t('dashboard.sections.fiatCurrencies') }}:</div>
                    <v-radio-group v-model="fiatCurrency" row>
                        <v-radio
                            :disabled="!showCryptoAsFiat"
                            class="mr-4 mb-4"
                            v-for="(currency, key) in available_currencies" :key="key"
                            :label="currency.name"
                            :value="currency.name"
                        >
                            <template v-slot:label>
                                {{ currency.name }}
                                <svg class="ml-1" style="max-width: 14px;height: 14px" v-html="currency.icon"></svg>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </v-card-text>

                <v-card-actions>
                    <v-spacer />

                    <v-btn
                        class="primary"
                        text
                        @click="saveBalance()"
                    >
                        {{ $t("common.save") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex"
import config from "@/configs"

export default {
    name: "ToolbarCryptoBalance",
    props: {
        cssClass: ""
    },
    data() {
        return {
            menu: null,
            balanceDialog: false,
            freespin_dialog: false,
            freebet_dialog: false,
            freespinAllowedProviders: [],
            freespinAllowedGames: [],
            freespinAllowedCategories: [],
            currencies: config.crypto_currencies.currencies,
            crypto_currencies: config.crypto_currencies.crypto_currencies,
        }
    },

    watch: {
        userActiveBonus() {
            this.$store.commit("app/setBonusUpdateKey")
        }
    },

    computed: {
        ...mapState({
            images_cdn: state => state.app.brandSettings.images_cdn,
            bonus_target: state => state.user.bonus_target,
            bonus_deposit: state => state.user.bonus_deposit,
            bonus: state => state.user.bonus,
            brand_currencies: state => state.app.brandSettings.currencies ? state.app.brandSettings.currencies.split(',') : null,
            available_currencies() {
                return this.brand_currencies ? this.currencies.filter(v => this.brand_currencies.find(c => c === v.name)) : []
            },
            cryptoBalances: state => state.user.crypto.balances,
            cryptoCurrentBalance: state => state.user.crypto.current,
        }),
        authUser() {
            return this.$auth.user();
        },
        cryptoCurrency: {
            get () {
                return this.$store.state.user.crypto.crypto_currency
            },
            set (value) {
                this.$store.commit('user/setCryptoCurrency', value);
            }
        },
        fiatCurrency: {
            get () {
                return this.$store.state.user.crypto.fiat_currency
            },
            set (value) {
                this.$store.commit('user/setFiatCurrency', value);
            }
        },
        showCryptoAsFiat: {
            get () {
                return this.$store.state.user.crypto.is_print
            },
            set (value) {
                this.$store.commit('user/setCryptoPrint', value);
            }
        },
        cryptoNullBalance: {
            get () {
                return this.$store.state.user.crypto.is_null_balance
            },
            set (value) {
                this.$store.commit('user/setCryptoNullBalance', value);
            }
        },
        balanceTotalFormatted() {
            if (!this.cryptoCurrentBalance) {
                return 0;
            }
            let result;
            if (this.showCryptoAsFiat) {
                result = parseFloat(this.cryptoCurrentBalance.amount) + parseFloat(this.cryptoCurrentBalance.amount_bonus)
            } else {
                result = parseFloat(this.cryptoCurrentBalance.amount_crypto) + parseFloat(this.cryptoCurrentBalance.amount_bonus_crypto)
            }
            result = result.toFixed(this.showCryptoAsFiat ? 2 : 4);
            return result;
        },
        freebetBonus() {
            return !Object.keys(this.bonus_deposit).length && this.userActiveBonus && Object.keys(this.userActiveBonus).length && this.userActiveBonus.type === 'freebet'
        },
        freespinBonus() {
            return !Object.keys(this.bonus_deposit).length && this.userActiveBonus && Object.keys(this.userActiveBonus).length && this.userActiveBonus.type === 'freespin' && +this.userActiveBonus.status === 1 && this.bonus_target < 100
        },
        userActiveBonus() {
            return this.$auth.user().active_bonus
        },
    },

    async created() {
        this.$store.commit('user/setCryptoPrint', this.authUser.is_crypto_print)
        this.$store.commit('user/setCryptoNullBalance', this.authUser.crypto_null_balance)
        this.$store.commit('user/setFiatCurrency', this.authUser.currency ?? 'USD')
        this.$store.commit('user/setCryptoCurrency', this.authUser.cryptoBalance.currency ?? 'BTC')
        await this.getBalances()
    },

    methods: {

        ...mapActions({
            setLayout: "app/setLayout",
            setCryptoBalance: 'user/setCryptoBalance',
        }),

        showCryptoCurrency(item) {
            let result;
            if (this.showCryptoAsFiat) {
                result = parseFloat(item.amount) + parseFloat(item.amount_bonus)
            } else {
                result = parseFloat(item.amount_crypto) + parseFloat(item.amount_bonus_crypto)
            }
            return result > 0 || !this.cryptoNullBalance;
        },

        async getBalances() {
            await axios.get("/api/show-balance").then(res => {
                this.setCryptoBalance({
                    balances: res.data,
                    userCryptoCurrency: this.cryptoCurrency
                })
            })
        },

        setDefaultCryptoBalance(balance) {
            this.cryptoCurrency = balance.name;
            axios.post("/api/set-default-crypto-currencies", {
                currency: balance.name
            })
        },

        saveBalance() {
            axios.post('/api/set-currencies', {
                "crypto_print"          : this.showCryptoAsFiat,
                "crypto_null_balance"   : this.cryptoNullBalance,
                "fiat_currency"         : this.fiatCurrency,
                "crypto_currency"       : this.cryptoCurrency
            }).then(async () => {
                if (this.authUser.currency !== this.fiatCurrency) {
                    await this.$auth.fetch();
                }
                this.balanceDialog = false
            })
        },

        getSymbol(name) {
            const currency = this.currencies.find(v => v.name === name)
            return currency ? currency.symbol : ""
        },

        getIcon(name) {
            return this.crypto_currencies.find(v => v.name === name)
                ? this.crypto_currencies.find(v => v.name === name).icon
                : ""
        },

        getFreebetInfo() {
            this.freebet_dialog = true
        },

        getFreespinInfo() {
            axios.get('/api/freespin_info', {
                params: {
                    providers: this.$auth.user().active_bonus.settings.providers,
                    games: this.$auth.user().active_bonus.settings.freespin_games,
                    categories: this.$auth.user().active_bonus.settings.categories,
                }
            }).then(res => {
                this.freespin_dialog = true
                this.freespinAllowedProviders = res.data.providers
                this.freespinAllowedGames = res.data.games
                this.freespinAllowedCategories = res.data.categories
            })
        },

        getImage(gameItem, cloudflare_size, cdn_size) {
            if (this.mdAndUp) {
                if (gameItem.banner.includes('.svg') || gameItem.banner.includes('gif')) {
                    return gameItem.banner
                }
                if (gameItem.banner_cloudflare) {
                    return gameItem.banner_cloudflare + cloudflare_size
                } else {
                    return this.images_cdn + cdn_size + '/' + gameItem.banner
                }
            } else {
                if (gameItem.mobile_banner.includes('.svg') || gameItem.mobile_banner.includes('gif')) {
                    return gameItem.mobile_banner
                }
                if (gameItem.mobile_banner_cloudflare) {
                    return gameItem.mobile_banner_cloudflare + cloudflare_size
                } else {
                    return this.images_cdn + cdn_size + '/' + gameItem.mobile_banner
                }
            }
        }
    }
}
</script>

<style lang="scss">
.freespin-game {
    max-width: 24%;
    width: 100%;
    margin-right: 6px;
    margin-bottom: 6px;

    &:nth-child(4n) {
        margin-right: 0;
    }

    @include media("md-and-down") {
        max-width: 49%;
        margin-right: 0;
    }
}

.cashback-icon {
    animation: wobble 2s ease-in-out infinite;
}

.wallet-settings-link {
    position: sticky;
    left: 0;
    bottom: 0;
    background: #000;
}

@keyframes wobble {
    0% {
        transform: translateZ(0);
    }
    15% {
        transform: translate3d(-25%, 0, 0) rotate(-5deg);
    }
    30% {
        transform: translate3d(20%, 0, 0) rotate(3deg);
    }
    45% {
        transform: translate3d(-15%, 0, 0) rotate(-3deg);
    }
    60% {
        transform: translate3d(10%, 0, 0) rotate(2deg);
    }
    75% {
        transform: translate3d(-5%, 0, 0) rotate(-1deg);
    }
    to {
        transform: translateZ(0);
    }
}
</style>
