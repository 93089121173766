import i18n from "@/plugins/vue-i18n";
import Swal from 'sweetalert2';

/**
 * @param {Object} options
 * @param {string} options.title
 * @param {string} options.text
 * @param {string} options.type
 * @param {boolean} options.showCancelButton
 * @param {string} options.confirmButtonColor
 * @param {string} options.cancelButtonColor
 * @param {string} options.confirmButtonText
 * @returns {Promise<unknown>}
 */
const confirmAlert = async (options) => {
    options = options || {};
    let swalOptions = {
        title: 'Are you sure?',
        text: '',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#05090c',
        cancelButtonColor: '#ff3774',
        confirmButtonText: 'Confirm',
    };

    swalOptions = {
        ...swalOptions,
        ...options,
    }

    return new Promise((reject) => {
        Swal.fire(swalOptions).then(res => {
            reject(res.isConfirmed)
        });
    })
}

/**
 * @param {string} [text]
 * @return {Promise}
 */
export const cancelBonus = (text) => {
    const options = {
        title: i18n.t('alertDialog.cancelBonus.title'),
        text: text ?? i18n.t('alertDialog.cancelBonus.text'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'var(--v-primary-base)',
        cancelButtonColor: 'var(--v-cancel-base)',
        confirmButtonText: i18n.t('alertDialog.cancelBonus.confirmButton'),
        cancelButtonText: i18n.t('alertDialog.cancelBonus.cancelButton'),
    };
    return confirmAlert(options);
}

/**
 * @param {string} [text]
 * @return {Promise}
 */
export const confirmBonus = (text) => {
    const options = {
        title: i18n.t('alertDialog.confirmBonus.title'),
        text: text ?? i18n.t('alertDialog.confirmBonus.text'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'var(--v-primary-base)',
        cancelButtonColor: 'var(--v-cancel-base)',
        confirmButtonText: i18n.t('alertDialog.confirmBonus.confirmButton'),
        cancelButtonText: i18n.t('alertDialog.confirmBonus.cancelButton'),
    };
    return confirmAlert(options);
}

/**
 * @param {string} [text]
 * @return {Promise}
 */
export const confirmBuyCrypto = (text) => {
    const options = {
        title: i18n.t('alertDialog.confirmBuyCrypto.title'),
        text: text ?? i18n.t('alertDialog.confirmBuyCrypto.text'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'var(--v-primary-base)',
        cancelButtonColor: 'var(--v-cancel-base)',
        confirmButtonText: i18n.t('alertDialog.confirmBuyCrypto.confirmButton'),
        cancelButtonText: i18n.t('alertDialog.confirmBuyCrypto.cancelButton'),
    };
    return confirmAlert(options);
}


export default confirmAlert

