var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app-bar',{staticClass:"main-toolbar elevation-0 mobile-toolbar",staticStyle:{"z-index":"91","background-color":"var(--v-header-base)"},attrs:{"app":"","height":_vm.height.main,"extension-height":_vm.height.extension},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('app-tabs',_vm._l((_vm.headerItems),function(headerItem,i){return _c('header-item',{key:i,attrs:{"item":headerItem}})}),1)]},proxy:true}])},[[_c('div',[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',{staticClass:"d-flex flex-grow-1 align-center justify-space-between"},[_c('logo-responsive'),_vm._v(" "),_c('div',{staticClass:"main-toolbar__buttons"},[(_vm.authUser && _vm.hasCorrectFreeSpinBonusWalletCombination)?_c('toolbar-bonus'):_vm._e(),_vm._v(" "),(_vm.authUser && !_vm.settings.crypto)?_c('toolbar-balance-static',{key:_vm.bonusUpdateKey,attrs:{"css-class":"mx-2"}}):_vm._e(),_vm._v(" "),(_vm.authUser)?_c('toolbar-crypto-balance',{attrs:{"cssClass":"mx-2"}}):_vm._e(),_vm._v(" "),(_vm.settings.crypto)?_c('toolbar-crypto-wallet-links'):_vm._e(),_vm._v(" "),(
                                    (_vm.authUser && !_vm.profileDrawerStyle) ||
                                        (_vm.authUser &&
                                            _vm.profileDrawerStyle === 'classic')
                                )?_c('toolbar-user',{on:{"open":_vm.openProfile}}):_vm._e(),_vm._v(" "),(
                                    _vm.authUser &&
                                        _vm.profileDrawerStyle &&
                                        _vm.profileDrawerStyle === 'modern'
                                )?_c('toolbar-user-modern'):_vm._e(),_vm._v(" "),(!_vm.authUser)?_c('toolbar-auth-links',{attrs:{"login":true,"register":true}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"d-flex align-center justify-end ml-2"},[(_vm.isDesktop)?_c('toolbar-language',{attrs:{"cssClass":''}}):_vm._e()],1)],1)])])],_vm._v(" "),_vm._v(" "),(_vm.$auth.user() && _vm.$auth.user().active_bonus)?_c('v-dialog',{attrs:{"max-width":"600","dark":""},model:{value:(_vm.freebet_dialog),callback:function ($$v) {_vm.freebet_dialog=$$v},expression:"freebet_dialog"}},[_c('v-card',{staticClass:"pb-2"},[_c('v-card-title',{staticClass:"headline"},[_vm._v("\n                    "+_vm._s(_vm.$t("dashboard.sections.bonuses.freebetDetails"))+"\n                ")]),_vm._v(" "),_c('v-card-text',{staticClass:"d-flex align-center mb-2"},[_c('div',{staticClass:"text-subtitle-1"},[_vm._v("\n                        "+_vm._s(_vm.$t("dashboard.sections.bonuses.couponAmount"))+":\n                    ")]),_vm._v(" "),_c('div',{staticClass:"text-subtitle-1 font-weight-bold ml-2"},[_vm._v("\n                        "+_vm._s(_vm.$auth.user().active_bonus.settings
                                .freebet_coupon_amount / 100)+"\n                    ")])]),_vm._v(" "),_c('v-card-text',{staticClass:"d-flex align-center mb-2"},[_c('div',{staticClass:"text-subtitle-1"},[_vm._v("\n                        "+_vm._s(_vm.$t(
                                "dashboard.sections.bonuses.minimumOddsForSingleBets"
                            ))+":\n                    ")]),_vm._v(" "),_c('div',{staticClass:"text-subtitle-1 font-weight-bold ml-2"},[_vm._v("\n                        "+_vm._s(_vm.$auth.user().active_bonus.settings.single_bet)+"\n                    ")])]),_vm._v(" "),_c('v-card-text',{staticClass:"d-flex align-center mb-2"},[_c('div',{staticClass:"text-subtitle-1"},[_vm._v("\n                        "+_vm._s(_vm.$t(
                                "dashboard.sections.bonuses.minimumOddsForMultipleBets"
                            ))+":\n                    ")]),_vm._v(" "),_c('div',{staticClass:"text-subtitle-1 font-weight-bold ml-2"},[_vm._v("\n                        "+_vm._s(_vm.$auth.user().active_bonus.settings.multiple_bet)+"\n                    ")])]),_vm._v(" "),_c('v-card-text',{staticClass:"d-flex align-center mb-2"},[_c('div',{staticClass:"text-subtitle-1"},[_vm._v("\n                        "+_vm._s(_vm.$t("dashboard.sections.bonuses.notice"))+":\n                    ")]),_vm._v(" "),_c('div',{staticClass:"text-subtitle-1 font-weight-bold ml-2"},[_vm._v("\n                        "+_vm._s(_vm.$t("dashboard.sections.bonuses.freebetNotice"))+"\n                    ")])]),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"tile-gradient-dark",attrs:{"text":""},on:{"click":function($event){_vm.freebet_dialog = false}}},[_vm._v("\n                        "+_vm._s(_vm.$t("common.close"))+"\n                    ")])],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.$auth.user() && _vm.$auth.user().active_bonus)?_c('v-dialog',{attrs:{"max-width":"600","dark":""},model:{value:(_vm.freespin_dialog),callback:function ($$v) {_vm.freespin_dialog=$$v},expression:"freespin_dialog"}},[_c('v-card',{staticClass:"pb-2"},[_c('v-card-title',{staticClass:"headline"},[_vm._v("\n                    "+_vm._s(_vm.$t("dashboard.sections.bonuses.freespinDetails"))+"\n                ")]),_vm._v(" "),_c('v-card-text',{staticClass:"d-flex align-center mb-2"},[_c('div',{staticClass:"text-subtitle-1"},[_vm._v("\n                        "+_vm._s(_vm.$t("dashboard.sections.bonuses.freespinsAmount"))+":\n                    ")]),_vm._v(" "),_c('div',{staticClass:"text-subtitle-1 font-weight-bold ml-2"},[_vm._v("\n                        "+_vm._s(_vm.$auth.user().active_bonus.settings.freespin_quantity)+"\n                    ")])]),_vm._v(" "),(
                        _vm.freespinAllowedProviders &&
                            _vm.freespinAllowedProviders.length
                    )?_c('v-card-text',[_c('div',{staticClass:"text-subtitle-1 mb-2"},[_vm._v("\n                        "+_vm._s(_vm.$t(
                                "dashboard.sections.bonuses.allowedGameProviders"
                            ))+":\n                    ")]),_vm._v(" "),_vm._l((_vm.freespinAllowedProviders),function(provider){return _c('span',{key:provider.id,staticClass:"ml-2"},[_c('router-link',{staticClass:"text-subtitle-1 font-weight-bold",attrs:{"to":'/casino/' + provider.slug}},[_vm._v(_vm._s(provider.original_name)+",")])],1)})],2):_vm._e(),_vm._v(" "),(_vm.freespinAllowedGames && _vm.freespinAllowedGames.length)?_c('v-card-text',[_c('div',{staticClass:"text-subtitle-1 mb-2"},[_vm._v("\n                        "+_vm._s(_vm.$t("dashboard.sections.bonuses.allowedGames"))+":\n                    ")]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-wrap justify-space-between justify-lg-start"},_vm._l((_vm.freespinAllowedGames),function(game){return _c('router-link',{key:game.id,staticClass:"freespin-game",attrs:{"to":'/casino/' +
                                    (game.game_provider
                                        ? game.game_provider.slug
                                        : 'provider') +
                                    '/' +
                                    game.slug}},[_c('v-img',{staticClass:"rounded elevation-1",attrs:{"max-width":"150","aspect-ratio":"1","src":_vm.getImage(game, 'public', 200),"lazy-src":_vm.getImage(game, '250x250', 50),"position":"center top"}})],1)}),1)]):_vm._e(),_vm._v(" "),(
                        _vm.freespinAllowedCategories &&
                            _vm.freespinAllowedCategories.length
                    )?_c('v-card-text',[_c('div',{staticClass:"text-subtitle-1 mb-2"},[_vm._v("\n                        "+_vm._s(_vm.$t("dashboard.sections.bonuses.allowedCategories"))+":\n                    ")]),_vm._v(" "),_vm._l((_vm.freespinAllowedCategories),function(category){return _c('span',{key:category.id,staticClass:"ml-2"},[_c('router-link',{staticClass:"text-subtitle-1 font-weight-bold",attrs:{"to":'/casino/category/' + category.slug}},[_vm._v("\n                            "+_vm._s(category.name[_vm.current_lang]
                                    ? category.name[_vm.current_lang]
                                    : category.name.en)+",\n                        ")])],1)})],2):_vm._e(),_vm._v(" "),(!_vm.freespinIsRealMoney)?_c('v-card-text',[_c('div',{staticClass:"text-subtitle-1 mb-2"},[_vm._v("\n                        "+_vm._s(_vm.$t(
                                "dashboard.sections.bonuses.turnoverRequirements"
                            ))+":\n                        "+_vm._s(_vm.$auth.user().active_bonus.settings
                                .rollover_requirement)+"x\n                        "+_vm._s(_vm.$t(
                                "dashboard.sections.bonuses.turnoverRequirementsText"
                            ))+"\n                    ")])]):_vm._e(),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"tile-gradient-dark",attrs:{"text":""},on:{"click":function($event){_vm.freespin_dialog = false}}},[_vm._v("\n                        "+_vm._s(_vm.$t("common.close"))+"\n                    ")])],1)],1)],1):_vm._e()],2)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }