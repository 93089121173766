<template>
    <div class="text-center" style="height: 30px;">

        <v-menu offset-y z-index="110">
            <template v-slot:activator="{ on, attrs }">
                <v-chip dark small label v-bind="attrs" v-on="on" style="height: 30px;">
                    {{ userTotalAmount }} {{ $auth.user().currency }}
                </v-chip>
            </template>
            <v-list v-if="userBonusAmount > 0" dark class="blue-grey darken-5">
                <v-list-item>
                    <v-list-item-title class="text-subtitle-2">{{ $t("common.bonusMoney") }}:
                        {{ userBonusAmount }} {{ $auth.user().currency }}
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>

import {mapState} from "vuex";

export default {
    name: "ToolbarBalanceGame",
    data() {
        return {
            amount: null,
            bonus_amount: 0,
        }
    },
    computed: {
        ...mapState({
            userAmount: state => state.user.game_amount,
            userBonusAmount: state => state.user.game_bonus_amount,
            userTotalAmount: state => state.user.game_total_amount < 0 ? 0 : state.user.game_total_amount,
        }),
    },
    methods: {},
}
</script>
