<template>
    <v-dialog v-model="freespinDialog" max-width="400" persistent>
        <v-card
            dark
            color="secondary"
            tile
            flat
            class="pa-4">
            <v-card-title class="mb-4">{{ $t('dialog.freespinMessage') }}</v-card-title>
            <v-card-subtitle>
                <div class="d-block mb-2">{{ $t('dialog.freespinQty') }}: {{ freespinSetting.freespin_quantity }}</div>
                <div class="d-block mb-2">{{ $t('dialog.validUntil') }}: {{
                        freespinSetting.freespin_valid_until
                    }}
                </div>
            </v-card-subtitle>
            <v-card-actions>
                <v-btn dark @click="closeFreespinDialog()">{{ $t('buttons.no') }}</v-btn>
                <v-btn color="primary" class="ml-2" @click="freespinRequest()">{{ $t('buttons.yes') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "CloneDialog",
    props: {
        freespinDialog: {
            type: Boolean,
            default: () => false
        },
        freespinSetting: {
            type: Object,
            default: () => {
            }
        },
        freespin_launch_code: {
            type: String
        }
    },
    methods: {
        freespinRequest() {
            axios.get(`/api/user-dashboard/freespin-request?api_user_id=${this.$auth.user().api_user_id}&launch_code=${this.freespin_launch_code}&token=${this.$auth.user().game_token}`).then(res => {

            }).catch(e => {
                console.log(e)
            })
            this.$emit('closeFreespinDialog')
        },
        closeFreespinDialog() {
            this.$emit('closeFreespinDialog')
        }
    }
}
</script>

<style scoped>

</style>
