<template>
    <div :class="{'d-flex': width>0}" style="height: 100%">
        <div class="modal-appender-image" :class="{loaded: isShow, 'd-none': width<1}" :style="wrapperStyle">
            <div class="modal-appender-image__inner">
                <img src="/images/login.jpg" alt="" @load="load">
            </div>
        </div>
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: 'ModalAppenderImage',
    props: {
        width: {
            type: [Number, String],
            required: true
        },
        parentActive: {
            type: Boolean
        }
    },
    data: () => {
        return {
            isShow: false,
        }
    },
    computed: {
        wrapperStyle() {
            return {
                width: this.width + 'px',
            }
        }
    },
    methods: {
        load() {
            this.isShow = true;
        }
    },
}
</script>
<style lang="scss" scoped>
    .modal-appender-image {
        flex-shrink: 0;
        overflow: hidden;

        .modal-appender-image__inner {
            overflow: hidden;
            transform: translateX(400px);
            transition: all .3s ease;
        }
        &.loaded .modal-appender-image__inner {
            transform: translateX(0);
        }

        img {
            display: block;
            object-fit: cover;
        }
    }
</style>
