<template>
    <v-dialog
        v-model="activeComputed"
        width="480"
        persistent
        :fullscreen="smAndDown"
        :hide-overlay="smAndDown"
    >
        <v-card class="text-center pa-1" dark color="blue-grey darken-4">
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="red darken-1"
                    icon
                    @click="activeComputed = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-actions>

            <v-card-title class="justify-center display-1 mb-2">{{ $t('common.attention') }}</v-card-title>

            <v-card-text>
                {{ $t('common.timeoutBetMessage') }}
            </v-card-text>

            <v-btn
                class="tile-gradient-primary white--text mt-4"
                x-large
                height="56"
                width="100%"
                @click="activeComputed = false"
            >
                {{ $t("common.ok") }}
            </v-btn>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions} from 'vuex';
import breaks from "@/mixins/breaks";

export default {
    props: ['active'],
    mixins: [breaks],
    computed: {
        activeComputed: {
            get() {
                return this.active;
            },
            set(value) {
                this.makeActiveWindow({active: value, type: 'betTimeout'});
            }
        }
    },
    methods: {
        ...mapActions('app', ['makeActiveWindow']),
    }
}
</script>

<style scoped>

</style>
