import {toInteger} from "lodash/lang";

export default {
    setProvidersGroupBySlug(state, payload) {
        state.providersGroupBySlug = payload
    },
    setCategories: (state, payload) => {
        state.categories = payload;
    },
    setGamePage: (state, payload) => {
        state.gamePage = payload;
    },
    clearGamesByCategories: (state) => {
        state.gamesByCategories  = {};
    },
    addGamesToGamesByCategories: (state, payload) => {
        state.gamesByCategories  = {...state.gamesByCategories, ...payload};
    },

    initialized: (state) => {
        state.initialized = true;
    },

    setGameUrl: (state, payload) => {
        state.gameUrl = payload;
    },
    updateItems: (state, payload) => {
        state.items = payload
    },
    setItems: (state, payload) => {
        state.items = payload
    },
    addItems: (state, payload) => {
        state.items = [...state.items, ...payload]
    },
    setPagination: (state, payload) => {
        state.pagination = _.clone({...state.pagination, ...payload})
    },
    setPaginatePage: (state, payload) => {
        state.paginatePage = payload
    },
    nextPaginatePage: (state) => {
        state.paginatePage++
    },
    setHasNextPage: (state, payload) => {
      state.hasNextPage = !!payload;
    },
    filteredByProvider(state, payload) {
        state.filteredByProvider = payload
    },
    updateFilter(state, payload) {
        state.filter = {...state.filter, ...payload}
    },
    setFilter(state, payload) {
        payload.providersId && (payload.providersId = _toArray(payload.providersId))
        payload.categoriesId && (payload.categoriesId = _toArray(payload.categoriesId))
        state.filter = payload;
    },
    setPlatform(state, payload) {
        state.platform = payload;
    }
}

function _toArray(val) {
    return val ? typeof val === 'string' ? val.split(/\s*,\s*/).map(v=>parseInt(v)) : val : [];
}
