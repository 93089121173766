import menuPages from './menus/pages.menu'

export default {
  // main navigation - side menu
  menu: [
    {
      text: 'Pages',
      key: 'menu.pages',
      items: menuPages
    }
  ],

  // footer links
  footer: [{
    text: 'Docs',
    key: 'menu.docs',
    href: 'https://vuetifyjs.com',
    target: '_blank'
  }]
}
