export default {
    categoryTypes: {
        'casino': {
            is_live: 0,
            is_virtual_sport: 0,
        },
        'live-casino': {
            is_live: 1,
            is_virtual_sport: 0,
        },
        'virtual-sports': {
            is_live: 0,
            is_virtual_sport: 1,
        },
    },
    providerTypes: {
        'casino': '',
        'live-casino': 'live-casino',
        'virtual-sports': 'virtual-sports'
    },
    default: {
        categoryType: 'casino',
        providerType: ''
    }
}
