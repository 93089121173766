<template>

    <div class="custom-radio-group">
        <div class="custom-radio-group__messages">
            <div class="custom-radio-group__label"> {{ $attrs.label }} </div>
            <div class="custom-radio-group__error"> {{ getErrors }} </div>
        </div>
        <v-radio-group
            v-bind="$attrs"
            v-on="$listeners"
            ref="radioGroup"
        >
            <template v-for="slot in parentSlots" #[slot]>
                <slot :name="slot" />
            </template>
        </v-radio-group>
    </div>

    <!--  :appendOuterIcon="appendOuterIcon"-->
    <!--  :autofocus="autofocus"-->
    <!--  :clearable="clearable"-->
    <!--  :clearIcon="clearIcon"-->
    <!--  :counter="counter"-->
    <!--  :counterValue="counterValue"-->
    <!--  :filled="filled"-->
    <!--  :flat="flat"-->
    <!--  :fullWidth="fullWidth"-->
    <!--  :label="label"-->
    <!--  :outlined="outlined"-->
    <!--  :placeholder="placeholder"-->
    <!--  :prefix="prefix"-->
    <!--  :prependInnerIcon="prependInnerIcon"-->
    <!--  :reverse="reverse"-->
    <!--  :rounded="rounded"-->
    <!--  :shaped="shaped"-->
    <!--  :singleLine="singleLine"-->
    <!--  :solo="solo"-->
    <!--  :soloInverted="soloInverted"-->
    <!--  :suffix="suffix"-->
    <!--  :type="type"-->
</template>

<script>
export default {
    name: "CustomRadioGroup",
    components: {},
    inheritAttrs: false,
    data() {
        return {
            isMounted: false
        }
    },
    computed: {
        parentSlots() {
            return Object.keys(this.$slots)
        },
        getErrors() {
            if (this.isMounted) {
                const radioGroup = this.$refs.radioGroup;
                return (radioGroup.hasState && radioGroup?.errorBucket?.length) ? this.$refs.radioGroup.errorBucket[0] : this.$attrs['error-messages'];
            }
            return '';
        }
    },
    mounted() {
        this.isMounted = true;
    },
}
</script>

<style lang="scss">
.custom-radio-group {

    .custom-radio-group__messages {
        display: flex;
        align-items: flex-end;
        width: 100%;
        margin-bottom: 8px;
        text-align: left;

        color: var(--v-gray-2-base);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        .custom-radio-group__title {

        }

        .custom-radio-group__error {
            margin-left: 8px;
            color: var(--v-error-base);
        }
    }

    .v-input--selection-controls {
        margin: 0;
        padding: 0;
    }

    .v-input--radio-group {
        .v-input--radio-group__input {
            height: 40px;
            justify-content: stretch;
            width: 100%;
            border: 1px solid rgba(215, 218, 224, 0.50);
            border-radius: 4px;

            &:hover {
                border-color: rgba(215, 218, 224, 1);

                .v-radio {
                    border-color: rgba(215, 218, 224, 1);
                }
            }

            legend {
                display: none;
            }
        }

        &.error--text {
            .v-input--radio-group__input {
                border: 2px solid var(--v-error-base);
            }

            .v-radio:not(:last-of-type) {
                border-right: 2px solid var(--v-error-base);

                &:hover {
                    border-right: 2px solid var(--v-error-base);
                }
            }
        }

        .v-messages {
            display: none;
        }


        .v-radio {
            flex-grow: 1;
            height: 100%;
            margin: 0 !important;
            padding: 0 46px;

            &:not(:last-of-type) {
                border-right: 1px solid rgba(215, 218, 224, 0.50);
            }

            &:hover {
                background-color: var(--v-background-lighten1);
            }

            &.v-item--active {
                background-color: var(--v-gray-2-base);

                .v-label {
                    color: var(--v-gray-3-base);

                }
            }


            .v-input--selection-controls__input {
                display: none;
            }

            .v-label {
                justify-content: center;

                color: var(--v-gray-2-base);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
            }
        }
    }
}
</style>
