<template>
    <v-tabs class="app-tabs header-tabs"
            :class="$auth.user() ? 'app-tabs-custom' : ''"
            :background-color="backgroundColor"
            :centered="centered"
            hide-slider
            :color="color"
            :show-arrows="false"
            :grow="grow"
            v-model="value"
            @change="onChange"
    >
        <slot/>
    </v-tabs>
</template>

<script>
export default {
  name: "AppTabs",
  model: {
    prop: 'model',
    event: 'change'
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'transparent'
    },
    color: {
      type: String,
      default: 'primary',
    },
    centered: {
      type: Boolean,
      default: false
    },
    grow: {
      type: Boolean,
      default: false
    },
    model: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    value: 0
  }),
  watch: {
    model () {
      this.init();
    }
  },
  created () {
    this.init();
  },
  methods: {
    init () {
      this.value = this.model;
    },
    onChange () {
      this.$emit('change', this.value);
    }
  }
}
</script>

<style lang="scss">
@import '../../../sass/modules/tabs';
</style>
