export default [
        {
            title: {
                en: 'Soccer'
            },
            image: '/images/icons/sportsbook/soccer.svg',
            link: '/sports1#/prematch/soccer/'
        },
        {
            title: {
                en: 'Basketball'
            },
            image: '/images/icons/sportsbook/basketball.svg',
            link: '/sports1#/prematch/basketball/'
        },
        {
            title: {
                en: 'Tennis'
            },
            image: '/images/icons/sportsbook/tennis.svg',
            link: '/sports1#/prematch/tennis/'
        },
        {
            title: {
                en: 'Volleyball'
            },
            image: '/images/icons/sportsbook/volleyball.svg',
            link: '/sports1#/prematch/volleyball/'
        },
        {
            title: {
                en: 'American Football'
            },
            image: '/images/icons/sportsbook/am-football.svg',
            link: '/sports1#/prematch/american-football/'
        },
        {
            title: {
                en: 'Ice Hockey'
            },
            image: '/images/icons/sportsbook/hockey.svg',
            link: '/sports1#/prematch/ice-hockey/'
        },
        {
            title: {
                en: 'Rugby'
            },
            image: '/images/icons/sportsbook/rugby.svg',
            link: '/sports1#/prematch/rugby/'
        },
        {
            title: {
                en: 'Baseball'
            },
            image: '/images/icons/sportsbook/baseball.svg',
            link: '/sports1#/prematch/baseball/'
        },
        {
            title: {
                en: 'Snooker'
            },
            image: '/images/icons/sportsbook/snooker.svg',
            link: '/sports1#/prematch/snooker/'
        },
    ]
