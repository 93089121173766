<template>
    <div class="in-menu-banner">
        <div class="in-menu-banner__label">
            Active
        </div>

            <div class="in-menu-banner__expires">
                Expires in 23 Days • 4 Hr • 15 Min
            </div>
            <div class="in-menu-banner__title">
                Bet £20 on Sports events and Get £10
            </div>
            <div class="in-menu-banner__progress">
                <v-progress-linear
                    value="20"
                    height="5px"
                    style="border-radius: 10px"
                />
                <v-icon>mdi-gift-outline</v-icon>
            </div>
            <div class="in-menu-banner__condition">
                <div class="in-menu-banner__amount">
                    <span class="primary--text">£0.50</span>
                    of £20.00
                </div>
                <a href="#" class="in-menu-banner__terms">
                    T&C's Apply
                </a>
            </div>
            <v-btn
                class="button--gray mt-2 mb-0"
                width="100%"
            >
                View Bonus Details
            </v-btn>
        </div>

</template>

<script>

import {mapState} from "vuex";
import Items from "@/configs/menus/user.menu"

export default {

};
</script>

<style lang="scss">

.in-menu-banner {
    // max-width: 350px;
    position: relative;
    margin-top: 16px;
    padding: 16px;
    border: 2px solid var(--v-primary-base);
    border-radius: 12px !important;

    > * {
        margin-bottom: 4px;
    }

    &:last-child {
        margin-bottom: 0;
    }

    .in-menu-banner__label {
        position: absolute;
        top: -10px;
        left: 24px;
        padding: 0 4px;
        background-color: var(--v-primary-base);
        border-radius: 5px;

        color: var(--v-background-base);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 128.571% */
        letter-spacing: 1px;
        text-transform: uppercase;

        &:before, &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            height: 100%;
            width: 4px;
            background-color: currentColor;
        }
        &:before {
            left:-4px;
        }

        &:after {
            right:-4px;
        }
    }

    .in-menu-banner__expires {
        color: var(--v-gray-2-base);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }

    .in-menu-banner__title {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }

    .in-menu-banner__progress {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
    }

    .in-menu-banner__condition {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
    }
}
</style>
