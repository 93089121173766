<template>
    <div class="game-top-bar">
        <div class="d-flex justify-content-end align-items-center flex-gap-4 flex-grow-1">
            <a
                href="#"
                @click.prevent="$router.push(`/${$route.meta.page?$route.meta.page:'casino'}`)"
                class="game-top-bar__back"
            >
                <v-icon class="mb-1">betvamos-arrow-left</v-icon>
                Back to Games
            </a>

            <div class="game-top-bar__name">
<!--                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path d="M12.3 2.78129V0.300085C12.3 0.134346 12.1659 0 11.9999 0C11.8342 0 11.6998 0.134346 11.6998 0.300085V2.78129C11.6998 2.94703 11.834 3.08138 11.9999 3.08138C12.1657 3.08138 12.3 2.94703 12.3 2.78129Z" fill="#D7DAE0"/>-->
<!--                    <path d="M7.39068 4.31546C7.44174 4.31546 7.49322 4.30248 7.54031 4.27528C7.68386 4.19241 7.73304 4.00889 7.65017 3.86534L6.40946 1.71643C6.32638 1.57288 6.14244 1.52391 5.99973 1.60657C5.85617 1.68944 5.80699 1.87296 5.88986 2.01651L7.13058 4.16542C7.18624 4.26168 7.2871 4.31546 7.39068 4.31546Z" fill="#D7DAE0"/>-->
<!--                    <path d="M4.16628 7.13062L2.01737 5.88991C1.87423 5.80704 1.6905 5.85601 1.60764 5.99977C1.52477 6.14333 1.57395 6.32664 1.7175 6.40972L3.8664 7.65043C3.91349 7.67763 3.96518 7.69061 4.01603 7.69061C4.11982 7.69061 4.22048 7.63683 4.27615 7.54056C4.35922 7.39701 4.30983 7.21348 4.16628 7.13062Z" fill="#D7DAE0"/>-->
<!--                    <path d="M0 11.9993C0 12.165 0.134137 12.2994 0.300084 12.2994H2.78151C2.94724 12.2994 3.08159 12.165 3.08159 11.9993C3.08159 11.8336 2.94745 11.6992 2.78151 11.6992H0.300084C0.134137 11.6992 0 11.8336 0 11.9993Z" fill="#D7DAE0"/>-->
<!--                    <path d="M3.86616 16.3489L1.71726 17.5897C1.5737 17.6727 1.52452 17.856 1.60739 17.9996C1.66306 18.0959 1.76392 18.1496 1.86751 18.1496C1.91857 18.1496 1.97005 18.1367 2.01713 18.1095L4.16604 16.8687C4.30959 16.7859 4.35877 16.6024 4.2759 16.4588C4.19282 16.3153 4.00887 16.2659 3.86616 16.3489Z" fill="#D7DAE0"/>-->
<!--                    <path d="M7.13082 19.8339L5.89011 21.9828C5.80724 22.1263 5.85642 22.3096 5.99997 22.3927C6.04706 22.4199 6.09875 22.4329 6.1496 22.4329C6.25339 22.4329 6.35405 22.3791 6.40971 22.2828L7.65042 20.1339C7.73329 19.9904 7.68411 19.8071 7.54056 19.724C7.39742 19.6409 7.21368 19.6901 7.13082 19.8339Z" fill="#D7DAE0"/>-->
<!--                    <path d="M11.7 21.2181V23.6993C11.7 23.865 11.8341 23.9993 12 23.9993C12.1658 23.9993 12.3001 23.865 12.3001 23.6993V21.2181C12.3001 21.0523 12.166 20.918 12 20.918C11.8343 20.918 11.7 21.0523 11.7 21.2181Z" fill="#D7DAE0"/>-->
<!--                    <path d="M16.4592 19.724C16.3156 19.8069 16.2665 19.9904 16.3493 20.1339L17.59 22.2828C17.6457 22.3791 17.7466 22.4329 17.8502 22.4329C17.9012 22.4329 17.9527 22.4199 17.9998 22.3927C18.1433 22.3098 18.1925 22.1263 18.1096 21.9828L16.8689 19.8339C16.7859 19.6901 16.6023 19.6409 16.4592 19.724Z" fill="#D7DAE0"/>-->
<!--                    <path d="M19.8337 16.8688L21.9826 18.1095C22.0297 18.1367 22.0812 18.1497 22.1323 18.1497C22.2361 18.1497 22.3367 18.0959 22.3924 17.9996C22.4753 17.8561 22.4261 17.6728 22.2825 17.5897L20.1336 16.349C19.9903 16.2659 19.8067 16.3151 19.7237 16.4589C19.6408 16.6024 19.6902 16.7859 19.8337 16.8688Z" fill="#D7DAE0"/>-->
<!--                    <path d="M23.7 11.6992H21.2185C21.0528 11.6992 20.9185 11.8336 20.9185 11.9993C20.9185 12.165 21.0526 12.2994 21.2185 12.2994H23.7C23.8657 12.2994 24 12.165 24 11.9993C24 11.8336 23.8659 11.6992 23.7 11.6992Z" fill="#D7DAE0"/>-->
<!--                    <path d="M19.9843 7.6904C20.0353 7.6904 20.0868 7.67742 20.1339 7.65022L22.2828 6.40951C22.4264 6.32664 22.4756 6.14332 22.3927 5.99977C22.3094 5.85601 22.1255 5.80704 21.9827 5.88991L19.8338 7.13062C19.6903 7.21349 19.6411 7.39701 19.724 7.54056C19.7798 7.63683 19.8805 7.6904 19.9843 7.6904Z" fill="#D7DAE0"/>-->
<!--                    <path d="M16.6093 4.31546C16.7131 4.31546 16.8137 4.26168 16.8694 4.16542L18.1101 2.01651C18.193 1.87296 18.1438 1.68964 18.0002 1.60657C17.8569 1.52391 17.6729 1.57288 17.5903 1.71643L16.3496 3.86534C16.2667 4.00889 16.3159 4.19221 16.4594 4.27528C16.5067 4.30248 16.5582 4.31546 16.6093 4.31546Z" fill="#D7DAE0"/>-->
<!--                    <path d="M12 18.6054C15.6428 18.6054 18.6064 15.6418 18.6064 11.999C18.6064 8.35614 15.6428 5.39258 12 5.39258C8.35712 5.39258 5.39355 8.35614 5.39355 11.999C5.39355 15.6418 8.35712 18.6054 12 18.6054ZM7.55269 7.97578L8.98947 9.41257C8.45231 10.0372 8.10699 10.8289 8.04153 11.6988H6.00855C6.07991 10.2693 6.65034 8.97226 7.55269 7.97578ZM17.9911 11.6988H15.9583C15.8928 10.8289 15.5477 10.037 15.0103 9.41257L16.4471 7.97578C17.3495 8.97232 17.9199 10.2693 17.9911 11.6988ZM16.4471 16.0219L15.0103 14.5851C15.5475 13.9605 15.8928 13.1688 15.9583 12.2989H17.9913C17.9199 13.7284 17.3495 15.0254 16.4471 16.0219ZM11.9999 8.62534C13.86 8.62534 15.3734 10.1387 15.3734 11.9989C15.3734 13.859 13.86 15.3724 11.9999 15.3724C10.1397 15.3724 8.62631 13.859 8.62631 11.9989C8.62631 10.1387 10.1397 8.62534 11.9999 8.62534ZM9.4136 15.0089C10.038 15.5463 10.8299 15.8916 11.6998 15.957V17.99C10.2701 17.9189 8.97329 17.3482 7.97681 16.4461L9.4136 15.0089ZM12.3 15.957C13.1701 15.8915 13.9617 15.5463 14.5862 15.0091L16.023 16.4459C15.0264 17.348 13.7295 17.9187 12.3 17.9898V15.957ZM14.5862 8.9885C13.9617 8.45112 13.1699 8.1058 12.3 8.04034V6.00736C13.7297 6.07851 15.0265 6.64915 16.023 7.55129L14.5862 8.9885ZM11.6998 8.04034C10.8297 8.10584 10.038 8.45112 9.4136 8.98828L7.97681 7.5515C8.97335 6.64936 10.2703 6.07872 11.6998 6.00758V8.04034ZM8.04148 12.2988C8.10698 13.1687 8.45205 13.9606 8.98942 14.585L7.55264 16.0218C6.65028 15.0253 6.07986 13.7283 6.00871 12.2988H8.04148Z" fill="#D7DAE0"/>-->
<!--                    <path d="M9.41149 11.3443L10.3672 12.4314L10.2331 13.8726C10.2234 13.978 10.2699 14.0808 10.3555 14.1431C10.4076 14.181 10.4695 14.2005 10.5319 14.2005C10.5721 14.2005 10.6125 14.1923 10.6508 14.176L11.9796 13.6026L13.309 14.176C13.4065 14.2183 13.5185 14.2055 13.6041 14.1431C13.6897 14.081 13.7361 13.978 13.7265 13.8726L13.5919 12.4314L14.5477 11.3443C14.6178 11.2647 14.6406 11.1543 14.6077 11.0534C14.5749 10.9525 14.4914 10.8768 14.3882 10.8535L12.9759 10.5359L12.2376 9.291C12.1292 9.10873 11.8298 9.10873 11.7214 9.291L10.9831 10.5359L9.57082 10.8535C9.46765 10.8768 9.38415 10.9527 9.3513 11.0534C9.31865 11.1543 9.34146 11.2647 9.41157 11.3443H9.41149ZM11.2411 11.0929C11.3216 11.0747 11.3911 11.0243 11.4332 10.9534L11.9795 10.0322L12.5259 10.9534C12.5682 11.0243 12.6374 11.0747 12.718 11.0929L13.7631 11.3279L13.0558 12.1324C13.0014 12.1943 12.9746 12.2761 12.9823 12.3582L13.0821 13.4248L12.0984 13.0006C12.0229 12.9682 11.9371 12.9682 11.8609 13.0006L10.8776 13.4248L10.977 12.3584C10.9845 12.2761 10.958 12.1945 10.9035 12.1326L10.1962 11.3282L11.2411 11.0929Z" fill="#D7DAE0"/>-->
<!--                </svg>-->
<!--                Top Games/-->
                {{ game.name }}
                <game-top-bar-bonus class="ml-2" />
            </div>
        </div>

        <div class="d-flex justify-content-end align-items-center flex-gap-2">
<!--            <v-btn-->
<!--                class="button-black pa-0"-->
<!--                outlined-->
<!--                width="36"-->
<!--                min-width="36"-->
<!--                height="36"-->
<!--            >-->
<!--                <v-icon size="18">betvamos-volume-off</v-icon>-->
<!--            </v-btn>-->
<!--            <v-btn-->
<!--                class="button-black pa-0"-->
<!--                outlined-->
<!--                width="36"-->
<!--                min-width="36"-->
<!--                height="36"-->
<!--            >-->
<!--                <v-icon size="18">betvamos-star-outline-2</v-icon>-->
<!--            </v-btn>-->
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        class="button-black pa-0"
                        outlined
                        width="36"
                        min-width="36"
                        height="36"
                        v-on="on"
                    >
                        <v-icon size="18">{{ currentScreenView.icon }}</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item
                        v-for="view in screenView"
                        :key="view.type"
                    >
                        <v-btn
                            class="button-black pa-0"
                            outlined
                            width="36"
                            min-width="36"
                            height="36"
                            @click="setView(view)"
                        >
                            <v-icon size="18">{{ view.icon }}</v-icon>
                        </v-btn>
                    </v-list-item>
                </v-list>
            </v-menu>

            <v-btn
                class="button-black pa-0"
                outlined
                width="36"
                min-width="36"
                height="36"
                @click="setFullscreen"
            >
                <v-icon size="18">betvamos-fullscreen</v-icon>
            </v-btn>
        </div>



<!--        <v-tooltip bottom class="surface">-->
<!--            <template v-slot:activator="{ on, attrs }">-->
<!--                <div-->
<!--                    class="mr-4"-->
<!--                    style="cursor:pointer;"-->
<!--                    @click="$router.push('/casino')"-->
<!--                    v-bind="attrs"-->
<!--                    v-on="on">-->
<!--                    <v-icon dark large>mdi-chevron-left</v-icon>-->
<!--                </div>-->
<!--            </template>-->
<!--            <span class="text-overline">{{ $t("slotsGame.back") }}</span>-->
<!--        </v-tooltip>-->


<!--        <div class="ml-10 d-flex align-center">-->
<!--            <v-tooltip bottom v-for="(item, i) in gridView" :key="i" class="surface">-->
<!--                <template v-slot:activator="{ on, attrs }">-->
<!--                    <div-->
<!--                        v-bind="attrs"-->
<!--                        v-on="on"-->
<!--                        class="game-top-sidebar-grid-item mr-1"-->
<!--                        :class="item.active ? 'active' : ''"-->
<!--                        @click="setView(i + (i === 2 ? 2 : 1))">-->
<!--                        <v-icon large>{{ item.icon }}</v-icon>-->
<!--                    </div>-->
<!--                </template>-->
<!--                <span class="text-overline">{{ $t(item.text) }}</span>-->
<!--            </v-tooltip>-->
<!--        </div>-->

<!--        <v-card dark color="transparent" class="d-flex justify-center flex-grow-1 flex-shrink-0">-->
<!--            <div class="d-flex justify-center flex-grow-1 flex-shrink-0" v-if="authUser">-->
<!--                <div v-if="depositBonus || (freespinBonus && !freespinIsRealMoney)"-->
<!--                     style="width: 80%"-->
<!--                >-->
<!--                    <div class="text-subtitle-2 text-uppercase">{{ activeBonus.name }}</div>-->
<!--                    <div class="d-flex align-center">-->
<!--                        <v-progress-linear-->
<!--                            :value="bonus_target"-->
<!--                            height="25">-->
<!--                            <template v-slot:default="{ value }">-->
<!--                                <strong>{{ bonus_target }}%</strong>-->
<!--                            </template>-->
<!--                        </v-progress-linear>-->
<!--                        <v-btn small class="ml-2 tile-gradient-dark"-->
<!--                               v-if="!disallowPlayerCancelBonus"-->
<!--                               @click="currentBonus = activeBonus; confirmDialog = true">-->
<!--                            {{ $t("dashboard.sections.bonuses.cancelBonus") }}-->
<!--                        </v-btn>-->
<!--                        <div class="text-caption ml-2">{{ $t("dashboard.sections.bonuses.endsAt") }}:-->
<!--                            {{ $moment(activeBonus.ends_at).format('DD.MM.YYYY') }}-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

<!--                <div v-else-if="Object.keys(bonus_deposit).length && bonus_target < 100" style="width: 80%">-->
<!--                    <div class="text-subtitle-2 text-uppercase">{{ bonus_deposit.name }}</div>-->
<!--                    <div class="d-flex align-center">-->
<!--                        <v-progress-linear-->
<!--                            :value="bonus_target"-->
<!--                            height="25">-->
<!--                            <template v-slot:default="{ value }">-->
<!--                                <strong>{{ bonus_target }}%</strong>-->
<!--                            </template>-->
<!--                        </v-progress-linear>-->
<!--                        <v-btn small class="ml-2 tile-gradient-dark"-->
<!--                               v-if="!disallowPlayerCancelBonus"-->
<!--                               @click="currentBonus = bonus_deposit; confirmDialog = true">-->
<!--                            {{ $t("dashboard.sections.bonuses.cancelBonus") }}-->
<!--                        </v-btn>-->
<!--                        <div class="text-caption ml-2">{{ $t("dashboard.sections.bonuses.endsAt") }}:-->
<!--                            {{ $moment(activeBonus.ends_at).format('DD.MM.YYYY') }}-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </v-card>-->

<!--        <v-card dark class="d-flex align-center justify-end"-->
<!--                style="background-color: transparent; box-shadow: none">-->
<!--            <div class="text-body-2 mr-4">{{ time }}</div>-->
<!--            <v-btn-->
<!--                v-if="authUser && lgAndUp"-->
<!--                color="primary"-->
<!--                class="mr-2 px-2 black&#45;&#45;text"-->
<!--                to="/user/deposit"-->
<!--                height="30">-->
<!--                {{ $t("menu.deposit") }}-->
<!--            </v-btn>-->
<!--            <toolbar-balance-game-->
<!--                v-if="authUser && activeBonus && (activeBonus.type === 'deposit' || activeBonus.type === 'freespin')"/>-->
<!--            <toolbar-balance-static-->
<!--                v-if="authUser && !activeBonus || authUser && activeBonus.type !== 'deposit' && activeBonus.type !== 'freespin'"/>-->

<!--            <div class="ml-2">-->
<!--                <toolbar-language v-if="lgAndUp"/>-->
<!--            </div>-->

<!--            <toolbar-user v-if="authUser && !profileDrawerStyle || authUser && profileDrawerStyle === 'classic'" @open="openProfile"/>-->
<!--            <toolbar-user-modern v-if="authUser && profileDrawerStyle && profileDrawerStyle === 'modern'" />-->
<!--            <toolbar-auth-links :login="true" :register="true" v-else></toolbar-auth-links>-->
<!--        </v-card>-->
<!--        <v-dialog-->
<!--            v-model="confirmDialog"-->
<!--            max-width="350"-->
<!--            dark-->
<!--        >-->
<!--            <v-card class="pb-2">-->
<!--                <v-card-title class="headline">-->
<!--                    {{ $t("dashboard.sections.bonuses.confirmCancelBonusText") }}-->
<!--                </v-card-title>-->

<!--                <v-card-actions>-->
<!--                    <v-spacer></v-spacer>-->

<!--                    <v-btn-->
<!--                        class="tile-gradient-dark"-->
<!--                        text-->
<!--                        @click="confirmDialog = false"-->
<!--                    >-->
<!--                        {{ $t("common.disagree") }}-->
<!--                    </v-btn>-->

<!--                    <v-btn-->
<!--                        class="tile-gradient-primary"-->
<!--                        text-->
<!--                        @click="confirmDialog= false, confirmCloseBonus()"-->
<!--                    >-->
<!--                        {{ $t("common.agree") }}-->
<!--                    </v-btn>-->
<!--                </v-card-actions>-->
<!--            </v-card>-->
<!--        </v-dialog>-->
    </div>
</template>

<script>
import LogoResponsive from "../../../common/LogoResponsive";
import {mapActions, mapState} from "vuex";
import breaks from "../../../../mixins/breaks";
import ToolbarUser from "../../../toolbar/ToolbarUser";
import ToolbarBalanceStatic from "../../../toolbar/ToolbarBalanceStatic";
import ToolbarBalanceGame from "../../../toolbar/ToolbarBalanceGame";
import ToolbarLanguage from "../../../toolbar/ToolbarLanguage";
import ToolbarAuthLinks from "../../../toolbar/ToolbarAuthLinks";
import moment from "moment";
import Bonus from "../../../../models/Bonus";
import ToolbarUserModern from "../../../toolbar/ToolbarUserModern";
import GameTopBarBonus from "@/components/pages/slots/game/GameTopBarBonus.vue";

export default {
    name: "GameTopBar",
    components: {
        GameTopBarBonus,
        LogoResponsive,
        ToolbarUser,
        ToolbarBalanceStatic,
        ToolbarBalanceGame,
        ToolbarLanguage,
        ToolbarAuthLinks,
        ToolbarUserModern,
    },
    mixins: [breaks],
    props: {
        // gridView: {
        //     type: Array,
        //     default: () => []
        // },
        game: {
            type: Object,
            default: {}
        },
        screenView: {
            type: Array,
            default: () => []
        }
    },
    data: () => ({
        time: '',
        timeInterval: '',
        currentBonus: {},
        confirmDialog: false,
    }),
    created() {
        this.timeInterval = setInterval(this.currentTime, 1000)
    },
    computed: {
        activeBonus() {
            return this.$auth.user() ? this.$auth.user().active_bonus : null
        },
        depositBonus() {
            return !Object.keys(this.bonus_deposit).length && this.activeBonus && Object.keys(this.activeBonus).length && this.activeBonus.type === 'deposit' && (this.activeBonus.game_type === 'casino' || this.activeBonus.game_type === 'universal') && +this.activeBonus.status === 1 && this.bonus_target < 100
        },
        freespinBonus() {
            return !Object.keys(this.bonus_deposit).length && this.activeBonus && Object.keys(this.activeBonus).length && this.activeBonus.type === 'freespin' && +this.activeBonus.status === 1 && this.bonus_target < 100
        },
        freespinIsRealMoney() {
            return this.$auth.user().active_bonus.settings.freespin_is_real_money
        },
        authUser() {
            return this.$auth.user();
        },
        bonuses() {
            if (this.$auth.user().bonuses !== null) {
                return this.$auth.user().bonuses.filter((b) => b.is_active)
            }
        },
        ...mapState({
            current_lang: state => state.user.current_lang,
            bonus_target: state => state.user.bonus_target,
            bonus_deposit: state => state.user.bonus_deposit,
            disallowPlayerCancelBonus: state => state.app.brandSettings.disallow_player_cancel_bonus,
            profileDrawerStyle: state => state.app.brandSettings.profile_drawer_style,
        }),
        // availableScreenViews() {
        //     return [
        //         {icon: 'betvamos-multiscreen-x1', type: 1},
        //         {icon: 'betvamos-multiscreen-x2', type: 2},
        //         {icon: 'betvamos-multiscreen-x4', type: 4},
        //         {icon: 'betvamos-multiscreen-x6', type: 6},
        //         {icon: 'betvamos-multiscreen-x9', type: 9},
        //     ]
        // },
        currentScreenView() {
            return this.screenView.length ? this.screenView.find(v => v.active === true) || this.screenView[0] : []
        }
    },
    methods: {
        ...mapActions({
            getUserBonuses: 'app/getUserBonuses',
            setBonusDeposit: 'user/setBonusDeposit',
        }),
        async confirmCloseBonus() {
            let model = new Bonus(this.currentBonus);
            await model.delete().then(res => {
                this.$auth.user().active_bonus = null
                this.setBonusDeposit({});
            });
        },
        currentTime() {
            this.time = moment().locale(this.current_lang).format('HH:mm dddd DD MMMM YYYY')
        },
        openNavigation() {
            this.$emit("open-navigation");
        },
        openProfile() {
            this.$emit("open-profile");
        },
        ...mapActions('app', ['makeActiveWindow']),
        setView(view) {
            this.$emit('setView', view.type)
        },
        setFullscreen() {
            this.$emit('setFullscreen')
        }
    }
}
</script>

<style lang="scss">

.game-top-bar {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;

    .game-top-bar__back {
        margin-left: 8px;
        color: var(--v-gray-1-base);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.5px;

        &:hover, &:active {
            color: var(--v-primary-base) !important;

            .v-icon {
                color: var(--v-primary-base) !important;
            }
        }

        .v-icon {
            color: var(--v-gray-1-base) !important;
        }
    }

    .game-top-bar__name {
        display: flex;
        flex-grow: 1;
        align-items: center;
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }
}

.flex-gap-2 {
    gap: 8px;
}

.flex-gap-4 {
    gap: 16px;
}

</style>
