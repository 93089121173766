import Vue from 'vue'
import Vuex from 'vuex'

import onChangeLangPlugin from "@/store/plugins/onChangeLangPlugin";
import onChangePlatformPlugin from "@/store/plugins/onChangePlatformPlugin";

// Modules
import AppModule from './modules/app'
import GamesModule from './modules/games'
import TranslatableModule from './modules/translatable'
import UserModule from "./modules/user";

const actions = {
    onChangeLang: () => {},
    init: async ({dispatch}) => {
        // await dispatch('app/_init');
        // await dispatch('user/_init');
        // await dispatch('translatable/_init');
        await dispatch('games/_init');

    }
}

Vue.use(Vuex)

/**
 * Main Vuex Store
 */
const store = new Vuex.Store({
    actions,
  modules: {
    app: AppModule,
    games: GamesModule,
    translatable: TranslatableModule,
    user: UserModule,
  },
    plugins: [onChangeLangPlugin, onChangePlatformPlugin]
})

// store.dispatch('_init');

export default store
