<template>
    <div class="d-flex align-center flex-nowrap mr-sm-0">
        <v-btn
            v-if="$auth.user()"
            @click="makeActiveWindow({ active: true, type: 'wallet' })"
            color="primary"
            class="mr-2 px-2"
            height="30">
            {{ $t("menu.wallet") }}
        </v-btn>
    </div>
</template>


<script>
import breaks from "@/mixins/breaks"
import {mapActions} from "vuex"

export default {
    mixins: [breaks],
    computed: {
        sizes() {
            return this.xsOnly ? {height: "42px", tile: true} : {height: "40px", tile: false};
        }
    },
    methods: {
        ...mapActions('app', ['makeActiveWindow'])
    }
}
</script>

<style lang="scss">

.btn-register {
    background-color: #323335 !important;
    text-transform: uppercase !important;

    @media screen and (max-width: 600px) {
        min-width: 80px !important;
        height: 30px !important;
        width: 60px !important;
        border-radius: 4px !important;
        font-weight: bold !important;
        text-transform: capitalize !important;
    }
}

.btn-login {
    text-transform: uppercase !important;

    @media screen and (max-width: 600px) {
        min-width: 80px !important;
        height: 30px !important;
        width: 60px !important;
        border-radius: 4px !important;
        font-weight: bold !important;
        text-transform: capitalize !important;
    }
}

</style>
