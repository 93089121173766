<template>
    <v-dialog
        v-model="activeComputed"
        width="480"
        persistent
        :fullscreen="smAndDown"
        :hide-overlay="smAndDown"
    >
        <v-card class="text-center pa-1" dark color="blue-grey darken-4">
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="red darken-1"
                    icon
                    @click="activeComputed = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-actions>

            <v-card-title class="justify-center display-1 mb-2">{{ $t('auth.verifyEmail.title') }}</v-card-title>
            <v-card-subtitle>
                {{ $t('auth.verifyEmail.subtitle') }}
            </v-card-subtitle>

            <v-card-text>
                <v-form
                    class="d-lg-flex align-lg-start justify-lg-space-between mb-6"
                    ref="form"
                    v-model="isFormValid"
                    lazy-validation
                    @submit.prevent
                >
                    <v-row>
                        <v-col cols="12">
                            <v-btn
                                :loading="isLoading"
                                class="tile-gradient-primary white--text"
                                x-large
                                height="56"
                                width="100%"
                                @click="submit"
                            >
                                <v-icon left>mdi-check</v-icon>
                                {{ $t("dashboard.sections.memberVerify.blocks.emailVerification.form.button") }}
                            </v-btn>
                        </v-col>
                        <v-col cols="12" v-if="successMessage">
                            <span class="green--text ml-2 font-weight-bold">
                                {{ $t("dashboard.sections.memberVerify.blocks.emailVerification.success") }}
                            </span>
                        </v-col>
                        <v-col cols="12" v-if="errorMessage">
                            <span class="red--text ml-2 font-weight-bold">
                                {{ errorMessage }}
                            </span>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions} from 'vuex';
import breaks from "@/mixins/breaks";

export default {
    props: ['active'],
    mixins: [breaks],
    data() {
        return {
            // reset button
            isLoading: false,
            successMessage: false,
            errorMessage: null,

            // form
            isFormValid: true,
            email: '',
            sendToEmail: false,

            // form error
            error: false,
            errors: {},
            errorMessages: '',

            // input rules
            rules: {
                validEmail: (value) => {
                    const textError = "invalid email"
                    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if (!re.test(value)) {
                        return textError
                    }
                    return true
                }
            }
        }
    },
    computed: {
        activeComputed: {
            get() {
                return this.active;
            },
            set(value) {
                this.makeActiveWindow({active: value, type: 'verifyEmail'});
            }
        }
    },
    methods: {
        ...mapActions('app', ['makeActiveWindow']),
        submit() {
            if (this.$refs.form.validate()) {
                this.isLoading = true;
                axios.get('/api/user-dashboard/send-link-to-email').then(() => {
                    this.isLoading = false;
                    this.successMessage = true
                    setTimeout(() => {
                        this.successMessage = false
                        this.activeComputed = false
                    }, 5000)
                }).catch(error => {
                    this.isLoading = false;
                    if (error.response.data) {
                        this.errorMessage = error.response.data
                        setTimeout(() => {
                            this.errorMessage = null
                        }, 5000)
                    }
                    if (error.response && error.response.data && error.response.data.errors) {
                        this.errors = error.response.data.errors
                    }
                })
            }
        },
        resetErrors() {
            this.errors = {};
        },
    }
}
</script>

<style scoped>

</style>
