<template>
    <component
        :is="bonusTypeComponent"
        v-model="isActive"
        component=""
    >
        <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip
                v-if="isHasBonus"
                bottom
            >
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon
                        class="ml-3 cashback-icon"
                        v-bind="{...$attrs, ...attrs}"
                        v-on="{...tooltip, ...menu}"
                        @click="isShowDialog = true"
                    >
                        mdi-gift
                    </v-icon>
                </template>
                <span>{{ tooltipText }}</span>
            </v-tooltip>
        </template>
    </component>
</template>
<script>

import breaks from "@/mixins/breaks";
import bonuses from "@/mixins/bonuses";
import {BONUS_TYPES} from "@/configs/bonuses";
import BonusModalFreespin from "@/components/bonuses/BonusModalFreespin.vue";
import BonusModalFreebet from "@/components/bonuses/BonusModalFreebet.vue";
import BonusModalDeposit from "@/components/bonuses/BonusModalDeposit.vue";

const BONUS_TYPE_COMPONENTS = {
    [BONUS_TYPES.DEPOSIT]:  BonusModalDeposit,
    [BONUS_TYPES.FREEBET]:  BonusModalFreebet,
    [BONUS_TYPES.FREESPIN]: BonusModalFreespin,
}

export default {
    name: 'BonusModal',
    mixins: [breaks, bonuses],
    inheritAttrs: false,
    data: () => {
        return {
            isActive: false,
        }
    },
    computed: {
        bonusTypeComponent() {
            return BONUS_TYPE_COMPONENTS[this.bonusType];
        },
        tooltipText() {
            switch(this.bonusType) {
                case BONUS_TYPES.DEPOSIT:  return this.$t('common.depositBonus');
                case BONUS_TYPES.FREEBET:  return this.$t('common.freebetBonus');
                case BONUS_TYPES.FREESPIN: return this.$t('common.freespinBonus');
                default:                   return this.bonusType;
            }
        }
    }
}
</script>
