import gamePages from "@/configs/gamePages";
import Vuetify from "@/plugins/vuetify";

export default {
    categoriesByGamePage: state => {
        const filter = gamePages.categoryTypes[state.gamePage] || gamePages.default.categoryType;
        return state.categories.filter(c => c.is_live === filter.is_live && c.is_virtual_sport === filter.is_virtual_sport)
    },
    getCategoryBySlug: state => slug => {
        const result = state.categories.filter(c => c.slug === slug)
        return result.length ? result[0] : null;
    },
    getCategoryByUrl: state => url => {
        const result = state.categories.filter(c => c.url === url)
        return result.length ? result[0] : null;
    },
    getCategoryById: state => id => {
        const result = state.categories.filter(c => c.id === id)
        return result.length ? result[0] : null;
    },

    allProviders: state => {
        const result = [];
        Object.values(state.providersGroupBySlug).forEach(p => p.forEach(ip => result.push(ip)))
        return result;
    },
    providers: (state, getters) => {
        return getters.allProviders.filter(p => {
            if(state.platform === 'mobile') {
                return p.has_mobile_games === true
            } else {
                return p.has_desktop_games === true
            }
         })
    },
    providersByGamePage: (state, getters) => {
        const type = gamePages.providerTypes[state.gamePage] || gamePages.default.providerType;
        const casinoType = new Set(['live-casino', 'virtual-games', 'virtual-sports']);
        const isDesktop = !Vuetify.framework.breakpoint.mobile;

        return getters.providers.filter(p => {
            return p.provider_types?.some(
                pt => {
                    if(type) {
                        return pt.slug === type
                    } else {
                        return !casinoType.has(pt.slug)
                    }
                })
        })
    },
    gamesByCategory: state => categoryId => {
        return state.gamesByCategories[categoryId] || [];
    }
}
