export default {
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.mobile;
    },
    isDesktop () {
        return !this.isMobile;
    },
    smAndDown () {
      return this.$vuetify.breakpoint.smAndDown;
    },
    smAndUp () {
      return this.$vuetify.breakpoint.smAndUp;
    },
    mdAndDown () {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    mdAndUp () {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    lgAndDown () {
      return this.$vuetify.breakpoint.lgAndDown;
    },
    lgAndUp () {
      return this.$vuetify.breakpoint.lgAndUp;
    },
    seOnly () {
      return this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.se;
    },
    xsOnly () {
      return this.$vuetify.breakpoint.xsOnly;
    },
    smOnly () {
      return this.$vuetify.breakpoint.smOnly;
    },
    mdOnly () {
      return this.$vuetify.breakpoint.mdOnly;
    },
    lgOnly () {
      return this.$vuetify.breakpoint.lgOnly;
    },
    xlOnly () {
      return this.$vuetify.breakpoint.xlOnly;
    }
  }
}
