const casinoPages = [
    {
        path: 'category/:category',
        name: 'casino.category',
        props: true,
        meta: {
            noKeyForRouteView: true
        },
        component: () => import(/* webpackChunkName: "casino/category" */ '../components/pages/slots/CategoryGames.vue'),
    },
    {
        path: '/casino/providers',
        name: 'casino.provider',
        component: () => import(/* webpackChunkName: "casino/category" */ '../components/pages/slots/AllProvidersGames.vue'),
    },
    {
        path: 'search',
        name: 'games.search',
        meta: {
            noKeyForRouteView: true
        },
        component: () => import(/* webpackChunkName: "casino/category" */ '../components/pages/slots/FilteredGames.vue'),
    },
    {
        path: 'category',
        name: 'casino',
        meta: {
            noKeyForRouteView: true
        },
        component: () => import(/* webpackChunkName: "casino/category" */ '../components/pages/slots/AllCategoriesGames.vue'),
    },

    {
        path: '',
        name: 'casino',
        meta: {
            noKeyForRouteView: true
        },
        component: () => import(/* webpackChunkName: "casino/category" */ '../components/pages/slots/AllCategoriesGames.vue'),
    },
]

export default [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "home"*/ '@/pages/Home')
    },
    {
        path: '/vip',
        name: 'vip',
        component: () => import(/* webpackChunkName: "home"*/ '@/pages/VipPage')
    },
    {
        path: '/rules',
        name: 'rules',
        component: () => import(/* webpackChunkName: "rules"*/ '@/pages/Page')
    },
    {
        path: '/rules/:url',
        name: 'rules',
        component: () => import(/* webpackChunkName: "rules/url"*/ '@/pages/Page')
    },
    {
        path: '/rules/:url/:childUrl',
        name: 'rules',
        component: () => import(/* webpackChunkName: "rules/url/childUrl"*/ '@/pages/Page')
    },
    {
        path: '/information',
        name: 'information',
        component: () => import(/* webpackChunkName: "information"*/ '@/pages/Page')
    },
    {
        path: '/information/:url',
        name: 'information',
        component: () => import(/* webpackChunkName: "information/url"*/ '@/pages/Page')
    },
    {
        path: '/information/:url/:childUrl',
        name: 'information',
        component: () => import(/* webpackChunkName: "information/url/childUrl"*/ '@/pages/Page')
    },
    {
        path: '/blog',
        name: 'blog',
        component: () => import(/* webpackChunkName: "blog"*/ '@/pages/Page')
    },
    {
        path: '/blog/:url',
        name: 'blog',
        component: () => import(/* webpackChunkName: "blog/url"*/ '@/pages/Page')
    },
    {
        path: '/blog/:url/:childUrl',
        name: 'blog',
        component: () => import(/* webpackChunkName: "blog/url/childUrl"*/ '@/pages/Page')
    },
    {
        path: '/articles/:url',
        name: 'article',
        component: () => import(/* webpackChunkName: "article"*/ '@/pages/Article')
    },
    {
        path: '/sports',
        redirect: '/sports/prematch'
    }
    ,{
        path: '/sports/prematch',
        name: 'KickerTechSports',
        component: () => import(/* webpackChunkName: "tg-sports" */ '../pages/sports/KickerTech.vue'),
    },
    {
        path: '/sports/live',
        name: 'KickerTechSports',
        component: () => import(/* webpackChunkName: "tg-sports" */ '../pages/sports/KickerTech.vue'),
    },
    {
        path: '/sports/prematch/*',
        name: 'KickerTechSports',
        component: () => import(/* webpackChunkName: "tg-sports" */ '../pages/sports/KickerTech.vue'),
    },
    {
        path: '/sports/live/*',
        name: 'KickerTechSports',
        component: () => import(/* webpackChunkName: "tg-sports" */ '../pages/sports/KickerTech.vue'),
    },
    {
        path: '/sports/betlist',
        name: 'KickerTechSports',
        component: () => import(/* webpackChunkName: "tg-sports" */ '../pages/sports/KickerTech.vue'),
    },
    {
        path: '/sports/betlist/*',
        name: 'KickerTechSports',
        component: () => import(/* webpackChunkName: "tg-sports" */ '../pages/sports/KickerTech.vue'),
    },
    {
        path: '/fifa-world-cup-2022',
        name: 'FifaWorldCup2022',
        component: () => import(/* webpackChunkName: "FifaWorldCup2022" */ '../pages/sports/FifaWorldCup2022'),
    },
    {
        path: '/sports2',
        name: 'sports2',
        component: () => import(/* webpackChunkName: "sports" */ '../pages/sports/Matrix'),
    },
    {
        path: '/sports2/*',
        name: 'sports2',
        component: () => import(/* webpackChunkName: "sports" */ '../pages/sports/Matrix'),
    },
    {
        path: '/live-sports2/',
        name: 'live-sports',
        component: () => import(/* webpackChunkName: "sports" */ '../pages/sports/Matrix'),
    },
    {
        path: '/live-sports2/*',
        name: 'live-sports2',
        component: () => import(/* webpackChunkName: "sports" */ '../pages/sports/Matrix'),
    },
    {
        path: '/live-sports',
        name: 'live-sports',
        component: () => import(/* webpackChunkName: "live-sports" */ '../pages/sports/Index'),
    },
    {
        path: '/live-sports/*',
        name: 'live-sports',
        component: () => import(/* webpackChunkName: "live-sports/url" */ '../pages/sports/Index'),
    },
    // {
    //     path: '/virtual-games',
    //     name: 'virtual-games',
    //     component: () => import(/* webpackChunkName: "virtual-games" */ '../pages/virtual-games/Index'),
    // },
    // {
    //     path: '/virtual-games/:slug',
    //     name: 'virtual-games',
    //     component: () => import(/* webpackChunkName: "virtual-games/provider" */ '../pages/virtual-games/Index'),
    // },
    {
        path: '/e-sports',
        name: 'e-sports',
        component: () => import(/* webpackChunkName: "e-sports" */ '../pages/e-sports/Index'),
    },
    {
        path: '/tombola',
        name: 'tombola',
        component: () => import(/* webpackChunkName: "tombola" */ '../pages/tombola/Index'),
    },


    {
        path: '/casino',
        name: 'casino',
        meta: {
          noKeyForRouteView: true
        },
        component: () => import(/* webpackChunkName: "casino" */ '../pages/casino/Index'),
        children: casinoPages,
    },

    {
        path: '/virtual-sports',
        name: 'casino',
        meta: {
            noKeyForRouteView: true
        },
        component: () => import(/* webpackChunkName: "casino" */ '../pages/virtual-sports/Index'),
        children: casinoPages,
    },
    {
        path: '/live-casino',
        name: 'casino',
        meta: {
            noKeyForRouteView: true
        },
        component: () => import(/* webpackChunkName: "casino" */ '../pages/live-casino/Index'),
        children: casinoPages,
    },
    {
        path: '/casino/:provider/:launchUrl',
        name: 'slots-game',
        component: () => import(/* webpackChunkName: "slots-game/provider/launch" */ '../pages/casino/SlotsGame'),
        props: true,
        meta: {
            dark: true,
            page: 'casino',
            // disabled: ['header', 'footer', 'bottomBar']
        }
    },
    {
        path: '/live-casino/:provider/:launchUrl',
        name: 'slots-game',
        component: () => import(/* webpackChunkName: "slots-game/provider/launch" */ '../pages/casino/SlotsGame'),
        props: true,
        meta: {
            dark: true,
            page: 'live-casino',
            // disabled: ['header', 'footer', 'bottomBar']
        }
    },
    {
        path: '/virtual-sports/:provider/:launchUrl',
        name: 'slots-game',
        component: () => import(/* webpackChunkName: "slots-game/provider/launch" */ '../pages/casino/SlotsGame'),
        props: true,
        meta: {
            dark: true,
            page: 'virtual-sports',
            // disabled: ['header', 'footer', 'bottomBar']
        }
    },
    {
        path: '/affiliate',
        redirect: '/affiliate/signup',
        name: 'affiliate'
    },
    {
        path: '/affiliate/signup',
        name: 'affiliate.signup',
        component: () => import(/* webpackChunkName: "affiliate" */ '../pages/affiliate/RegisterPageAffiliate.vue'),
        props: true,
        meta: {
            layout: 'affiliate',
            dark: false
        }
    },
    {
        path: '/affiliate/login',
        name: 'affiliate.login',
        component: () => import(/* webpackChunkName: "affiliate" */ '../pages/affiliate/LoginPageAffiliate.vue'),
        props: true,
        meta: {
            layout: 'affiliate',
            dark: false
        }
    },
    {
        path: '/authorize/:provider/callback',
        name: 'soc-auth',
        component: () => import(/* webpackChunkName: "soc-auth" */ '../pages/auth/SocAuth'),
        props: true,
        meta: {
            layout: 'registration'
        }
    },
];
