<template>

    <div class="custom-autocomplete">
        <div
            v-if="$attrs.label"
            class="custom-autocomplete__messages"
        >
            <div class="custom-autocomplete__label">
                {{ $attrs.label }}:
                <span v-if="required && !getErrors" class="error--text">
                    *
                </span>
            </div>
            <div class="custom-autocomplete__error"> {{ getErrors }} </div>
        </div>
        <v-autocomplete
            v-bind="$attrs"
            v-on="$listeners"
            ref="textField"
        >
            <template v-for="slot in parentSlots" #[slot]>
                <slot :name="slot" />
            </template>
            <template v-if="getErrors" #append>

                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_62_3837" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                        <rect x="0.5" width="24" height="24" fill="#D9D9D9"/>
                    </mask>
                    <g mask="url(#mask0_62_3837)">
                        <path d="M12.5016 22.0246C12.2349 22.0246 11.9807 21.9746 11.7391 21.8746C11.4974 21.7746 11.2766 21.6329 11.0766 21.4496L3.05156 13.4246C2.86823 13.2246 2.72656 13.0038 2.62656 12.7621C2.52656 12.5204 2.47656 12.2663 2.47656 11.9996C2.47656 11.7329 2.52656 11.4746 2.62656 11.2246C2.72656 10.9746 2.86823 10.7579 3.05156 10.5746L11.0766 2.54961C11.2766 2.34961 11.4974 2.20378 11.7391 2.11211C11.9807 2.02044 12.2349 1.97461 12.5016 1.97461C12.7682 1.97461 13.0266 2.02044 13.2766 2.11211C13.5266 2.20378 13.7432 2.34961 13.9266 2.54961L21.9516 10.5746C22.1516 10.7579 22.2974 10.9746 22.3891 11.2246C22.4807 11.4746 22.5266 11.7329 22.5266 11.9996C22.5266 12.2663 22.4807 12.5204 22.3891 12.7621C22.2974 13.0038 22.1516 13.2246 21.9516 13.4246L13.9266 21.4496C13.7432 21.6329 13.5266 21.7746 13.2766 21.8746C13.0266 21.9746 12.7682 22.0246 12.5016 22.0246ZM12.5016 20.0246L20.5266 11.9996L12.5016 3.97461L4.47656 11.9996L12.5016 20.0246ZM11.5016 12.9996H13.5016V6.99961H11.5016V12.9996ZM12.5016 15.9996C12.7849 15.9996 13.0224 15.9038 13.2141 15.7121C13.4057 15.5204 13.5016 15.2829 13.5016 14.9996C13.5016 14.7163 13.4057 14.4788 13.2141 14.2871C13.0224 14.0954 12.7849 13.9996 12.5016 13.9996C12.2182 13.9996 11.9807 14.0954 11.7891 14.2871C11.5974 14.4788 11.5016 14.7163 11.5016 14.9996C11.5016 15.2829 11.5974 15.5204 11.7891 15.7121C11.9807 15.9038 12.2182 15.9996 12.5016 15.9996Z" fill="#DD0D00"/>
                    </g>
                </svg>

            </template>
        </v-autocomplete>
    </div>

</template>

<script>
export default {
    name: "CustomAutocomplete",
    components: {},
    inheritAttrs: false,
    props: {
        required: {
            type: Boolean
        },
    },
    data() {
        return {
            isMounted: false
        }
    },
    computed: {
        parentSlots() {
            return Object.keys(this.$slots)
        },
        getErrors() {
            if (this.isMounted) {
                const textField = this.$refs.textField;
                return (textField.hasState && textField?.errorBucket?.length) ? this.$refs.textField.errorBucket[0] : this.$attrs['error-messages'];
            }
            return '';
        }
    },
    mounted() {
        this.isMounted = true;
    },
}
</script>

<style lang="scss">
.custom-autocomplete {
    margin-bottom: 8px;

    .custom-autocomplete__messages {
        display: flex;
        align-items: flex-end;
        width: 100%;
        text-align: left;

        color: var(--v-gray-2-base);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        .custom-autocomplete__title {

        }

        .custom-autocomplete__error {
            margin-left: 8px;
            color: #DD0D00;
        }
    }

    .v-input {
        &.primary--text, .primary--text {
            color: var(--v-gray-2-base) !important;
            caret-color: var(--v-gray-2-base) !important;
        }

        color: var(--v-gray-2-base);
        caret-color: var(--v-gray-2-base);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active{
            -webkit-background-clip: text;
            -webkit-text-fill-color: var(--v-gray-2-lighten3);
            transition: background-color 5000s ease-in-out 0s;
            box-shadow: inset 0 0 20px 20px var(--v-gray-3-base);
        }

        .error--text {
            color: #DD0D00 !important;
            caret-color: #DD0D00 !important;

            ::placeholder {
                color: #DD0D00 !important;
                caret-color: #DD0D00 !important;
            }
        }


        .v-input__slot {
            height: 40px !important;
            min-height: 40px !important;
        }

        .v-input__append-inner {
            margin-top: 8px !important;
        }

        .v-label {
            display: none;
            //top: -26px !important;
            //left: -10px !important;
            //
            //color: var(--v-gray-2-base) !important;
            //caret-color: var(--v-gray-2-base) !important;
            //transition: none !important;
            //transform: none !important;
            //
            //font-size: 16px;
            //font-style: normal;
            //font-weight: 400;
            //line-height: 24px;
        }

        .v-autocomplete__details {
            display: none;
        }


        fieldset {
            //border-color: green;
        }

        fieldset:focus {
            //border-color: var(--v-gray-2-base);
        }

        legend {
            width: 0 !important;
        }
    }

    .v-text-field__details {
        display: none;
    }

    &.autocomplete--gray {
        .v-input__slot {
            background-color: var(--v-gray-2-base);

            input, input::placeholder, .v-label, .v-autocomplete__selections, .v-autocomplete__slot, .v-icon {
                color: var(--v-background-base) !important;
                caret-color: var(--v-background-base) !important;
            }
        }

        .v-input__slot {
            padding: 0;
            margin-top: 0 !important;
            margin-bottom: 0 !important;
        }
    }

    &.autocomplete--white {
        .custom-autocomplete__label {
            color: black;
        }

        input, input::placeholder, .v-label, .v-autocomplete__selections, .v-autocomplete__slot, .v-icon {
            color: black !important;
        }

        .v-input {
            &.primary--text, .primary--text {
                color: var(--v-background-base) !important;
                caret-color: var(--v-background-base) !important;
            }

            color: var(--v-background-base);
            caret-color: var(--v-background-base);

            .v-icon {
                color: var(--v-background-base);
            }

            input, input::placeholder {
                color: var(--v-background-base);
                caret-color: var(--v-background-base);
            }

            input::placeholder {
                color: var(--v-gray-3-base);
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
                -webkit-text-fill-color: var(--v-gray-3-base) !important;
                transition: background-color 5000s ease-in-out 0s !important;
                box-shadow: inset 0 0 20px 20px white !important;
            }

            .error--text {
                color: var(--v-error-base) !important;
                caret-color: var(--v-error-base) !important;

                ::placeholder {
                    color: var(--v-error-base) !important;
                    caret-color: var(--v-error-base) !important;
                }
            }

            .v-input__control {
                .v-input__slot {
                    background-color: white;
                }
            }

        }
    }

    &.autocomplete--white .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
        color: rgba(0, 0, 0, .40);
    }
    &.autocomplete--white .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot:hover fieldset {
        color: rgba(0, 0, 0, .60) !important;
    }
}
</style>
