<template>
    <div v-if="item.type === 'dropdown'">
        <v-menu offset-y class="text-center rounded-0">
            <template v-slot:activator="{ on, attrs }">
                <v-tab
                    style="padding-bottom: 8px; padding-top: 8px;"
                    color="transparent"
                    v-bind="attrs"
                    v-on="on">
                  <span v-if="item.icon" class="mr-1">
                      <v-icon class="mb-1" medium>{{ item.icon }}</v-icon>
                  </span>
                    <span v-if="item.image" class="mr-1">
                      <v-img max-width="30" :lazy-src="item.image" :src="item.image"></v-img>
                    </span>
                    <span>{{ item.title }}</span>
                    <v-badge
                        v-if="item.badge"
                        tile
                        class="header-badge"
                        dark
                        color="red"
                        :content="item.badge.toUpperCase()">
                        <span></span>
                    </v-badge>
                </v-tab>
            </template>
            <v-list tile dark color="#111b27">
                <v-list-item v-if="dropdownItem.link_type !== 'game'"
                    v-for="(dropdownItem, index) in item.dropdownItems"
                    :to="dropdownItem.link"
                    :target="dropdownItem.link_type === 'external' ? '_blank' : '_self'"
                    :key="index">
                    <v-badge
                        v-if="dropdownItem.badge"
                        dot
                        color="red"
                        :content="dropdownItem.badge.toUpperCase()">
                        <v-list-item-title class="d-flex">
                          <span v-if="dropdownItem.icon" class="mr-1">
                              <v-icon class="mb-1" small>{{ dropdownItem.icon }}</v-icon>
                          </span>
                            <span v-if="dropdownItem.image" class="mr-1">
                              <v-img max-width="30" :lazy-src="dropdownItem.image" :src="dropdownItem.image"></v-img>
                            </span>
                            <span>{{ dropdownItem.title }}</span>
                        </v-list-item-title>
                    </v-badge>
                    <template v-else>
                        <v-list-item-title class="d-flex">
                            <span v-if="dropdownItem.icon" class="mr-1"><v-icon class="mb-1" small>{{ dropdownItem.icon }}</v-icon></span>
                            <span v-if="dropdownItem.image" class="mr-1">
                              <v-img max-width="30" :lazy-src="dropdownItem.image" :src="dropdownItem.image"></v-img>
                            </span>
                            <span>{{ dropdownItem.title }}</span>
                        </v-list-item-title>
                    </template>
                </v-list-item>
                <v-list-item v-else
                             v-for="(dropdownItem, index) in item.dropdownItems"
                             :key="index"
                             @click="goToGame(dropdownItem.link)"
                >
                    <v-badge
                        v-if="dropdownItem.badge"
                        dot
                        color="red"
                        :content="dropdownItem.badge.toUpperCase()">
                        <v-list-item-title class="d-flex">
                          <span v-if="dropdownItem.icon" class="mr-1">
                              <v-icon class="mb-1" small>{{ dropdownItem.icon }}</v-icon>
                          </span>
                            <span v-if="dropdownItem.image" class="mr-1">
                              <v-img max-width="30" :lazy-src="dropdownItem.image" :src="dropdownItem.image"></v-img>
                            </span>
                            <span>{{ dropdownItem.title }}</span>
                        </v-list-item-title>
                    </v-badge>
                    <template v-else>
                        <v-list-item-title class="d-flex">
                            <span v-if="dropdownItem.icon" class="mr-1"><v-icon class="mb-1" small>{{ dropdownItem.icon }}</v-icon></span>
                            <span v-if="dropdownItem.image" class="mr-1">
                              <v-img max-width="30" :lazy-src="dropdownItem.image" :src="dropdownItem.image"></v-img>
                            </span>
                            <span>{{ dropdownItem.title }}</span>
                        </v-list-item-title>
                    </template>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
    <v-tab v-else-if="item.type === 'link' && item.link_type !== 'game'"
           :target="item.link_type === 'external' ? '_blank' : '_self'"
           :to="item.link"
           exact
    >
        <span v-if="item.icon" class="mr-1">
            <v-icon class="mb-1" medium>{{ item.icon }}</v-icon>
        </span>
        <span v-if="item.image" class="mr-1">
          <v-img max-width="30" :lazy-src="item.image" :src="item.image"></v-img>
        </span>
        <span>{{ item.title }}</span>
        <v-badge
            v-if="item.badge"
            tile
            class="header-badge"
            dark
            color="red"
            :content="item.badge.toUpperCase()">
            <span></span>
        </v-badge>
    </v-tab>
    <v-tab v-else-if="item.type === 'link'&& item.link_type === 'game'"
           @click="goToGame(item.link)"
    >
        <span v-if="item.icon" class="mr-1">
            <v-icon class="mb-1" medium>{{ item.icon }}</v-icon>
        </span>
        <span v-if="item.image" class="mr-1">
          <v-img max-width="30" :lazy-src="item.image" :src="item.image"></v-img>
        </span>
        <span>{{ item.title }}</span>
        <v-badge
            v-if="item.badge"
            tile
            class="header-badge"
            dark
            color="red"
            :content="item.badge.toUpperCase()">
            <span></span>
        </v-badge>
    </v-tab>
</template>

<script>
import {mapActions, mapState} from "vuex";
import axios from "axios";

export default {
    name: "HeaderItem",
    props: ['item'],
    computed: {
        ...mapState('app', ['translationSettings']),
        authUser() {
            return this.$auth.user()
        },
        currency() {
            return this.authUser ? this.authUser.currency : this.currencies[0]
        },
        lang() {
            return this.current_lang ? this.current_lang : this.authUser ? this.authUser.language : 'tr'
        },
        platform() {
            return this.mdAndUp ? 'desktop' : 'mobile'
        },
        ...mapState({
            verify_email: state => state.app.brandSettings.verify_email,
            game: (state) => state.games.gameUrl,
        }),
        ...mapState({
            current_lang: state => state.user.current_lang
        }),
    },
    methods: {
        ...mapActions('app', ['makeActiveWindow']),
        ...mapActions({
            getGameUrl: "games/getGameUrl",
        }),
        gameLinkStore() {
            const link = this.game.launch_url ? this.game.launch_url : this.game.slug
            const provider = this.game.game_provider ? this.game.game_provider.slug : 'provider'
            this.$store.commit('app/setGameLinkBeforeLogin', `/casino/${provider}/${link}`)
        },
        goToGame(launch_code) {
            if (this.authUser && !this.authUser.email_verified_at && this.verify_email && this.verify_email.includes('bets')) {
                this.makeActiveWindow({active: true, type: 'verifyEmail'})
                return false
            }
            if (!this.authUser) {
                this.getGame(launch_code)
                return false
            }
            axios.get(`/api/games/launch-url/${launch_code}?currency=${this.currency}&lang=${this.lang}&platform=${this.platform}`).then(res => {
                if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
                    window.location.href = res.data.game_link
                } else {
                    window.open(res.data.game_link, '_blank')
                }
            })
        },
        getGame(launch_code) {
            this.getGameUrl([null, launch_code]).then(() => {
                if (this.game) {
                    this.$emit('drawerClose')
                    this.makeActiveWindow({active: true, type: 'login'})
                    this.gameLinkStore()
                }
            })

        },
    }
}
</script>

<style scoped>

</style>
