<template>
  <v-card class="py-2" tile width="100%" max-width="262px" flat dark v-if="socials.length">
    <v-card-title class="pa-2">{{$t("dashboard.socialMedia.title")}}</v-card-title>
    <v-navigation-drawer floating permanent  width="100%" height="auto" class="shades transparent">
      <v-list nav dense class="pa-0">
        <v-list-item-group color="primary">
          <v-list-item
            v-for="(item, i) in socials"
            :key="i"
            :href="item.url"
            target="_blank"
            class="d-flex align-center pa-3 mb-2 pa-2 blue-grey darken-5"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-content class="mb-0 pa-0">
              <v-list-item-title>{{$t(item.name)}}</v-list-item-title>
              <div class="text-caption">{{$t('dashboard.socialMedia.follow') + ' ' + item.name + ' ' + $t('dashboard.socialMedia.page')}}</div>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    socials: []
  }),
    async created() {
      await axios.get('/api/social-networks').then(res => this.socials = res.data)
    }
};
</script>
