import {setCookie} from "@/helpers/cookies";

export default {
    setBonusAmount: async (state, payload) => {
        return new Promise(() => {
            state.bonus_amount = payload
        })
    },
    setBonusTarget: async (state, payload) => {
        return new Promise(() => {
            state.bonus_target = payload
        })
    },
    setBonusDeposit: async (state, payload) => {
        return new Promise(() => {
            state.bonus_deposit = payload
        })
    },
    setAmount: async (state, payload) => {
        return new Promise(() => {
            state.amount = payload
        })
    },
    setTotalAmount: async (state, payload) => {
        return new Promise(() => {
            state.total_amount = payload
        })
    },
    setMigratedUser: async (state, payload) => {
        return new Promise(() => {
            state.migrated_user = payload
        })
    },
    setLogin: async (state, payload) => {
        return new Promise(() => {
            state.login = payload
        })
    },
    setCurrentLang: async (state, payload) => {
        setCookie('language', payload, 10000000);
        return new Promise(() => {
            state.current_lang = payload
        })
    },
    setLoadedGame: async (state, payload) => {
        return new Promise(() => {
            state.loadedGame = payload
        })
    },
    setFavoriteGames: async (state, payload) => {
        return new Promise(() => {
            state.favorite_games = payload
        })
    },
    setGameTotalAmount: async (state, payload) => {
        return new Promise(() => {
            state.game_total_amount = payload
        })
    },
    setGameBonusAmount: async (state, payload) => {
        return new Promise(() => {
            state.game_bonus_amount = payload
        })
    },
    setGameAmount: async (state, payload) => {
        return new Promise(() => {
            state.game_amount = payload
        })
    },
    toggleVisibilityBalance: (state, payload) => {
        state.hide_balance = !state.hide_balance;
    },
    setCryptoBalances: (state, payload) => {
        state.crypto.balances = payload
    },
    setCryptoCurrentBalance: (state, payload) => {
        state.crypto.current = payload
    },
    setCryptoPrint: (state, payload) => {
        state.crypto.is_print = payload
    },
    setFiatCurrency: (state, payload) => {
        state.crypto.fiat_currency = payload
    },
    setCryptoCurrency: (state, payload) => {
        state.crypto.crypto_currency = payload
    },
    setCryptoNullBalance: (state, payload) => {
        state.crypto.is_null_balance = payload
    },
    setBonusData: (state, payload) => {
        if (payload) {

            let status = null
            if (String(payload.status).length > 1) {
                status = payload.status
            }
            else {
                switch (String(payload.status)) {
                    case "1":
                        status = "process"
                        break;
                    case "2":
                        status = "pending"
                        break;
                    case "3":
                        status = "canceled"
                        break;
                    case "4":
                        status = "completed"
                        break;
                    default:
                        status = `unknown status ${payload.status}`
                        console.error(status)
                }
            }

            state.bonus.isActive = true
            state.bonus.data = {
                id: payload.id,
                status: status,
                name: payload.name,
                type: payload.type,
                game: payload.game,
                currency: payload.currency
            }
        }
    }
}
