<template>
    <v-dialog
        v-model="activeComputed"
        width="480"
    >
        <v-card class="text-center pa-1" color="login-popup">
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color=""
                    icon
                    @click="activeComputed = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-actions>

<!--            <v-card-title class="justify-center">Error!</v-card-title>-->
<!--            <v-card-subtitle>{{ $t('auth.login.subtitle') }}</v-card-subtitle>-->
<!--            <v-card-subtitle v-if="comingFromAnotherSystem">{{ $t('auth.login.migratedUserWelcome') }}</v-card-subtitle>-->
            <div class="mb-4">
                {{ message }}
            </div>
            <v-card-text>

            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";
import breaks from "@/mixins/breaks";


export default {
    name: "Error",
  components: {
  },
    props: ['active'],
    mixins: [breaks],
    data() {
        return {

        }
    },
    computed: {
        ...mapState({
            message: state => state.app.errorMessage,
        }),
        activeComputed: {
            get() {
                return this.active;
            },
            set(value) {
                this.makeActiveWindow({active: value, type: 'error'});
            }
        }
    },
    methods: {
        ...mapActions('app', ['makeActiveWindow']),
    }
}
</script>

<style lang="scss">
</style>
