<template>
    <div>
        <div class="text-h4 text-lg-h3 mt-8 text-center">{{ $t("slotsGame.demoNotSupported") }}</div>
        <div class="d-flex align-center justify-center mt-6">
            <v-btn
                x-large
                dark
                @click="makeActiveWindow({ active: true, type: 'login'})"
                class="secondary mx-1 mr-4">
                {{ $t("menu.authLogin") }}
            </v-btn>
            <v-btn
                color="primary"
                class="black--text"
                x-large
                @click="makeActiveWindow({ active: true, type: 'register'})">
                {{ $t("menu.authRegister") }}
            </v-btn>
        </div>
        <div class="d-flex align-center justify-center mt-4">
            <v-btn
                color="primary"
                class="black--text"
                x-large
                to="/casino">
                {{ $t("common.backToCasino") }}
            </v-btn>
        </div>
    </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
    name: "DemoNotSupported",
    methods: {
        ...mapActions('app', ['makeActiveWindow']),
    }
}
</script>

<style scoped>

</style>
