export default [
    {
        path: '/auth/signin',
        name: 'auth-signin',
        component: () => import(/* webpackChunkName: "auth-signin" */ '@/pages/auth/SigninPage.vue'),
        meta: {
            auth: false,
            layout: 'auth'
        }
    },
    {
        path: '/auth/signup',
        name: 'auth-signup',
        component: () => import(/* webpackChunkName: "auth-signup" */ '@/pages/auth/SignupPage.vue'),
        meta: {
            auth: false,
            layout: 'auth'
        }
    },
    {
        path: '/auth/forgot-password',
        name: 'auth-forgot',
        component: () => import(/* webpackChunkName: "auth-forgot" */ '@/pages/auth/ForgotPage.vue'),
        meta: {
            layout: 'auth'
        }
    },
    {
        path: '/auth/reset-password',
        name: 'auth-reset',
        component: () => import(/* webpackChunkName: "auth-reset" */ '@/pages/auth/ResetPage.vue'),
        meta: {
            layout: 'auth'
        }
    },
    {
        path: '/auth/verify-email',
        name: 'verify-email',
        component: () => import(/* webpackChunkName: "auth-reset" */ '@/pages/auth/ValidateEmail.vue'),
        meta: {
            layout: 'auth'
        }
    }
]
