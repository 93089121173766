<template>
    <div>
        <v-menu
            v-if="showListLocales"
            content-class="menu-gray"
            offset-y
            transition="slide-y-transition"
            z-index="1111111"
        >
            <template v-slot:activator="{ on }">
                <v-btn text v-on="on" class="pl-1 pr-2" :class="cssClass" style="min-width: 50px">
                    <template v-if="currentLocale">
                        <flag-icon
                            round
                            :flag="currentLocale.flag"
                            size="28"
                        ></flag-icon>
                        <span class="pl-1" style="text-transform: uppercase;" v-html="currentLocale.code"><!-- {{ currentLocale.code }} --></span>
                        <v-icon v-if="showArrow" right>mdi-chevron-down</v-icon>
                    </template>
                </v-btn>
            </template>

            <v-list dense nav class=" list--uppercase-sm">
                <v-list-item v-for="locale in availableLocales" :key="locale.code" @click="setLocale(locale.code)" class="pr-0">
                    <flag-icon :flag="locale.flag" round></flag-icon>
                    <v-list-item-title class="px-1 text-uppercase">{{ locale.code }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import FlagIcon from '../common/FlagIcon'
import Translation from "../../models/Translation";
import {mapState, mapActions} from "vuex";
/*
|---------------------------------------------------------------------
| Language Switcher Component
|---------------------------------------------------------------------
|
| Locale menu to choose the language based on the locales present in
| vue-i18n locales available array
|
*/
export default {
    components: {
        FlagIcon
    },
    props: {
        cssClass: '',
        // Show dropdown arrow
        showArrow: {
            type: Boolean,
            default: false
        },
        // Show the country label
        showLabel: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        ...mapState('app', ['translationSettings']),
        ...mapState({
            current_lang: state => state.user.current_lang
        }),
        currentLocale() {
            return this.translationSettings.find((i) => i.code === this.current_lang)
        },
        availableLocales() {
            return this.translationSettings.filter((i) => i.code !== this.current_lang)
        },
        showListLocales() {
            return this.translationSettings.length > 1
        }
    },
    methods: {
        ...mapActions('translatable', ['updateTranslatable']),
        async setLocale(locale) {
            this.$i18n.locale = locale
            this.$vuetify.lang.current = locale
            localStorage.setItem('lang', locale);
            await this.getLocale(locale)
            this.$store.commit('user/setCurrentLang', locale)
        },
        async getLocale(locale) {
            let dynamic = await Translation.find(locale);
            await this.updateTranslatable(locale);
            this.$i18n.setLocaleMessage(this.$i18n.locale, {...this.$i18n.getLocaleMessage(this.$i18n.locale), ...dynamic})
        }
    }
}
</script>

<style lang="scss">
.flag-icon {
    flex: 0 0 auto;
}
</style>
