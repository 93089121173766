export default {
    required: v => !!((typeof v === 'string' && v.trim().length > 0) || v === 0 || v),
    turkishPassport: (value) => {
        if (!(/^[1-9]\d{10}$/).test(value)) {
            return false
        }
        let odds = 0
        let evens = 0
        let sumOf10 = 0
        const digits = value.split('')
        const d10 = Number(digits[9])
        const d11 = Number(digits[10])

        digits.forEach(function (d, index) {
            d = Number(d);
            if (index < 10) sumOf10 += d;
            if (index < 9) {
                if ((index + 1) % 2 === 0) {
                    evens += d;
                } else {
                    odds += d;
                }
            }
        })

        if (sumOf10 % 10 !== d11 || ((odds * 7) + (evens * 9)) % 10 !== d10 || (odds * 8) % 10 !== d11) {
            return false
        }

        return true
    },
    email: (value) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(value)) {
            return false
        }
        return true
    },
    phone: v => false,
    lettersOnly: v => /^[A-Za-z]+$/.test(v),
    passwordWithDigits: v => /(?=.*[0-9])/.test(v),
    minLength: minLength => v => v && (v + '').length >= minLength,
    same: sameValue => v => v + '' === sameValue + '',
}
