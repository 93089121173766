export default [
    {
        icon: "custom-profile",
        title: "dashboard.menu.account",
        type: "dropdown",
        items: [
            {
                title: "dashboard.sections.detail.title",
                link: "/user/detail",
            },
            {
                title: "dashboard.sections.security.title",
                link: "/user/security",
            },
            {
                title: "dashboard.sections.memberVerify.title",
                link: "/user/verification",
            },
        ]
    },
    {
        icon: "custom-messages",
        title: "dashboard.sections.messages.title",
        link: "/user/messages",
        type: "messages"
    },
    // {
    //     icon: "betvamos-star-outline",
    //     title: "dashboard.sections.bonuses.title",
    //     link: "/user/bonuses",
    // },
    {
        icon: "custom-history",
        title: "dashboard.sections.betsHistory.title",
        link: "/user/my-bets",
    },
    {
        icon: "custom-payment",
        title: "dashboard.menu.banking",
        type: "dropdown",
        items: [
            // {
            //     title: "dashboard.sections.deposit.title",
            //     link: "/user/deposit",
            // },
            {
                title: "dashboard.sections.withdrawal.title",
                link: "",
                function: (that) => {that.$store.dispatch('app/makeActiveWindow', {type: 'wallet', active: 'withdrawal'})}
            },
            // {
            //     title: "dashboard.sections.paymentMethods.title",
            //     link: "/user/payment-templates",
            // },
            {
                title: "dashboard.sections.history.title",
                link: "/user/history"
            },

        ]
    },
    // {
    //     icon: "mdi-account-circle",
    //     title: "dashboard.sections.overview.title",
    //     link: "/user/info",
    // },
    // {
    //     icon: "mdi-information",
    //     title: "dashboard.sections.memberInfo.title",
    //     link: "update",
    // },
    // {
    //     icon: "betvamos-star-outline",
    //     title: "dashboard.sections.memberVerify.title",
    //     link: "/user/verification",
    // },

    // {
    //     icon: "mdi-send",
    //     title: "dashboard.sections.sendToFriend.title",
    //     link: "/user/transfer",
    // },
    // {
    //     icon: "mdi-file-document",
    //     title: "Documents",
    //     link: "/user/documents",
    // },
]
