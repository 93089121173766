export default [
    {
        path: '/promotions',
        name: 'promotions',
        component: () => import(/* webpackChunkName: "home"*/ '@/pages/general/promotions/Promotions')
    },
    {
        path: '/promotions/:url',
        name: 'promotions',
        component: () => import(/* webpackChunkName: "home"*/ '@/pages/PromotionPage')
    },
    {
        path: '/promotions/:url/:childUrl',
        name: 'promotions',
        component: () => import(/* webpackChunkName: "home"*/ '@/pages/PromotionPage')
    },
];
