import Model from './Model';
import i18n from '@/plugins/vue-i18n';

export default class Bonus extends Model {
    // Set the resource route of the model
    resource() {
        return 'user-dashboard/bonuses'
    }

    async request(config) {
        // config.url = config.url + (i18n.locale ? `?lang=${i18n.locale}` : '');
        return await this.$http.request(config);
    }
}
