<template>
    <v-list nav dense v-if="showListLocales" class="transparent">
        <div class="pa-1 overline white--text">{{ $t('common.languages') }}</div>
        <v-list-group>
            <template v-slot:activator>
                <v-list-item-icon>
                    <flag-icon
                        :class="[$vuetify.rtl ? 'ml-1' : 'mr-0']"
                        :flag="currentLocale ? currentLocale.flag : 'gb'"
                    ></flag-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ currentLocale ? currentLocale.label : 'English' }}
                    </v-list-item-title>
                </v-list-item-content>
            </template>
            <v-list-item v-for="(locale, index) in availableLocales"
                         link
                         :key="index"
                         @click="setLocale(locale.code)"
            >
                <v-list-item-icon>
                    <flag-icon :class="[$vuetify.rtl ? 'ml-1' : 'mr-1']" :flag="locale.flag">
                    </flag-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ locale.label }}</v-list-item-title>
            </v-list-item>
        </v-list-group>
    </v-list>
</template>

<script>
import FlagIcon from "@/components/common/FlagIcon";
import {mapActions, mapState} from "vuex";
import Translation from "../../models/Translation";

export default {
    name: "MainLanguagesMenu",
    components: {
        FlagIcon
    },
    computed: {
        showListLocales() {
            return this.translationSettings.length > 1
        },
        ...mapState('app', ['translationSettings']),
        ...mapState({
            current_lang: state => state.user.current_lang
        }),
        currentLocale() {
            return this.translationSettings.find((i) => i.code === this.current_lang)
        },
        availableLocales() {
            return this.translationSettings.filter((i) => i.code !== this.current_lang)
        }
    },
    methods: {
        ...mapActions('translatable', ['updateTranslatable']),
        async setLocale(locale) {
            this.$i18n.locale = locale
            this.$vuetify.lang.current = locale
            this.$vuetify.rtl = locale === 'ar'
            localStorage.setItem('lang', locale);
            await this.getLocale(locale)
            this.$store.commit('user/setCurrentLang', locale)
        },
        async getLocale(locale) {
            let dynamic = await Translation.find(locale);
            await this.updateTranslatable(locale);
            this.$i18n.setLocaleMessage(this.$i18n.locale, {
                ...this.$i18n.getLocaleMessage(this.$i18n.locale), ...dynamic
            })
        }
    }
}
</script>
