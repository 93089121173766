import router from "@/router";

const state = {};

const mutations = {};

const actions = {
    updateTranslatable: async ({state, commit, dispatch}, payload) => {
        await dispatch('app/getControlContents', payload, {root: true});
        switch (router.currentRoute.name) {
            // case 'casino':
            // case 'casino.category':
            // case 'live-casino':
            //     await dispatch('gameCategories/getGameCategories', payload, {root: true});
            //     break;
            case 'user-bonuses':
                await dispatch('app/getUserBonuses', {}, {root: true});
                break;
            default:
            // ...
        }
    },
};

const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
