<template>
  <v-card class="d-flex align-center justify-start pa-0 mb-2" flat dark tile>
    <v-icon left class="primary rounded" @click="$router.go(-1)">mdi-chevron-left</v-icon>
    <v-card-title class="text-subtitle-1 pa-0"
      >{{ $t(pageTitle) }}</v-card-title
    >
  </v-card>
</template>

<script>
export default {
  props: ["pageTitle"],
};
</script>
