export default {
  currency: {
    label: 'USD',
    decimalDigits: 2,
    decimalSeparator: '.',
    thousandsSeparator: ',',
    currencySymbol: '$',
    currencySymbolNumberOfSpaces: 0,
    currencySymbolPosition: 'left'
  },
  availableCurrencies: [
    {
      label: 'EUR',
      decimalDigits: 2,
      decimalSeparator: '.',
      thousandsSeparator: ',',
      currencySymbol: '€',
      currencySymbolNumberOfSpaces: 1,
      currencySymbolPosition: 'right'
    },
    {
      label: 'TRY',
      decimalDigits: 2,
      decimalSeparator: '.',
      thousandsSeparator: ',',
      currencySymbol: '₺',
      currencySymbolNumberOfSpaces: 0,
      currencySymbolPosition: 'left'
    }
  ]
}
