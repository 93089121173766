<template>
    <div class="fill-height game-preloader" :style="`background-image: url('${backgroundImage}');`">
        <div class="game-preloader__overlay">
            <v-progress-circular
                class="preloader-spin"
                :size="45"
                :width="5"
                indeterminate
            />
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex"

export default {
    name: "GamePreloader",
    props: {
        backgroundImage: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapState({
            //preloaderLogo: state => state.app.brandSkin.length ? state.app.brandSkin.find(v => v.key === 'logo') : null,
        }),
    },
}
</script>

<style lang="scss">
.game-preloader {
    width: 100%;
    aspect-ratio: calc(3/2);
    background-color: var(--v-background-base);
    background-size: 100% 100%;

    .game-preloader__overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }
}
</style>
