import Vuetify from "@/plugins/vuetify";

import {wrappedGames, wrappedGameUrl, wrappedGameUrlLaunch} from "@/requests/games/games"
import GameItem from "@/models/GameItem"
import GameItemsByCategories from "@/models/GameItemsByCategories"
import Games from "@/api/games";
import gamePages from "@/configs/gamePages";

async function _fetchGamesByCategories(payload = {}) {
    let limit_games = 21;
    let query = GameItemsByCategories;

    query = payload.platform !== 'mobile' ? query.where("is_desktop", 1) : query.where("is_mobile", 1)
    limit_games = payload.platform !== 'mobile' ? 21 : 21


    query.orderBy("-is_promoted")

    if(payload.categories && Array.isArray(payload.categories)) {
        query.whereIn(['category'], payload.categories);
    }

    if(payload.category) {
        query.where(["categories"], payload.category)
    }

    query.orderBy("-is_promoted")
    query.limit(limit_games)

    const data = await query.get()

    return data;
}

async function _fetchFilteredGames(payload = {}) {
    let limit_games = 21;
    let query = GameItem;

    query = payload.platform !== 'mobile' ? query.where("is_desktop", 1) : query.where("is_mobile", 1)
    limit_games = payload.platform !== 'mobile' ? 21 : 21


    query.orderBy("-is_promoted")
    query.params({"type": "search"})


    if(payload.categories && Array.isArray(payload.categories)) {
        query.whereIn(['category', 'category_id'], payload.categories);
    }

    if(payload.category) {
        query.where(["category", "category_id"], payload.category)
    }
    // query.where(["category", "category_id"], this.activeCategoryId)

    query.orderBy("-is_promoted")
    query.limit(limit_games)

    const games = await query.get()

    return games.data;
}


export default {
    async getCategories({state, commit, rootState}, payload = {lang: ''}) {
        let lang = payload.lang;
        if (!lang) {
            lang = rootState.user.current_lang;
        }
        const result = await Games.getCategories({lang});
        commit('setCategories', result);
    },
    async getProviders({state, commit, rootState}, payload = {gamePage: '', sort: '', platform: ''}) {
        const filter = {};
        filter.type = payload.gamePage ? gamePages.providerTypes[payload.gamePage] : gamePages.default.providerType;
        if(!filter.type) {
            filter.type = '_all-types'
        }
        if(payload.sort) {
            filter.sort = payload.sort;
        }
        switch (payload.platform) {
            case 'desktop':
                filter.is_desktop = 1;
                break;
            case 'mobile':
                filter.is_mobile = 1;
                break;
            // default: filter.is_desktop = 1; filter.is_mobile = 1;
        }
        const data = await Games.getProviders(filter);

        commit('setProvidersGroupBySlug', data[0]);
    },

    // async getGamesByAllCategories({state, commit, rootState}, payload = {lang: ''}) {
    //     const result = {};
    //     let count = 0;
    //     commit('clearGamesByCategories')
    //     const data = await _fetchGamesByCategories({platform: rootState.app.platform})
    //     commit('addGamesToGamesByCategories', data[0])
    //     // for(const c of state.categories) {
    //     //     // if(count-- <= 0) break;
    //     //     result[c.id]  = await _fetchFilteredGames({category: c.id, platform: rootState.app.platform})
    //     //     commit('addGamesToGamesByCategories', result)
    //     // }
    // },

    addGamesToGamesByCategories({state, commit}, payload = {categoryId: -1, games: [], hasNextPagePaginate: false}) {
        if(payload.categoryId > -1 && payload.games) {
            state.gamesByCategories[payload.categoryId] = {games: payload.games, hasNextPagePaginate: payload.hasNextPagePaginate};
        }
    },

    async getItems({state, commit}) {
        const {data} = await wrappedGames({params: state.pagination});
        commit("setPagination", data.pagination)
        commit("updateItems", data)
    },
    async getGameUrl({state, commit}, [slug = null, launchUrl = null]) {
        let data
        if (slug !== null) {
            data = await wrappedGameUrl(slug).then(res => res.data)
        } else {
            data = await wrappedGameUrlLaunch(launchUrl).then(res => res.data)
        }
        commit("setGameUrl", data);
    },
    async getSoftswissGameUrl({state, commit}, [slug = null, launchUrl = null]) {
        let data
        if (slug !== null) {
            data = await axios.get('/api/softswiss-game-url?launch_url=' + slug).then(res => res.data)
        } else {
            data = await axios.get('/api/softswiss-game-url?launch_url=' + launchUrl).then(res => res.data)
        }
        commit("setGameUrl", data);
    },
    setPagination({commit}, payload) {
        commit("setPagination", payload)
    },
    setPaginatePage({commit}, payload)  {
        commit("setPaginatePage", payload)
    },
    nextPaginatePage({commit})  {
        commit("nextPaginatePage")
    },
    setCurrentProvider({commit}, payload) {
        commit("setProvider", payload)
    },
    updateFilter({commit, dispatch}, payload) {
        commit('updateFilter', payload)
        dispatch('setPaginatePage', 1)
    },
    setFilter({commit, dispatch}, payload) {
        commit('setFilter', payload)
        dispatch('setPaginatePage', 1)
    },
    async fetchFilteredGames({commit, state, rootState}, payload = {}) {
        if(!state.initialized) {
            return false;
        }

        if(payload.categories || payload.category) {
            commit('setFilter', {});
        }

        if(payload.method === 'clear') {
            // commit('setFilter', {})
            commit('setPaginatePage', 1)
            commit('setItems', [])
        }

        if(payload.method !== 'add') {
            commit('setHasNextPage', true)
            commit('setItems', [])
        }
        if(!state.hasNextPage) {
            return false;
        }
        state.loading = true
        let limit_games = 30;
        let query = GameItem;

        query = rootState.app.platform !== 'mobile' ? query.where("is_desktop", 1) : query.where("is_mobile", 1)
        limit_games = rootState.app.platform !== 'mobile' ? 30 : 20


        query.orderBy("-is_promoted")
        query.params({"type": "search"})

        if (state.filter.search) {
            query.where("original_name", state.filter.search)
        }

        if (state.filter.provider) {
            // const providerId = this.providers[this.activeProviderSlug].map(p => p.id)
            query.whereIn("provider_id", [state.filter.provider.join(',')])
        }

        if(payload.categories && Array.isArray(payload.categories)) {
            query.whereIn(['category', 'category_id'], payload.categories);
        }

        if(payload.category) {
            query.where(["category", "category_id"], payload.category)
        }
        // query.where(["category", "category_id"], this.activeCategoryId)

        query.page(state.paginatePage)
        query.orderBy("-is_promoted")
        query.limit(limit_games)

        const games = await query.get()
        if(games.next_page_url) {
            commit('setHasNextPage', true)
        } else {
            commit('setHasNextPage', false)
        }

        if(payload.method === 'add') {
            commit("addItems", games.data)
        } else {
            commit("updateItems", games.data)
        }

        // await query.get().then(response => {
        //     const games = response.data
        //     this.$store.commit("games/updateItems", games)
        //
        //     // if(this.isMorePagination) {
        //     //     this.isMorePagination = false
        //     //     this.games = this.games.concat(items)
        //     // } else {
        //     //     this.games = items
        //     // }
        //     //
        //     // this.current_page = response.current_page
        //     // this.prev_page_url = response.prev_page_url
        //     // this.next_page_url = response.next_page_url
        //     // this.loading = false
        // })
    },

    setGamePage ({commit}, gamePage) {
        commit('setGamePage', gamePage);
    },

    async _init({dispatch, commit, rootState}) {
        await dispatch('getCategories');
        await dispatch('getProviders');
        // dispatch('getGamesByAllCategories', {platform: rootState.app.platform});

        commit('initialized');
    },
    onChangeLang: {
        root: true,
        handler: ({dispatch}) => {
            dispatch('getCategories');
        }
    },

    onChangePlatform: {
        root: true,
        handler: ({dispatch, rootState, commit}) => {
            commit('setPlatform', rootState.app.platform);
            // dispatch('getGamesByAllCategories', {platform: rootState.app.platform});
        }
    }
}
