<template>
    <v-dialog
        v-model="isActive"
        max-width="350"
        dark
    >
        <v-card class="pb-2">
            <v-card-title class="headline">
                {{$t("dashboard.sections.bonuses.confirmCancelBonusText")}}
            </v-card-title>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                    class="button-cancel"
                    text
                    @click="isActive = false"
                >
                    {{$t("common.disagree")}}
                </v-btn>

                <v-btn
                    class="tile-gradient-primary"
                    text
                    @click="[isActive = false, confirmCloseBonus()]"
                >
                    {{$t("common.agree")}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Bonus from "@/models/Bonus";
import {mapActions} from "vuex";
import bonuses from "@/mixins/bonuses";

export default {
    mixins: [bonuses],
    props: {
        value: {
            type: Boolean,
            required: true,
        }
    },
    computed: {
        isActive: {
            get() {
                return this.value;
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    },
    methods: {
        ...mapActions({
            setBonusDeposit: 'user/setBonusDeposit',
            showError: 'app/showError',
        }),
        async confirmCloseBonus() {
            let model = new Bonus(this.bonus);
            await model.delete().then(() => {
                this.$auth.user().active_bonus = null
                this.setBonusDeposit({});
            })
            .catch(() => {
                this.showError({
                    error: {
                        message: "You cannot cancel this bonus"
                    }
                })
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.headline {
    word-break: keep-all;
}
.button-cancel {
    background-color: #434343 !important;
    color: #bbb !important;
    caret-color: #bbb !important;
}
</style>
