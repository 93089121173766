<template>
    <v-navigation-drawer
        v-model="drawerActive"
        app
        floating
        touchless
        temporary
        disable-resize-watcher
        class="elevation-1 navigation-mobile mt-sm-16 ml-sm-5"
        max-width="288"
        width="288"
        height="auto"
        @input="input"
        @transitionend="drawerTransitionEnd"
        style="z-index: 9999"
    >
        <!-- Navigation menu info -->
<!--        <template v-slot:prepend>-->
<!--            <v-app-bar height="64">-->
<!--                <logo-responsive/>-->

<!--                <v-spacer/>-->
        <div v-if="xsOnly" class="ma-4"></div>
        <div v-if="xsOnly" style="position: absolute; right: 16px; top:24px; z-index: 1;">
            <v-btn
                small
                icon
                plain
                class=""
                :class="[$vuetify.rtl ? 'ml-0' : 'mr-0']"
                @click="drawerClose">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
<!--            </v-app-bar>-->
<!--        </template>-->

<!--        <div class="d-flex justify-space-between mb-1" v-if="$auth.user()">-->
<!--            <v-card-->
<!--                class="tile-gradient-primary tile-gradient-dark ml-1"-->
<!--                elevation="1"-->
<!--                to="/user/deposit"-->
<!--                color="blue-grey darken-3"-->
<!--                width="49%">-->
<!--                <v-card-text class="text-center">-->
<!--                    <span class="pt-1 lh-dense d-block text-no-wrap text-subtitle-1 font-weight-bold">{{-->
<!--                            $t("menu.deposit")-->
<!--                        }}</span>-->
<!--                </v-card-text>-->
<!--            </v-card>-->
<!--            <v-card-->
<!--                class="tile-gradient-dark mr-1"-->
<!--                elevation="1"-->
<!--                to="/user/withdrawal"-->
<!--                color="blue-grey darken-3"-->
<!--                width="49%">-->
<!--                <v-card-text class="text-center">-->
<!--                    <span class="pt-1 lh-dense d-block text-no-wrap text-subtitle-1 font-weight-bold">{{-->
<!--                            $t("menu.withdrawal")-->
<!--                        }}</span>-->
<!--                </v-card-text>-->
<!--            </v-card>-->
<!--        </div>-->

<!--        <v-sheet color="transparent" v-if="profileDrawerStyle && profileDrawerStyle === 'modern'">-->
<!--            <v-row dense class="pa-2">-->
<!--                <v-col cols="12">-->
<!--                    <v-list class="py-0 transparent">-->
<!--                        <div class="overline white&#45;&#45;text transparent">{{ $t("dashboard.sections.bonuses.title") }}</div>-->
<!--                    </v-list>-->
<!--                </v-col>-->
<!--                <v-col v-if="depositBonus || freespinBonus"-->
<!--                       cols="12"-->
<!--                       class="pb-4"-->
<!--                >-->
<!--                    <div class="text-subtitle-2 mb-2 text-uppercase">-->
<!--                        {{ activeBonus.name }}-->
<!--                    </div>-->
<!--                    <div class="d-flex align-center">-->
<!--                        <v-progress-linear-->
<!--                            :value="bonus_target"-->
<!--                            height="25">-->
<!--                            <template v-slot:default="{ value }">-->
<!--                                <strong>{{ bonus_target }}%</strong>-->
<!--                            </template>-->
<!--                        </v-progress-linear>-->
<!--                        <v-btn-->
<!--                            v-if="!disallowPlayerCancelBonus"-->
<!--                            small-->
<!--                            class="ml-2 tile-gradient-dark"-->
<!--                            @click="currentBonus = activeBonus; confirmDialog = true">-->
<!--                            {{ $t("dashboard.sections.bonuses.cancelBonus") }}-->
<!--                        </v-btn>-->
<!--                    </div>-->
<!--                </v-col>-->
<!--                <v-col v-else-if="cashbackBonus"-->
<!--                       cols="12"-->
<!--                       class="pb-4"-->
<!--                >-->
<!--                    <div class="text-subtitle-2 mb-2 text-uppercase">-->
<!--                        {{ activeBonus.name }}-->
<!--                    </div>-->
<!--                    <div class="text-subtitle-2 text-uppercase">-->
<!--                        {{ $t('dashboard.sections.bonuses.labels.potentialCashback') }}:-->
<!--                        {{-->
<!--                            bonus_target ? (bonus_target / 100).toFixed(2) : activeBonus.potential_cashback ? (activeBonus.potential_cashback / 100).toFixed(2) : 0-->
<!--                        }}-->
<!--                        {{ activeBonus.balance.currency }}-->
<!--                    </div>-->
<!--                </v-col>-->
<!--                <v-col v-else-if="freebetBonus"-->
<!--                       cols="12"-->
<!--                       class="pb-4"-->
<!--                >-->
<!--                    <div class="text-subtitle-2 mb-2 text-uppercase">-->
<!--                        {{ activeBonus.name }}-->
<!--                    </div>-->
<!--                </v-col>-->
<!--                <v-col v-else-if="Object.keys(bonus_deposit).length && bonus_target < 100"-->
<!--                       cols="12"-->
<!--                       class="pb-4"-->
<!--                >-->
<!--                    <div class="text-subtitle-2 mb-2 text-uppercase">{{ bonus_deposit.name }}</div>-->
<!--                    <div class="d-flex align-center">-->
<!--                        <v-progress-linear-->
<!--                            :value="bonus_target"-->
<!--                            height="25">-->
<!--                            <template v-slot:default="{ value }">-->
<!--                                <strong>{{ bonus_target }}%</strong>-->
<!--                            </template>-->
<!--                        </v-progress-linear>-->
<!--                        <v-btn-->
<!--                            v-if="!disallowPlayerCancelBonus"-->
<!--                            small-->
<!--                            class="ml-2 tile-gradient-dark"-->
<!--                            @click="currentBonus = bonus_deposit; confirmDialog = true">-->
<!--                            {{ $t("dashboard.sections.bonuses.cancelBonus") }}-->
<!--                        </v-btn>-->
<!--                    </div>-->
<!--                </v-col>-->

<!--                <v-col cols="12" v-else>-->
<!--                    <div class="text-white">{{ $t("dashboard.sections.bonuses.notActiveBonuses") }}</div>-->
<!--                </v-col>-->
<!--            </v-row>-->
<!--        </v-sheet>-->

<!--        <template v-if="carousel.length">-->
<!--            <v-divider/>-->
<!--            &lt;!&ndash; Navigation menu slider &ndash;&gt;-->
<!--            <navigation-drawer-swiper-->
<!--                @drawerClose="drawerClose"-->
<!--                :active="drawerOpened"-->
<!--                class="mt-1"-->
<!--                :items="carousel"/>-->
<!--        </template>-->

<!--        <template v-if="mobile_menu.length">-->
<!--            <v-divider/>-->
<!--            &lt;!&ndash; Navigation menu slider &ndash;&gt;-->
<!--            <div v-for="section in mobile_menu" :key="section.id" class="px-2 mb-4">-->
<!--                <div class="pa-1 mb-1 overline white&#45;&#45;text">{{ getCurrentLang(section.title) }}</div>-->
<!--                <div-->
<!--                    v-for="(item, i) in orderBy(section.items)"-->
<!--                     :key="i">-->
<!--                    <v-card v-if="item.game"-->
<!--                            class="tile-gradient-dark mb-1"-->
<!--                            elevation="1"-->
<!--                            color="blue-grey darken-3"-->
<!--                            min-width="90px"-->
<!--                            @click="goToGame(item.link)"-->
<!--                    >-->
<!--                        <v-card-text class="d-flex align-center pa-2">-->
<!--                            <v-icon>{{ item.icon }}</v-icon>-->
<!--                            <span class="lh-dense ml-2">-->
<!--                            {{ getCurrentLang(item.subtitle) }}-->
<!--                        </span>-->
<!--                            <v-chip-->
<!--                                v-if="item.badge"-->
<!--                                class="ml-4 text-uppercase"-->
<!--                                label-->
<!--                                x-small-->
<!--                                color="#ff2b5a"-->
<!--                            >-->
<!--                                {{ $t('badges.' + item.badge) }}-->
<!--                            </v-chip>-->
<!--                        </v-card-text>-->
<!--                    </v-card>-->
<!--                    <v-card v-if="!item.game && !item.external_link"-->
<!--                        class="tile-gradient-dark mb-1"-->
<!--                        elevation="1"-->
<!--                        :to="item.link"-->
<!--                        color="blue-grey darken-3"-->
<!--                        min-width="90px">-->
<!--                        <v-card-text class="d-flex align-center pa-2">-->
<!--                            <v-icon>{{ item.icon }}</v-icon>-->
<!--                            <span class="lh-dense ml-2">-->
<!--                            {{ getCurrentLang(item.subtitle) }}-->
<!--                        </span>-->
<!--                            <v-chip-->
<!--                                v-if="item.badge"-->
<!--                                class="ml-4 text-uppercase"-->
<!--                                label-->
<!--                                x-small-->
<!--                                color="#ff2b5a"-->
<!--                            >-->
<!--                                {{ $t('badges.' + item.badge) }}-->
<!--                            </v-chip>-->
<!--                        </v-card-text>-->
<!--                    </v-card>-->
<!--                    <v-card v-if="!item.game && item.external_link"-->
<!--                        class="tile-gradient-dark mb-1"-->
<!--                        elevation="1"-->
<!--                        :target="item.new_tab ? '_blank' : ''"-->
<!--                        :href="item.link"-->
<!--                        color="blue-grey darken-3"-->
<!--                        min-width="90px">-->
<!--                        <v-card-text class="d-flex align-center pa-2">-->
<!--                            <v-icon>{{ item.icon }}</v-icon>-->
<!--                            <span class="lh-dense ml-2">-->
<!--                            {{ getCurrentLang(item.subtitle) }}-->
<!--                        </span>-->
<!--                            <v-chip-->
<!--                                v-if="item.badge"-->
<!--                                class="ml-4 text-uppercase"-->
<!--                                label-->
<!--                                x-small-->
<!--                                color="#ff2b5a"-->
<!--                            >-->
<!--                                {{ $t('badges.' + item.badge) }}-->
<!--                            </v-chip>-->
<!--                        </v-card-text>-->
<!--                    </v-card>-->
<!--                </div>-->

<!--            </div>-->
<!--        </template>-->

<!--        <template v-if="navigation.menu.length">-->
<!--            <v-divider/>-->
<!--            &lt;!&ndash; Navigation menu &ndash;&gt;-->
<!--            <main-menu @drawerClose="drawerClose"/>-->
<!--        </template>-->

<!--        <template v-if="translationSettings.length">-->
<!--            <v-divider/>-->
<!--            &lt;!&ndash; Languages menu &ndash;&gt;-->
<!--            <main-languages-menu/>-->
<!--        </template>-->

<!--        &lt;!&ndash; Navigation menu footer &ndash;&gt;-->
<!--&lt;!&ndash;        <template v-slot:append>&ndash;&gt;-->
<!--&lt;!&ndash;            &lt;!&ndash; Footer navigation links &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="pa-1 text-center surface">&ndash;&gt;-->
<!--&lt;!&ndash;                <v-btn&ndash;&gt;-->
<!--&lt;!&ndash;                    v-for="(item, index) in navigation.footer"&ndash;&gt;-->
<!--&lt;!&ndash;                    :key="index"&ndash;&gt;-->
<!--&lt;!&ndash;                    to="/"&ndash;&gt;-->
<!--&lt;!&ndash;                    :target="item.target"&ndash;&gt;-->
<!--&lt;!&ndash;                    small&ndash;&gt;-->
<!--&lt;!&ndash;                    class="primary black&#45;&#45;text"&ndash;&gt;-->
<!--&lt;!&ndash;                    text>&ndash;&gt;-->
<!--&lt;!&ndash;                    {{ item.key ? $t(item.key) : item.text }}&ndash;&gt;-->
<!--&lt;!&ndash;                </v-btn>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </template>&ndash;&gt;-->
<!--        <v-dialog-->
<!--            v-model="confirmDialog"-->
<!--            max-width="350"-->
<!--            dark-->
<!--        >-->
<!--            <v-card class="pb-2">-->
<!--                <v-card-title class="headline">-->
<!--                    {{ $t("dashboard.sections.bonuses.confirmCancelBonusText") }}-->
<!--                </v-card-title>-->

<!--                <v-card-actions>-->
<!--                    <v-spacer></v-spacer>-->

<!--                    <v-btn-->
<!--                        class="tile-gradient-dark"-->
<!--                        text-->
<!--                        @click="confirmDialog = false"-->
<!--                    >-->
<!--                        {{ $t("common.disagree") }}-->
<!--                    </v-btn>-->

<!--                    <v-btn-->
<!--                        class="tile-gradient-primary"-->
<!--                        text-->
<!--                        @click="confirmDialog= false, confirmCloseBonus()"-->
<!--                    >-->
<!--                        {{ $t("common.agree") }}-->
<!--                    </v-btn>-->
<!--                </v-card-actions>-->
<!--            </v-card>-->
<!--        </v-dialog>-->
        <left-menu v-if="authUser" class="mt-0" @click="closeNavigationDrawer"/>
    </v-navigation-drawer>
</template>

<script>
import {mapActions, mapState} from "vuex";
import LogoResponsive from "@/components/common/LogoResponsive";
import NavigationDrawerSwiper from "@/components/navigation/NavigationDrawerSwiper";
import MainMenu from '@/components/navigation/MainMenu'
import MainLanguagesMenu from "@/components/navigation/MainLanguagesMenu";
import _ from "lodash"

import breaks from "@/mixins/breaks";
import config from '@/configs'
import axios from "axios";
import Bonus from "../../models/Bonus";
import LeftMenu from "@/components/user/LeftMenu.vue";

export default {
    name: "NavigationDrawer",
    components: {
        LeftMenu,
        LogoResponsive,
        NavigationDrawerSwiper,
        MainMenu,
        MainLanguagesMenu
    },
    mixins: [breaks],
    model: {
        prop: 'model',
        event: 'input'
    },
    props: {
        carousel: {
            type: Array,
            default: () => []
        },
        mobile_menu: {
            type: Array,
            default: () => []
        },
        model: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        confirmDialog: false,
        drawerActive: false,
        drawerOpened: false,
        searchQuery: null,
        navigation: config.navigation,
        currentBonus: {},
    }),
    computed: {
        ...mapState({
            profileDrawerStyle: state => state.app.brandSettings.profile_drawer_style,
            bonus_target: state => state.user.bonus_target,
            current_lang: state => state.user.current_lang,
            bonus_deposit: state => state.user.bonus_deposit,
            profileDrawerSetting: state => state.app.controlContents.profileDrawer,
            profileDrawerAuth: state => state.app.controlContents.hasOwnProperty('profileDrawer') && state.app.controlContents.profileDrawer && state.app.controlContents.profileDrawer.settings && state.app.controlContents.profileDrawer.settings.auth
                ? state.app.controlContents.profileDrawer.settings.auth
                : [],
            profileDrawerNoAuth: state => state.app.controlContents.hasOwnProperty('profileDrawer') && state.app.controlContents.profileDrawer && state.app.controlContents.profileDrawer.noauth && state.app.controlContents.profileDrawer.settings.noauth
                ? state.app.controlContents.profileDrawer.settings.noauth
                : [],
            disallowPlayerCancelBonus: state => state.app.brandSettings.disallow_player_cancel_bonus
        }),
        activeBonus() {
            return this.$auth.user() ? this.$auth.user().active_bonus : null
        },
        depositBonus() {
            return !Object.keys(this.bonus_deposit).length && this.activeBonus && Object.keys(this.activeBonus).length && this.activeBonus.type === 'deposit' && +this.activeBonus.status === 1 && this.bonus_target < 100
        },
        freespinBonus() {
            return !Object.keys(this.bonus_deposit).length && this.activeBonus && Object.keys(this.activeBonus).length && this.activeBonus.type === 'freespin' && +this.activeBonus.status === 1 && this.bonus_target < 100
        },
        cashbackBonus() {
            return !Object.keys(this.bonus_deposit).length && this.activeBonus && Object.keys(this.activeBonus).length && this.activeBonus.type === 'cashback'
        },
        freebetBonus() {
            return !Object.keys(this.bonus_deposit).length && this.activeBonus && Object.keys(this.activeBonus).length && this.activeBonus.type === 'freebet'
        },
        authUser() {
            return this.$auth.user()
        },
        currency() {
            return this.authUser ? this.authUser.currency : this.currencies[0]
        },
        lang() {
            return this.current_lang ? this.current_lang : this.authUser ? this.authUser.language : 'tr'
        },
        platform() {
            return this.mdAndUp ? 'desktop' : 'mobile'
        },
        ...mapState({
            verify_email: state => state.app.brandSettings.verify_email,
            game: (state) => state.games.gameUrl,
        }),
        ...mapState({
            current_lang: state => state.user.current_lang
        }),
        ...mapState('app', ['menuTheme', 'controlContents', 'translationSettings']),
    },
    watch: {
        model() {
            this.init();
        },
        lgAndUp() {
            if (this.lgAndUp) {
                this.drawerActive = false;
                this.input();
            }
        }
    },
    created() {
        this.init();
    },
    methods: {
        closeNavigationDrawer() {
            this.drawerActive = false;
        },
        getCashback() {
            axios.get('/api/user-dashboard/get-cashback')
        },
        ...mapActions('app', ['makeActiveWindow']),
        ...mapActions({
            getUserBonuses: 'app/getUserBonuses',
            setBonusDeposit: 'user/setBonusDeposit',
        }),
        async confirmCloseBonus() {
            let model = new Bonus(this.currentBonus);
            await model.delete().then(res => {
                this.$auth.user().active_bonus = null
                this.setBonusDeposit({});
            });
        },
        ...mapActions('app', ['makeActiveWindow']),
        ...mapActions({
            getGameUrl: "games/getGameUrl",
        }),
        gameLinkStore() {
            const link = this.game.launch_url ? this.game.launch_url : this.game.slug
            const provider = this.game.game_provider ? this.game.game_provider.slug : 'provider'
            this.$store.commit('app/setGameLinkBeforeLogin', `/casino/${provider}/${link}`)
        },
        goToGame(launch_code) {
            if (this.authUser && !this.authUser.email_verified_at && this.verify_email && this.verify_email.includes('bets')) {
                this.makeActiveWindow({active: true, type: 'verifyEmail'})
                return false
            }
            if (!this.authUser) {
                this.getGame(launch_code)
                return false
            }
            axios.get(`/api/games/launch-url/${launch_code}?currency=${this.currency}&lang=${this.lang}&platform=${this.platform}`).then(res => {
                if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
                    window.location.href = res.data.game_link
                } else {
                    window.open(res.data.game_link, '_blank')
                }
            })
        },
        getGame(launch_code) {
            this.getGameUrl([null, launch_code]).then(() => {
                if (this.game) {
                    this.drawerClose()
                    this.makeActiveWindow({active: true, type: 'login'})
                    this.gameLinkStore()
                }
            })

        },
        orderBy(items) {
            items.map(v => v.position = +v.position)
            return _.orderBy(items,'position','asc')
        },
        getCurrentLang(item) {
            return item.hasOwnProperty(this.current_lang) ? item[this.current_lang] : ''
        },
        init() {
            this.drawerActive = this.model;
        },
        drawerClose() {
            this.drawerActive = false;
            this.input();
        },
        input() {
            this.$emit('input', this.drawerActive);
        },
        search() {
            // search action
        },
        clearSearchQuery() {
            this.searchQuery = null;
        },
        drawerTransitionEnd() {
            this.drawerOpened = this.drawerActive;
        },
        setLocale(code) {
            // set locale action
        }
    }
}
</script>
<style lang="scss">
.v-overlay--active {
    .v-overlay__scrim {
        //background-color: rgba(0,0,0,0.3) !important;
        //backdrop-filter: blur(10px);
        //opacity: 1 !important;
    }
}
.navigation-mobile {
    & .v-navigation-drawer__content {
        //background-color: #fff;
        //color: #000;
    }
}

</style>
