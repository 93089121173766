export default {
    casinoCategoryItems: [
        {
            title: {
                en: 'All Games'
            },
            image: '/images/icons/casino/all_games.svg'
        },
        {
            title: {
                en: 'Top Games'
            },
            image: '/images/icons/casino/top_games.svg'
        },
        {
            title: {
                en: 'New Games'
            },
            image: '/images/icons/casino/new_games.png'
        },
        {
            title: {
                en: 'Slots'
            },
            image: '/images/icons/casino/slots.svg'
        },
        {
            title: {
                en: 'Table Games'
            },
            image: '/images/icons/casino/table_games.svg'
        },
        {
            title: {
                en: 'Live Games'
            },
            image: '/images/icons/casino/live_games.svg'
        },
        {
            title: {
                en: 'Jackpots'
            },
            image: '/images/icons/casino/jackpots.svg'
        },
        {
            title: {
                en: 'Drops & Wins'
            },
            image: '/images/icons/casino/drops.svg'
        },
        {
            title: {
                en: 'Megaways'
            },
            image: '/images/icons/casino/megaways.svg'
        }
    ],
    liveCategoryItems: [
        {
            title: {
                en: 'Soccer'
            },
            image: '/images/icons/sportsbook/soccer.svg'
        },
        {
            title: {
                en: 'Basketball'
            },
            image: '/images/icons/sportsbook/basketball.svg'
        },
        {
            title: {
                en: 'Tennis'
            },
            image: '/images/icons/sportsbook/tennis.svg'
        },
        {
            title: {
                en: 'Volleyball'
            },
            image: '/images/icons/sportsbook/volleyball.svg'
        },
        {
            title: {
                en: 'American Football'
            },
            image: '/images/icons/sportsbook/am-football.svg'
        },
        {
            title: {
                en: 'Ice Hockey'
            },
            image: '/images/icons/sportsbook/hockey.svg'
        },
        {
            title: {
                en: 'Rugby'
            },
            image: '/images/icons/sportsbook/rugby.svg'
        },
        {
            title: {
                en: 'Baseball'
            },
            image: '/images/icons/sportsbook/baseball.svg'
        },
        {
            title: {
                en: 'Snooker'
            },
            image: '/images/icons/sportsbook/snooker.svg'
        }
    ]
}
