<template>
    <v-dialog v-model="searchDialog" overlay-opacity="0.6" content-class="search-dialog">
        <v-card light tile>
            <v-text-field
                light
                solo
                hide-details
                flat
                autofocus
                class="text-body-1 py-4 search-overlay__input"
                append-icon="mdi-close"
                :placeholder="$t('form.search.placeholder')"
                @input="inputSearch"
                @click:append="searchDialog = false"
                v-model="search"
            ></v-text-field>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "SearchShowingDialog",
    props: {
        searchDialog: {
            type: Boolean,
            default: () => false
        },
        search: {
            type: String,
            default: () => null
        }
    },
    methods: {
        inputSearch() {
            this.$emit('inputSearch')
        }
    }
}
</script>

<style scoped>

</style>
