<template>
    <div ref="telegram"></div>
</template>

<script>
export default {
    name: 'TelegramLogin',
    props: {
        scriptName: {
            type: String,
            default: 'login'
        },
        mode: {
            type: String,
            default: 'callback',
            validator (value) { return ['callback', 'redirect'].includes(value) }
        },
        telegramLogin: {
            type: String,
            required: true,
            validator (value) { return value.endsWith('bot') || value.endsWith('Bot') }
        },
        redirectUrl: {
            type: String,
            default: ''
        },
        requestAccess: {
            type: String,
            default: 'write',
            validator (value) { return ['read', 'write'].includes(value) }
        },
        size: {
            type: String,
            default: 'medium',
            validator (value) { return ['small', 'medium', 'large'].includes(value) }
        },
        userpic: {
            type: Boolean,
            default: false
        },
        radius: {
            type: String
        },
        // этот параметр важен в случае загрузки компонента в модальное окно,
        // иначе он не загрузится в другом модальном окне, если он там требуется
        closed: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            scriptElement: null
        }
    },
    watch: {
        closed(val) {
            if (val) {
                this.scriptElement = null;
                this.$refs.telegram.innerHTML = null;
            } else {
                this.setElement()
            }
        }
    },
    methods: {
        onTelegramAuth(user) {
            this.$emit('callback', user)
        },
        setElement() {
            const script = document.createElement('script')
            script.async = true
            script.src = `https://telegram.org/js/telegram-widget.js?${this.scriptName}`

            script.setAttribute('data-size', this.size)
            script.setAttribute('data-userpic', this.userpic)
            script.setAttribute('data-telegram-login', this.telegramLogin)
            script.setAttribute('data-request-access', this.requestAccess)
            if (this.radius) { script.setAttribute('data-radius', this.radius) }

            let windowScript = `onTelegramAuth${this.scriptName}`

            if (this.mode === 'callback') {
                window[windowScript] = this.onTelegramAuth
                script.setAttribute('data-onauth', `${windowScript}(user)`)
            } else {
                script.setAttribute('data-auth-url', this.redirectUrl)
            }

            this.scriptElement = this.$refs.telegram.appendChild(script)
        }
    },
    mounted () {
        this.setElement()
    }
}
</script>
