<template>
    <div>
        <v-list-item v-if="menuItem.type === 'link' && menuItem.link_type !== 'game'"
                     :input-value="menuItem.title"
                     :to="menuItem.link"
                     :target="menuItem.link_type === 'external' ? '_blank' : '_self'"
                     active-class="primary--text"
                     link
        >
            <v-list-item-icon>
                <v-icon :small="small">
                    {{ menuItem.icon || 'mdi-circle-medium' }}
                </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>
                    {{ menuItem.title }}
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-item v-else-if="menuItem.type === 'link' && menuItem.link_type === 'game' && authUser"
                     :input-value="menuItem.title"
                     @click="goToGame(menuItem.link)"
                     active-class="primary--text"
                     link
        >
            <v-list-item-icon>
                <v-icon :small="small">
                    {{ menuItem.icon || 'mdi-circle-medium' }}
                </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>
                    {{ menuItem.title }}
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-else-if="menuItem.type === 'link' && menuItem.link_type === 'game' && !authUser"
                     :input-value="menuItem.title"
                     @click="getGame(menuItem.link)"
                     active-class="primary--text"
                     link
        >
            <v-list-item-icon>
                <v-icon :small="small">
                    {{ menuItem.icon || 'mdi-circle-medium' }}
                </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>
                    {{ menuItem.title }}
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-group v-else
                      :sub-group="subgroup"
                      :to="menuItem.link"
                      link
        >
            <template v-slot:activator>
                <v-list-item-icon v-if="!subgroup">
                    <v-icon :small="small">{{ menuItem.icon || 'mdi-circle-medium' }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ menuItem.title }}
                    </v-list-item-title>
                </v-list-item-content>
            </template>

            <slot></slot>

        </v-list-group>
    </div>
</template>

<script>
/*
|---------------------------------------------------------------------
| Navigation Menu Item Component
|---------------------------------------------------------------------
|
| Navigation items for the NavMenu component
|
*/
import {mapActions, mapState} from "vuex";
import axios from "axios";
import breaks from "../../mixins/breaks";

export default {
    mixins: [breaks],
    computed: {
        authUser() {
            return this.$auth.user()
        },
        currency() {
            return this.authUser ? this.authUser.currency : this.currencies[0]
        },
        lang() {
            return this.current_lang ? this.current_lang : this.authUser ? this.authUser.language : 'tr'
        },
        platform() {
            return this.mdAndUp ? 'desktop' : 'mobile'
        },
        ...mapState({
            verify_email: state => state.app.brandSettings.verify_email,
            game: (state) => state.games.gameUrl,
        }),
    },
    methods: {
        getGame(launch_code) {
            this.getGameUrl([null, launch_code]).then(() => {
                if (this.game) {
                    this.$emit('drawerClose')
                    this.makeActiveWindow({active: true, type: 'login'})
                    this.gameLinkStore()
                }
            })

        },
        goToGame(launch_code) {
            if (this.authUser && !this.authUser.email_verified_at && this.verify_email && this.verify_email.includes('bets')) {
                this.makeActiveWindow({active: true, type: 'verifyEmail'})
                return false
            }
            axios.get(`/api/games/launch-url/${launch_code}?currency=${this.currency}&lang=${this.lang}&platform=${this.platform}`).then(res => {
                if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
                    window.location.href = res.data.game_link
                } else {
                    window.open(res.data.game_link, '_blank')
                }
            })
        },
        gameLinkStore() {
            const link = this.game.launch_url ? this.game.launch_url : this.game.slug
            const provider = this.game.game_provider ? this.game.game_provider.slug : 'provider'
            this.$store.commit('app/setGameLinkBeforeLogin', `/casino/${provider}/${link}`)
        },
        ...mapActions('app', ['makeActiveWindow']),
        ...mapActions({
            getGameUrl: "games/getGameUrl",
        }),
    },
    props: {
        menuItem: {
            type: Object,
            default: () => {
            }
        },
        subgroup: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        }
    }
}
</script>
