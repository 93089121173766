<template>
    <div class="d-flex align-center flex-nowrap mr-sm-0" v-if="!disabled_registration_mode">

        <login-toolbar v-if="login && mdAndUp && !auth_modal"/>

        <v-btn
            v-else-if="(login && !mdAndUp) || auth_modal"
            @click="makeActiveWindow({ active: true, type: 'login' })"
            class="btn-login button--primary px-2 px-sm-4 text-uppercase"
            :height="sizes.height"
            :style="sizes.borderRadius"
        >
            {{ $t("menu.authLogin") }}
        </v-btn>

        <v-btn
            v-if="register"
            @click="makeActiveWindow({ active: true, type: 'register' })"
            :height="sizes.height"
            class="btn-register button--yellow px-2 px-sm-4 ml-2 ml-sm-2 text-uppercase"
            :tile="sizes.tile"
        >
            {{ $t("menu.authRegister") }}
        </v-btn>
    </div>
</template>


<script>
import breaks from "@/mixins/breaks"
import {mapActions, mapState} from "vuex"
import LoginToolbar from "@/components/modals/LoginToolbar.vue";

export default {
    components: {LoginToolbar},
    mixins: [breaks],
    props: {
        login: {},
        register: {}
    },
    computed: {
        ...mapState({
            disabled_registration_mode: state => state.app.brandSettings.disabled_registration_mode ? state.app.brandSettings.disabled_registration_mode : false,
            auth_modal: state => state.app.brandSettings.auth_modal ?? false,
        }),
        sizes() {
            return this.xsOnly ? {height: "42px", tile: true} : {height: "40px", tile: false};
        },
    },
    methods: {
        ...mapActions('app', ['makeActiveWindow'])
    }
}
</script>

<style lang="scss">

.btn-register {
    @media screen and (max-width: 600px) {
        min-width: 80px !important;
        height: 30px !important;
        width: 60px !important;
        border-radius: 4px !important;
        font-weight: bold !important;
        text-transform: capitalize !important;
    }
}

.btn-login {
    @media screen and (max-width: 600px) {
        min-width: 80px !important;
        height: 30px !important;
        width: 60px !important;
        border-radius: 4px !important;
        font-weight: bold !important;
        text-transform: capitalize !important;
    }
}

</style>
