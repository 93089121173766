import store from "../store";

export default class TagEvent {


    update()
    {
        store.commit('app/tagEventInc')
        this.getTags()
        this.fetchBanners()
        this.fetchContent()
    }

     getTags()
    {
        store.dispatch('app/getTags')
    }

    fetchBanners()
    {
        store.dispatch('app/getBanners')
    }

    fetchContent()
    {
        store.dispatch('app/getControlContents', store.state.user.current_lang)
    }
}
