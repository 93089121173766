<template>
    <div class="fill-width game-wrap-bg"
         :style="demo ? `background-image: url('${game.background_banner}')` : ''">
        <preloader v-show="isPreloader"/>
        <transition name="preloader" mode="out-in">
            <div v-show="!isPreloader" class="secondary">
                <v-container v-if="demo">
                    <div class="game-wrap" :class="{ full_screen: fullScreen }">
                        <div
                            class="game-right-sidebar d-flex flex-column align-start justify-start pa-4"
                            :class="sidebarActive ? 'active' : ''"
                            @mouseover="lgAndUp ? sidebarActive = true : sidebarActive = false"
                            @mouseleave="sidebarActive = false">

                            <div
                                class="game-right-sidebar-btn d-flex align-center mb-2 mb-lg-4"
                                @click="closeFullScreen()"
                            >
                                <v-icon dark large class="mr-5">mdi-close</v-icon>
                                <span class="text-truncate">{{ $t("slotsGame.closeGame") }}</span>
                            </div>
                            <div class="game-right-sidebar-btn d-flex align-center mb-2 mb-lg-4"
                                 @click="toggleFullScreen">
                                <v-icon dark large class="mr-5"
                                        v-text="fullScreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen'"></v-icon>
                                <span class="text-truncate"
                                      v-text="fullScreen ? $t('slotsGame.exitFullScreen') : $t('slotsGame.fullScreen')"></span>
                            </div>
                            <div class="game-right-sidebar-btn d-flex align-center mb-2 mb-lg-4"
                                 @click="searchDialog = true">
                                <v-icon dark large class="mr-5">mdi-magnify</v-icon>
                                <span class="text-truncate">{{ $t("common.search") }}</span>
                            </div>
                            <div class="game-right-sidebar-btn d-flex align-center mb-2 mb-lg-4"
                                 @click="makeActiveWindow({ active: true, type: 'login'})"
                                 v-if="!authUser"
                            >
                                <v-icon dark large class="mr-5">mdi-login</v-icon>
                                <span class="text-truncate">{{ $t("slotsGame.loginRegister") }}</span>
                            </div>
                            <div class="game-right-sidebar-btn d-flex align-center mb-2 mb-lg-4"
                                 @click="$router.push('/user/deposit')"
                                 v-if="authUser"
                            >
                                <v-icon dark large class="mr-5">mdi-wallet-outline</v-icon>
                                <span class="text-truncate">{{ $t("slotsGame.deposit") }}</span>
                            </div>

                            <div class="similar-games mt-6" v-if="similarGames.length">
                                <div class="text-h5 mb-4">{{ $t("slotsGame.similarGames") }}</div>
                                <div class="games-wrap d-flex flex-wrap">

                                    <router-link
                                        :to="gameLink(game)"
                                        v-if="authUser"
                                        v-for="game in similarGames"
                                        :key="game.id"
                                        class="similar-game mb-2">
                                        <v-badge
                                            v-if="game.badge"
                                            tile
                                            class="game-badge"
                                            dark
                                            color="red"
                                            :content="game.badge.name.toUpperCase()"
                                        />
                                        <v-sheet color="transparent" dark>
                                            <div
                                                class="similar-game-wrap d-flex flex-column align-center justify-center">
                                                <div
                                                    class="similar-game-title text-lg-h6 text-center lh-1 d-none d-lg-block">
                                                    {{ game.name }}
                                                </div>
                                            </div>
                                            <v-img
                                                class="rounded elevation-1"
                                                aspect-ratio="1"
                                                :src="game.banner"
                                                lazy-src="/images/placeholder/game_square_placeholder.jpg"
                                                position="center top">
                                            </v-img>
                                        </v-sheet>
                                    </router-link>

                                    <v-sheet
                                        color="transparent"
                                        dark
                                        class="similar-game mb-2"
                                        v-if="!authUser"
                                        v-for="game in similarGames"
                                        :key="game.id">
                                        <v-badge
                                            v-if="game.badge"
                                            tile
                                            class="game-badge"
                                            dark
                                            color="red"
                                            :content="game.badge.name.toUpperCase()"
                                        />
                                        <div class="similar-game-wrap d-flex flex-column align-center justify-center">
                                            <div
                                                class="similar-game-title text-lg-h6 text-center lh-1 d-none d-lg-block">
                                                {{ game.name }}
                                            </div>
                                            <div class="d-block d-lg-flex similar-game-btns">
                                                <v-btn
                                                    color="primary"
                                                    x-small
                                                    :to="gameLink(game)"
                                                    v-if="game.is_demo_enabled">{{ $t("slotsGame.tryForFree") }}
                                                </v-btn>

                                            </div>
                                        </div>
                                        <v-img
                                            class="rounded elevation-1"
                                            aspect-ratio="1"
                                            :src="game.banner"
                                            lazy-src="/images/placeholder/game_square_placeholder.jpg"
                                            position="center top">
                                        </v-img>
                                    </v-sheet>
                                </div>
                            </div>

                            <div class="text-center game-current-time">{{ time }}</div>
                        </div>
                        <iframe
                            v-if="game"
                            :src="game.game_link"
                            frameborder="0"
                            width="100%"
                            align="center"
                            scrolling="no"
                            class="game-iframe-single"
                            :class="{ full_screen: fullScreen }"
                            @load="iframeLoaded()"
                        ></iframe>
                    </div>
                </v-container>
                <v-container v-else>
                    <div class="text-h4 text-lg-h3 mt-8 text-center">{{ $t("slotsGame.demoNotSupported") }}</div>
                    <div class="d-flex align-center justify-center mt-6">
                        <v-btn
                            x-large
                            dark
                            @click="makeActiveWindow({ active: true, type: 'login'})"
                            class="blue-grey darken-4 mx-1 mr-4">
                            {{ $t("menu.authLogin") }}
                        </v-btn>
                        <v-btn
                            color="primary"
                            x-large
                            @click="makeActiveWindow({ active: true, type: 'register'})">
                            {{ $t("menu.authRegister") }}
                        </v-btn>
                    </div>
                    <div class="d-flex align-center justify-center mt-4">
                        <v-btn
                            color="primary"
                            x-large
                            to="/casino">
                            {{ $t("common.backToCasino") }}
                        </v-btn>
                    </div>
                </v-container>
            </div>
        </transition>
        <v-dialog v-model="searchDialog" overlay-opacity="0.6" content-class="search-dialog">
            <v-card light tile>
                <v-text-field
                    light
                    solo
                    hide-details
                    flat
                    autofocus
                    class="text-body-1 py-4 search-overlay__input"
                    append-icon="mdi-close"
                    :placeholder="$t('form.search.placeholder')"
                    @input="inputSearch"
                    @click:append="searchDialog = false"
                    v-model="search"
                ></v-text-field>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showingGames" max-width="1200">
            <v-card
                light
                tile
                flat
                class="pa-2 pa-md-8 mx-auto position-relative">

                <v-icon @click="showingGames = false" class="slots-game-search-game-close">mdi-close</v-icon>

                <v-card-title class="mb-6" v-if="searchGames.length">{{ $t("slotsGame.showing") }} {{
                        searchGames.length
                    }} {{ $t("slotsGame.games") }}
                </v-card-title>
                <v-card-title v-else>{{ $t("slotsGame.gamesNotFound") }}</v-card-title>
                <div class="text-center my-4" v-if="loading">
                    <v-progress-circular
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                </div>

                <div class="d-flex flex-wrap" v-if="searchGames.length">

                    <router-link
                        :to="gameLink(game)"
                        :target="mdAndDown ? '_blank' : '_self'"
                        v-if="authUser"
                        v-for="game in searchGames"
                        :key="game.id"
                        class="slots-game-search-game mb-2">
                        <v-badge
                            v-if="game.badge"
                            tile
                            class="game-badge"
                            dark
                            color="red"
                            :content="game.badge.name.toUpperCase()"
                        />
                        <v-sheet color="transparent" dark>
                            <div class="slots-game-search-game-wrap d-flex flex-column align-center justify-center">
                                <div
                                    class="slots-game-search-game-title text-lg-h4 text-center lh-1 d-none d-lg-block">
                                    {{ game.name }}
                                </div>
                            </div>
                            <v-img
                                class="rounded elevation-1"
                                aspect-ratio="1"
                                :src="game.banner"
                                lazy-src="/images/placeholder/game_square_placeholder.jpg"
                                position="center top">
                            </v-img>
                        </v-sheet>
                    </router-link>

                    <v-sheet
                        color="transparent"
                        dark
                        class="slots-game-search-game mb-2"
                        v-if="!authUser"
                        v-for="game in searchGames"
                        :key="game.id">
                        <v-badge
                            v-if="game.badge"
                            tile
                            class="game-badge"
                            dark
                            color="red"
                            :content="game.badge.name.toUpperCase()"
                        />
                        <div class="slots-game-search-game-wrap d-flex flex-column align-center justify-center">
                            <div class="slots-game-search-game-title text-lg-h4 text-center lh-1 d-none d-lg-block">
                                {{ game.name }}
                            </div>
                            <div class="d-block d-lg-flex slots-game-search-game-btns">
                                <v-btn
                                    color="success"
                                    class="d-flex d-lg-inline-flex mr-0 mb-2 mr-lg-2 mb-lg-0"
                                    @click="makeActiveWindow({ active: true, type: 'login'})">
                                    {{ $t("slotsGame.login") }}
                                </v-btn>
                                <v-btn
                                    color="primary"
                                    :to="gameLink(game)"
                                    :target="mdAndDown ? '_blank' : '_self'"
                                    v-if="game.is_demo_enabled">{{ $t("slotsGame.tryForFree") }}
                                </v-btn>
                            </div>
                        </div>
                        <v-img
                            class="rounded elevation-1"
                            aspect-ratio="1"
                            :src="game.banner"
                            lazy-src="/images/placeholder/game_square_placeholder.jpg"
                            position="center top">
                        </v-img>
                    </v-sheet>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog
        v-model="error.active"
        activator="parent"
        width="50%"
      >
        <v-card class="text-center">
            <v-card-text class="text-h4 py-2">
                Error
            </v-card-text>
          <v-card-text>
            {{ error.message }}<br>
            If the error persists please contact customer supports
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" style="color:#000;" block @click="error.active = false">Close Dialog</v-btn>
          </v-card-actions>
        </v-card> 
      </v-dialog>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import GameItem from "../../../../models/GameItem";
import breaks from "../../../../mixins/breaks";
import Preloader from "../../../../components/Preloader";

export default {
    name: 'SingleSlotGame',
    components: {
        Preloader
    },
    mixins: [breaks],
    data: () => ({
        position: 'fixed',
        fullScreen: false,
        demo: true,
        error: {
            active: false,
            message: ''
        },
        sidebarActive: false,
        time: '',
        timeInterval: '',
        demoTimeInterval: '',
        searchDialog: false,
        showingGames: false,
        search: '',
        searchGames: [],
        similarGames: [],
        loading: false,
        category_id: '',
        isPreloader: true,
        game: {}
    }),
    props: {
        launchUrl: {
            type: String,
        },
        slug: {
            type: String,
        },
    },
    computed: {
        currency() {
            return this.authUser ? this.authUser.currency : this.currencies[0]
        },
        ...mapState({
            currencies: state => state.app.brandSettings.currencies ? state.app.brandSettings.currencies.split(',') : [],
            login: state => state.user.login
        }),
        lang() {
            return this.current_lang ? this.current_lang : this.authUser ? this.authUser.language : 'tr'
        },
        platform() {
            return this.mdAndUp ? 'desktop' : 'mobile'
        },
        ...mapState({
            current_lang: state => state.user.current_lang,
        }),
        authUser() {
            return this.$auth.user();
        },
        deboucedGames() {
            return _.debounce(this.getGames, 500)
        }
    },
    async created() {
        this.setDemoLayout()
        document.querySelector('html').classList.add('scroll-hidden')
        this.timeInterval = setInterval(this.currentTime, 1000)
        const launch_url = this.launchUrl || this.slug
        await axios.get(`/api/games/launch-url/${launch_url}?currency=${this.currency}&lang=${this.lang}&platform=${this.platform}`).then(res => {
            if (res.data.error?.message) {
                    this.error.active = true
                    this.error.message = res.data.error?.message
                }  else {
            this.game = res.data

            this.category_id = this.game.categories.length ? this.game.categories.find(v => v).id : ''
            if (this.category_id) {
                this.getSimilarGames()
            }
            if (this.mdAndDown) {
                window.location.href = this.game.game_link
            }

            if (!this.authUser) {
                if (this.game && this.game.is_demo_enabled > 0) {
                    this.demo = true
                } else {
                    this.demo = false
                }
            }

            this.category_id = this.game.categories.length ? this.game.categories.find(v => v).id : ''
            if (this.category_id) {
                this.getSimilarGames()
            }
            if (this.mdAndDown) {
                window.location.href = this.game.game_link
            }
        }
        })
        if (!this.$auth.user()) {
            this.demoTimeInterval = setInterval(() => {
                this.makeActiveWindow({ active: true, type: 'register'})
            }, 60000)
        }
    },
    beforeDestroy() {
        if (this.timeInterval) clearInterval(this.timeInterval)
        if (this.demoTimeInterval) clearInterval(this.demoTimeInterval)
        document.querySelector('html').classList.remove('scroll-hidden')
    },
    watch: {
        login() {
            if (this.login || this.$auth.user()) {
                if (this.demoTimeInterval) clearInterval(this.demoTimeInterval)
            }
        }
    },
    methods: {
        iframeLoaded() {
            this.isPreloader = false
        },
        ...mapActions('app', ['makeActiveWindow']),
        setDemoLayout() {
            if (!this.demo) {
                this.setLayout({footer: true, header: true, bottomBar: true})
            }
        },
        ...mapActions({
            getGameUrl: "games/getGameUrl",
            setLayout: "app/setLayout",
        }),
        currentTime() {
            const date = new Date()
            this.time = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ':' + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes())
        },
        toggleFullScreen() {
            this.fullScreen = !this.fullScreen
            if (!document.fullscreenElement) {
                document.documentElement.requestFullscreen();
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                }
            }
        },
        closeFullScreen() {
            if (document.fullscreenElement && document.exitFullscreen) {
                document.exitFullscreen();
            }
            this.fullScreen = false
            this.$router.back()
        },
        async getGames() {
            this.searchGames = []
            this.loading = true
            this.showingGames = true
            let query = this.mdAndDown
                ? GameItem.where("original_name", this.search).where("is_mobile", 1)
                : GameItem.where("original_name", this.search).where("is_desktop", 1)
            query.limit(8)
            query.params({type: 'search'})
            let response = await query.get();
            this.searchGames = response.data
            this.loading = false
        },
        async getSimilarGames() {
            let query = GameItem.where("is_desktop", 1)
            query.params({
                'category_id': this.category_id,
            })
            query.limit(9)
            let response = await query.get();
            this.similarGames = response.data
            this.similarGames = this.similarGames.filter(i => i.slug !== this.slug)
        },
        inputSearch() {
            if (this.search.trim() !== "" && this.search.length >= 3) {
                this.deboucedGames()
            }
        },
        gameLink(game) {
            const link = game.launch_url ? game.launch_url : game.slug
            const provider = game.game_provider ? game.game_provider.slug : 'provider'
            return `/casino/${provider}/${link}`
        },
    },
}
</script>


<style lang="scss">
.game-badge {
    position: absolute;
    top: 16px;
    left: 4px;
    z-index: 10;
}

.scroll-hidden {
    overflow-y: hidden;
}

.game-wrap-bg {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.game-wrap {
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 4;

    &.full_screen {
        position: fixed;
        z-index: 99;
    }
}

.game-current-time {
    position: absolute;
    bottom: 15px;
    left: 0;
    width: 100%;
    font-size: 16px;
}

.game-right-sidebar {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 5;
    background-color: #111b27;
    width: 95px;
    transition: width .3s ease;

    .similar-games {
        visibility: hidden;
        opacity: 0;
        transition: opacity .3s ease;
        width: 100%;
        transition-delay: .3s;

        .games-wrap {
            overflow-y: auto;
            max-height: 300px;

            &::-webkit-scrollbar {
                width: 4px;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-button {
                height: 0px;
                width: 0px;
            }

            &::-webkit-scrollbar-track {
                background-color: transparent;
            }

            &::-webkit-scrollbar-track-piece {
                background-color: transparent;
                width: 0;
                height: 0;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: #dedede;
            }
        }
    }

    &.active {
        width: 260px;

        .similar-games {
            visibility: visible;
            opacity: 1;
        }

        .game-right-sidebar-btn {
            padding: 12px 20px;
        }
    }

    &-btn {
        width: 100%;
        cursor: pointer;
        transition: all .3s ease;
        padding: 12px;
        border-radius: 5px;
        font-size: 15px;

        &:hover {
            background-color: #1f2834;
        }
    }
}

.game-iframe-single {
    max-height: 90vh;
    height: 2000px;
    width: calc(100% - 95px);

    body {
        margin: 0 !important;
    }

    &.full_screen {
        height: 100%;
        max-height: 100%;
    }
}

.toggle-screen-btn {
    position: fixed;
    top: 60px;
    left: 5px;
    transition: all .3s ease;


    &.full_screen {
        top: 16px;
    }
}

.search-games-wrap {
    overflow-y: auto;
    max-height: 210px
}

.search-games-wrap, .games-wrap {
    padding-right: 10px;

    &::-webkit-scrollbar {
        width: 4px;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-button {
        height: 0px;
        width: 0px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-track-piece {
        background-color: transparent;
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #dedede;
    }
}

.similar-game {
    position: relative;
    max-width: 48%;
    width: 100%;
    margin-right: 4px;

    &:nth-child(2n) {
        margin-right: 0;
    }

    .v-image {
        transition: filter .3s ease;
    }

    &-title {
        opacity: 0;
        transform: translateY(-20px);
        transition: all 0.3s ease;
        padding: 0 5px;
        margin-bottom: 20px;
    }

    &-wrap {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: 2;
    }

    &-btns {
        position: relative;
        z-index: -1;

        .v-btn {
            transform: translateY(20px);
            visibility: hidden;
            opacity: 0;
            position: relative;
            z-index: -1;
            transition-delay: 0.05s
        }

        @include media("xs-only") {
            .v-btn {
                padding: 0 5px !important;
                height: 25px !important;
                font-size: 10px !important;
            }
        }
    }

    &:hover {
        .similar-game-title {
            opacity: 1;
            transform: translateY(0px);
        }

        .v-image {
            filter: brightness(0.3);
        }

        .similar-game-btns {
            z-index: 1;
        }

        .v-btn {
            transform: translateY(0);
            visibility: visible;
            opacity: 1;
            z-index: 1;
        }
    }
}

.slots-game-search-game {
    position: relative;
    max-width: 24%;
    width: 100%;
    margin-right: 8px;

    &:nth-child(4n) {
        margin-right: 0;
    }

    .v-image {
        transition: filter .3s ease;
    }

    &-title {
        opacity: 0;
        transform: translateY(-20px);
        transition: all 0.3s ease;
        padding: 0 5px;
        margin-bottom: 20px;
    }

    &-wrap {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: 2;
    }

    &-btns {
        position: relative;
        z-index: -1;

        .v-btn {
            transform: translateY(20px);
            visibility: hidden;
            opacity: 0;
            position: relative;
            z-index: -1;

            &:first-child {
                transition-delay: 0.05s;
            }

            &:nth-child(2) {
                transition-delay: 0.1s;
            }
        }

        @include media("xs-only") {
            .v-btn {
                padding: 0 5px !important;
                height: 25px !important;
                font-size: 10px !important;
            }
        }
    }

    &:hover {
        .slots-game-search-game-title {
            opacity: 1;
            transform: translateY(0px);
        }

        .v-image {
            filter: brightness(0.3);
        }

        .slots-game-search-game-btns {
            z-index: 1;
        }

        .v-btn {
            transform: translateY(0);
            visibility: visible;
            opacity: 1;
            z-index: 1;
        }
    }

    &-close {
        position: absolute !important;
        right: 20px;
        top: 20px;
        z-index: 2;
    }
}

.v-dialog.search-dialog {
    align-self: flex-start;
    margin: 0;
    border-radius: 0;
}

@include media("xs-only") {
    .scroll-hidden {
        overflow-y: auto;
    }
    .slots-game-search-game {
        max-width: 48%;
        margin-right: 4px;

        &:nth-child(2n) {
            margin-right: 0;
        }

        &-title {
            font-size: 12px;
        }
    }
}

@media (max-width: 1250px) {
    .game-iframe-single {
        width: calc(100% - 60px);
    }
    .game-right-sidebar {
        width: 60px;

        .similar-games {
            display: none;
        }

        &.active {
            width: 210px;

            .game-right-sidebar-btn {
                padding: 7px 0;
            }
        }

        &-btn {
            padding: 7px 0;
            font-size: 13px;
        }
    }
}
</style>
