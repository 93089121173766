<template>
    <div>
        <v-btn v-if="loggedIn" icon class="elevation-2 custom-user-btn" @click.stop="open">
            <v-badge
              color="success"
              dot
              bordered
              offset-x="5"
              offset-y="5">
                <v-icon>mdi-account</v-icon>
            </v-badge>
        </v-btn>
        <v-btn v-else icon @click.stop="open">
            <v-icon>mdi-account</v-icon>
        </v-btn>
    </div>
</template>

<script>
import config from '../../configs'
/*
|---------------------------------------------------------------------
| Toolbar User Component
|---------------------------------------------------------------------
|
| Quickmenu for user menu shortcuts on the toolbar
|
*/
export default {
  data: () => ({
    menu: config.toolbar.user,
    loggedIn: true
  }),
  methods: {
    open () {
      this.$emit('open');
    }
  }
}
</script>
