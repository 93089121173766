export default {
    validation: {
        passportError: 'Passport invalid. Check your passport'
    },
    slotsGame: {
        play: "Play",
        addToFavorite: "Add To Favorites",
        removeFromFavorite: "Remove From Favorites",
    },
    common: {
        success: "Success",
        add: 'Add',
        cancel: 'Cancel',
        description: 'Description',
        delete: 'Delete',
        languages: 'Languages',
        title: 'Title',
        save: 'Save',
        faq: 'FAQ',
        contact: 'Contact Us',
        tos: 'Terms of Service',
        policy: 'Privacy Policy',
        addedToFavorite: 'Game has been added to favorites'
    },
    board: {
        titlePlaceholder: 'Enter a title for this card',
        deleteDescription: 'Are you sure you want to delete this card?',
        editCard: 'Edit Card',
        deleteCard: 'Delete Card',
        state: {
            TODO: 'TO DO',
            INPROGRESS: 'INPROGRESS',
            TESTING: 'TESTING',
            DONE: 'DONE'
        }
    },
    chat: {
        online: 'Users Online ({count})',
        addChannel: 'Add Channel',
        channel: 'Channel | Channels',
        message: 'Message'
    },
    labels: {
        enableTwoFactorAuth: "Enable Two-Factor Authorization"
    },
    email: {
        compose: 'Compose Email',
        send: 'Send',
        subject: 'Subject',
        labels: 'Labels',
        emptyList: 'Empty email list',
        inbox: 'Inbox',
        sent: 'Sent',
        drafts: 'Drafts',
        starred: 'Starred',
        trash: 'Trash',
        work: 'Work',
        invoice: 'Invoice'
    },
    form: {
        send: 'Send',
        usernameEmail: 'Username / Email',
        bankAccounts: 'Bank Accounts',
        amount: 'Amount',
        enterAmount: 'Enter amount',
        description: 'Description',
        select: 'Select',
        descriptionPlaceholder: 'The thing that you want to tell us',
        pay: 'Pay',
        search: {
            label: 'Search',
            placeholder: 'Type the game name'
        }
    },
    todo: {
        addTask: 'Add Task',
        tasks: 'Tasks',
        completed: 'Completed',
        labels: 'Labels'
    },
    dashboard: {
        sections: {
          history: {
              tableHeader: {
                  payment_method: 'Payment Method'
              }
          }
        },
        activity: 'Activity',
        weeklySales: 'Weekly Sales',
        sales: 'Sales',
        recentOrders: 'Recent Orders',
        sources: 'Traffic Sources',
        lastweek: 'vs last week',
        orders: 'Orders',
        customers: 'Customers',
        tickets: 'Support Tickets',
        viewReport: 'View Report',
        overview: {
            title: 'Overview',
            pageTitle: 'Overview of Your Account',
            active: 'Active',
            notVerified: 'Not verified',
            validations: {
                title: 'Validations',
                phone: 'Phone number',
                email: 'E-mail address',
                documents: 'Your Documents'
            },
            financialPermissions: {
                title: 'Financial Permissions',
                deposit: 'Deposit',
                withdrawal: 'Withdrawal',
            },
            accesses: {
                title: 'Accesses',
                sportsbook: 'Sportsbook',
                liveBetting: 'Live Betting',
                casino: 'Casino',
                liveCasino: 'Live Casino',
                betonGames: 'Beton Games',
                bingo: 'Bingo',
            },
        },
        memberInfo: {
            title: 'Member Information',
            pageTitle: 'My Membership Information',
            passwordUpdate: 'Password Update',
            form: {
                name: 'Name',
                surname: 'Surname',
                username: 'Username',
                id: 'Identity Number',
                email: 'E-Mail',
                currency: 'Currency',
                gender: {
                    label: 'Gender',
                    male: 'Male',
                    female: 'Female'
                },
                country: 'Country',
                city: 'City',
                address: 'Address',
                currentPassword: 'Current Password',
                newPassword: 'New Password',
                newPasswordAgain: 'New Password - Again',
                btnUpdatePassword: 'Update Password',
                btnUpdateInfo: 'Update Changes',
                updated: 'Updated', // @todo: add in all locales
            }
        },
        memberVerify: {
            title: 'Membership Verification',
            pageTitle: 'Information Verification and Changing',
            phoneVerification: 'Phone Verification',
            emailVerification: 'Email Verification',
            phoneNum: 'Phone number',
            emailAddress: 'E-mail address',
            verified: 'Verified',
            sendCode: 'Send Code',
            sendLink: 'Send Link',
            notVerified: 'Not verified',
            phoneItems: {
                item1: {
                    title: 'How do I write a phone number?',
                    text: 'Select your country, type your phone number so that it will be 10 digits without 0 at the beginning. For example 5554443322',
                },
                item2: {
                    title: 'Write your phone',
                    text: 'Type your phone number and press the send code button. Then we will send a code to your mobile phone, do not share this code with anyone, including the authorities!',
                },
                item3: {
                    title: 'Write Verification Code',
                    text: 'Type the verification code from your mobile phone within the specified time and press the Verify my phone button.',
                },
                item4: {
                    title: 'Your Phone Number is Verified',
                    text: 'Your mobile phone has now been successfully approved! You can use your mobile phone for all operations requiring confirmation / information.',
                },
            },
            emailItems: {
                item1: {
                    title: 'How should I write my e-mail address?',
                    text: 'Write your access email address without spaces or capital letters. example@domain.com',
                },
                item2: {
                    title: 'Write Your E-Mail Address',
                    text: 'Type your e-mail address and press the Send Link button. Then we will send you a link to your e-mail address. Check your Inbox / Spam (Junk Mail).',
                },
                item3: {
                    title: 'Click Verification Link',
                    text: 'Click on the verification link sent to you, then your email address will be verified automatically. Remember, the validity of this link is only 30 minutes.',
                },
                item4: {
                    title: 'Your E-Mail Address Verified',
                    text: 'Your email address has now been successfully approved! You can use your mobile phone for all operations requiring confirmation / information.',
                },
            }
        },
        guess: {
            title: 'My Guess',
            pageTitle: 'My Guess',
            tabs: {
                all: 'All',
                pending: 'Pending',
                winner: 'Winner',
                lose: 'Lose',
                bet: 'Bet',
            },
            notFoundText: 'Data not found. Please be sure to filter the date range and filter.'
        },
        messages: {
            title: 'My messages',
            pageTitle: 'My Messages',
            waiting: 'Waiting',
            answered: 'Answered',
            completed: 'Completed',
            sendMessage: 'Send Message',
            noMessageText: 'You do not have a message yet.',
        },
        createNewMessage: {
            pageTitle: 'Create New Message',
            selectDepartament: 'Select Departament',
            writeSubject: 'Write subject',
            writeMessage: 'Write message',
            departaments: {
                advertisingPromo: 'Advertising and Promotion',
                financialOperations: 'Financial Operations',
                couponProblems: 'Coupon problems',
                casinoProblems: 'Casino problems',
                suggestionComplaint: 'Suggestion/Complaint',
                otherTopics: 'Other topics',
            }
        },
        deposit: {
            title: 'My Deposite',
            pageTitle: 'Bank Transfer Money Investment Form',
            operationTime: 'Operation Time',
            minute: 'Minute',
            minimumInvestment: 'Minimum Investment',
            maximumInvestment: 'Maximum Investment',
        },
        bonuses: {
            title: 'My Bonuses',
            pageTitle: 'My Bonuses',
            lowBalanceTitle: 'Your bonus balance is not enough to bet',
            lowBalance: 'Low balance',
            bonusesInfoText: 'You should not have coupon waiting in order to your bonuses to be finalized.',
            noAttachedText: 'There is no attached bonus with you. You should make deposit, for getting bonus.',
            noResultText: 'There are no results yet in the specified criteria',
            tabs: {
                receivable: 'Receivable',
                active: 'Active',
                completed: 'Completed',
            },
            headersTableText: {
                bonus: 'Bonus',
                amount: 'Amount',
                dateReceived: 'Date Received',
                bonusStatus: 'Bonus Status',
                receivability: 'Receivability',
            }
        },
        sendToFriend: {
            title: 'Send to Friend',
            pageTitle: 'Send to Friend',
            searchFriend: 'Search Friend',
            sendToFriendBtn: 'Send to friend',
            subtitle: 'Find your friend and send money!',
            text: 'Enter the username of a friend registered on the system and then transfer the money with your friend!'
        },
        history: {
            title: 'My History',
            pageTitle: 'My History',
            notFountText: 'Data not found. Please be sure to filter the date range and filter.',
            activities: {
                moneyActivities: 'Money Activities',
                casinoActivities: 'Casino Activities',
                couponActivities: 'Coupon Activities'
            }
        },
        welcome: 'Welcome',
        balance: 'Balance',
        socialMedia: {
            title: 'Social Media',
            fb: {
                title: 'Facebook',
                text: 'Follow our facebook page'
            },
            twitter: {
                title: 'Twitter',
                text: 'Follow our twitter page'
            },
            insta: {
                title: 'Instagram',
                text: 'Follow our instagram page'
            },
            youtube: {
                title: 'Youtube',
                text: 'Follow our youtube page'
            }
        },
        articles: {
            title: 'Articles',
            detail: 'Click for details',
            article: {
                title: 'Article1',

            }
        }
    },
    usermenu: {
        profile: 'Profile',
        profileMenu: 'Profile Menu',
        signin: 'Sign In',
        dashboard: 'Dashboard',
        signout: 'Sign Out',
        withdrawal: 'Withdrawal',
        myBets: 'My Bets',
        fastAccess: 'Fast Access',
        liveSupport: 'Live Support',
    },
    error: {
        notfound: 'Page Not Found',
        other: 'An Error Ocurred',
        payment: 'An error has occurred, try again, if the error persists, contact the administrator',
    },
    check: {
        title: 'Set New Password',
        backtosign: 'Back to Sign In',
        newpassword: 'New Password',
        button: 'Set new password and Sign in',
        error: 'The action link is invalid',
        verifylink: 'Verifying link...',
        verifyemail: 'Verifying email address...',
        emailverified: 'Email verified! Redirecting...'
    },
    forgot: {
        title: 'Forgot Password?',
        subtitle: 'Enter your account email address and we will send you a link to reset your password.',
        email: 'Email',
        button: 'Request Password Reset',
        backtosign: 'Back to Sign In'
    },
    login: {
        title: 'Sign In',
        email: 'Email',
        username: 'Username',
        password: 'Password',
        button: 'Sign In',
        orsign: 'Or sign in with',
        forgot: 'Forgot password?',
        noaccount: 'Don\'t have an account?',
        create: 'Create one here',
        error: 'The email / password combination is invalid'
    },
    register: {
        title: 'Create Account',
        name: 'Full name',
        surname: 'Full surname',
        username: 'User Name',
        country: 'Country',
        currency: 'Currency',
        phone: 'Phone',
        email: 'Email',
        password: 'Password',
        state: "State",
        city: "City",
        birthdate: "Date of Birth",
        button: 'Create Account',
        orsign: 'Or sign up with',
        agree: 'By signing up, you agree to the',
        account: 'Already have an account?',
        gender: 'Gender',
        male: 'Male',
        female: 'Female',
        address: 'Address',
        passport: 'Passport/ID',
        signin: 'Sign In'
    },
    utility: {
        maintenance: 'In Maintenance'
    },
    faq: {
        call: 'Have other questions? Please reach out '
    },
    ecommerce: {
        products: 'Products',
        filters: 'Filters',
        collections: 'Collections',
        priceRange: 'Price Range',
        customerReviews: 'Customer Reviews',
        up: 'and up',
        brand: 'Brand',
        search: 'Search for product',
        results: 'Results ( {0} of {1} )',
        orders: 'Orders',
        shipping: 'Shipping',
        freeShipping: 'Free Shipping',
        inStock: 'In Stock',
        quantity: 'Quantity',
        addToCart: 'Add To Cart',
        buyNow: 'Buy Now',
        price: 'Price',
        about: 'About this item',
        description: 'Description',
        reviews: 'Reviews',
        details: 'Product Details',
        cart: 'Cart',
        summary: 'Order Summary',
        total: 'Total',
        discount: 'Discount',
        subtotal: 'Subtotal',
        continue: 'Continue Shopping',
        checkout: 'Checkout'
    },
    breadcrumbs: {
        home: 'Home',
        rules: 'Rules',
        information: 'Information',
        promotions: 'Promotions',
        blog: 'Blog',
        test: 'Test',
        sports: 'Sports',
        football: 'Football'
    },
    menu: {
        search: 'Search (press "ctrl + /" to focus)',
        dashboard: 'Dashboard',
        logout: 'Logout',
        profile: 'Profile',
        blank: 'Blank Page',
        pages: 'Pages',
        others: 'Others',
        email: 'Email',
        chat: 'Chat',
        todo: 'Todo',
        board: 'Task Board',
        users: 'Users',
        usersList: 'List',
        usersEdit: 'Edit',
        ecommerce: 'Ecommerce',
        ecommerceList: 'Products',
        ecommerceProductDetails: 'Product Details',
        ecommerceOrders: 'Orders',
        ecommerceCart: 'Cart',
        auth: 'Auth Pages',
        authLogin: 'Log in',
        authRegister: 'Join now',
        authVerify: 'Verify Email',
        authForgot: 'Forgot Password',
        authReset: 'Reset Password',
        errorPages: 'Error Pages',
        errorNotFound: 'Not Found / 404',
        errorUnexpected: 'Unexpected / 500',
        utilityPages: 'Utility Pages',
        utilityMaintenance: 'Maintenance',
        utilitySoon: 'Coming Soon',
        utilityHelp: 'FAQs / Help',
        levels: 'Menu Levels',
        disabled: 'Menu Disabled',
        docs: 'Documentation',
        feedback: 'Feedback',
        support: 'Support',
        sportsBook: 'Sports Book',
        casino: 'Casino',
        liveCasino: 'Live Casino',
        soccer: 'Soccer',
        basketball: 'Basketball',
        football: 'Football',
        tennis: 'Tennis',
        billiard: 'Billiard',
        hockey: 'Hockey',
        boxing: 'Boxing',
        volleyball: 'Volleyball',
        baseball: 'Baseball'
    },
    auth: {
        register: {
            complete: "Complete Registration",
            fields: {
                scanningQR: "Set up you 2FA by scanning the barcode below. Alternatively, you can use the code",
                enterOTP: "Enter One Time Password from the app",
                oneTimePassword: "One Time Password",
            }
        }
    },
    // Vuetify components translations
    $vuetify: {
        badge: 'Badge',
        back: 'Back',
        close: 'Close',
        dataIterator: {
            noResultsText: 'No matching records found',
            loadingText: 'Loading items...'
        },
        dataTable: {
            itemsPerPageText: 'Rows per page:',
            ariaLabel: {
                sortDescending: 'Sorted descending.',
                sortAscending: 'Sorted ascending.',
                sortNone: 'Not sorted.',
                activateNone: 'Activate to remove sorting.',
                activateDescending: 'Activate to sort descending.',
                activateAscending: 'Activate to sort ascending.'
            },
            sortBy: 'Sort by'
        },
        dataFooter: {
            itemsPerPageText: 'Items per page:',
            itemsPerPageAll: 'All',
            nextPage: 'Next page',
            prevPage: 'Previous page',
            firstPage: 'First page',
            lastPage: 'Last page',
            pageText: '{0}-{1} of {2}'
        },
        datePicker: {
            itemsSelected: '{0} selected',
            nextMonthAriaLabel: 'Next month',
            nextYearAriaLabel: 'Next year',
            prevMonthAriaLabel: 'Previous month',
            prevYearAriaLabel: 'Previous year',
            selectDateRange: 'Select Date Range',
            birthday: 'Date of Birth',
            ok: 'Ok',
            cancel: 'Cancel'
        },
        noDataText: 'No data available',
        carousel: {
            prev: 'Previous visual',
            next: 'Next visual',
            ariaLabel: {
                delimiter: 'Carousel slide {0} of {1}'
            }
        },
        calendar: {
            moreEvents: '{0} more'
        },
        fileInput: {
            counter: '{0} files',
            counterSize: '{0} files ({1} in total)'
        },
        timePicker: {
            am: 'AM',
            pm: 'PM'
        },
        pagination: {
            ariaLabel: {
                wrapper: 'Pagination Navigation',
                next: 'Next page',
                previous: 'Previous page',
                page: 'Goto Page {0}',
                currentPage: 'Current Page, Page {0}'
            }
        }
    }
}
