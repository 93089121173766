<template>
    <div class="d-flex justify-space-between">
        <div class="multislot">
            <div class="multislot-inner">
                <game-top-bar
                    v-if="$route.name === 'slots-game'"
                    :game="game"
                    :screen-view="screenView"
                    @open-profile="profileDrawerActive = true"
                    @setView="setView"
                    @setFullscreen="iframeControl({action: 'fullscreen'}, game, 0)"
                />
                <div>
                    <game-preloader v-show="isPreloader" :background-image="game ? getGameBackgroundImage(game) : ''" />
                    <transition name="preloader" mode="out-in">
                        <div v-show="!isPreloader" class="">
                            <template v-if="demo">
                                <div class="game-wrap" :class="{ full_screen: fullScreen }">

                                    <iframes-container
                                        v-if="showIframe"
                                        :views="views"
                                        :gamesLoading="gamesLoading"
                                        :recommended_games="recommended_games"
                                        :fullScreen="fullScreen"
                                        @iframeControl="iframeControl"
                                        @addSearchGame="addSearchGame"
                                        @addGame="addGame"
                                        @iframeLoaded="iframeLoaded"
                                    />

                                </div>
                            </template>
                            <template v-else-if="!demo && disabled_bets">
                                <div>
                                    <div class="text-h4 text-lg-h3 mt-8 text-center">{{ $t("slotsGame.disabledBets") }}</div>
                                    <div class="text-subtitle-1 mt-3 text-center">{{ $t("slotsGame.verifyEmail") }}</div>
                                    <div class="d-flex align-center justify-center mt-4">
                                        <v-btn
                                            class="blue-grey darken-4 mr-4"
                                            x-large
                                            dark
                                            @click="makeActiveWindow({ active: true, type: 'verifyEmail'})">
                                            {{ $t("common.verifyEmail") }}
                                        </v-btn>
                                        <v-btn
                                            color="primary"
                                            x-large
                                            to="/casino">
                                            {{ $t("common.backToCasino") }}
                                        </v-btn>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <demo-not-supported/>
                            </template>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
        <game-side-bar
            :recent-games="similarGames"
            :recommended-games="recommended_games"
        />
        <!--         :categories="categories"-->
        <search-game-dialog
            :addGameDialog="addGameDialog"
            :search="search"

            :providers="providers"
            :search_category_id="search_category_id"
            :search_provider_id="search_provider_id"
            :searchGames="searchGames"
            :currentIframe="currentIframe"
            :loading="loading"
            :pagination_current_page="pagination_current_page"
            :pagination_last_page="pagination_last_page"
            @setSearchQuery="setSearchQuery"
            @addGame="addGame"
            @showGames="showGames"
            @inputSearch="inputSearch"
            @closeSearchGameDialog="addGameDialog = false"
            @cleanSearch="search = ''"
            @changePagination="pagination_current_page++"
        />
        <clone-dialog
            :cloneDialog="cloneDialog"
            @closeCloneDialog="cloneDialog = false"
            @cloneGame="cloneGame"/>

        <freespin-dialog
            :freespin_launch_code="freespin_launch_code"
            :freespinDialog="freespinDialog"
            :freespinSetting="freespinSetting"
            @closeFreespinDialog="closeFreespinDialog"
        />

        <!--        <profile-drawer v-model="profileDrawerActive"/>-->
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import GameItem from "@/models/GameItem";
import breaks from "@/mixins/breaks";
import GamePreloader from "@/components/pages/slots/game/GamePreloader";
import GameProvider from "@/models/GameProvider";
// import RightSideBar from "@/components/pages/slots/game/RightSideBar";
import GameTopBar from "@/components/pages/slots/game/GameTopBar";
import IframesContainer from "@/components/pages/slots/game/IframesContainer";
import DemoNotSupported from "@/components/pages/slots/game/DemoNotSupported";
import SearchDialog from "@/components/pages/slots/game/SearchDialog";
import ShowingGamesDialog from "@/components/pages/slots/game/ShowingGamesDialog";
import SearchGameDialog from "@/components/pages/slots/game/SearchGameDialog";
import CloneDialog from "@/components/pages/slots/game/CloneDialog";
import ProfileDrawer from "@/components/navigation/ProfileDrawer";
import SingleSlotGame from "@/components/pages/slots/game/SingleSlotGame";
import FreespinDialog from "./FreespinDialog";
import GameSideBar from "@/components/pages/slots/game/GameSideBar.vue";
import gameImages from "@/mixins/gameImages";

export default {
    name: 'MultiSlotGame',
    components: {
        GameSideBar,
        GameTopBar,
        GamePreloader,
        // RightSideBar,
        IframesContainer,
        DemoNotSupported,
        SearchDialog,
        ShowingGamesDialog,
        SearchGameDialog,
        CloneDialog,
        ProfileDrawer,
        SingleSlotGame,
        FreespinDialog
    },
    mixins: [breaks, gameImages],
    data: () => ({
        disabled_bets: false,
        profileDrawerActive: false,
        cloneDialog: false,
        freespinDialog: false,
        freespin_launch_code: '',
        cloneGameUrl: '',
        gamesLoading: [
            {loading: true},
            {loading: false},
            {loading: false},
            {loading: false},
        ],
        currentIframe: {
            item: {},
            index: null
        },
        addGameDialog: false,
        search_category_id: null,
        search_provider_id: [],
        recommended_games: [],
        screenView: [
            {icon: 'betvamos-multiscreen-x1', type: 1, active: true},
            {icon: 'betvamos-multiscreen-x2', type: 2, active: false},
            {icon: 'betvamos-multiscreen-x4', type: 4, active: false},
            // {icon: 'betvamos-multiscreen-x6', type: 6},
            // {icon: 'betvamos-multiscreen-x9', type: 9},
        ],
        views: [
            {
                game: {},
                session_error: false
            }
        ],
        position: "fixed",
        fullScreen: false,
        demo: true,
        timeInterval: "",
        searchDialog: false,
        showingGames: false,
        search: "",
        searchGames: [],
        similarGames: [],
        loading: false,
        category_id: '',
        isPreloader: true,
        providers: {},
        pagination_current_page: 1,
        pagination_last_page: null,
        game: {},
        freespinSetting: {},
        showIframe: false,
    }),
    props: {
        gameLoadByRouter: {
            type: Object,
            default: null,
        },
        launchUrl: {
            type: String,
        },
        slug: {
            type: String,
        },
    },
    computed: {
        currency() {
            return this.authUser ? this.authUser.currency : this.currencies[0]
        },
        ...mapState({
            currencies: state => state.app.brandSettings.currencies ? state.app.brandSettings.currencies.split(',') : [],
            login: state => state.user.login,
            default_language: state => state.app.brandSettings.default_language,
            verify_email: state => state.app.brandSettings.verify_email,
            images_cdn: state => state.app.brandSettings.images_cdn,
            loadedGame: state => state.user.loadedGame
        }),
        lang() {
            if (localStorage.getItem('lang')) {
                return localStorage.getItem('lang')
            } else {
                return this.default_language ? this.default_language : this.authUser ? this.authUser.language : 'tr'
            }
        },
        platform() {
            return this.mdAndUp ? 'desktop' : 'mobile'
        },
        ...mapState({
            current_lang: state => state.user.current_lang,
            casino_game_lobby_type_single: state => state.app.brandSettings.casino_game_lobby_type_single,
            casino_game_lobby_type_multi: state => state.app.brandSettings.casino_game_lobby_type_multi,
            brandSettings: state => state.app.brandSettings
        }),
        ...mapState({
            categories: state => state.game.categories,
        }),
        authUser() {
            return this.$auth.user();
        },
        deboucedGames() {
            return _.debounce(this.getGames, 500)
        }
    },
    async created() {
        await this.create()
    },
    beforeDestroy() {
        if (this.timeInterval) clearInterval(this.timeInterval)
        document.querySelector('html').classList.remove('scroll-hidden')
        this.setLayout({footer: true, header: true, bottomBar: true})
    },
    watch: {
        loadedGame(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.create()
            }
        },
        brandSettings() {
            this.init()
        },
        login() {
            if (this.login) {
                window.location.reload()
            }
        }
    },
    methods: {
        async create() {
            if (this.$auth.user() && !this.$auth.user().email_verified_at && this.verify_email && this.verify_email.includes('bets')) {
                this.makeActiveWindow({ active: true, type: 'verifyEmail'})
                this.demo = false
                this.disabled_bets = true
                this.isPreloader = false
                return false
            }
            if (Object.keys(this.brandSettings).length) {
                await this.init()
            }
        },
        ...mapActions('app', ['makeActiveWindow']),
        closeFreespinDialog() {
            this.freespinDialog = false
            this.showIframe = true
        },
        ...mapActions({
            setBalance: 'user/setGameBalance',
        }),
        openMobile(game) {
            if(game.game_link) {
                this.$router.replace(game.game_link);
            }
        },
        async init() {
            // this.setDemoLayout()
            if(this.gameLoadByRouter){
                this.game = this.gameLoadByRouter;

                await this.getFreespin(this.game)

                if (!this.authUser) {
                    if (this.game && this.game.is_demo_enabled > 0) {
                        if (this.mdAndDown) {
                            this.openMobile(this.game);
                        }
                        this.demo = true
                    } else {
                        this.demo = false
                        this.isPreloader = false
                    }
                } else {
                    if (this.isMobile) {
                        this.openMobile(this.game);
                    }
                }

                if (this.game) {
                    this.game.url = this.$route.fullPath
                }

                this.category_id = this.game && this.game.categories.length ? this.game.categories.find(v => v).id : ''
                if (this.category_id) {
                    this.getSimilarGames()
                }
            } else {
                const launch_url = this.$route.params.launchUrl
                if (launch_url) {
                    await axios.get(`/api/games/launch-url/${launch_url}?currency=${this.currency}&lang=${this.lang}&platform=${this.platform}`)
                        .then(async(res) => {
                            this.game = res.data
                            if (this.$auth.user()) {
                                axios.get('/api/user-activity?page=game-' + this.game.original_name)
                            }

                            await this.getFreespin(this.game)

                            if (!this.authUser) {
                                if (this.game && this.game.is_demo_enabled > 0) {
                                    if (this.mdAndDown) {
                                        this.openMobile(this.game);
                                    }
                                    this.demo = true
                                } else {
                                    this.demo = false
                                    this.isPreloader = false
                                }
                            } else {
                                if (this.isMobile) {
                                    this.openMobile(this.game);
                                }
                            }

                            if (this.game) {
                                this.game.url = this.$route.fullPath
                            }

                            this.category_id = this.game && this.game.categories.length ? this.game.categories.find(v => v).id : ''
                            if (this.category_id) {
                                this.getSimilarGames()
                            }
                        })
                }
            }

            this.views[0].game = this.game
            this.gamesLoading[0].loading = false
            await GameProvider.get().then(res => {
                this.providers = res[0]
            })

            // await this.getGameCategories()
        },
        async getFreespin(game) {
            if (this.$auth.user() && this.$auth.user().softswiss_freespin) {
                this.freespin_launch_code = game.launch_code
                this.freespinSetting = this.$auth.user().active_bonus.type === 'freespin' || this.$auth.user().active_bonus.type === 'deposit' && this.$auth.user().active_bonus.settings.deposit_freespin ?
                    (this.$auth.user().active_bonus.sub_bonus_id ? this.$auth.user().active_bonus.sub_bonus.settings : this.$auth.user().active_bonus.settings) : {}
                if (Object.keys(this.freespinSetting).length && this.freespinSetting.freespin_games.includes(game.launch_code) && game.has_freespins) {
                    axios.get(`/api/user-dashboard/freespin-request?api_user_id=${this.$auth.user().api_user_id}&launch_code=${this.freespin_launch_code}&token=${this.$auth.user().game_token}`).then(res => {
                        this.showIframe = true
                    }).catch(e => {
                        this.showIframe = true
                    })
                } else {
                    this.showIframe = true
                }
            } else {
                this.showIframe = true
            }
        },
        setSearchQuery(category_id = null, provider_id = null) {
            this.search_category_id = category_id
            this.search_provider_id = provider_id
        },
        async showGames(provider = [], category = null) {
            this.loading = true
            if (category) {
                this.categories.map(v => v.activeClass = false)
            }
            let query = this.mdAndDown
                ? GameItem.where("original_name", this.search).where("is_mobile", 1).where('category', this.search_category_id).whereIn('provider_id', this.search_provider_id)
                : GameItem.where("original_name", this.search).where("is_desktop", 1).where('category', this.search_category_id).whereIn('provider_id', this.search_provider_id)
            if (!this.authUser) {
                query.where('is_demo_enabled', true)
            }
            query.limit(48)
            query.page(this.pagination_current_page)
            query.params({type: 'search'})
            let response = await query.get();
            this.pagination_last_page = response.last_page
            if (this.pagination_current_page > 1) {
                response.data.map(v => this.searchGames.push(v))
            } else {
                this.searchGames = response.data
            }
            this.loading = false
        },
        ...mapActions({
            // getGameCategories: 'game/getGameCategories'
        }),
        addSearchGame(item, index) {
            this.addGameDialog = true
            this.currentIframe = {item, index}
        },
        async addGame(game, item, index) {
            this.gamesLoading[index].loading = true

            const launch_url = game.launch_url || game.launch_code

            this.views.map(v => {
                if (v.game.id === game.id) {
                    item.session_error = true
                }
            })

            if (!item.session_error) {
                axios.get(`/api/games/launch-url/${launch_url}?currency=${this.currency}&lang=${this.lang}&platform=${this.platform}`).then(async(res) => {
                    item.game = res.data
                    await this.getFreespin(item.game)
                })
            }
        },
        cloneGame() {
            window.open(this.cloneGameUrl, '_blank')
            this.cloneDialog = false
        },
        iframeControl(icon, item, index) {
            if (icon.action === 'close') {
                if (index === 0 && this.views.length === 1) {
                    this.$router.push('/casino')
                }
                item.game = {}
            }
            if (icon.action === 'clone') {
                this.cloneDialog = true
                this.cloneGameUrl = `${window.location.protocol}//${window.location.hostname}/casino/${item.game.game_provider.slug}/${item.game.launch_url || item.game.launch_code}`
            }
            if (icon.action === 'fullscreen') {
                document.getElementById('iframe-' + index).requestFullscreen()
            }
            if (icon.action === 'reload') {
                this.gamesLoading[index].loading = true
                const launch_url = item.game.launch_url || item.game.launch_code
                axios.get(`/api/games/launch-url/${launch_url}?currency=${this.currency}&lang=${this.lang}&platform=${this.platform}`).then(res => {
                    item.game = res.data
                })
            }
            if (icon.action === 'session_error') {
                item.session_error = false
                this.gamesLoading[index].loading = false
                item.game = {}
            }
        },
        setView(num) {

            this.screenView.map((v, i) => {
                v.active = false
                if (i + 1 === num || i === 2 && num === 4) {
                    v.active = true
                }
            })
            if (num === 1) {
                this.views.splice(1, 3)
            }
            if (num === 2) {
                if (this.views.length === 4) {
                    this.views.splice(2, 2)
                }
                if (this.views.length === 1) {
                    this.views.push({
                        game: {},
                        session_error: false
                    })
                }
            }
            if (num === 4) {
                if (this.views.length === 2) {
                    this.views.push(
                        {game: {}, session_error: false},
                        {game: {}, session_error: false},
                    )
                }
                if (this.views.length === 1) {
                    this.views.push(
                        {game: {}, session_error: false},
                        {game: {}, session_error: false},
                        {game: {}, session_error: false},
                    )
                }
            }
        },
        iframeLoaded(index) {
            this.gamesLoading[index].loading = false
            this.isPreloader = false
        },
        ...mapActions('app', ['makeActiveWindow']),
        setDemoLayout() {
            if (!this.demo) {
                this.setLayout({footer: true, header: true, bottomBar: true})
            }
        },
        ...mapActions({
            getGameUrl: "games/getGameUrl",
            setLayout: "app/setLayout",
        }),
        toggleFullScreen() {
            this.fullScreen = !this.fullScreen
            if (!document.fullscreenElement) {
                document.documentElement.requestFullscreen();
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                }
            }
        },
        closeFullScreen() {
            if (document.fullscreenElement && document.exitFullscreen) {
                document.exitFullscreen();
            }
            this.fullScreen = false
            this.$router.back()
        },
        async getGames() {
            this.searchGames = []
            this.loading = true
            let query = this.mdAndDown
                ? GameItem.where("original_name", this.search).where("is_mobile", 1)
                : GameItem.where("original_name", this.search).where("is_desktop", 1)
            if (!this.authUser) {
                query.where('is_demo_enabled', true)
            }
            query.limit(8)
            query.params({type: 'search'})
            query.page(this.pagination_current_page)
            let response = await query.get();
            this.pagination_last_page = response.last_page
            if (this.pagination_current_page > 1) {
                response.data.map(v => this.searchGames.push(v))
            } else {
                this.searchGames = response.data
            }
            this.loading = false
        },
        async getSimilarGames() {
            let query = GameItem.where("is_desktop", 1)
            if (!this.authUser) {
                query.where('is_demo_enabled', true)
            }
            query.params({
                'category_id': this.category_id,
            })
            query.limit(10)
            let response = await query.get();
            this.similarGames = response.data
            this.similarGames = this.similarGames.filter(i => i.slug !== this.$route.params.launchUrl)
            this.recommended_games = this.similarGames.slice(6)
        },
        inputSearch(search) {
            this.search = search
            if (this.search.trim() !== "" && this.search.length >= 3) {
                this.deboucedGames()
            }
        },
        gameLink(game) {
            const link = game.launch_url ? game.launch_url : game.slug
            const provider = game.game_provider ? game.game_provider.slug : 'provider'
            return `/casino/${provider}/${link}`
        },
    }
}
</script>


<style lang="scss">
.full-width {
    width: 100%;
}

.game-wrap {
    flex-grow: 1;
}

.multislot {
    flex-grow: 1;

    .multislot-inner {
        max-width: 1300px;
        margin: 0 auto;
    }
}

.found-games-wrap {
    max-height: 275px;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 4px;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-button {
        height: 0px;
        width: 0px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-track-piece {
        background-color: transparent;
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #dedede;
    }
}

.game-wrap-list-item {
    cursor: pointer;
    transition: all .3s ease;

    &:hover, &.active {
        color: var(--v-anchor-base);
    }
}

.game-iframe-games-wrap {
    max-width: 80%;
    width: 100%;

    .theme--dark.v-btn::before {
        opacity: .08;
    }
}

.iframes-container {
    max-width: 1420px;
}

.game-iframe-controls {
    padding: 15px 10px;
    background-color: #111b27;
    border-radius: 0 7px 7px 0;
    position: absolute;
    right: 0;
    max-width: 70px;
    top: 0;
}

.game-iframe-controls .v-icon.v-icon:hover {
    color: #ff2b5a;
}

.iframe-wrap {
    height: 41.5vw;
    width: calc(100vw - 505px);
    position: relative;
    background-color: #111b27;

    &.no-bg {
        background-color: transparent;
    }
}

.iframe-wrap.w50 {
    width: calc(39vw - 60px);
    height: 20.2vw;
    max-width: 50%;
}

.game-badge {
    position: absolute;
    top: 16px;
    left: 4px;
    z-index: 10;
}

.scroll-hidden {
    overflow-y: hidden;
}

.game-wrap-bg {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.loaded-game {
    .game-wrap-bg {
        max-height: 200px;
        min-height: 200px;
        background-image: none !important;
    }
    .game-wrap {
        .iframe-wrap {
            height: 183px;
            width: 100%;
            max-width: 100%;
            margin: 0 !important;
            padding: 0 !important;
            display: none !important;
            &:first-child {
                display: flex !important;
            }
            .game-iframe-controls {
                display: none !important;
            }
        }
    }
}

.game-wrap {
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 4;

    &.full_screen {
        position: fixed;
        z-index: 99;
    }
}

.game-current-time {
    position: absolute;
    bottom: 15px;
    left: 0;
    width: 100%;
    font-size: 16px;
}

.game-right-sidebar {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 5;
    background-color: #111b27;
    width: 95px;
    transition: width .3s ease;

    .similar-games {
        visibility: hidden;
        opacity: 0;
        transition: opacity .3s ease;
        width: 100%;
        transition-delay: .3s;

        .games-wrap {
            overflow-y: auto;
            max-height: 300px;

            &::-webkit-scrollbar {
                width: 4px;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-button {
                height: 0px;
                width: 0px;
            }

            &::-webkit-scrollbar-track {
                background-color: transparent;
            }

            &::-webkit-scrollbar-track-piece {
                background-color: transparent;
                width: 0;
                height: 0;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: #dedede;
            }
        }
    }

    &.active {
        width: 260px;

        .similar-games {
            visibility: visible;
            opacity: 1;
        }

        .game-right-sidebar-btn {
            padding: 12px 20px;
        }
    }

    &-btn {
        width: 100%;
        cursor: pointer;
        transition: all .3s ease;
        padding: 12px;
        border-radius: 5px;
        font-size: 15px;

        &:hover {
            background-color: #1f2834;
        }
    }
}

.game-iframe {
    background-color: #111b27;

    body {
        margin: 0 !important;
    }

    &.full_screen {
        height: 100%;
        max-height: 100%;
    }
}

.toggle-screen-btn {
    position: fixed;
    top: 60px;
    left: 5px;
    transition: all .3s ease;


    &.full_screen {
        top: 16px;
    }
}

.search-games-wrap {
    overflow-y: auto;
    max-height: 210px
}

.search-games-wrap, .games-wrap {
    padding-right: 10px;

    &::-webkit-scrollbar {
        width: 4px;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-button {
        height: 0px;
        width: 0px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-track-piece {
        background-color: transparent;
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #dedede;
    }
}

.similar-game {
    position: relative;
    max-width: 48%;
    width: 100%;
    margin-right: 4px;

    &:nth-child(2n) {
        margin-right: 0;
    }

    .v-image {
        transition: filter .3s ease;
    }

    &-title {
        opacity: 0;
        transform: translateY(-20px);
        transition: all 0.3s ease;
        padding: 0 5px;
        margin-bottom: 20px;
    }

    &-wrap {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: 2;
    }

    &-btns {
        position: relative;
        z-index: -1;

        .v-btn {
            transform: translateY(20px);
            visibility: hidden;
            opacity: 0;
            position: relative;
            z-index: -1;
            transition-delay: 0.05s
        }

        @include media("xs-only") {
            .v-btn {
                padding: 0 5px !important;
                height: 25px !important;
                font-size: 10px !important;
            }
        }
    }

    &:hover {
        .similar-game-title {
            opacity: 1;
            transform: translateY(0px);
        }

        .v-image {
            filter: brightness(0.3);
        }

        .similar-game-btns {
            z-index: 1;
        }

        .v-btn {
            transform: translateY(0);
            visibility: visible;
            opacity: 1;
            z-index: 1;
        }
    }
}

.slots-game-search-game {
    position: relative;
    max-width: 24%;
    width: 100%;
    margin-right: 8px;

    &:nth-child(4n) {
        margin-right: 0;
    }

    .v-image {
        transition: filter .3s ease;
    }

    &-title {
        opacity: 0;
        transform: translateY(-20px);
        transition: all 0.3s ease;
        padding: 0 5px;
        margin-bottom: 20px;
    }

    &-wrap {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: 2;
    }

    &-btns {
        position: relative;
        z-index: -1;

        .v-btn {
            transform: translateY(20px);
            visibility: hidden;
            opacity: 0;
            position: relative;
            z-index: -1;

            &:first-child {
                transition-delay: 0.05s;
            }

            &:nth-child(2) {
                transition-delay: 0.1s;
            }
        }

        @include media("xs-only") {
            .v-btn {
                padding: 0 5px !important;
                height: 25px !important;
                font-size: 10px !important;
            }
        }
    }

    &:hover {
        .slots-game-search-game-title {
            opacity: 1;
            transform: translateY(0px);
        }

        .v-image {
            filter: brightness(0.3);
        }

        .slots-game-search-game-btns {
            z-index: 1;
        }

        .v-btn {
            transform: translateY(0);
            visibility: visible;
            opacity: 1;
            z-index: 1;
        }
    }

    &-close {
        position: absolute !important;
        right: 20px;
        top: 20px;
        z-index: 2;
    }
}

.v-dialog.search-dialog {
    align-self: flex-start;
    margin: 0;
    border-radius: 0;
}

@include media("xs-only") {
    .scroll-hidden {
        overflow-y: auto;
    }
    .slots-game-search-game {
        max-width: 48%;
        margin-right: 4px;

        &:nth-child(2n) {
            margin-right: 0;
        }

        &-title {
            font-size: 12px;
        }
    }
}

@media (max-width: 1400px) {
    .iframes-container {
        max-width: 1000px;
    }
}

@media (max-width: 1250px) {
    .game-iframe {
        width: calc(100% - 60px);
    }
    .game-right-sidebar {
        width: 60px;

        .similar-games {
            display: none;
        }

        &.active {
            width: 210px;

            .game-right-sidebar-btn {
                padding: 7px 0;
            }
        }

        &-btn {
            padding: 7px 0;
            font-size: 13px;
        }
    }
}
</style>
