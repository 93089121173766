<template>
    <v-overlay
        class="app-preloader"
        :opacity="preloader.opacity"
        z-index="9999999"
        color="var(--v-background-base)"
        :value="preloader.active"
    >
        <v-container bg fill-height grid-list-md text-xs-center>
            <v-layout row wrap align-center>
                <v-flex>
                    <v-row justify="center" align="center">
                        <img src="/images/logo/loading.svg" alt="preloader">
<!--                        <v-progress-circular-->
<!--                            class="preloader-spin"-->
<!--                            :size="170"-->
<!--                            :width="10"-->
<!--                            indeterminate-->
<!--                        >-->
<!--                            <v-img-->
<!--                                contain-->
<!--                                max-width="130"-->
<!--                                src="/images/logo/logo_new.png"-->
<!--                                alt="Nummus"-->
<!--                            />-->
<!--                        </v-progress-circular>-->
                    </v-row>
                </v-flex>
            </v-layout>
        </v-container>
    </v-overlay>
    <!--    <div class="app-preloader">-->
    <!--        -->
    <!--    </div>-->
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "Preloader",
    computed: {
        ...mapState({
            preloader: state => state.app.preloader
            //preloaderLogo: state => state.app.brandSkin.length ? state.app.brandSkin.find(v => v.key === 'logo') : null,
        })
    }
};
</script>

<style lang="scss">
.app-preloader .v-overlay__scrim {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--v-background-base);
}
</style>
