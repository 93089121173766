import Translation from "@/models/Translation";
import i18n from "@/plugins/vue-i18n";

const setBalance = async ({state, commit}, params) => {
    let bonus = params.amount_bonus > 0 ? params.amount_bonus : 0
    let total = bonus + params.amount;
    commit('setTotalAmount', total.toFixed(2));
    commit('setBonusAmount', bonus.toFixed(2));
    commit('setAmount', params.amount?.toFixed(2));
}

const setGameBalance = async ({state, commit}, params) => {
    let bonus = params.amount_bonus > 0 ? params.amount_bonus : 0
    let total = bonus + params.amount;
    commit('setGameTotalAmount', total.toFixed(2));
    commit('setGameBonusAmount', bonus.toFixed(2));
    commit('setGameAmount', params.amount.toFixed(2));
}

const setCryptoBalance = ({state, commit}, params) => {
    commit('setCryptoBalances', params.balances);
    commit('setCryptoCurrentBalance', params.balances.find(obj => obj.name === params.userCryptoCurrency))
}

const setGameCryptoBalance = ({state, commit}, balances) => {
    let balance = {
        amount: balances.fiat.amount / 100,
        amount_bonus: balances.fiat.amount_bonus / 100,
        amount_crypto: balances.crypto.amount,
        amount_bonus_crypto: balances.crypto.amount_bonus,
    }
    commit('setCryptoCurrentBalance', balance);
}

const setBonusTarget = async ({state, commit}, params) => {
    commit('setBonusTarget', params.bonus_target);
}

const setBonusDeposit = async ({state, commit}, params) => {
    commit('setBonusDeposit', params);
}

const setFavoriteGames = async ({state, commit}, payload) => {
    axios.get('/api/favorite_games').then(res => {
        commit('setFavoriteGames', res.data);
    })
}
const setBonus = ({commit},payload) => {
    commit('setBonusData',payload)
}

const toggleVisibilityBalance = ({state, commit}, payload) => {
    commit('toggleVisibilityBalance');
}

const _init = async ({dispatch, commit, rootState}) => {
    // const default_language = rootState.app.brandSettings.default_language;
    // const translationSettings = rootState.translationSettings;
    //
    // async function getLocale(locale) {
    //     let dynamic = await Translation.find(locale);
    //     await dispatch('translatable/updateTranslatable', {}, {root:true});
    //     i18n.setLocaleMessage(i18n.locale, {...i18n.getLocaleMessage(i18n.locale), ...dynamic})
    // }
    //
    //
    // try {
    //     const lang = default_language ? default_language : translationSettings.length ? translationSettings[0].code : 'en'
    //     if (localStorage.getItem('lang')) {
    //         await getLocale(localStorage.getItem('lang'));
    //         commit('setCurrentLang', localStorage.getItem('lang'))
    //     } else {
    //         await getLocale(lang);
    //         commit('setCurrentLang', lang)
    //     }
    // } catch (e) {
    //     console.error(e)
    // }
}

export default {
    setBalance,
    setBonusTarget,
    setBonusDeposit,
    setFavoriteGames,
    setGameBalance,
    toggleVisibilityBalance,
    setCryptoBalance,
    setGameCryptoBalance,
    setBonus,
    _init
}
