<template>
    <v-dialog
        content-class="modal-login"
        :fullscreen="smAndDown"
        :hide-overlay="smAndDown"
        v-model="activeComputed"
        max-width="666"
        persistent
        light
    >
        <v-card>
            <div class="d-flex justify-space-between pt-2 pr-2">
                <v-card-title class="px-0 pl-10 pb-3 d-block">
                    <div class="modal-login__title">
                        {{ $t("menu.wallet") }}
                    </div>
                </v-card-title>
                <v-btn color='black' icon @click="activeComputed = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
<!--            <v-card-actions-->
<!--                class="d-flex flex-nowrap justify-space-between align-center"-->
<!--            >-->
<!--                <div class="modal-login__title">-->
<!--                    {{ $t("menu.wallet") }}-->
<!--                </div>-->

<!--                <v-btn-->
<!--                    icon-->
<!--                    color="red darken-1"-->
<!--                    class="mr-n2"-->
<!--                    @click="activeComputed = false"-->
<!--                >-->
<!--                    <v-icon>mdi-close</v-icon>-->
<!--                </v-btn>-->
<!--            </v-card-actions>-->

            <v-tabs
                v-model="currentTab"
                centered
                hide-slider
                dark
            >
                <v-tab
                    v-for="item in tabs"
                    :key="item.key"
                    :href="`#${item.key}`"
                    class="dialog-tab grow text-h6 font-weight-bold text-uppercase"
                >
                    {{ item.t }}
                </v-tab>
            </v-tabs>

            <v-tabs-items
                v-model="currentTab"
                class="dialog-tab-content"
                dark
            >
                <v-tab-item
                    v-for="item in tabs"
                    :key="item.key"
                    :value="item.key"
                >
                    <component
                        component=""
                        :is="item.component"
                    />
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions} from "vuex"
import breaks from "@/mixins/breaks"

import TheWalletDeposit from "@/components/modals/TheWallet/TheWalletDeposit.vue"
import TheWalletWithdraw from "@/components/modals/TheWallet/TheWalletWithdraw.vue"
import TheWalletBuyCrypto from "@/components/modals/TheWallet/TheWalletBuyCrypto.vue"

export default {
    name: "TheWallet",

    components: {
        TheWalletDeposit,
        TheWalletWithdraw,
        TheWalletBuyCrypto
    },

    props: {
        active: {type: [Boolean, String], default: false},
    },

    mixins: [
        breaks,
    ],

    data() {
        return {
            currentTab: "deposit"
        }
    },

    computed: {
        tabs() {
            return [
                {key: 'deposit',    t: this.$t("menu.deposit"),    component: TheWalletDeposit},
                {key: 'withdrawal', t: this.$t("menu.withdrawal"), component: TheWalletWithdraw},
                {key: 'buy_crypto', t: this.$t("menu.buyCrypto"),  component: TheWalletBuyCrypto},
            ]
        },

        activeComputed: {
            get() {
                return this.active;
            },
            set(value) {
                this.makeActiveWindow({active: value, type: 'wallet'});
            }
        },
    },

    watch: {
        active(val) {
            if (val && this.tabs.find(t => t.key === val)) {
                this.currentTab = val;
            }
        }
    },

    mounted() {
        this.checkUrlForShowModal();
    },

    methods: {
        ...mapActions({
            makeActiveWindow: 'app/makeActiveWindow',
        }),

        checkUrlForShowModal() {
            const tabName = new URLSearchParams(window.location.search).get('modal')

            if (tabName && ['deposit', 'withdrawal', 'buy_crypto'].includes(tabName)) {
                let newQueryParams = {
                    ...this.$route.query,
                    modal: undefined,
                };

                this.$router.replace({ query: newQueryParams });

                this.activeComputed = tabName;
            }
        },
    },
}
</script>

<style lang="scss" scoped>

.modal-login {
    .v-card {
        box-shadow: none !important;
    }

    .theme--dark.v-tabs-items {
        background-color: white !important;
    }
}

.modal-login__title {
    font-weight: 600;
    text-transform: uppercase;
}

.dialog-tab-content {
    padding: 12px 20px 16px !important;
    //box-shadow: none !important;
}

.dialog-tab {
    background-color: var(--v-primary-base) !important;
    color: #fff !important;

    &.v-tab--active {
        background-color: var(--v-background-base) !important;
        color: var(--v-secondary-base) !important;
    }
}

</style>
