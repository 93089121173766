<template>
    <div class="mob-nav-menu transparent">
        <!-- menu level 1 -->
        <nav-menu-item v-for="(parent, i) in menu" :key="i" :menu-item="parent" @drawerClose="$emit('drawerClose')">
            <template v-if="parent.type === 'dropdown'">
                <v-row no-gutters style="width: 300px;overflow-x: auto;">
                    <v-col
                        cols="4" class="pa-4px"
                        v-for="(child, j) in parent.dropdownItems"
                        :key="j"
                    >
                        <v-card
                            class="tile-gradient-dark"
                            v-if="child.link_type !== 'game'"
                            :to="child.link"
                            :target="child.link_type === 'external' ? '_blank' : '_self'"
                        >
                            <v-card-text class="text-center">
                                <v-icon>{{ child.icon }}</v-icon>
                                <span class="pt-1 lh-dense d-block text-no-wrap">
                                    {{ child.title }}
                                </span>
                            </v-card-text>
                        </v-card>

                        <v-card
                            class="tile-gradient-dark"
                            v-if="child.link_type === 'game' && authUser"
                            @click="goToGame(child.link)"
                        >
                            <v-card-text class="text-center">
                                <v-icon>{{ child.icon }}</v-icon>
                                <span class="pt-1 lh-dense d-block text-no-wrap">
                                    {{ child.title }}
                                </span>
                            </v-card-text>
                        </v-card>

                        <v-card
                            class="tile-gradient-dark"
                            v-if="child.link_type === 'game' && !authUser"
                            @click="getGame(child.link)"
                        >
                            <v-card-text class="text-center">
                                <v-icon>{{ child.icon }}</v-icon>
                                <span class="pt-1 lh-dense d-block text-no-wrap">
                                    {{ child.title }}
                                </span>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
        </nav-menu-item>
    </div>
</template>

<script>
import NavMenuItem from './NavMenuItem'
import {mapActions, mapState} from "vuex"
import axios from "axios"

export default {
    components: {
        NavMenuItem
    },
    methods: {
        getGame(launch_code) {
            this.getGameUrl([null, launch_code]).then(() => {
                if (this.game) {
                    this.$emit('drawerClose')
                    this.makeActiveWindow({active: true, type: 'login'})
                    this.gameLinkStore()
                }
            })

        },
        goToGame(launch_code) {
            if (this.authUser && !this.authUser.email_verified_at && this.verify_email && this.verify_email.includes('bets')) {
                this.makeActiveWindow({active: true, type: 'verifyEmail'})
                return false
            }
            axios.get(`/api/games/launch-url/${launch_code}?currency=${this.currency}&lang=${this.lang}&platform=${this.platform}`).then(res => {
                if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
                    window.location.href = res.data.game_link
                } else {
                    window.open(res.data.game_link, '_blank')
                }
            })
        },
        gameLinkStore() {
            const link = this.game.launch_url ? this.game.launch_url : this.game.slug
            const provider = this.game.game_provider ? this.game.game_provider.slug : 'provider'
            this.$store.commit('app/setGameLinkBeforeLogin', `/casino/${provider}/${link}`)
        },
        ...mapActions('app', ['makeActiveWindow']),
        ...mapActions({
            getGameUrl: "games/getGameUrl",
        }),
    },
    computed: {
        authUser() {
            return this.$auth.user()
        },
        currency() {
            return this.authUser ? this.authUser.currency : this.currencies[0]
        },
        lang() {
            return this.current_lang ? this.current_lang : this.authUser ? this.authUser.language : 'tr'
        },
        platform() {
            return this.mdAndUp ? 'desktop' : 'mobile'
        },
        ...mapState({
            verify_email: state => state.app.brandSettings.verify_email,
            game: (state) => state.games.gameUrl,
        }),
    },
    props: {
        menu: {
            type: Array,
            default: () => []
        }
    }
}
</script>

<style lang="scss">
.mob-nav-menu .v-list-group__items {
    overflow-x: auto;
}
</style>
