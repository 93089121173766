<template>
    <v-card class="pa-4 user__left-menu">
        <v-card-title class="pa-0">
            {{ $t("dashboard.sidebarParameters.welcome") }}, {{ fullName }}
        </v-card-title>

        <v-card-subtitle class="pa-2"></v-card-subtitle>
        <v-card
            class="d-flex justify-space-between py-2"
            flat
        >

            <v-btn
                class="button--black px-4"
                @click="showWallet('withdrawal')"
            >
                {{ $t("dashboard.sidebarParameters.withdraw") }}
            </v-btn>

            <v-btn
                class="button--primary px-4"
                @click="showWallet('deposit')"
            >
                {{ $t("dashboard.sidebarParameters.deposit") }}
            </v-btn>
        </v-card>

        <div class="user__left-menu-balance pt-2">
            <div class="d-flex justify-space-between align-items-stretch">
                <div>
                    <div class="user__left-menu-balance-card">
                        <div class="user__left-menu-balance-card-balance">
                            {{ balance }}
                        </div>
                        <div class="user__left-menu-balance-card-label">
                            {{ $t("dashboard.sidebarParameters.balance") }}
                        </div>
                    </div>
<!--                    <div class="user__left-menu-balance-card">-->
<!--                        <div class="user__left-menu-balance-card-balance">-->
<!--                            0-->
<!--                        </div>-->
<!--                        <div class="user__left-menu-balance-card-label">-->
<!--                            {{ $t("dashboard.sidebarParameters.spin") }}-->
<!--                        </div>-->
<!--                    </div>-->
                </div>
                <div>
                    <div class="user__left-menu-balance-card">
                        <div class="user__left-menu-balance-card-balance">
                            {{ bonusBalance }}
                        </div>
                        <div class="user__left-menu-balance-card-label">
                            {{ $t("dashboard.sidebarParameters.bonus") }}
                        </div>
                    </div>
<!--                    <div class="user__left-menu-balance-card">-->
<!--                        <div class="user__left-menu-balance-card-balance">-->
<!--                            0-->
<!--                        </div>-->
<!--                        <div class="user__left-menu-balance-card-label">-->
<!--                            {{ $t("dashboard.sidebarParameters.bet") }}-->
<!--                        </div>-->
<!--                    </div>-->
                </div>
                <div class="d-flex flex-column justify-space-around">
<!--                    <div class="pb-4">-->
<!--                        <v-btn-->
<!--                            icon-->
<!--                            small-->
<!--                            @click="toggleVisibilityBalance"-->
<!--                        >-->
<!--                            <v-icon v-if="hideBalance">betvamos-eye-outline</v-icon>-->
<!--                            <v-icon v-else>betvamos-eye-off-outline</v-icon>-->
<!--                        </v-btn>-->
<!--                    </div>-->
                    <div class="pb-4">
                        <v-btn
                            icon
                            small
                            :loading="loadingBalance"

                            @click="refreshBalance"

                        >
                            <v-icon>betvamos-sync</v-icon>
                        </v-btn>
                    </div>
                </div>
            </div>
<!--                <v-btn-->
<!--                    class="button&#45;&#45;gray mb-4"-->
<!--                    width="100%"-->
<!--                    to="/user/deposit"-->
<!--                    @click="linkClick()"-->
<!--                >-->
<!--                    {{ $t("dashboard.sidebarParameters.cashback") }}-->
<!--                </v-btn>-->
        </div>

<!--        <v-navigation-drawer-->
<!--            floating-->
<!--            permanent-->
<!--            width="100%"-->
<!--            height="auto"-->
<!--            class=""-->
<!--        >-->
            <v-list nav dense>
<!--                <v-list-item-group v-model="selectedItem">-->
                <template v-for="(item, itemIndex) in items">
                    <v-list-group
                        v-if="item.type === 'dropdown' && item.items.length"
                        :key="itemIndex"
                        :prepend-icon="item.icon"
                        color="gray-2"
                        :value="item.items.some(i => i.link === $route.path || i.link === activePath)"
                    >
                        <template v-slot:activator>
                            <v-list-item-title> {{ $t(item.title) }} </v-list-item-title>
                        </template>

                        <v-list-item
                            v-for="(subItem, subItemIndex) in item.items"
                            :key="`${itemIndex}-${subItemIndex}`"
                            :to="subItem.link"
                            @click="linkClick(subItem)"
                            color="gray-2"
                            class="px-6"
                            :class="{'list-active': activePath === subItem.link}"
                            :input-value="subItem.link && activePath === subItem.link"
                        >
<!--                            <v-list-item-icon>-->
<!--                                <v-icon v-text="subItem.icon"></v-icon>-->
<!--                            </v-list-item-icon>-->

<!--                            <v-list-item-content>-->
                                <v-list-item-title>{{ $t(subItem.title) }}</v-list-item-title>
<!--                            </v-list-item-content>-->

<!--                            <v-list-item-icon>-->
<!--                                <v-icon>mdi-chevron-down</v-icon>-->
<!--                            </v-list-item-icon>-->
                        </v-list-item>
                    </v-list-group>

                    <v-list-item
                        v-else-if="item.type === 'messages'"
                        :key="'item'-itemIndex"
                        :to="item.link"
                        @click="linkClick(item)"
                        color="gray-2"
                        :class="{'list-active': activePath === item.link}"
                        :input-value="activePath === item.link"
                    >
                        <v-list-item-icon>
                            <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                        </v-list-item-content>

<!--                        <v-list-item-icon style="align-items: center">-->
<!--                            <div class="user__left-menu-messages-counter">-->
<!--                                7-->
<!--                            </div>-->
<!--                        </v-list-item-icon>-->
                    </v-list-item>

                    <v-list-item
                        v-else
                        :key="item.id"
                        :to="item.link"
                        @click="linkClick(item)"
                        color="gray-2"
                        :class="{'list-active': activePath === item.link}"
                        :input-value="activePath === item.link"
                    >
                        <v-list-item-icon>
                            <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                        </v-list-item-content>

<!--                        <v-list-item-icon>-->
<!--                            <v-icon>mdi-chevron-down</v-icon>-->
<!--                        </v-list-item-icon>-->
                    </v-list-item>

                </template>

                <v-list-item
                    color="gray-2"
                    @click="logout"
                >
                    <v-list-item-icon>
                        <v-icon>betvamos-logout</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ $t("menu.logout") }}</v-list-item-title>
                    </v-list-item-content>

                </v-list-item>
<!--                </v-list-item-group>-->
            </v-list>
<!--        </v-navigation-drawer>-->
<!--        <in-menu-banner/>-->
    </v-card>
</template>

<script>

import {mapState, mapActions} from "vuex";
import Items from "@/configs/menus/user.menu"
import InMenuBanner from "@/components/user/InMenuBanner.vue";

export default {
    components: {InMenuBanner},
    name: 'LeftMenu',
    props: {
        activePath: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        selectedItem: 0,
        items: Items,
        loadingBalance: false,
        balancePlaceholder: '***.**'
    }),
    computed: {
        balance() {
            return this.hideBalance ? this.balancePlaceholder : this.currencySymbol + this.userAmount;
        },
        bonusBalance() {
            return this.hideBalance ? this.balancePlaceholder : this.currencySymbol + this.userBonusAmount;
        },
        currencySymbol() {
            return this.currency?.currencySymbol ? this.currency.currencySymbol : this.$auth.user().currency;
        },
        currency() {
            return this.availableCurrencies.find(c => {
                return c.label === this.$auth.user().currency
            });
        },
        fullName() {
          const {firstname, lastname, username} = this.$auth.user()
          return firstname && lastname ? `${firstname} ${lastname}` : username
        },
        ...mapState({
            userAmount: state => state.user.amount,
            userBonusAmount: state => state.user.bonus_amount,
            userTotalAmount: state => state.user.total_amount,
            availableCurrencies: state => state.app.availableCurrencies,
            hideBalance: state => state.user.hide_balance,
        }),
    },
    methods: {
        ...mapActions({
            setBalance: 'user/setBalance',
            toggleVisibilityBalance: 'user/toggleVisibilityBalance',
            makeActiveWindow: 'app/makeActiveWindow',
        }),
        async refreshBalance() {
            this.loadingBalance = true;
            await this.$auth.fetch();
            this.setBalance({
                amount: this.$auth.user().amount,
                amount_bonus: this.$auth.user().amount_bonus
            });
            this.loadingBalance = false;
        },
        linkClick(item = null) {
            if (item && item.function) {
                item.function(this);
            }
            this.$emit('click');
        },
        showWallet(tab) {
            this.makeActiveWindow({type: 'wallet', active: tab});
            this.linkClick();
        },
        async logout() {
            await this.$auth.logout({});
            this.$store.commit('user/setLogin', false)
        }
    },
};
</script>

<style lang="scss">
.user__left-menu {
    max-width: 600px;
    background-color: var(--v-background-base) !important;

    .v-sheet {
        background-color: var(--v-background-base) !important;
    }

    .v-card__title {
        color: var(--v-gray-2-base);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }

    .user__left-menu-balance-card {
        margin-bottom: 16px;
    }

    .user__left-menu-balance-card-balance {
        color: #fff;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    .user__left-menu-balance-card-label{
        color: var(--v-gray-2-base);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }

    .v-list-item--active {
        background-color: var(--v-gray-3-base);
        opacity: 1;
    }

    .v-list-group__header {
        background-color: transparent;
    }

    .v-list-item--active:before {
        //background-color: var(--v-gray-3-base);
        opacity: 0;
    }

    .user__left-menu-messages-counter {
        height: 18px;
        padding: 0 9px;
        background-color: var(--v-primary-base);
        border-radius: 4px;

        color: var(--v-background-base);
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
}
</style>
