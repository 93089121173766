<template>
    <div
        class="balance-static"
        :class="cssClass"
    >

        <v-menu offset-y z-index="110">
            <template v-slot:activator="{ on, attrs }">
                <div v-on="on">
                    {{ balance }}
                </div>
            </template>
            <v-list v-if="userBonusAmount > 0" dark class="blue-grey darken-5">
                <v-list-item>
                    <v-list-item-title class="text-subtitle-2">{{ $t("common.bonusMoney") }}:
                        {{ bonusBalance }}
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>

import {mapActions, mapState} from "vuex";

export default {
    name: "ToolbarBalanceStatic",
    props: {
        cssClass: ''
    },
    data() {
        return {
            amount: null,
            freespin_dialog: false,
            freebet_dialog: false,
            bonus_amount: 0,
            freespinAllowedProviders: [],
            freespinAllowedGames: [],
            freespinAllowedCategories: [],
            balancePlaceholder: '***.**'
        }
    },
    watch: {
        userActiveBonus() {
            this.$store.commit('app/setBonusUpdateKey')
        }
    },
    computed: {
        balance() {
            return this.hideBalance ? this.balancePlaceholder : this.currencySymbol + this.userTotalAmount;
        },
        bonusBalance() {
            return this.hideBalance ? this.balancePlaceholder : this.currencySymbol + this.userBonusAmount;
        },
        currencySymbol() {
            return this.currency?.currencySymbol ? this.currency.currencySymbol : this.$auth.user().currency;
        },
        currency() {
            return this.availableCurrencies.find(c => {
                return c.label === this.$auth.user().currency
            });
        },
        freebetBonus() {
            return !Object.keys(this.bonus_deposit).length && this.userActiveBonus && Object.keys(this.userActiveBonus).length && this.userActiveBonus.type === 'freebet'
        },
        freespinBonus() {
            return !Object.keys(this.bonus_deposit).length && this.userActiveBonus && Object.keys(this.userActiveBonus).length && this.userActiveBonus.type === 'freespin' && +this.userActiveBonus.status === 1 && this.bonus_target < 100
        },
        userActiveBonus() {
            return this.$auth.user() ? this.$auth.user().active_bonus : null
        },
        ...mapState({
            images_cdn: state => state.app.brandSettings.images_cdn,
            userAmount: state => state.user.amount,
            userBonusAmount: state => state.user.bonus_amount,
            userTotalAmount: state => state.user.total_amount < 0 ? 0 : state.user.total_amount,
            bonus_target: state => state.user.bonus_target,
            bonus_deposit: state => state.user.bonus_deposit,
            availableCurrencies: state => state.app.availableCurrencies,
            hideBalance: state => state.user.hide_balance,
        }),
    },
    methods: {
        ...mapActions({
            setLayout: "app/setLayout",
        }),
        getFreebetInfo() {
            this.freebet_dialog = true
        },
        getFreespinInfo() {
            axios.post('/api/freespin_info', {
                    providers: this.$auth.user().active_bonus.settings.providers,
                    games: this.$auth.user().active_bonus.settings.freespin_games,
                    categories: this.$auth.user().active_bonus.settings.categories,
            }).then(res => {
                this.freespin_dialog = true
                this.freespinAllowedProviders = res.data.providers
                this.freespinAllowedGames = res.data.games
                this.freespinAllowedCategories = res.data.categories
            })
        },
        getImage(gameItem, cloudflare_size, cdn_size) {
            if (this.mdAndUp) {
                if (gameItem.banner_cloudflare) {
                    return gameItem.banner_cloudflare + cloudflare_size
                } else {
                    return this.images_cdn + cdn_size + '/' + gameItem.banner
                }
            } else {
                if (gameItem.mobile_banner_cloudflare) {
                    return gameItem.mobile_banner_cloudflare + cloudflare_size
                } else {
                    return this.images_cdn + cdn_size + '/' + gameItem.mobile_banner
                }
            }
        },
    }
}
</script>

<style lang="scss">
.balance-static {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;

    @media screen and (max-width: 600px) {
        font-size: 12px;
    }
}
</style>
