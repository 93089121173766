<template>
  <div class="d-flex text-center flex-column flex-md-row flex-grow-1">
    <v-sheet
        class="layout-side mx-auto mx-md-1 d-none d-md-flex flex-md-column justify-space-between px-2"
        color="auth-bar"
        dark>
      <div class="d-flex flex-column mt-3 mt-md-10 pa-2">
        <logo-responsive class="mx-auto"/>
        <div class="display-2 font-weight-bold primary--text mt-12">
          {{ product.name }}
        </div>
          <div class="title my-2">{{ $t('sidebar.subtitle') }}</div>
          <div>
            <v-btn to="/" class="primary my-4">{{ $t('sidebar.button') }}</v-btn>
          </div>
      </div>

    </v-sheet>

    <div class="pa-2 pa-md-4 flex-grow-1 align-center justify-center d-flex flex-column">
      <div class="layout-content ma-auto w-full">
        <slot></slot>
      </div>
      <div class="overline mt-4">{{ product.name }} - {{ product.version }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LogoResponsive from "@/components/common/LogoResponsive";

export default {
  components: {
    LogoResponsive
  },
  computed: {
    ...mapState('app', ['product'])
  }
}
</script>

<style scoped>
.layout-side {
    width: 420px;
}

.layout-content {
    max-width: 480px;
}
</style>
