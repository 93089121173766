import maps from './maps'
import time from './time'
import icons from './icons'
import theme from './theme'
import toolbar from './toolbar'
import locales from './locales'
import analytics from './analytics'
import currencies from './currencies'
import navigation from './navigation'
import countries from './countries'
import categories from './categories'
import crypto_currencies from './crypto_currencies'

import sportsbook from './sportsbook'
import products from './products'
import userProfileFields from "@/configs/userProfileFields"



export default {
  // product display information
  product: {
    name: 'Casino Games',
    version: '1.2.0'
  },

  // google maps
  maps,

  // time configs
  time,

  // icon libraries
  icons,

  // theme configs
  theme,

  // toolbar configs
  toolbar,

  // locales configs
  locales,

  // analytics configs
  analytics,

  // currencies configs
  currencies,

  // navigation configs
  navigation,

  // countries
  countries,

  categories,

  crypto_currencies,

  sportsbook,
  products,

  userProfileFields
}
