<template>
    <router-link to="/" class="d-block">
        <v-img
            :height="sizes.height"
            :width="sizes.width"
            contain
            src="/images/logo/logo_new.png"
            alt="Nummus"
        />
    </router-link>
</template>

<script>
import breaks from "@/mixins/breaks"
import { mapState } from "vuex"

export default {
    name: "LogoResponsive",
    props: {
        // logo: {
        // }
    },
    mixins: [breaks],
    computed: {
        ...mapState({
            // logo: state => state.app.brandSkin.length ? state.app.brandSkin.find(v => v.key === 'logo') : null,
        }),
        sizes() {
            return this.xsOnly
                ? { height: "30px", width: "81px" }
                : { height: "50px", width: "134px" };
        }
    }
};
</script>
