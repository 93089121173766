<template>
  <v-card class="py-2" tile width="100%" max-width="262px" flat dark v-if="articlesLang.length">
    <v-card-title class="pa-2">{{
      $t("dashboard.articles.title")
    }}</v-card-title>
    <v-navigation-drawer
      floating
      permanent
      width="100%"
      height="auto"
      class="shades transparent"
    >
      <v-list nav dense class="pa-0">
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item
            v-for="(item, i) in articlesLang"
            :key="i"
            :to="'/articles/' + item.url"
            class="d-flex align-center pa-3 mb-2 pa-2 blue-grey darken-5"
          >
            <v-list-item-icon>
              <v-icon>mdi-arrow-right-circle-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content class="mb-0 pa-0">
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
              <div class="text-caption">
                {{ $t("dashboard.articles.detail") }}
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    selectedItem: 0,
    articles: [],
  }),
    computed: {
      articlesLang() {
          let articles = this.articles.filter(v => v.language === this.$auth.user().language)
          return articles
      }
    },
    async created() {
      await axios.get('/api/articles').then(res => this.articles = res.data)
    }
};
</script>
