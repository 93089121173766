<template>
    <div
        class="d-flex align-center"
        :class="cssClass"
    >
        <router-link
            v-if="deposit"
            to="/user/deposit"
            class="icon-link"
        >
            <v-icon>betvamos-cash-plus</v-icon>
        </router-link>
        <v-btn
            v-if="withdrawal"
            height="30"
            class="toolbar_login_button px-2 with-butt-h"
            to="/user/withdrawal">
            {{ $t("menu.withdrawal") }}
        </v-btn>
    </div>
</template>


<script>
import breaks from "@/mixins/breaks";
import {mapActions} from "vuex";

export default {
    mixins: [breaks],
    props: {
        cssClass: '',
        deposit: {},
        withdrawal: {},
    },
    methods: {
        ...mapActions('app', ['makeActiveWindow'])
    }
};
</script>
<style lang="scss" >

</style>
