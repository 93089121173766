import Vue from 'vue';
import store from '../store';

export const wrapRequest = fn => (...params) => (
  fn(...params)
    .catch((error) => {
      store.dispatch("app/showError", {
        error: { message: Vue.i18n.t('error.other') }
      });

      throw error;
  })
);
