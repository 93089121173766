import {BONUS_TYPES} from "@/configs/bonuses";
import {mapState} from "vuex";

export default {
    computed: {
        ...mapState({
            bonus_target: state => state.user.bonus_target,
            disallowPlayerCancelBonus: state => state.app.brandSettings.disallow_player_cancel_bonus,
        }),
        bonus() {
            return this.$auth.user().active_bonus ?? {};
        },
        isHasBonus() {
            return this.bonus !== null && this.bonusType;
        },
        bonusType() {
            return Object.values(BONUS_TYPES).find(t => t === this.bonus?.type);
        },
        isHasBonusProgressBar() {
            return this.isHasBonus && [BONUS_TYPES.DEPOSIT, BONUS_TYPES.FREESPIN].includes(this.bonusType);
        },
        progressBarPercent() {
            let result = null;
            if (this.isHasBonusProgressBar) {
                result = this.bonus_target >= 0
                    ? this.bonus_target
                    : (this.bonus.amount_processed / this.bonus.amount_to_process)
                if (result && typeof result === 'number') {
                    result = (result * 100).toFixed(1);
                }
            }

            return this.bonus_target === 0 ? this.bonusCycleCompleted : this.bonus_target;
        },
        bonusMoneyData() {
            return {
                rollover: this.bonus.settings ? this.bonus.settings.rollover_requirement : 1,
                finalAmount: this.bonus.amount_to_process,
                currentAmount: this.bonus.amount_processed
            }
        },
        bonusCycleCompleted() {
            const data = this.bonusMoneyData;
            return data.finalAmount === 0 ? 0 :
                ((data.currentAmount / data.finalAmount) * 100).toFixed(1);
        },
    },
    methods: {
        getFreebetInfo() {
        },
        async getFreespinInfo(bonus) {
            const data = await axios.post('/api/freespin_info', {
                providers: bonus?.settings?.providers,
                games: bonus?.settings?.freespin_games,
                categories: bonus?.settings?.categories,
            })
            return {
                providers:  data?.data?.providers ?? [],
                games:      data?.data?.games ?? [],
                categories: data?.data?.categories ?? []
            }
        },
        getImage(gameItem, cloudflare_size, cdn_size) {
            if (this.mdAndUp) {
                if (gameItem.banner_cloudflare) {
                    return gameItem.banner_cloudflare + cloudflare_size
                } else {
                    return this.images_cdn + cdn_size + '/' + gameItem.banner
                }
            } else {
                if (gameItem.mobile_banner_cloudflare) {
                    return gameItem.mobile_banner_cloudflare + cloudflare_size
                } else {
                    return this.images_cdn + cdn_size + '/' + gameItem.mobile_banner
                }
            }
        }
    }
}
