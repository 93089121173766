<template>
    <div
        class="d-flex align-center"
        :class="cssClass"
    >
        <router-link
            to="#"
            class="icon-link"
        >
            <v-icon>betvamos-face-agent</v-icon>
        </router-link>
    </div>
</template>


<script>
import breaks from "@/mixins/breaks";
import {mapActions} from "vuex";

export default {
    mixins: [breaks],
    props: {
        cssClass: '',
        deposit: {},
        withdrawal: {},
    },
    methods: {
        ...mapActions('app', ['makeActiveWindow'])
    }
};
</script>
<style lang="scss" >

</style>
