<template>
    <div class="main-menu-slider">
        <v-sheet color="transparent" class="pb-1" height="87px">
            <v-slide-x-reverse-transition>
                <swiper v-show="active" class="swiper" :options="swiperOption">
                    <swiper-slide v-for="(item, i) in items" :key="i" class="nav-swiper-slide">
                        <v-card v-if="item.link_type !== 'game'"
                                :key="i"
                                :target="item.link_type === 'external' ? '_blank' : '_self'"
                                class="tile-gradient-dark ml-1"
                                elevation="1"
                                :to="item.link"
                                color="blue-grey darken-3"
                                min-width="90px"
                                height="90px"
                        >
                            <v-card-text class="text-center">
                                <v-icon>{{ item.icon }}</v-icon>

                                <span class="pt-1 lh-dense d-block text-no-wrap">
                                    {{ item.title }}
                                </span>
                            </v-card-text>
                        </v-card>

                        <v-card v-else
                                @click="goToGame(item.link)"
                                :key="i"
                                class="tile-gradient-dark ml-1"
                                elevation="1"
                                color="blue-grey darken-3"
                                min-width="90px">
                            <v-card-text class="text-center">
                                <v-icon>{{ item.icon }}</v-icon>
                                <span class="pt-1 lh-dense d-block text-no-wrap">
                                    {{ item.title }}
                                </span>
                            </v-card-text>
                        </v-card>
                    </swiper-slide>
                </swiper>
            </v-slide-x-reverse-transition>
        </v-sheet>
    </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/swiper.min.css'
import {mapActions, mapState} from "vuex";
import axios from "axios";
import breaks from "../../mixins/breaks";

export default {
    name: "NavigationDrawerSwiper",
    components: {
        Swiper,
        SwiperSlide
    },
    props: {
        items: {
            type: Array,
            default: () => []
        },
        active: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        swiperOption: {
            slidesPerView: 'auto',
            freeMode: true
        },
    }),
    mixins: [breaks],
    computed: {
        authUser() {
            return this.$auth.user()
        },
        currency() {
            return this.authUser ? this.authUser.currency : this.currencies[0]
        },
        lang() {
            return this.current_lang ? this.current_lang : this.authUser ? this.authUser.language : 'tr'
        },
        platform() {
            return this.mdAndUp ? 'desktop' : 'mobile'
        },
        ...mapState({
            verify_email: state => state.app.brandSettings.verify_email,
            game: (state) => state.games.gameUrl,
        }),
        ...mapState({
            current_lang: state => state.user.current_lang
        }),
    },
    methods: {
        ...mapActions('app', ['makeActiveWindow']),
        ...mapActions({
            getGameUrl: "games/getGameUrl",
        }),
        gameLinkStore() {
            const link = this.game.launch_url ? this.game.launch_url : this.game.slug
            const provider = this.game.game_provider ? this.game.game_provider.slug : 'provider'
            this.$store.commit('app/setGameLinkBeforeLogin', `/casino/${provider}/${link}`)
        },
        goToGame(launch_code) {
            if (this.authUser && !this.authUser.email_verified_at && this.verify_email && this.verify_email.includes('bets')) {
                this.makeActiveWindow({active: true, type: 'verifyEmail'})
                return false
            }
            if (!this.authUser) {
                this.getGame(launch_code)
                return false
            }
            axios.get(`/api/games/launch-url/${launch_code}?currency=${this.currency}&lang=${this.lang}&platform=${this.platform}`).then(res => {
                if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
                    window.location.href = res.data.game_link
                } else {
                    window.open(res.data.game_link, '_blank')
                }
            })
        },
        getGame(launch_code) {
            this.getGameUrl([null, launch_code]).then(() => {
                if (this.game) {
                    this.$emit('drawerClose')
                    this.makeActiveWindow({active: true, type: 'login'})
                    this.gameLinkStore()
                }
            })

        },
    }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings';


.nav-swiper-slide {
    width: auto !important;

    .v-card {
        box-sizing: border-box;
    }

    &:last-child {
        .v-card {
            margin-right: map-deep-get($utilities, 'margin-right', values, 1);
        }
    }
}
</style>
