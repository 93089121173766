import { wrapRequest } from "@/helpers/wrap-request";

import axios from "axios";

const gamesRequest = (params) => axios.get('/api/games', params);
const gameUrlRequest = (params) => axios.get('/api/games/' + params);
const gameLaunchUrlRequest = (params) => axios.get('/api/games/launch-url/' + params);

export const wrappedGames = wrapRequest(gamesRequest);
export const wrappedGameUrl = wrapRequest(gameUrlRequest);
export const wrappedGameUrlLaunch = wrapRequest(gameLaunchUrlRequest);
