<template>
    <div class="side-game-bar">
<!--        <div class="side-game-bar__item side-game-bar__item--spin ">-->
<!--            <div class="side-game-bar__item-title">-->
<!--                Remaining Free Spins-->
<!--            </div>-->
<!--            <div class="side-game-bar__spins">-->
<!--                <div class="side-game-bar__spins-items">-->
<!--                    <div class="side-game-bar__spins-item">-->

<!--                        <svg class="side-game-bar__spins-item-image" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                            <path d="M11.6778 7.64258C7.43774 7.64258 4 11.0803 4 15.3214C4 19.5625 7.4375 23.0001 11.6778 23.0001C15.9182 23.0001 19.3557 19.5622 19.3557 15.3214C19.3557 11.0805 15.9182 7.64258 11.6778 7.64258ZM11.6778 22.197C7.88088 22.197 4.80284 19.1185 4.80284 15.3211C4.80284 11.5237 7.88088 8.44527 11.6778 8.44527C15.4748 8.44527 18.5528 11.5237 18.5528 15.3211C18.5528 19.1185 15.4748 22.197 11.6778 22.197Z" fill="#FEB400"/>-->
<!--                            <path d="M15.343 11.9266C15.0571 12.6532 13.7684 12.8619 12.7656 12.4505C11.6909 12.0095 11.1683 11.0009 11.4428 10.2906C11.7366 9.53012 12.8846 9.34889 13.9548 9.74418C14.9484 10.1113 15.6412 11.1681 15.343 11.9266Z" fill="#FEB400"/>-->
<!--                            <path d="M7.45633 7.93312C8.59766 7.27913 9.87577 6.90039 11.1891 6.82698C10.4428 3.99201 11.4834 2.30687 11.4834 2.30687C9.84436 3.29672 9.92139 5.61097 9.92139 5.61097C8.53321 2.30687 7.32475 1 7.32475 1C7.20254 3.9167 7.80665 6.27317 7.80665 6.27317C6.64337 4.36385 4.67651 4.39669 4.67651 4.39669C6.5619 5.53048 7.26303 7.31734 7.45633 7.93312Z" fill="#FEB400"/>-->
<!--                        </svg>-->

<!--                        <a href="#" class="side-game-bar__spins-item-title">Pragmatic Play</a>-->
<!--                        <span class="side-game-bar__spins-item-amount">320</span>-->
<!--                    </div>-->
<!--                    <div class="side-game-bar__spins-item">-->

<!--                        <svg class="side-game-bar__spins-item-image" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                            <path d="M18.3678 13.7417L14.2248 13.85L14.076 18.3542L13.8506 21.0189L10.2396 20.9828V18.4264L9.94201 13.9582L5.60963 14.1385L5.46087 9.81462L11.84 9.77855L18.4445 9.5982L18.5572 10.1753L18.3678 13.7417ZM21.5326 16.42L22.2494 11.5956L19.256 5.54032L17.2227 6.21212L14.5674 3.46629L9.8789 1L8.85329 4.66111L8.12746 2.73587L4.52542 6.13097L2.51025 9.53057L2.42008 13.0519L1 12.3981L1.96926 18.0431L4.16476 21.6772L9.34016 23.3905L13.5824 23.5393L20.8947 20.9603L23 15.9375L21.5348 16.42H21.5326Z" fill="#FEB400"/>-->
<!--                        </svg>-->


<!--                        <a href="#" class="side-game-bar__spins-item-title">Pragmatic Play</a>-->
<!--                        <span class="side-game-bar__spins-item-amount">320</span>-->
<!--                    </div>-->
<!--                    <div class="side-game-bar__spins-item">-->

<!--                        <svg class="side-game-bar__spins-item-image" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                            <path d="M8.30481 10.4138V9.48047H6.13098H5.66432H5.19775V14.2577H5.66432H6.13098H8.30481V13.3243H6.13098V12.3358H7.99724V11.4024H6.13098V10.4138H8.30481Z" fill="#FEB400"/>-->
<!--                            <path d="M11.8436 9.48047H10.7597H9.82644H8.68823V10.4138H9.82644V14.2577H10.7597V10.4138H11.8436V9.48047Z" fill="#FEB400"/>-->
<!--                            <path d="M23.0001 9.48047H21.9162H20.9829H19.8447V10.4138H20.9829V14.2577H21.9162V10.4138H23.0001V9.48047Z" fill="#FEB400"/>-->
<!--                            <path d="M15.3595 10.4142V9.48087H13.1858H12.7191H12.5242V8H12.2524V9.48087V14.2581V15.7634H12.5242V14.2581H12.7191H13.1858H15.3595V13.3247H13.1858V12.3361H15.0519V11.4028H13.1858V10.4142H15.3595Z" fill="#FEB400"/>-->
<!--                            <path d="M3.72129 9.48047V12.3962L1.84549 9.48047H1.73007H1V14.2577H1.93332V11.3419L3.78078 14.2136L3.78097 14.2135L3.80932 14.2577H4.65461V9.48047H3.72129Z" fill="#FEB400"/>-->
<!--                            <path d="M18.5489 9.48047V12.3962L16.6731 9.48047H16.5577H15.8276V14.2577H16.7609V11.3419L18.6084 14.2136L18.6085 14.2135L18.637 14.2577H19.4822V9.48047H18.5489Z" fill="#FEB400"/>-->
<!--                        </svg>-->


<!--                        <a href="#" class="side-game-bar__spins-item-title">Pragmatic Play</a>-->
<!--                        <span class="side-game-bar__spins-item-amount">320</span>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="side-game-bar__spins-more">-->
<!--                    <a href="#">See More</a>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <div
            v-if="compRecentGames.length"
            class="side-game-bar__item side-game-bar__item--spin"

        >
            <div class="side-game-bar__item-title">
                Recently played
            </div>
            <div class="side-game-bar__games">
                <div
                    v-for="game in compRecentGames"
                    :key="`recent-${game.id}`"
                    class="side-game-bar__games-item"
                >
                    <v-img
                        class="side-game-bar__games-item-image"
                        :src="getGameImage(game)"
                        width="120"
                        max-width="120"
                        height="60"
                    />
                    <div class="side-game-bar__games-item-title"> {{ game.original_name }} </div>
                    <v-btn
                        class="button--gray"
                        tag="a"
                        :to="gameLink(game)"
                    >
                        Play
                    </v-btn>
                </div>
            </div>
        </div>

        <div
            v-if="compRecommendedGames.length"
            class="side-game-bar__item"

        >
            <div class="side-game-bar__item-title">
                Recommended for you
            </div>
            <div class="side-game-bar__games">
                <div
                    v-for="game in compRecommendedGames"
                    :key="`recommended-${game.id}`"
                    class="side-game-bar__games-item"
                >
                    <v-img
                        class="side-game-bar__games-item-image"
                        :src="getGameImage(game)"
                        width="120"
                        max-width="120"
                        height="60"
                    />
                    <div class="side-game-bar__games-item-title"> {{ game.original_name }} </div>
                    <v-btn
                        class="button--gray"
                        tag="a"
                        :to="gameLink(game)"
                    >
                        Play
                    </v-btn>
                </div>
            </div>
        </div>

<!--        <div-->
<!--            class="side-game-bar__item"-->

<!--        >-->
<!--            <div class="side-game-bar__item-title">-->
<!--                Your Bonus-->
<!--            </div>-->
<!--            <div class="side-game-bar__bonus">-->
<!--                <v-img-->
<!--                    src="/images/bonuses/2.png"-->
<!--                    width="100%"-->
<!--                    height="100%"-->
<!--                />-->
<!--                <div class="side-game-bar__bonus-wrapper">-->
<!--                    <div class="side-game-bar__bonus-title">Casino Welcome Bonus</div>-->
<!--                    <div class="side-game-bar__bonus-subtitle">New Players Only</div>-->
<!--                    <div class="side-game-bar__bonus-expires">-->
<!--                        <div class="side-game-bar__bonus-expires-text">Expires in:</div>-->
<!--                        <div class="side-game-bar__bonus-expires-date">23 Days</div>-->
<!--                    </div>-->

<!--                    <v-expansion-panels class="side-game-bar__bonus-accordion">-->
<!--                        <v-expansion-panel class="side-game-bar__bonus-accordion-item">-->
<!--                            <v-expansion-panel-header class="side-game-bar__bonus-accordion-title">-->
<!--                                How the offer works-->
<!--                            </v-expansion-panel-header>-->
<!--                        </v-expansion-panel>-->
<!--                        <v-expansion-panel class="side-game-bar__bonus-accordion-item">-->
<!--                            <v-expansion-panel-header class="side-game-bar__bonus-accordion-title">-->
<!--                                Make a Deposit-->
<!--                            </v-expansion-panel-header>-->
<!--                        </v-expansion-panel>-->
<!--                        <v-expansion-panel class="side-game-bar__bonus-accordion-item">-->
<!--                            <v-expansion-panel-header class="side-game-bar__bonus-accordion-title">-->
<!--                                Play 5 games on casino-->
<!--                            </v-expansion-panel-header>-->
<!--                        </v-expansion-panel>-->
<!--                    </v-expansion-panels>-->
<!--                    <div class="side-game-bar__bonus-button">-->
<!--                        <a class="side-game-bar__bonus-button-link" href="#">T&C apply</a>-->
<!--                        <v-btn-->
<!--                            class="button&#45;&#45;gray"-->
<!--                            block-->
<!--                        >-->
<!--                            Play-->
<!--                        </v-btn>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

    </div>
</template>

<script>

import gameImages from "@/mixins/gameImages";

export default {
    name: 'GameSideBar',
    mixins: [gameImages],
    props: {
        recentGames: {
            type: Array,
            default: () => []
        },
        recommendedGames: {
            type: Array,
            default: () => []
        },
    },
    data: () => ({
        maxRecentGames: 5,
        maxRecommendedGames: 3
    }),
    computed: {
        compRecentGames() {
            return this.recentGames.slice(0, this.maxRecentGames);
        },
        compRecommendedGames() {
            return this.recommendedGames.slice(0, this.maxRecommendedGames);
        }
    },
    methods: {
        gameLink(game) {
            const link = game.launch_code ? game.launch_code : game.slug
            const provider = game.game_provider ? game.game_provider.slug : 'provider'
            return `/casino/${provider}/${link}`
        },
    }
}
</script>

<style lang="scss">
.side-game-bar {
    width: 385px;

    .side-game-bar__item {
        background-color: var(--v-background-base);
        box-shadow: 0px -1px 0px 0px #171A1F inset;

        .side-game-bar__item-title {
            padding: 16px;
            background: var(--v-gray-3-base);
            box-shadow: 0px -1px 0px 0px #171A1F inset;
            color: #fff;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;

        }

        .side-game-bar__spins {
            padding: 8px 16px;

            .side-game-bar__spins-item {
                display: flex;
                align-items: center;
                padding-bottom: 4px;

                .side-game-bar__spins-item-image {
                    width: 24px;
                    height: 24px;
                    margin-right: 8px;
                }

                .side-game-bar__spins-item-title {
                    flex-grow: 1;
                    overflow: hidden;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    text-decoration: underline;
                }

                .side-game-bar__spins-item-amount {
                    color: #fff;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 14px;
                }
            }

            .side-game-bar__spins-more {
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                text-decoration: underline;
                text-decoration-color: var(--v-primary-base);
            }
        }


        .side-game-bar__games {


            .side-game-bar__games-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 4px 16px 4px 4px;
                border-bottom: 1px solid #171A1F;

                .side-game-bar__games-item-image {
                    overflow: hidden;
                    border-radius: 4px;

                    .v-image__image {
                        background-size: 100% 120%;
                    }
                }

                .side-game-bar__games-item-title {
                    flex-grow: 1;
                    padding: 0 16px;
                }
            }
        }

        .side-game-bar__bonus {

            .side-game-bar__bonus-wrapper {
                padding: 8px 16px 16px;
            }

            .side-game-bar__bonus-title {
                color: #fff;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
            }

            .side-game-bar__bonus-subtitle {
                color: var(--var-gray-2-base);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 137.5% */
                text-transform: capitalize;
            }

            .side-game-bar__bonus-expires {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 8px 0;

                color: var(--gray-2-base);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;

                .side-game-bar__bonus-expires-date {
                    color: #fff;
                    font-weight: 600;
                }
            }

            .side-game-bar__bonus-button {


                .side-game-bar__bonus-button-link {
                    display: inline-block;
                    margin: 8px 0;
                    text-decoration: underline;
                }
            }


        }

        .side-game-bar__bonus-accordion {

            .side-game-bar__bonus-accordion-item {
                background-color: var(--v-gray-3-base);
                border-radius: 4px;

                .side-game-bar__bonus-accordion-title {
                    color: #fff;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px; /* 150% */
                    text-transform: capitalize;
                }
            }
        }


        &--spin {
            background-color: var(--v-gray-3-base);

            .side-game-bar__item-title {
                background: var(--v-background-base);
            }
        }
    }
}
</style>
