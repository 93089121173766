<template>
    <v-dialog v-model="showingGames" max-width="1200">
        <v-card
            light
            tile
            flat
            class="pa-2 pa-md-8 mx-auto position-relative">

            <v-icon @click="showingGames = false" class="slots-game-search-game-close">mdi-close</v-icon>

            <v-card-title class="mb-6" v-if="searchGames.length">{{ $t("slotsGame.showing") }} {{
                    searchGames.length
                }} {{ $t("slotsGame.games") }}
            </v-card-title>
            <v-card-title v-else>{{ $t("slotsGame.gamesNotFound") }}</v-card-title>
            <div class="text-center my-4" v-if="loading">
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </div>

            <div class="d-flex flex-wrap" v-if="searchGames.length">

                <router-link
                    :to="gameLink(game)"
                    :target="mdAndDown ? '_blank' : '_self'"
                    v-if="authUser"
                    v-for="game in searchGames"
                    :key="game.id"
                    class="slots-game-search-game mb-2">
                    <v-badge
                        v-if="game.badge"
                        tile
                        class="game-badge"
                        dark
                        color="red"
                        :content="game.badge.name.toUpperCase()"
                    />
                    <v-sheet color="transparent" dark>
                        <div class="slots-game-search-game-wrap d-flex flex-column align-center justify-center">
                            <div
                                class="slots-game-search-game-title text-lg-h4 text-center lh-1 d-none d-lg-block">
                                {{ game.original_name }}
                            </div>
                        </div>
                        <v-img
                            class="rounded elevation-1"
                            aspect-ratio="1"
                            :src="game.banner"
                            :lazy-src="game.banner"
                            position="center top">
                        </v-img>
                    </v-sheet>
                </router-link>

                <v-sheet
                    color="transparent"
                    dark
                    class="slots-game-search-game mb-2"
                    v-if="!authUser"
                    v-for="game in searchGames"
                    :key="game.id">
                    <v-badge
                        v-if="game.badge"
                        tile
                        class="game-badge"
                        dark
                        color="red"
                        :content="game.badge.name.toUpperCase()"
                    />
                    <div class="slots-game-search-game-wrap d-flex flex-column align-center justify-center">
                        <div class="slots-game-search-game-title text-lg-h4 text-center lh-1 d-none d-lg-block">
                            {{ game.original_name }}
                        </div>
                        <div class="d-block d-lg-flex slots-game-search-game-btns">
                            <v-btn
                                color="success"
                                class="d-flex d-lg-inline-flex mr-0 mb-2 mr-lg-2 mb-lg-0"
                                @click="makeActiveWindow({ active: true, type: 'login'})">
                                {{ $t("slotsGame.login") }}
                            </v-btn>
                            <v-btn
                                color="primary"
                                :to="gameLink(game)"
                                :target="mdAndDown ? '_blank' : '_self'"
                                v-if="game.is_demo_enabled">{{ $t("slotsGame.tryForFree") }}
                            </v-btn>
                        </div>
                    </div>
                    <v-img
                        class="rounded elevation-1"
                        aspect-ratio="1"
                        :src="game.banner"
                        :lazy-src="game.banner"
                        position="center top">
                    </v-img>
                </v-sheet>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import breaks from "../../../../mixins/breaks";
import {mapActions} from "vuex";

export default {
    name: "ShowingGamesDialog",
    props: {
        showingGames: {
            type: Boolean,
            default: () => false
        },
        loading: {
            type: Boolean,
            default: () => false
        },
        searchGames: {
            type: Array,
            default: () => []
        }
    },
    mixins: [breaks],
    computed: {
        authUser() {
            return this.$auth.user();
        },
    },
    methods: {
        ...mapActions('app', ['makeActiveWindow']),
        gameLink(game) {
            return this.$emit('gameLink', game)
        }
    }
}
</script>

<style scoped>

</style>
