<template>
    <v-container>
        <div class="item-star d-flex justify-center mt-5 mb-2">
            <v-img
                src="/images/logo/nummus-affiliates.png"
                alt="Nummus Affiliates"
                :width="245"
                :aspect-ratio="3.5"
            />
        </div>
        <v-spacer/>
        <div style="width: 100%;">
            <slot></slot>
        </div>
        <v-spacer/>
        <v-spacer/>
    </v-container>
</template>
<script>
import LogoResponsive from "@/components/common/LogoResponsive.vue";

export default {
    components: {LogoResponsive}
}
</script>
<style scoped>
.container {
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 32px;
}
</style>
