<template>
    <div v-if="isHasBonus">
        <div
            v-if="bonus.name"
            class="bonus-title"
        >
            <v-icon class="bonus-icon" size="18">custom-gift</v-icon>
            <span>{{ bonus.name }}</span>
        </div>
        <div
            v-if="isHasBonusProgressBar"
            class="d-flex align-center"
        >
            <v-progress-linear
                :value="progressBarPercent"
                height="25"
                color="secondary"
                rounded
                background-opacity="0"
            >
                <strong class="bonus-progress-title">{{ progressBarPercent }}%</strong>
            </v-progress-linear>
        </div>
        <div class="d-flex justify-end mt-4">
            <v-btn small color="primary"
                   v-if="!disallowPlayerCancelBonus"
                   @click="confirmDialog = true">
                {{ $t("dashboard.sections.bonuses.cancelBonus") }}
            </v-btn>
        </div>
        <bonus-confirm-cancel-dialog v-model="confirmDialog" />
    </div>
</template>

<script>


import bonuses from "@/mixins/bonuses";
import BonusConfirmCancelDialog from "@/components/modals/BonusConfirmCancelDialog.vue";

export default {
    components: {BonusConfirmCancelDialog},
    mixins: [bonuses],
    data: () => ({
        confirmDialog: false,
    })
};
</script>

<style lang="scss" scoped>
.bonus-title {
    margin-bottom: 12px;
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    line-height: 18px;
    color: var(--v-secondary-base);
    text-transform: uppercase;
    font-weight: 600;

    .bonus-icon {
        margin-right: 8px;
        color: inherit !important;
    }

    span {
        font-size: inherit;
        line-height: inherit;
    }
}

.bonus-progress-title {
    color: var(--v-secondary-base);
}

.v-progress-linear {
    border: 1px solid var(--v-secondary-base);
}
</style>
