<template>
  <v-bottom-navigation
    dark
    fixed
    class="bottom-bar justify-space-between justify-sm-space-around"
    v-model="value"
    :height="bottomBarHeight"
    :input-value="active"
  >
    <v-btn class="font-weight-regular">
      <span class="pt-4px">Rest</span>
      <v-icon :small="seOnly">mdi-television</v-icon>
    </v-btn>

    <v-btn class="font-weight-regular">
      <span class="pt-4px">Mobile App</span>
      <v-icon :small="seOnly">mdi-cellphone-iphone</v-icon>
    </v-btn>

    <v-btn
      :height="circleButtonHeight"
      width="40px"
      class="bottom-bar__circle-btn rounded-circle mt-n1 mx-1 elevation-2"
      fab
      :to="authUser ? '/user/deposit' : '/auth/signin'"
      large
    >
      <div class="text-subtitle-2 font-weight-bold">Add</div>
    </v-btn>

    <v-btn class="font-weight-regular">
      <span class="pt-4px">Live Betting</span>
      <v-icon :small="seOnly">mdi-television-play</v-icon>
    </v-btn>
    <v-btn class="font-weight-regular">
      <span class="pt-4px">Support</span>
      <v-icon :small="seOnly">mdi-face-agent</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import breaks from "@/mixins/breaks";
export default {
  name: "BottomBar",
  mixins: [breaks],
  model: {
    prop: "model",
    event: "change",
  },
  props: {
    model: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    value: 1,
  }),
  computed: {
    circleButtonHeight() {
      return this.seOnly ? "60px" : "70px";
    },
    bottomBarHeight() {
      return this.seOnly ? "54px" : "56px";
    },
    authUser() {
      return this.$auth.user();
    },
  },
};
</script>

<style lang="scss">
@import "../../../sass/vuetify/variables/mixins";
@import "~vuetify/src/styles/styles.sass";

.bottom-bar__circle-btn {
  background: map-get($pink, "accent-3") !important;
  border: 2px solid map-get($blue-grey, "lighten-3") !important;
}

@include media("se-only") {
  .bottom-bar {
      z-index: 90 !important;
    &.v-item-group.v-bottom-navigation .v-btn {
      font-size: 9px !important;
      max-width: 60px;
      min-width: 60px;
    }
    &__circle-btn {
      width: 60px !important;
      min-width: 60px !important;
    }
  }
}
</style>
