import GameItem from "@/models/GameItem";

const getGames = async (
                            filter = {
                                categoriesId: [],
                                providersId: [],
                                search: '',
                                platform: 'desktop'
                            },
                            pagePaginate,
                            limit = 9
                        ) => {

    const query = GameItem.instance();

    if(filter.categoriesId?.length) {
        query.whereIn(['category', 'category_id'], filter.categoriesId);
    }

    if (filter.providersId?.length) {
        query.whereIn("provider_id", filter.providersId)
    }

    if (filter.search) {
        query.where("original_name", filter.search)
    }

    if(filter.platform === 'mobile') {
        query.where("is_mobile", 1)
    } else {
        query.where("is_desktop", 1)
    }

    query.params({"type": "search"})

    query.page(pagePaginate)

    query.limit(limit)

    query.orderBy("-is_promoted")

    return query.get();
}

export default {
    getGames,
}
