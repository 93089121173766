<template>
    <div class="position-relative">
        <v-btn icon class="gray-3" @click="showProfileDrawer" :height="size" :width="size">
            <v-avatar class="secondary--text" :size="size">
                <v-icon :size="size">custom-login</v-icon>
            </v-avatar>
        </v-btn>
        <div
            v-if="isDesktop"
            class="profile-drawer-triangle"
            :class="{
            'profile-drawer-triangle--active': profileDrawerState
            }"
        >
            <v-icon color="secondary" size="36">custom-triangle</v-icon>
        </div>
    </div>
</template>

<script>
/*
|---------------------------------------------------------------------
| Toolbar User Component
|---------------------------------------------------------------------
|
| Quickmenu for user menu shortcuts on the toolbar
|
*/
import breaks from '@/mixins/breaks';
import ToolbarLeftMenu from "@/components/user/ToolbarLeftMenu.vue";
import {mapActions, mapState} from "vuex";

export default {
    components: {ToolbarLeftMenu},
    mixins: [breaks],
    data() {
        return {
            show: false,
            windowWidth: null,
            menu: [
                {
                    icon: "mdi-account-circle",
                    title: "dashboard.sections.overview.title",
                    link: "/user/info",
                },
                {
                    icon: "mdi-information",
                    title: "dashboard.sections.memberInfo.title",
                    link: "/user/update",
                },
                {
                    icon: "mdi-check-decagram",
                    title: "dashboard.sections.memberVerify.title",
                    link: "/user/verification",
                },
                {
                    icon: "mdi-soccer",
                    title: "dashboard.sections.betsHistory.title",
                    link: "/user/my-bets",
                },
                {
                    icon: "mdi-comment-text-multiple",
                    title: "dashboard.sections.messages.title",
                    link: "/user/messages",
                },
                {
                    icon: "mdi-wallet",
                    title: "dashboard.sections.deposit.title",
                    link: "/user/deposit",
                },
                {
                    icon: "mdi-wallet-giftcard",
                    title: "dashboard.sections.bonuses.title",
                    link: "/user/bonuses",
                },
                {
                    icon: "mdi-send",
                    title: "dashboard.sections.sendToFriend.title",
                    link: "/user/transfer",
                },
                {
                    icon: "mdi-history",
                    title: "dashboard.sections.history.title",
                    link: "/user/history",
                },
            ],
        }
    },
    computed: {
        ...mapState({
            profileDrawerState: (state) => state.app.profileDrawer,
        }),
        size() {
            return this.isDesktop ? 30 : 24;
        }
    },
    created() {
        this.windowWidth = window.innerWidth
        window.addEventListener('resize', this.handleResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        ...mapActions('app', ['setProfileDrawerActive']),
        handleResize() {
            if (this.windowWidth !== window.innerWidth) {
                this.windowWidth = window.innerWidth
                this.show = false
            }
        },
        async logout() {
            try {
                await this.$auth.logout({})
                this.$store.commit('user/setLogin', false)
            } catch (error) {
                // @todo: handle errors if request has not worked
            }
        },
        showProfileDrawer() {
            this.setProfileDrawerActive(true);
        }
    }
}
</script>

<style lang="scss" scoped>
.profile-drawer-triangle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: -4px;
    z-index: 100000;
    width: 36px;
    height: 0;
    transition: all 0.3s ease;
    overflow: hidden;

    &--active {
        height: 36px;
    }

    .v-icon {
        width: 100%;
    }
}
</style>
