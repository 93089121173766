<template>
    <div class="iframes-container d-flex flex-wrap align-start justify-center ma-auto"
         :class="views.length > 1 ? 'justify-space-between' : 'justify-center'">
        <div
            v-for="(item, index) in views"
            :key="index"
            class="iframe-wrap d-flex align-center justify-center"
            :class="iframeWrapClasses(item, index)"
        >
            <div class="text-center pa-4" v-if="item.session_error" style="max-width: 400px">
                {{ $t('slotGame.gameSessionClosed') }}
                <v-btn
                    color="primary"
                    class="mt-4 black--text"
                    @click="iframeControl({action: 'session_error'}, item, index)">
                    {{ $t('slotsGame.reload') }}
                </v-btn>
            </div>

            <div class="game-iframe-games-wrap"
                 v-if="!Object.keys(item.game).length && !gamesLoading[index].loading && !item.session_error">
                <div
                    class="text-center"
                >
                    <v-btn text dark class="mx-auto" @click="addSearchGame(item, index)">
                        <v-icon class="mr-2">mdi-plus</v-icon>
                        {{ $t('buttons.addGame') }}
                    </v-btn>
                </div>
                <div class="text-subtitle-2 text-bold text-uppercase mt-15 mb-4" v-if="recommended_games.length">
                    {{ $t('slotGame.recommended') }}
                </div>
                <div class="d-flex align-center justify-space-between">
                    <v-sheet
                        style="cursor: pointer;"
                        color="transparent"
                        dark
                        class="slots-game-search-game mb-2"
                        v-for="game in recommended_games"
                        :key="game.id"
                        @click="addGame(game, item, index)"
                    >
                        <v-badge
                            v-if="game.badge"
                            tile
                            class="game-badge"
                            dark
                            color="red"
                            :content="game.badge.name.toUpperCase()"
                        />
                        <div
                            class="slots-game-search-game-wrap d-flex flex-column align-center justify-center">
                            <div
                                class="slots-game-search-game-title text-center lh-1 text-capitalize d-none d-lg-block"
                                :class="views.length > 1 ? 'text-lg-h6' : 'text-lg-h4'"
                            >
                                <v-icon :x-large="views.length === 1" :large="views.length > 1"
                                        class="d-block mb-2">mdi-plus
                                </v-icon>
                                {{ game.original_name }}
                            </div>
                        </div>
                        <v-img
                            class="rounded elevation-1"
                            aspect-ratio="1"
                            :src="game.banner.includes('.svg') || game.banner.includes('gif') ? game.banner : images_cdn + '600/' + game.banner"
                            :lazy-src="game.banner.includes('.svg') || game.banner.includes('gif') ? game.banner : images_cdn + '10/' + game.banner"
                            position="center top">
                        </v-img>
                    </v-sheet>
                </div>
            </div>

            <div
                class="game-iframe-controls d-flex flex-column align-center justify-center surface"
                v-if="Object.keys(item.game).length && !gamesLoading[index].loading && !item.session_error"
            >
                <v-tooltip right v-for="(icon, i) in iframeControlsIcons" :key="i">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-bind="attrs"
                            v-on="on"
                            light
                            style="cursor:pointer;color: white;"
                            :class="i === 0 ? 'mt-0' : 'mt-3'"
                            :style="i === 1 || i === 3 ? 'font-size: 20px' : ''"
                            @click="iframeControl(icon, item, index)"
                        >
                            {{ icon.icon }}
                        </v-icon>
                    </template>
                    <span class="text-overline">{{ $t(icon.text) }}</span>
                </v-tooltip>
            </div>

            <div class="d-flex align-center justify-center flex-column"
                 v-if="gamesLoading[index].loading && !item.session_error"
                 style="width: 100%;height: 100%;background-color: var(--v-surface-base);">
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </div>

            <iframe
                :id="'iframe-' + index"
                v-if="Object.keys(item.game).length && !item.session_error"
                v-show="!gamesLoading[index].loading"
                :src="item.game.game_link"
                frameborder="0"
                width="100%"
                height="100%"
                align="center"
                class="game-iframe"
                :class="{ full_screen: fullScreen }"
                @load="iframeLoaded(index)"
            ></iframe>
        </div>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
    name: "IframesContainer",
    props: {
        views: {
            type: Array,
            default: () => []
        },
        gamesLoading: {
            type: Array,
            default: () => [
                {loading: true},
                {loading: false},
                {loading: false},
                {loading: false},
            ]
        },
        recommended_games: {
            type: Array,
            default: () => []
        },
        fullScreen: {
            type: Boolean,
            default: () => false
        }
    },
    data() {
        return {
            iframeControlsIcons: [
                {
                    'icon': 'mdi-close',
                    'action': 'close',
                    'text': 'slotsGame.closeGame'
                },
                {
                    'icon': 'mdi-checkbox-multiple-blank-outline',
                    'action': 'clone',
                    'text': 'slotsGame.cloneGame'
                },
                {
                    'icon': 'mdi-fullscreen',
                    'action': 'fullscreen',
                    'text': 'slotsGame.fullScreen'
                },
                {
                    'icon': 'mdi-reload',
                    'action': 'reload',
                    'text': 'slotsGame.reload'
                }
            ],
            demoTimeInterval: ''
        }
    },
    created() {
        if (!this.$auth.user()) {
            this.demoTimeInterval = setInterval(() => {
                this.makeActiveWindow({ active: true, type: 'register'})
            }, 60000)
        }
    },
    watch: {
        login() {
            if (this.login || this.$auth.user()) {
                if (this.demoTimeInterval) clearInterval(this.demoTimeInterval)
            }
        }
    },
    beforeDestroy() {
        if (this.demoTimeInterval) clearInterval(this.demoTimeInterval)
    },
    computed: {
        ...mapState({
            login: state => state.user.login,
            images_cdn: state => state.app.brandSettings.images_cdn,
        })
    },
    methods: {
        ...mapActions('app', ['makeActiveWindow']),
        iframeControl(icon, item, index) {
            this.$emit('iframeControl', icon, item, index)
        },
        iframeWrapClasses(item) {
            if (Object.keys(item.game).length && this.views.length === 1) {
                return 'pr-11 mt-2 no-bg'
            } else if (this.views.length > 1 && !Object.keys(item.game).length) {
                return 'w50 mt-2'
            } else if (this.views.length > 1 && Object.keys(item.game).length) {
                return 'w50 mt-2 pr-11 no-bg'
            }
        },
        addSearchGame(item, index) {
            this.$emit('addSearchGame', item, index)
        },
        addGame(game, item, index) {
            this.$emit('addGame', game, item, index)
        },
        iframeLoaded(index) {
            this.$emit('iframeLoaded', index)
        }
    }
}
</script>

<style scoped>

</style>
