import Banner from "../../../models/Banner";
import Bonus from "../../../models/Bonus";
import i18n from "../../../plugins/vue-i18n";

const showToast = ({state, commit}, message) => {
    if (state.toast.show) commit('hideToast')

    setTimeout(() => {
        commit('showToast', {
            color: 'black',
            message,
            timeout: 3000
        })
    })
}

const setPlatform = ({state, commit}, platform) => {
    if(platform === 'desktop' || platform === 'mobile') {
        if(state.platform !== platform) {
            commit('setPlatform', platform);
        }
    }
}

const showError = ({state, commit}, {message = 'Failed!', error}) => {
    if (state.toast.show) commit('hideToast')

    setTimeout(() => {
        commit('showToast', {
            color: 'error',
            message: message + ' ' + error.message,
            timeout: 10000
        })
    })
}

const showSuccess = ({state, commit}, message) => {
    if (state.toast.show) commit('hideToast')

    setTimeout(() => {
        commit('showToast', {
            color: 'success',
            message,
            timeout: 3000
        })
    })
}

const showPreloader = ({state, commit}, payload) => {
    const opacity = payload?.opacity ? payload.opacity : 1;
    commit('setPreloader', {active: true, opacity})
}

const hidePreloader = ({state, commit}) => {
    commit('setPreloader', {active: false, opacity: 0})
}

const setLayout = ({commit}, payload) => {
    commit('setLayout', payload);
}

const getBanners = async ({state, commit}) => {
    const banners = await Banner.get()
    commit('setBanners', _.groupBy(banners, 'type'))
}

const getBrandSettings = async ({state, commit}) => {
    await axios.get('/api/brand-setting').then(res => {
        commit('setBrandSettings', res ? res.data : {})
    })
}

const getTags = async ({state, commit}) => {
    await axios.get('/api/tags').then(res => {
        commit('setTags', res ? res.data : {})
    })
}

const getScripts = async ({state, commit}) => {
    await axios.get('/api/scripts').then(res => {
        commit('setScripts', res ? res.data : {})
    })
}

const getTopWinnersList = async ({state, commit}) => {
    await axios.post('/api/top_winners').then(res => {
        commit('setTopWinnersList', res ? res.data : [])
    })
}

const getBrandSkin = async ({state, commit}) => {
    await axios.get('/api/brand-skin').then(res => {
        commit('setBrandSkin', res ? res.data : {})
    })
}

const getControlContents = async ({state, commit}, payload) => {
    await axios.get(`/api/control-contents?lang=${payload}`).then(res => {
        commit('setControlContents', res.data);
    });

}

const getUserBonuses = async ({state, commit}, payload) => {
    const result = await Bonus.params({lang: payload}).get();
    commit('setUserBonuses', result);
}

const getTranslationSettings = async ({state, commit}) => {
    const result = await fetch('/api/translation-settings');
    commit('setTranslationSettings', await result.json());
}

const makeActiveWindow = ({state, commit}, payload) => {
    commit('updateActiveWindow', payload);
}

const setProfileDrawerActive = ({state, commit}, payload) => {
    commit('setProfileDrawerActive', payload);
}

const setRedirectOnAuth = ({commit}, payload) => {
    commit('setRedirectOnAuth', payload)
}

const updateUserDashboardMobileDialog = ({state, commit}, payload) => {
    commit('updateUserDashboardMobileDialog', payload);
}

const getCountry = ({state, commit}, payload) => {
    axios.get('/api/get-country').then(res => {
        commit('setCountry', res.data);
    })
}

const _init = async ({dispatch}) => {
    await dispatch('getTags');
    await dispatch('getBrandSkin')
    await dispatch('getBrandSettings')
    await dispatch('getTranslationSettings');
    await dispatch('getBanners');
}




export default {
    showToast,
    showError,
    showSuccess,
    showPreloader,
    hidePreloader,
    setLayout,
    getBanners,
    getControlContents,
    getUserBonuses,
    getTranslationSettings,
    makeActiveWindow,
    setRedirectOnAuth,
    getBrandSettings,
    getBrandSkin,
    getTopWinnersList,
    getTags,
    getScripts,
    getCountry,
    updateUserDashboardMobileDialog,
    setPlatform,
    _init,
    setProfileDrawerActive
}

