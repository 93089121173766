<template>
    <v-dialog
        v-if="isCurrencyEquals"
        v-model="isActive"
        max-width="600"
        dark
    >
        <template v-slot:activator="props">
            <slot name="activator" v-bind="props" />
        </template>

        <v-card class="pb-2">
            <v-card-title class="headline">
                {{ $t("dashboard.sections.bonuses.freebetDetails") }}
            </v-card-title>

            <v-card-text class="d-flex align-center mb-2">
                <div class="text-subtitle-1">{{ $t('dashboard.sections.bonuses.couponAmount') }}:</div>
                <div class="text-subtitle-1 font-weight-bold ml-2">
                    {{ bonus.settings.freebet_coupon_amount / 100 }}
                </div>
            </v-card-text>

            <v-card-text class="d-flex align-center mb-2">
                <div class="text-subtitle-1">{{ $t('dashboard.sections.bonuses.minimumOddsForSingleBets') }}:</div>
                <div class="text-subtitle-1 font-weight-bold ml-2">
                    {{ bonus.settings.single_bet }}
                </div>
            </v-card-text>

            <v-card-text class="d-flex align-center mb-2">
                <div class="text-subtitle-1">
                    {{ $t('dashboard.sections.bonuses.minimumOddsForMultipleBets') }}:
                </div>
                <div class="text-subtitle-1 font-weight-bold ml-2">
                    {{ bonus.settings.multiple_bet }}
                </div>
            </v-card-text>

            <v-card-text class="d-flex align-center mb-2">
                <div class="text-subtitle-1">{{ $t('dashboard.sections.bonuses.notice') }}:</div>
                <div class="text-subtitle-1 font-weight-bold ml-2">
                    {{ $t('dashboard.sections.bonuses.freebetNotice') }}
                </div>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="tile-gradient-dark"
                    text
                    @click="isActive = false"
                >
                    {{ $t("common.close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import breaks from "@/mixins/breaks";
import bonuses from "@/mixins/bonuses";
import RequestObject from "@/helpers/RequestObject";
import {mapState} from "vuex";
export default {
    name: 'BonusModalFreebet',
    mixins: [breaks, bonuses],
    model: {
        prop: 'value',
        event: 'update:value'
    },
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },
    data: () => {
        return {
            request: null,
        }
    },
    computed: {
        isActive: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('update:value', val);
            }
        },

        ...mapState({
            bonusCurrency: state => state.user.bonus.data.currency
        }),

        isCurrencyEquals() {
            return this.$auth.user().fiatBalance.currency === this.bonusCurrency
        }

    },
    created() {
        this.request = new RequestObject(this.getFreebetInfo, this.bonus);
        this.request.fetch();
    }
}
</script>
