export const STATUS = {
    PENDING: 'pending',
    LOADING: 'loading',
    SUCCESS: 'success',
    ERROR: 'error'
};

export default class RequestObject {

    constructor(fn, ...defaultParams) {
        this.fn = fn;
        this.defaultParams = defaultParams;
        this.status = STATUS.PENDING;
        this.error = null;
        this.data = null;
    }


    async fetch(...params) {
        try {
            this.status = STATUS.LOADING;
            this.error = null;
            this.data = await this.fn(...this.defaultParams, ...params);
            this.status = STATUS.SUCCESS;
        } catch (e) {
            this.status = STATUS.ERROR;
            this.data = null;
            this.error = e;

            // throw new Error(e);
        }
    }

    get isSuccess() {
        return this.status === STATUS.SUCCESS;
    }

    get isLoading() {
        return [STATUS.LOADING].includes(this.status);
    }

    get isError() {
        return this.status === STATUS.ERROR;
    }
}
