import countries from '@/configs/countries';
import currencies from "@/configs/currencies";
import fieldValidators from "@/helpers/fieldValidators";

export default [
    {
        name: "username",
        type: "input",
        icon: "mdi-clipboard-account-outline",
        value: "",
        label: "auth.register.fields.username.label",
        rules:
            [
                {
                    validator: fieldValidators.required,
                    message: 'form.errors.required'
                },
            ],
        disabled: true,
        cols: {
            cols: 12,
            sm: 6
        }
    },
    {
        name: "email",
        type: "email",
        icon: "mdi-email-open-outline",
        value: "",
        label: "auth.register.fields.email.label",
        rules: [fieldValidators.required, fieldValidators.email],
        disabled: true,
        cols: {
            cols: 12,
            sm: 6
        }
    },
    {
        name: "firstname",
        type: "input",
        icon: "",
        value: "",
        label: "auth.register.fields.firstname.label",
        rules:
            [
                {
                    validator: fieldValidators.required,
                    message: 'form.errors.required'
                },
                {
                    validator: fieldValidators.lettersOnly,
                    message: 'form.errors.lettersOnly'
                }
            ],
        disabled: false,
    },
    {
        name: "lastname",
        type: "input",
        icon: "",
        value: "",
        label: "auth.register.fields.lastname.label",
        rules:
            [
                {
                    validator: fieldValidators.required,
                    message: 'form.errors.required'
                },
                {
                    validator: fieldValidators.lettersOnly,
                    message: 'form.errors.lettersOnly'
                }
            ],
        disabled: false,
    },
    {
        name: "gender",
        type: "radio",
        icon: "",
        value: "",
        values: [
            {
                value: 0, //@todo : wrong gender value must 1
                label: 'auth.register.fields.gender.values.male',
            },
            {
                value: 1,
                label: 'auth.register.fields.gender.values.female',
            },
        ],
        label: "auth.register.fields.gender.label",
        rules:
            [
                {
                    validator: fieldValidators.required,
                    message: 'form.errors.required'
                },
            ],
        disabled: false,
    },


    // {
    //     icon: "mdi-card-account-details-outline",
    //     value: "",
    //     label: "dashboard.sections.detail.blocks.myMembershipInformation.form.fields.id",
    //     name: "identification_number",
    //     rules: [fieldValidators.required],
    //     disabled: true,
    // },
    {
        name: "birthday",
        type: "date",
        icon: "",
        value: "",
        label: "auth.register.fields.birthday.label",
        rules:
            [
                {
                    validator: fieldValidators.required,
                    message: 'form.errors.required'
                },
            ],
        disabled: false,
    },
    {
        name: "phone",
        type: "phone",
        icon: "",
        value: "",
        label: "auth.register.fields.phone.label",
        rules:
            [
                {
                    validator: fieldValidators.required,
                    message: 'form.errors.required'
                },
                // {
                //     validator: fieldValidators.phone,
                //     message: 'form.errors.phone'
                // },
            ],
        disabled: false,
    },
    {
        name: "passport",
        type: "input",
        icon: "mdi-passport",
        value: "",
        label: "auth.register.fields.passport.label",
        rules:
            [
                {
                    validator: fieldValidators.required,
                    message: 'form.errors.required'
                },
            ],
        disabled: false,
    },
    {
        name: "address",
        type: "input",
        icon: "",
        value: "",
        label: "auth.register.fields.address.label",
        rules:
            [
                {
                    validator: fieldValidators.required,
                    message: 'form.errors.required'
                },
            ],
        disabled: false,
    },
    {
        name: "city",
        type: "input",
        icon: "",
        value: "",
        label: "auth.register.fields.city.label",
        rules:
            [
                {
                    validator: fieldValidators.required,
                    message: 'form.errors.required'
                },
            ],
        disabled: false,
    },
    {
        name: "state",
        type: "input",
        icon: "",
        value: "",
        label: "auth.register.fields.state.label",
        rules:
            [
                {
                    validator: fieldValidators.required,
                    message: 'form.errors.required'
                },
            ],
        disabled: false,
    },
    {
        name: "zipcode",
        type: "input",
        icon: "",
        value: "",
        label: "auth.register.fields.zipcode.label",
        rules:
            [
                {
                    validator: fieldValidators.required,
                    message: 'form.errors.required'
                },
            ],
        disabled: false,
    },
    {
        name: "country",
        type: "autocomplete",
        icon: "",
        value: "",
        values: countries.countries,
        autocomplete: {
            text: 'name',
            value: 'alpha2Code'
        },
        label: "auth.register.fields.country.label",
        rules:
            [
                {
                    validator: fieldValidators.required,
                    message: 'form.errors.required'
                },
            ],
        disabled: false,
    },
    {
        name: "currency",
        type: "select",
        icon: "",
        value: "",
        values: currencies.availableCurrencies,
        filters: ['currency'],
        map: (c) => ({
            text: c.label,
            value: c.label
        }),
        label: "auth.register.fields.currency.label",
        rules:
            [
                {
                    validator: fieldValidators.required,
                    message: 'form.errors.required'
                },
            ],
        disabled: true,
    },
]
