<template>
  <v-list nav dense class="transparent">
    <div>
      <div class="pa-1 overline white--text">
          {{ $t('menu.pages') }}
      </div>
      <nav-menu :menu="items" @drawerClose="$emit('drawerClose')" />
    </div>
  </v-list>
</template>

<script>
import NavMenu from './NavMenu';
import {mapState} from "vuex";

export default {
  components: {
    NavMenu
  },
  computed: {
    ...mapState({
      items: state => state.app.controlContents.hasOwnProperty('mobileMenu') ? state.app.controlContents.mobileMenu.items : []
    })
  }
}
</script>
