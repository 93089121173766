<template>
    <v-menu
        nudge-width="200"
        nudge-bottom="32"
        nudge-left="100"
        dark
    >
        <template v-slot:activator="props">
            <slot name="activator" v-bind="props"/>
        </template>
        <template>
            <v-card class="pa-4">
                <div class="text-subtitle-2 mb-2 text-uppercase">
                    {{ bonus.name }}
                </div>
                <div class="d-flex align-center" v-if="!isFreespinIsRealMoney">
                    <v-progress-linear
                        :value="progressValue"
                        height="25"
                    >
                        <strong>{{ textValue }}</strong>
                    </v-progress-linear>
                </div>
            </v-card>
        </template>
    </v-menu>
</template>
<script>

import breaks from "@/mixins/breaks";
import bonuses from "@/mixins/bonuses";
import {mapState} from "vuex";
export default {
    name: 'BonusModalDeposit',
    mixins: [breaks, bonuses],
    model: {
        prop: 'value',
        event: 'update:value'
    },
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },
    data: () => {
        return {
            request: null,
            isPendingCancelBonus: false,
        }
    },
    computed: {
        ...mapState({
            bonus_target: state => state.user.bonus_target,
            isShowBonusProgressInAmount: state => state.app.brandSettings?.is_show_bonus_progress_in_amount ?? false,
            disallowPlayerCancelBonus: state => state.app.brandSettings?.disallow_player_cancel_bonus,
        }),
        isActive: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('update:value', val);
            }
        },

        isFreespinIsRealMoney() {
            return this.bonus && this.bonus?.settings?.freespin_is_real_money;
        },
        bonusMoneyData() {
            return this.bonus ? {
                rollover: this.bonus.settings ? this.bonus.settings.rollover_requirement : 1,
                finalAmount: this.bonus.amount_to_process,
                currentAmount: this.bonus.amount_processed
            } : null;
        },
        bonusCycleCompleted() {
            const data = this.bonusMoneyData;
            if (!data) return null;
            return data.finalAmount === 0 ? 0 :
                ((data.currentAmount / data.finalAmount) * 100).toFixed(1);
        },
        progressValue() {
            if(!this.bonus) return 0;
            return parseInt(this.bonus_target === 0 ? this.bonusCycleCompleted : this.bonus_target);
        },
        textValue() {
            let value = 0;
            if(!this.bonus) return value;

            if (this.isShowBonusProgressInAmount) {
                const toProcess = this.bonus.amount_to_process;
                const processed = this.bonus_target > 0 ? toProcess * this.bonus_target / 100 : 0;
                const isCashFormatInt = (toProcess % 100 === 0) && (processed % 100 === 0);
                value = this.formatCash(processed, isCashFormatInt) + ' / ' + this.formatCash(toProcess, isCashFormatInt) + ' ' + this.getCurrencyLabel(this.bonus.currency);
            } else {
                value = this.bonus_target === 0 ? this.bonusCycleCompleted : this.bonus_target;
                value += '%';
            }
            return value
        },
    },
    methods: {
        getCurrencyLabel(val) {
            return val;
        },
        formatCash(val, intFormat = false) {
            const res = val / 100;
            if (intFormat) {
                return parseInt(res);
            }
            return res.toFixed(2);
        }
    }
}
</script>
