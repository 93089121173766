export default [
  {
    path: '/error/not-found',
    name: 'error-not-found',
    component: () => import(/* webpackChunkName: "error-not-found" */ '@/pages/error/NotFoundPage.vue'),
    meta: {
      layout: 'error'
    }
  },
  {
    path: '/error/access-denied',
    name: 'error-access-denied',
    component: () => import(/* webpackChunkName: "error-access-denied" */ '@/pages/error/AccessDeniedPage.vue'),
    meta: {
      layout: 'error'
    }
  },
  {
    path: '/error/unexpected',
    name: 'error-unexpected',
    component: () => import(/* webpackChunkName: "error-unexpected" */ '@/pages/error/UnexpectedPage.vue'),
    meta: {
      layout: 'error'
    }
  }
]
