export default [
  { icon: 'mdi-soccer', key: 'menu.sportsBook', text: 'Sports Book', regex: /^\/sports-book/, mode: 'tiles',
    items: [
      { icon: 'mdi-soccer', key: 'menu.soccer', text: 'Soccer', link: '/sports#Sport/1' },
      { icon: 'mdi-basketball', key: 'menu.basketball', text: 'Basketball', link: '/sports#Sport/4' },
      { icon: 'mdi-football', key: 'menu.football', text: 'Football', link: '/sports#Sport/6' },
      { icon: 'mdi-tennis-ball', key: 'menu.tennis', text: 'Tennis', link: '/sports#Live/3' },
      { icon: 'mdi-volleyball', key: 'menu.volleyball', text: 'Volleyball', link: '/sports-book/volleyball' },
      { icon: 'mdi-baseball', key: 'menu.baseball', text: 'Baseball', link: '/sports-book/baseball' },
      { icon: 'mdi-billiards', key: 'menu.billiard', text: 'Billiard', link: '/sports#Sport/75' },
      { icon: 'mdi-hockey-sticks', key: 'menu.hockey', text: 'Hockey', link: '/sports#Sport/10' },
      { icon: 'mdi-boxing-glove', key: 'menu.boxing', text: 'Boxing', link: '/sports-book/boxing' },
    ]
  },
//   { icon: 'mdi-file-cog-outline', key: 'menu.utilityPages', text: 'Utility Pages', regex: /^\/utility/, mode: 'list',
//     items: [
//       { icon: 'mdi-file-outline', key: 'menu.utilityMaintenance', text: 'Maintenance', link: '/utility/maintenance' },
//       { icon: 'mdi-file-outline', key: 'menu.utilitySoon', text: 'Coming Soon', link: '/utility/coming-soon' },
//       { icon: 'mdi-file-outline', key: 'menu.utilityHelp', text: 'FAQs / Help', link: '/utility/help' }
//     ]
//   },
//   { icon: 'mdi-file-lock-outline', key: 'menu.auth', text: 'Auth Pages', regex: /^\/auth/, mode: 'list',
//     items: [
//       { icon: 'mdi-file-outline', key: 'menu.authLogin', text: 'Signin / Login', link: '/auth/signin' },
//       { icon: 'mdi-file-outline', key: 'menu.authRegister', text: 'Signup / Register', link: '/auth/signup' },
//       { icon: 'mdi-file-outline', key: 'menu.authVerify', text: 'Verify Email', link: '/auth/verify-email' },
//       { icon: 'mdi-file-outline', key: 'menu.authForgot', text: 'Forgot Password', link: '/auth/forgot-password' },
//       { icon: 'mdi-file-outline', key: 'menu.authReset', text: 'Reset Password', link: '/auth/reset-password' }
//     ]
//   }
]
