<template>
    <v-card class="pa-0 toolbar-left-menu">
        <v-card-title class="pa-4 pt-3 pb-2" style="color: white">
            {{ $t("dashboard.sidebarParameters.welcome") }}, {{ fullName }}
        </v-card-title>

        <hr class="toolbar-left-menu__hr">

        <toolbar-left-menu-bonus class="px-4 pt-4 pb-3"/>

        <v-card
            class="d-flex justify-space-between py-4"
            flat
        >

            <v-btn
                class="button--primary flex-grow-1"
                width="50%"
                tile
                @click="showWallet('withdrawal')"
            >
                {{ $t("dashboard.sidebarParameters.withdraw") }}
            </v-btn>

            <v-btn
                class="button--primary-outlined flex-grow-1"
                width="50%"
                tile
                :rounded="false"
                @click="showWallet('deposit')"
            >
                {{ $t("dashboard.sidebarParameters.deposit") }}
            </v-btn>
        </v-card>

        <div class="toolbar-left-menu-balance pt-1">
            <div class="toolbar-left-menu__balance-items">
                <div class="toolbar-left-menu__balance-item">
                    <div class="toolbar-left-menu-balance-card">
                        <div class="toolbar-left-menu-balance-card-balance">
                            {{ balance }}
                        </div>
                        <div class="toolbar-left-menu-balance-card-label">
                            {{ $t("dashboard.sidebarParameters.balance") }}
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column justify-space-around">
                    <div class="pb-4">
                        <v-btn
                            icon small
                            @click="refreshBalance"
                        >
                            <custom-refresh-icon
                                :rotate="loadingBalance"
                                size="16"
                            />
                        </v-btn>
                    </div>
                </div>
                <div class="toolbar-left-menu__balance-item">
                    <div class="toolbar-left-menu-balance-card">
                        <div class="toolbar-left-menu-balance-card-balance">
                            {{ bonusBalance }}
                        </div>
                        <div class="toolbar-left-menu-balance-card-label">
                            {{ $t("dashboard.sidebarParameters.bonus") }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <v-list class="pa-0 toolbar-left-menu__list" nav dense>
            <template v-for="(item, itemIndex) in items">
                <v-list-group
                    v-if="item.type === 'dropdown' && item.items.length"
                    class="toolbar-left-menu__list-group"
                    :key="itemIndex"
                    :value="item.items.some(i => i.link === $route.path || i.link === activePath)"
                >
                    <template v-slot:activator>
                        <v-list-item-title class="d-flex gap-2 flex-row align-center">
                            <v-icon size="16">{{ item.icon}}</v-icon>
                            <div>{{ $t(item.title) }}</div>
                        </v-list-item-title>
                    </template>

                    <v-list-item
                        v-for="(subItem, subItemIndex) in item.items"
                        :key="`${itemIndex}-${subItemIndex}`"
                        :to="subItem.link"
                        @click="linkClick(subItem)"
                        :class="{'list-active': activePath === subItem.link}"
                        :input-value="subItem.link && activePath === subItem.link"
                    >
                        <v-list-item-title>
                            <span class="d-inline-block">{{ $t(subItem.title) }}</span>
                        </v-list-item-title>
                    </v-list-item>
                </v-list-group>

                <v-list-item
                    v-else-if="item.type === 'messages'"
                    :key="'item'-itemIndex"
                    :to="item.link"
                    @click="linkClick(item)"
                    :class="{'list-active': activePath === item.link}"
                    :input-value="activePath === item.link"
                >

                    <v-list-item-title class="d-flex gap-2 flex-row align-center">
                        <v-icon v-text="item.icon" size="16"></v-icon>
                        <span>{{ $t(item.title) }}</span>
                    </v-list-item-title>
                </v-list-item>

                <v-list-item
                    v-else
                    :key="itemIndex"
                    :to="item.link"
                    @click="linkClick(item)"
                    :class="{'list-active': activePath === item.link}"
                    :input-value="activePath === item.link"
                >

                    <v-list-item-title class="d-flex gap-2 flex-row align-center">
                        <v-icon v-text="item.icon" size="16"></v-icon>
                        <span>{{ $t(item.title) }}</span>
                    </v-list-item-title>

                </v-list-item>

            </template>

            <v-list-item
                color="gray-2"
                @click="logout"
            >
                <v-list-item-title class="d-flex gap-2 flex-row align-center">
                    <v-icon size="16">custom-logout</v-icon>
                    <span>{{ $t("menu.logout") }}</span>
                </v-list-item-title>

            </v-list-item>
        </v-list>
    </v-card>
</template>

<script>

import {mapState, mapActions} from "vuex";
import Items from "@/configs/menus/user.menu"
import InMenuBanner from "@/components/user/InMenuBanner.vue";
import CustomRefreshIcon from "@/components/ui/CustomRefreshIcon.vue";
import ToolbarLeftMenuBonus from "@/components/user/ToolbarLeftMenuBonus.vue";

export default {
    components: {ToolbarLeftMenuBonus, CustomRefreshIcon, InMenuBanner},
    props: {
        activePath: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        selectedItem: 0,
        items: Items,
        loadingBalance: false,
        balancePlaceholder: '***.**'
    }),
    computed: {
        balance() {
            return this.hideBalance ? this.balancePlaceholder : this.currencySymbol + this.userAmount;
        },
        bonusBalance() {
            return this.hideBalance ? this.balancePlaceholder : this.currencySymbol + this.userBonusAmount;
        },
        currencySymbol() {
            return this.currency?.currencySymbol ? this.currency.currencySymbol : this.$auth.user().currency;
        },
        currency() {
            return this.availableCurrencies.find(c => {
                return c.label === this.$auth.user().currency
            });
        },
        fullName() {
            const {firstname, lastname, username} = this.$auth.user()
            return firstname && lastname ? `${firstname} ${lastname}` : username
        },
        ...mapState({
            userAmount: state => state.user.amount,
            userBonusAmount: state => state.user.bonus_amount,
            userTotalAmount: state => state.user.total_amount,
            availableCurrencies: state => state.app.availableCurrencies,
            hideBalance: state => state.user.hide_balance,
        }),
    },
    methods: {
        ...mapActions({
            setBalance: 'user/setBalance',
            toggleVisibilityBalance: 'user/toggleVisibilityBalance',
            makeActiveWindow: 'app/makeActiveWindow',
        }),
        async refreshBalance() {
            this.loadingBalance = true;
            await this.$auth.fetch();
            this.setBalance({
                amount: this.$auth.user().amount,
                amount_bonus: this.$auth.user().amount_bonus
            });
            this.loadingBalance = false;
        },
        linkClick(item = null) {
            if (item && item.function) {
                item.function(this);
            }
            this.$emit('click');
        },
        showWallet(tab) {
            this.makeActiveWindow({type: 'wallet', active: tab});
            this.linkClick();
        },
        async logout() {
            this.$emit('click')
            await this.$auth.logout({});
            this.$store.commit('user/setLogin', false)
        }
    },
};
</script>

<style lang="scss">
.toolbar-left-menu {
    width: 325px;
    max-width: 100vw;
    background-color: var(--v-header-base) !important;
    //border: 1px solid var(--v-primary-base) !important;
    border-radius: 5px !important;

    .v-sheet {
        background-color: var(--v-header-base) !important;
    }

    .v-card__title {
        color: var(--v-gray-2-base);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }

    .toolbar-left-menu-balance-card {
        margin-bottom: 20px;
    }

    .toolbar-left-menu-balance-card-balance {
        color: #fff;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    .toolbar-left-menu-balance-card-label {
        color: var(--v-gray-2-base);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }

    //.v-list-item--active {
    //    background-color: var(--v-gray-3-base);
    //    opacity: 1;
    //}

    .v-list-group__header {
        background-color: transparent;
        //border-top: 1px solid var(--v-primary-darken2);
    }

    .v-list-item--active:before {
        //background-color: var(--v-gray-3-base);
        opacity: 0;
    }

    .toolbar-left-menu-messages-counter {
        height: 18px;
        padding: 0 9px;
        background-color: var(--v-primary-base);
        border-radius: 4px;

        color: var(--v-background-base);
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .toolbar-left-menu__hr {
        background-color: var(--v-primary-darken2);
        height: 1px;
        margin: 0 16px;
        border: none;
    }

    .toolbar-left-menu__balance-items {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        gap: 8px;
        margin: 0 16px;
    }

    .toolbar-left-menu__balance-item {
        display: flex;
        justify-content: center;
    }

    .toolbar-left-menu__list {
        & > *:not(:last-child) {
            position: relative;

            &:after {
                content: '';
                display: block;
                position: absolute;
                height: 1px;
                min-height: 1px;
                background-color: var(--v-primary-darken2);
                margin: 0 16px;
                border: none;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }

        .v-list-item__title{
            color: white !important;
        }

        .v-list-group--active,
        .v-list-group--active .v-list-item__title {
            background-color: var(--v-secondary-base);
            color: var(--v-background-base) !important;
        }

        .v-list-item--active .v-list-item__title span:after {
            content: "";
            display: block;
            background-color: var(--v-primary-base);
            height: 1px;
        }

        .v-list-item__icon {
            margin-right: 4px !important;
        }

        .v-list-item {
            margin-bottom: 0 !important;
        }
    }

    .toolbar-left-menu__list-group {
        //padding-bottom: 8px;
        //background-color: var(--v-secondary-base);
        //color: var(--v-background-base);

        //.v-icon {
        //    width: 20px;
        //    margin-right: 8px !important;
        //    color: var(--v-background-base);
        //}

        //.v-list-item {
        //    border-bottom: none;
        //}

        .v-list-group__items .v-list-item__title {
            padding-left: 30px !important;
        }

        //.v-list-item, .v-list-item--active {
        //    min-height: auto !important;
        //    padding: 8px 48px;
        //    padding-right: 20px;
        //}
        //.v-list-item:before {
        //    color: var(--v-secondary-lighten4) !important;
        //}
        //
        //.v-list-item--active:hover:before {
        //    opacity: 0.08 !important;
        //}
        //
        //.v-list-item, .v-list-item--active, .v-list-item__title {
        //    color: var(--v-background-base);
        //    background-color: var(--v-secondary-base);
        //}

        //.toolbar-left-menu__list-group-title {
        //    display: flex;
        //    align-items: center;
        //    padding: 12px 20px;
        //    font-size: 0.875rem;
        //    font-weight: 600;
        //    line-height: 1rem;
        //}

    }

    .gap-2 {
        gap: 8px;
    }

}
</style>
