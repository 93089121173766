import Model from './Model';
import i18n from "../plugins/vue-i18n";


export default class GameCategory extends Model {
    // Set the resource route of the model
    resource() {
        return 'games/categories'
    }

    async request(config) {
        // config.params = {lang: i18n.locale};

        return await this.$http.request(config)
    }
}
